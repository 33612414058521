import { getAdvertiserAccounts } from '@/services/atosa-tiktok-ads/advertiser-account'
import { getTiktokAccounts } from '@/services/atosa-tiktok-ads/tiktok-account'
import { createTemplateName } from '@/services/atosa-tiktok-ads/tool'

const selectedTiktokBusinessMixin = {
    data() {
        return {
            m__fetching_ads_accounts: false,
            m__ads_accounts: [],
            m__selected_ads_account: null,
            m__selected_ads_account_list: [],
            m__ads_accounts_by_bc: []
        }
    },

    computed: {
        m__selected_tiktok_business() {
            return this.$store.getters.tiktokBusiness
                .s__selected_tiktok_business
        },
        m__selected_business_center() {
            console.log('====================================')
            console.log(
                this.$store.getters.businessCenter.s__selected_business_center,
                'selectedTiktokBusinessMixin m__selected_business_center'
            )
            console.log('====================================')
            return this.$store.getters.businessCenter
                .s__selected_business_center
        }
    },

    watch: {
        m__ads_accounts() {
            if (!this.m__ads_accounts.length) {
                return
            }

            if (this.$route.query.advertiser_id) {
                const temp = this.m__ads_accounts.find(
                    (item) =>
                        item.advertiser_id === this.$route.query.advertiser_id
                )

                if (temp) {
                    this.m__selected_ads_account = temp
                    return
                }
            }

            const id_ads_acc = localStorage.getItem(
                `id_ads_account_${this.m__selected_tiktok_business.id}`
            )
            if (!id_ads_acc) {
                this.m__selected_ads_account = this.m__ads_accounts[0]
                localStorage.setItem(
                    `id_ads_account_${this.m__selected_tiktok_business.id}`,
                    this.m__selected_ads_account.advertiser_id
                )

                return
            }

            const account = this.m__ads_accounts.find(
                (item) => item.advertiser_id === id_ads_acc
            )
            if (!account) {
                localStorage.removeItem(
                    `id_ads_account_${this.m__selected_tiktok_business.id}`
                )
                this.m__selected_ads_account = this.m__ads_accounts[0]
                localStorage.setItem(
                    `id_ads_account_${this.m__selected_tiktok_business.id}`,
                    this.m__selected_ads_account.advertiser_id
                )

                return
            }

            this.m__selected_ads_account = account
        },
        m__selected_business_center() {
            this.m__selected_ads_account_list = []
        }
    },

    methods: {
        async m__fetchAdsAccountsByTiktokBusiness() {
            if (
                !this.m__selected_tiktok_business ||
                !this.m__selected_tiktok_business.id ||
                this.m__ads_accounts.length > 0
            ) {
                return
            }

            this.m__fetching_ads_accounts = true

            try {
                const response = await getAdvertiserAccounts(
                    this.m__selected_tiktok_business.id,
                    ''
                )
                if (response.code === 0 && response.data.length > 0) {
                    // this.m__ads_accounts = response.data.filter(
                    //     (item) => item.belong_to_atosa
                    // )
                    this.m__ads_accounts = response.data
                }
            } catch (error) {
                console.error(error)
            }
            this.m__fetching_ads_accounts = false
        },
        async m__fetchAdsAccountsByBusinessCenter(bc_id) {
            if (!bc_id) {
                return
            }

            this.m__fetching_ads_accounts = true

            try {
                const response = await getAdvertiserAccounts(
                    this.m__selected_tiktok_business.id,
                    bc_id
                )
                if (response.code === 0 && response.data.length > 0) {
                    // this.m__ads_accounts = response.data.filter(
                    //     (item) => item.belong_to_atosa
                    // )
                    this.m__ads_accounts_by_bc = response.data
                }
            } catch (error) {
                console.error(error)
            }
            this.m__fetching_ads_accounts = false
        },

        m__handleChangeAdsAccount(value) {
            this.m__selected_ads_account = value

            // localStorage.setItem(
            //     `id_ads_acc_${this.m__selected_tiktok_business.id}`,
            //     value.advertiser_id
            // )
            // window.location = window.location.pathname
        },

        m__handleChangeAdsAccountList(value) {
            this.m__selected_ads_account_list = value

            if (
                this.m__selected_ads_account_list &&
                this.m__selected_ads_account_list.length > 0
            ) {
                this.m__selected_ads_account =
                    this.m__selected_ads_account_list[0]
            }
            // localStorage.setItem(
            //     `id_ads_acc_${this.m__selected_tiktok_business.id}`,
            //     value.advertiser_id
            // )
            // window.location = window.location.pathname
        },

        async m__fetchTiktokBusinesses() {
            const response = await getTiktokAccounts()

            if (response.code === 0 && response.data.is_first) {
                await createTemplateName({
                    config_name: 'Template name adgroup 1',
                    field_separator: '-',
                    fields: [
                        {
                            id: this.p__renderId(),
                            key: 'location_ids',
                            value: ''
                        },
                        { id: this.p__renderId(), key: 'languages', value: '' },
                        { id: this.p__renderId(), key: 'gender', value: '' }
                    ],
                    type: 'adgroup'
                })
                await createTemplateName({
                    config_name: 'Template name adgroup 2',
                    field_separator: '_',
                    fields: [
                        {
                            id: this.p__renderId(),
                            key: 'operating_systems',
                            value: ''
                        },
                        {
                            id: this.p__renderId(),
                            key: 'optimization_goal',
                            value: ''
                        }
                    ],
                    type: 'adgroup'
                })
                await createTemplateName({
                    config_name: 'Template name campaign 1',
                    field_separator: '_',
                    fields: [
                        {
                            id: this.p__renderId(),
                            key: 'objective_type',
                            value: ''
                        },
                        {
                            id: this.p__renderId(),
                            key: 'budget',
                            value: ''
                        },
                        {
                            id: this.p__renderId(),
                            key: 'time',
                            value: ''
                        }
                    ],
                    type: 'campaign'
                })
            }

            if (response.code === 0 && response.data.list.length > 0) {
                const tiktok_businesses = response.data.list

                await this.$store.dispatch(
                    'tiktokBusiness/s__setTiktokBusinesses',
                    tiktok_businesses
                )
            } else {
                await this.$store.dispatch(
                    'tiktokBusiness/s__setTiktokBusinesses',
                    []
                )
                // this.$router.push({
                //     path: `${this.p__group_route.ads_manager}/${this.p__route.tiktok_business}/connect`
                // })
            }
        }
    }
}

export default selectedTiktokBusinessMixin
