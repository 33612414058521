<template>
    <div v-if="!item.hidden || (item.is_admin && $store.state.user.s__is_admin)">
        <template
            v-if="
                hasOneShowingChild(item.children, item) &&
                (!onlyOneChild.children || onlyOneChild.noShowingChildren) &&
                !item.alwaysShow
            "
        >
            <InternalLink
                v-if="onlyOneChild.meta"
                :to="resolvePath(onlyOneChild.path)"
            >
                <el-menu-item
                    :index="resolvePath(onlyOneChild.path)"
                    class="flex items-center"
                >
                    <InternalIcon
                        :icon="
                            onlyOneChild.meta.icon ||
                            (item.meta && item.meta.icon)
                        "
                        :title="onlyOneChild.meta.title"
                    />
                </el-menu-item>
            </InternalLink>
        </template>
        <template v-else>
            <template v-if="item.isGroup">
                <p
                    v-if="!collapse"
                    class="uppercase mt-4 mb-2 text-sm font-semibold ml-6"
                >
                    {{ item.name }}
                </p>
                <el-menu-item-group v-if="collapse">
                    <sidebar-item
                        v-for="child in item.children"
                        :key="child.path"
                        :is-nest="true"
                        :item="child"
                        :base-path="resolvePath(child.path)"
                    />
                </el-menu-item-group>
                <sidebar-item
                    v-for="child in item.children"
                    v-else
                    :key="child.path"
                    :is-nest="true"
                    :item="child"
                    :base-path="resolvePath(child.path)"
                />
            </template>
            <template v-else>
                <el-submenu
                    ref="subMenu"
                    :index="resolvePath(item.path)"
                    popper-append-to-body
                >
                    <template slot="title">
                        <InternalIcon
                            v-if="item.meta"
                            :icon="item.meta && item.meta.icon"
                            :title="item.meta.title"
                        />
                    </template>
                    <sidebar-item
                        v-for="child in item.children"
                        :key="child.path"
                        :is-nest="true"
                        :item="child"
                        :base-path="resolvePath(child.path)"
                    />
                </el-submenu>
            </template>
        </template>
    </div>
</template>

<script>
import path from 'path'
import InternalIcon from './icon'
import InternalLink from './link'

export default {
    name: 'SidebarItem',
    components: { InternalIcon, InternalLink },
    props: {
        // route object
        item: {
            type: Object,
            required: true
        },
        isNest: {
            type: Boolean,
            default: false
        },
        basePath: {
            type: String,
            default: ''
        },
        collapse: {
            type: Boolean,
            required: false
        }
    },
    data() {
        this.onlyOneChild = null
        return {}
    },

    mounted() {
        this.fixBugIniOS()
    },
    methods: {
        hasOneShowingChild(children = [], parent) {
            let hide = false
            const showingChildren = children.filter((item) => {
                if (item.isNotShow) {
                    hide = true
                }
                if (item.hidden) {
                    return false
                } else {
                    // Temp set(will be used if only has one showing child)
                    this.onlyOneChild = item
                    return true
                }
            })
            // When there is only one child router, the child router is displayed by default
            if (showingChildren.length === 1) {
                return hide
            }

            // Show parent if there are no child router to display
            if (showingChildren.length === 0) {
                this.onlyOneChild = {
                    ...parent,
                    path: '',
                    noShowingChildren: true
                }
                return true
            }

            return false
        },

        resolvePath(routePath) {
            if (this.isExternal(routePath)) {
                return routePath
            }
            if (this.isExternal(this.basePath)) {
                return this.basePath
            }
            return path.resolve(this.basePath, routePath)
        },

        isExternal(path) {
            return /^(https?:|mailto:|tel:)/.test(path)
        },

        fixBugIniOS() {
            const $subMenu = this.$refs.subMenu
            if ($subMenu) {
                const handleMouseleave = $subMenu.handleMouseleave
                $subMenu.handleMouseleave = (e) => {
                    handleMouseleave(e)
                }
            }
        }
    }
}
</script>
