var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-box w-full"},[_c('div',{staticClass:"flex justify-between"},[_c('p',{staticClass:"text-xs font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.ad_list'))+" "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('page.campaign.desc_max_create_xxx_ad', {
                        xxx: _vm.max_ad
                    }),"placement":"top"}},[_c('i',{staticClass:"header-icon el-icon-info text-desc-text"})])],1),_c('p',{staticClass:"text-xs font-semibold text-desc-text"},[_vm._v(" "+_vm._s(_vm.items.length)+" ")])]),(_vm.selected_item)?_c('ul',{staticClass:"border-t-[1px] border-b-[1px] border-border my-4 pb-3 pt-1 overflow-y-scroll max-h-[320px]"},[_vm._l((_vm.items_in_line),function(item){return _c('li',{key:item.extension.id,staticClass:"relative"},[_c('div',{staticClass:"flex mt-2 space-x-3 cursor-pointer overflow-hidden p-2 rounded border-[1px]",class:[
                    item.extension.id === _vm.selected_item.extension.id
                        ? 'border-brand-atosa'
                        : 'hover:border-border border-transparent'
                ],on:{"click":function($event){return _vm.handleSelectAd(item)}}},[_c('div',{staticClass:"min-w-[72px] w-[72px] h-[72px] bg-bg rounded overflow-hidden relative"},[(
                            _vm.objective_type !== 'PRODUCT_SALES' ||
                            _vm.selected_item.extension.shopping_ads_type !==
                                'LIVE' ||
                            _vm.selected_item.extension
                                .shopping_live_ad_setup !== 'live_video'
                        )?_c('div',{staticClass:"w-full h-full"},[(
                                item.extension.video &&
                                item.extension.video.poster_url
                            )?_c('img',{staticClass:"w-full h-full object-contain",attrs:{"src":item.extension.video.poster_url,"alt":""}}):_vm._e(),(
                                item.extension.video &&
                                item.extension.video.video_cover_url
                            )?_c('img',{staticClass:"w-full h-full object-contain",attrs:{"src":item.extension.video.video_cover_url,"alt":""}}):_vm._e()]):_vm._e(),_vm._m(0,true)]),(
                        _vm.objective_type !== 'PRODUCT_SALES' ||
                        _vm.selected_item.extension.shopping_ads_type !==
                            'LIVE' ||
                        _vm.selected_item.extension.shopping_live_ad_setup !==
                            'live_video'
                    )?_c('div',{staticClass:"flex-1 flex flex-col"},[_c('p',{staticClass:"text-xs font-semibold truncate w-[90%]"},[_vm._v(" "+_vm._s(item.ad_name ? item.ad_name : _vm.$t('common.name'))+" ")]),_c('p',{staticClass:"util-max-2-text-lines leading-3 text-desc-text text-xs font-semibold"},[_vm._v(" "+_vm._s(item.extension.video && item.extension.video.file_name ? item.extension.video.file_name : '')+" ")])]):_vm._e()]),_c('div',{staticClass:"flex mt-auto space-x-2 absolute bottom-3 left-[90px]"},[(
                        _vm.objective_type !== 'PRODUCT_SALES' ||
                        (_vm.objective_type === 'PRODUCT_SALES' &&
                            item.extension.shopping_ads_type === 'LIVE' &&
                            item.extension.shopping_live_ad_setup !==
                                'live_video') ||
                        (_vm.objective_type === 'PRODUCT_SALES' &&
                            item.extension.shopping_ads_type !== 'LIVE')
                    )?_c('i',{staticClass:"el-icon-copy-document cursor-pointer",on:{"click":function($event){return _vm.handleCloneAd(item)}}}):_vm._e(),(_vm.items_in_line.length > 1)?_c('i',{staticClass:"el-icon-delete cursor-pointer",on:{"click":function($event){return _vm.handleDeleteAd(item.extension.id)}}}):_vm._e()])])}),_c('li',{attrs:{"id":"bottom_select_ad"}})],2):_vm._e(),_c('div',{staticClass:"flex"},[(
                _vm.objective_type !== 'PRODUCT_SALES' ||
                (_vm.objective_type === 'PRODUCT_SALES' &&
                    _vm.items_in_line[0].extension.shopping_ads_type ===
                        'LIVE' &&
                    _vm.items_in_line[0].extension.shopping_live_ad_setup !==
                        'live_video') ||
                (_vm.objective_type === 'PRODUCT_SALES' &&
                    _vm.items_in_line[0].extension.shopping_ads_type !== 'LIVE')
            )?_c('el-button',{staticClass:"mx-auto",attrs:{"size":"small","disabled":_vm.items_in_line.length === _vm.max_ad},on:{"click":_vm.handleCreateNewAd}},[_vm._v(" "+_vm._s(_vm.$t('button.create'))+" ")]):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"},[_c('i',{staticClass:"el-icon-caret-right text-white text-xl"})])
}]

export { render, staticRenderFns }