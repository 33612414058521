import { asyncRoutes, constantRoutes } from '@/router'

const state = {
    routes: [],
    has_sync: false
}

const mutations = {
    SET_ROUTES: (state, routes) => {
        state.routes = constantRoutes.concat(routes)
        state.has_sync = true
    }
}

const actions = {
    generateRoutes({ commit }) {
        return new Promise((resolve) => {
            const listRouter = []
            asyncRoutes.forEach((element) => {
                const { children } = element

                if (children) {
                    let countSub = 0
                    const child = []
                    children.forEach((ele) => {
                        if (ele.children) {
                            let countSubChid = 0
                            const childOfChild = []
                            ele.children.forEach((d) => {
                                countSubChid++
                                childOfChild.push(d)
                            })
                            if (countSubChid > 0) {
                                countSub++
                                child.push({
                                    ...ele,
                                    children: childOfChild
                                })
                            }
                        } else {
                            countSub++
                            child.push(ele)
                        }
                    })
                    if (countSub > 0) {
                        listRouter.push({ ...element, children: child })
                    }
                } else {
                    listRouter.push(element)
                }
            })
            commit('SET_ROUTES', listRouter)
            resolve(listRouter)
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
