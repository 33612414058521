import { p__default_identity_image_uri, p__renderId } from './index'

export const p__default_state_infor_ad = {
    ad_format: 'SINGLE_VIDEO',
    ad_name: '',
    ad_text: '',
    ad_texts: null,
    app_name: '',
    avatar_icon_web_uri: '',
    call_to_action: '',
    call_to_action_id: null,
    card_id: '',
    catalog_id: '',
    click_tracking_url: '',
    creative_type: '',
    deeplink: '',
    deeplink_type: '',
    display_name: '',
    dynamic_destination: 'UNSET',
    dynamic_format: 'UNSET',
    external_action: 'ACTIVE',
    fallback_type: 'UNSET',
    identity_id: '',
    identity_type: 'TT_USER',
    identity_authorized_bc_id: '',
    image_ids: [],
    impression_tracking_url: '',
    is_aco: false,
    is_creative_authorized: false,
    is_new_structure: true,
    item_group_ids: [],
    landing_page_url: '',
    landing_page_urls: null,
    operation_status: 'ENABLE',
    page_id: null,
    playable_url: '',
    product_set_id: '',
    product_specific_type: 'PRODUCT_SET',
    profile_image: '',
    shopping_ads_deeplink_type: '',
    shopping_ads_fallback_type: '',
    shopping_ads_video_package_id: '',
    vast_moat_enabled: false,
    vertical_video_strategy: 'SINGLE_VIDEO',
    video_id: '',
    tiktok_item_id: '',
    extension: {
        id: () => p__renderId(),
        video: null,
        identity: {
            identity_id: '',
            identity_type: '',
            identity_authorized_bc_id: '',
            display_name: '',
            image_uri: p__default_identity_image_uri
        },
        shopping_live_ad_setup: '',
        shopping_ads_type: '',
        store_id: '',
        use_spark_ads: false,
        selected_products: []
    }
}
