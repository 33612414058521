<template>
    <div class="component-box">
        <p class="font-semibold mb-8">
            {{ $t('page.campaign.content_exclusions') }}
        </p>

        <p class="text-xs">
            {{ $t('page.campaign.desc_content_exclusions') }}
        </p>

        <div class="flex space-x-3 mt-4 mb-2">
            <div
                v-for="item in content_exclusion_options"
                :key="item.value"
                class="border-[1px] border-l-[4px] p-4 w-[280px] bg-bg rounded-lg"
                :class="[
                    selected_item_in_line.brand_safety_type === item.value
                        ? 'border-brand-atosa'
                        : 'hover:border-border border-transparent',
                    type_form !== 'create'
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer'
                ]"
                @click="handleSelect(item.value)"
            >
                <p class="font-semibold text-sm mb-2">
                    {{ item.label }}
                </p>
                <p class="component-text-desc">
                    {{ item.desc }}
                </p>
            </div>
        </div>

        <p class="component-text-desc leading-5">
            {{ $t('page.campaign.noti_desc_full_inventory_1') }}
            <learn-more-anchor
                href="https://ads.tiktok.com/help/article?aid=10011439"
            ></learn-more-anchor>
            <br />
            {{ $t('page.campaign.noti_desc_full_inventory_2') }}
        </p>
    </div>
</template>

<script>
export default {
    props: ['selected_item', 'type_form'],

    data() {
        return {
            content_exclusion_options: [
                {
                    label: this.$t('page.campaign.full_inventory'),
                    value: 'NO_BRAND_SAFETY',
                    desc: this.$t('page.campaign.desc_full_inventory')
                },
                {
                    label: this.$t('page.campaign.standard_inventory'),
                    value: 'STANDARD_INVENTORY',
                    desc: this.$t('page.campaign.desc_standard_inventory')
                },
                {
                    label: this.$t('page.campaign.limited_inventory'),
                    value: 'LIMITED_INVENTORY',
                    desc: this.$t('page.campaign.desc_limited_inventory')
                }
            ]
        }
    },

    computed: {
        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        }
    },

    mounted() {
        if (!this.selected_item_in_line?.brand_safety_type) {
            this.selected_item_in_line.brand_safety_type = 'STANDARD_INVENTORY'
        }
    },

    methods: {
        handleSelect(val) {
            if (this.type_form !== 'create') {
                return
            }

            this.selected_item_in_line.brand_safety_type = val
        }
    }
}
</script>
