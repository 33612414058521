<!-- eslint-disable vue/attribute-hyphenation -->
<template>
    <div class="relative">
        <slot></slot>

        <div
            class="absolute -top-1 h-3 -translate-y-1/2 left-3 px-1"
            :class="[bg]"
        >
            <p class="text-xxs">
                {{ label }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String
        },

        bg: {
            type: String,
            default: 'bg-white'
        }
    }
}
</script>
