import request from './_config'
const path = `file`

export function getVideos(tiktok_account_id, params) {
    return request({
        url: `${path}/video/${tiktok_account_id}`,
        method: 'get',
        params
    })
}

export function getInfoVideo(tiktok_account_id, params) {
    return request({
        url: `${path}/video/info/${tiktok_account_id}`,
        method: 'get',
        params: {
            advertiser_id: params.advertiser_id,
            video_ids: JSON.stringify(params.video_ids)
        }
    })
}

export function uploadImage(tiktok_account_id, formData) {
    return request({
        url: `${path}/image/${tiktok_account_id}`,
        method: 'post',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData
    })
}

export function uploadVideo(tiktok_account_id, formData) {
    return request({
        url: `${path}/video/${tiktok_account_id}`,
        method: 'post',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData
    })
}
