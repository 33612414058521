<template>
    <el-dialog
        :visible.sync="visible_in_line"
        width="560px"
        class="el-dialog-center"
        :append-to-body="true"
        :close-on-click-modal="true"
        :show-close="false"
    >
        <div
            class="w-[560px] h-[360px] mx-[-20px] mb-[-16px] mt-[-46px] bg-black"
        >
            <video
                v-if="preview_url || info_video"
                ref="video"
                class="w-full h-full"
                controls
                @loadeddata="playVideo"
            >
                <source :src="preview_url || info_video.preview_url" />
                Your browser does not support HTML video.
            </video>
        </div>
    </el-dialog>
</template>

<script>
import { getInfoVideo } from '@/services/atosa-tiktok-ads/file'

export default {
    props: [
        'visible',
        'advertiser_id',
        'tiktok_account_id',
        'video_id',
        'preview_url'
    ],

    data() {
        return {
            info_video: null
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        }
    },

    watch: {
        video_id() {
            this.handleGetInfoVideo()
        },

        visible_in_line() {
            if (this.visible_in_line) {
                if (this.$refs?.video) {
                    this.$refs.video.load()
                }
                this.playVideo()
            } else if (this.$refs.video) {
                this.$refs.video.pause()
            }
        }
    },

    methods: {
        playVideo() {
            if (this.$refs?.video) {
                this.$refs.video.play()
            }
        },

        async handleGetInfoVideo() {
            this.info_video = null

            if (!this.video_id) {
                return
            }

            try {
                const response = await getInfoVideo(this.tiktok_account_id, {
                    advertiser_id: this.advertiser_id,
                    video_ids: [this.video_id]
                })
                if (response.code === 0 && response.data?.list.length > 0) {
                    this.info_video = response.data.list[0]
                }
            } catch (error) {
                console.error(error)
            }
        }
    }
}
</script>
