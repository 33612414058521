import axios from 'axios'
import router from '@/router'
import { Message } from 'element-ui'
import i18n from '@/plugins/language'
import store from '@/store'

// create an axios instance
const instance = axios.create({
    baseURL: process.env.VUE_APP_ATOSA_TIKTOK_ADS_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 80_000 // request timeout
})

// request interceptor
instance.interceptors.request.use(
    (config) => {
        const s__end_domain = store.getters.app.s__end_domain || 'asia'

        config.headers['authorization'] = localStorage.getItem('token')
        config.headers['Accept-Language'] = localStorage.getItem('code_lang')
        config.headers['end-domain'] = s__end_domain

        return config
    },
    (error) => {
        // do something with request error
        console.error(error) // for debug
        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    (response) => {
        if (
            ['report/export_excel/', `report/export/excel`].some((item) =>
                response.config.url.includes(item)
            )
        ) {
            return response.data
        }
        if (response.data.code === 0) {
            return response.data
        }
        let message =
            response && response.data ? response.data.message : message
        if (
            [
                'No permission to view or operate.',
                'Invalid identity, please double check your authorization!.'
            ].every((mess) => mess !== message)
        ) {
            if (response?.data?.error_id) {
                message += ` (error_id: ${response.data.error_id})`
            }
            Message({
                message: message,
                type: 'error',
                duration: 4000
            })
        }

        return Promise.reject(response)
    },
    function (error) {
        if (router.currentRoute.fullPath === '/login') {
            return Promise.reject(error.response)
        }

        let message =
            error.response && error.response.data
                ? error.response.data.message
                : error.message
        if (error?.response?.data?.error_id) {
            message += ` (error_id: ${error.response.data.error_id})`
        }
        Message({
            message: message,
            type: 'error',
            duration: 4000
        })

        if (!error.response) {
            console.error(error)
            return Promise.reject({
                data: { message: i18n.t('common.an_error_has_occurred') }
            })
        }

        if (error.response.status === 401) {
            return router.push('/login')
        }

        return Promise.reject(error.response)
    }
)

export default instance
