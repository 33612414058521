var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.selected_item)?_c('div',{staticClass:"flex h-full space-x-4"},[_c('div',{staticClass:"min-w-[560px] w-[560px] flex flex-col space-y-4"},[_c('div',{staticClass:"component-box",attrs:{"id":"input_ad_name_wrapper"}},[_c('p',{staticClass:"component-label"},[_vm._v(" "+_vm._s(_vm.$t('common.name'))+" ")]),_c('el-input',{staticClass:"flex-1",attrs:{"id":"input_ad_name","size":"small"},model:{value:(_vm.selected_item.ad_name),callback:function ($$v) {_vm.$set(_vm.selected_item, "ad_name", $$v)},expression:"selected_item.ad_name"}})],1),(
                _vm.objective_type === 'PRODUCT_SALES' &&
                _vm.selected_item.extension.shopping_ads_type === 'LIVE'
            )?_c('AdSetup',{attrs:{"items":_vm.items_in_line,"selected_item":_vm.selected_item,"advertiser_id":_vm.advertiser_id,"tiktok_account_id":_vm.tiktok_account_id},on:{"update:items":function($event){_vm.items_in_line=$event},"update:selected_item":function($event){_vm.selected_item=$event}}}):_vm._e(),(
                ['PRODUCT_SALES'].every((item) => item !== _vm.objective_type)
            )?_c('Identity',{attrs:{"selected_item":_vm.selected_item,"objective_type":_vm.objective_type,"belong_to_atosa":_vm.belong_to_atosa,"advertiser_id":_vm.advertiser_id,"tiktok_account_id":_vm.tiktok_account_id},on:{"update:selected_item":function($event){_vm.selected_item=$event}}}):_vm._e(),_c('AdDetails',{attrs:{"items":_vm.items_in_line,"selected_item":_vm.selected_item,"objective_type":_vm.objective_type,"advertiser_id":_vm.advertiser_id,"bc_id":_vm.bc_id,"catalog_id":_vm.catalog_id,"currency":_vm.currency,"belong_to_atosa":_vm.belong_to_atosa,"tiktok_account_id":_vm.tiktok_account_id,"product_source":_vm.product_source,"adgroup_info":_vm.adgroup_info},on:{"update:items":function($event){_vm.items_in_line=$event},"update:selected_item":function($event){_vm.selected_item=$event}}}),(
                _vm.selected_item.extension.shopping_ads_type !==
                'PRODUCT_SHOPPING_ADS'
            )?_c('Tracking',{attrs:{"selected_item":_vm.selected_item,"advertiser_id":_vm.advertiser_id,"tiktok_account_id":_vm.tiktok_account_id,"objective_type":_vm.objective_type},on:{"update:selected_item":function($event){_vm.selected_item=$event}}}):_vm._e(),_c('div',{staticClass:"component-box invisible !-mt-4"})],1),_c('div',{staticClass:"h-fit min-w-[280px] w-[280px]",class:[_vm.m__class_top]},[(
                _vm.type_form !== 'edit' &&
                _vm.selected_item.extension.shopping_ads_type !==
                    'PRODUCT_SHOPPING_ADS'
            )?_c('div',{staticClass:"w-full"},[_c('SelectAd',{attrs:{"items":_vm.items_in_line,"selected_item":_vm.selected_item,"handleSelectAd":_vm.handleSelectAd,"objective_type":_vm.objective_type},on:{"update:items":function($event){_vm.items_in_line=$event}}}),(
                    ['PRODUCT_SALES'].some(
                        (item) => item !== _vm.objective_type
                    )
                )?_c('CustomTargetAdgroupBox',{staticClass:"mt-4",attrs:{"tiktok_account_id":_vm.tiktok_account_id,"objective_type":_vm.objective_type,"items":_vm.items_in_line,"selected_item":_vm.selected_item},on:{"update:items":function($event){_vm.items_in_line=$event},"update:selected_item":function($event){_vm.selected_item=$event}}}):_vm._e()],1):_vm._e(),_c('PreviewAd',{staticClass:"w-full",attrs:{"objective_type":_vm.objective_type,"selected_item":_vm.selected_item}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }