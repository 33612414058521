<template>
    <div id="following_hashtag_wrapper" class="border-border border-[1px] rounded-lg p-4">
        <div class="flex items-center space-x-2">
            <p class="component-label">
                {{ $t('page.campaign.following_hashtags') }}
            </p>

            <div class="!ml-auto"></div>

            <p
                class="underline underline-offset-1 cursor-pointer text-brand-atosa text-xs"
                @click="is_show_create_target_dialog = true"
            >
                {{ $t('common.save') }}
            </p>

            <p
                class="underline underline-offset-1 cursor-pointer text-brand-atosa text-xs"
                @click="
                    show_list_save_target_table = !show_list_save_target_table
                "
            >
                {{
                    show_list_save_target_table
                        ? $t('common.close_list')
                        : $t('common.list')
                }}
            </p>

            <i
                class="el-icon-delete text-sm cursor-pointer"
                @click="handleUnselected"
            ></i>
        </div>

        <p class="component-text-desc mt-0 mb-3">
            {{ $t('page.campaign.desc_following_hashtags') }}
        </p>

        <div v-if="selected_hashtags.length" class="flex gap-1 flex-wrap mb-2">
            <el-tag
                v-for="item in selected_hashtags"
                :key="item.keyword_id"
                size="small"
                type="info"
                closable
                @close="handleCloseTag(item.keyword_id)"
            >
                {{ item.keyword }}
            </el-tag>
        </div>

        <el-autocomplete
            v-model="keyword_hashtag"
            size="small"
            class="w-full"
            :fetch-suggestions="querySearch"
            :placeholder="$t('input.placeholder.please_select')"
            :trigger-on-focus="false"
            @select="handleSelect"
        >
            <template slot-scope="{ item }">
                <div>{{ item.keyword }}</div>
            </template>
        </el-autocomplete>

        <el-table
            v-if="show_list_save_target_table"
            max-height="400"
            :data="list_custom_targets"
            class="mt-2"
        >
            <el-table-column width="260" :label="$t('common.name')">
                <template #default="scope">
                    <div class="flex space-x-2">
                        <div>
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="scope.row.custom_target_name"
                                placement="top"
                            >
                                <p class="font-semibold truncate">
                                    {{ scope.row.custom_target_name }}
                                </p>
                            </el-tooltip>
                        </div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column :label="$t('table.header.action')" min-width="160">
                <template slot-scope="scope">
                    <div>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="$t('common.add_to_target')"
                            placement="top"
                        >
                            <el-button
                                size="small"
                                icon="el-icon-plus"
                                @click="
                                    handleAddCustomTargetToList(
                                        scope.row.data_config
                                    )
                                "
                            >
                            </el-button>
                        </el-tooltip>

                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="$t('common.remove_to_target')"
                            placement="top"
                        >
                            <el-button
                                size="small"
                                icon="el-icon-minus"
                                @click="
                                    handleRemoveCustomTargetToList(
                                        scope.row.data_config
                                    )
                                "
                            >
                            </el-button>
                        </el-tooltip>

                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="$t('common.replate_to_target')"
                            placement="top"
                        >
                            <el-button
                                size="small"
                                icon="el-icon-aim"
                                @click="
                                    handleReplaceCustomTargetToList(
                                        scope.row.data_config
                                    )
                                "
                            >
                            </el-button>
                        </el-tooltip>
                    </div>
                </template>
            </el-table-column>

            <el-table-column
                :label="$t('common.option')"
                fixed="right"
                width="100"
            >
                <template slot-scope="scope">
                    <el-button
                        type="danger"
                        size="small"
                        @click="handleDeleteCustomTarget(scope.row._id)"
                    >
                        {{ $t('button.delete') }}
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog
            :visible.sync="is_show_create_target_dialog"
            :close-on-click-modal="true"
            :append-to-body="true"
            width="480px"
        >
            <div slot="title">
                <p class="text-lg font-semibold">
                    {{ $t('button.save_custom_target') }}
                </p>
            </div>

            <div>
                <p class="component-label">
                    {{ $t('common.name') }}
                </p>
                <el-input
                    v-model="custom_target_name"
                    type="text"
                    size="small"
                    :placeholder="$t('input.placeholder.please_input')"
                >
                </el-input>
            </div>

            <template slot="footer">
                <div class="flex items-center justify-end mt-4">
                    <el-button
                        size="small"
                        @click="is_show_create_target_dialog = false"
                    >
                        {{ $t('button.close') }}
                    </el-button>

                    <el-button
                        :disabled="!custom_target_name"
                        type="primary"
                        size="small"
                        icon="el-icon-check"
                        @click="handleCreateCustomTarget"
                    >
                        {{ $t('button.confirm') }}
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { getQueryTool } from '@/services/atosa-tiktok-ads/tool'
import {
    createCustomTarget,
    getCustomTargets,
    deleteCustomTarget
} from '@/services/atosa-tiktok-ads/custom-target'
import _ from 'lodash'

export default {
    props: ['selected_item', 'tiktok_account_id', 'advertiser_id'],

    data() {
        return {
            keyword_hashtag: '',
            hashtag_options: [],
            selected_hashtags: [],
            list_custom_targets: [],
            custom_target_name: '',
            is_show_create_target_dialog: false,
            show_list_save_target_table: false
        }
    },

    computed: {
        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        }
    },

    watch: {
        'selected_item.id'() {
            this.initData()
        },

        selected_item() {
            this.initData()
        },

        selected_hashtags() {
            this.selected_item_in_line.extension.following_hashtag_names =
                this.selected_hashtags.map((item) => item.keyword)
        }
    },

    mounted() {
        this.fetchCustomTargets()

        this.initData()
    },

    methods: {
        initData() {
            this.selected_hashtags = []

            if (this.selected_item_in_line?.actions.length > 0) {
                const hashtag_action = this.selected_item_in_line.actions.find(
                    (item) => item.action_scene === 'HASHTAG_RELATED'
                )

                if (hashtag_action) {
                    this.fetchInitHashtags(hashtag_action.action_category_ids)
                }
            }
        },

        handleUnselected() {
            this.selected_hashtags = []

            this.handleChangeSelected()
        },

        async fetchCustomTargets() {
            try {
                const response = await getCustomTargets({
                    custom_target_name: '',
                    custom_target_type: 'HASHTAG_RELATED',
                    tiktok_account_id: ''
                })

                this.list_custom_targets = response.data
            } catch (error) {
                console.error(error)
            }
        },

        async handleCreateCustomTarget() {
            if (!this.selected_hashtags.length) {
                this.p__showNotify(
                    'warning',
                    this.$t(
                        'page.campaign.message_dont_choose_interest_keyword_ids'
                    )
                )
                return
            }

            if (!this.custom_target_name) {
                return
            }

            try {
                const data_config = JSON.stringify({
                    selected_hashtags: this.selected_hashtags
                })

                await createCustomTarget(this.tiktok_account_id, {
                    custom_target_name: this.custom_target_name,
                    custom_target_type: 'HASHTAG_RELATED',
                    data_config
                })

                this.custom_target_name = ''
                this.is_show_create_target_dialog = false

                this.fetchCustomTargets()

                this.p__showNotify(
                    'success',
                    this.$t('common.create_data_success')
                )
            } catch (error) {
                console.error(error)
            }
        },

        async fetchInitHashtags(keyword_ids) {
            const response = await getQueryTool(this.tiktok_account_id, {
                path: 'hashtag/get/',
                params: {
                    advertiser_id: this.advertiser_id,
                    keyword_ids: JSON.stringify(keyword_ids)
                }
            })

            if (response.code === 0) {
                this.selected_hashtags = response.data.keywords_status.filter(
                    (item) => item.keyword_status === 'ONLINE'
                )

                this.handleChangeSelected()
            }
        },

        async fetchHashtags() {
            const response = await getQueryTool(this.tiktok_account_id, {
                path: 'hashtag/recommend/',
                params: {
                    advertiser_id: this.advertiser_id,
                    keywords: JSON.stringify([this.keyword_hashtag])
                }
            })

            if (response.code === 0) {
                this.hashtag_options = response.data.recommend_keywords.filter(
                    (item) => item.keyword_status === 'ONLINE'
                )
            }
        },

        async querySearch(queryString, cb) {
            if (!queryString) {
                cb([])
            } else {
                await this.fetchHashtags()

                cb(this.hashtag_options)
            }
        },

        handleChangeSelected() {
            if (this.selected_hashtags.length === 0) {
                this.selected_item_in_line.actions =
                    this.selected_item_in_line.actions.filter(
                        (action) => action.action_scene !== 'HASHTAG_RELATED'
                    )
                return
            }

            const ht_action = {
                action_scene: 'HASHTAG_RELATED',
                action_period: 0,
                action_category_ids: this.selected_hashtags.map(
                    (ht) => ht.keyword_id
                ),
                video_user_actions: ['VIEW_HASHTAG']
            }

            if (
                this.selected_item_in_line.actions.some(
                    (action) => action.action_scene === 'HASHTAG_RELATED'
                )
            ) {
                this.selected_item_in_line.actions =
                    this.selected_item_in_line.actions.map((action) => {
                        if (action.action_scene === 'HASHTAG_RELATED') {
                            return ht_action
                        }

                        return action
                    })
            } else {
                this.selected_item_in_line.actions = [
                    ...this.selected_item_in_line.actions,
                    ht_action
                ]
            }
        },

        handleSelect(item) {
            this.keyword_hashtag = ''

            const indexFilterById = this.selected_hashtags.findIndex(
                (i) => i.keyword_id === item.keyword_id
            )

            if (indexFilterById === -1) {
                this.selected_hashtags = [...this.selected_hashtags, item]

                this.handleChangeSelected()
            }
        },

        async handleDeleteCustomTarget(ct_id) {
            try {
                await this.$confirm(
                    this.$t('common.delete_data_confirm'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                )
            } catch (e) {
                return
            }

            try {
                await deleteCustomTarget(this.tiktok_account_id, {
                    custom_target_id: ct_id
                })

                this.fetchCustomTargets()

                this.p__showNotify(
                    'success',
                    this.$t('common.delete_data_success')
                )
            } catch (error) {
                console.error(error)
            }
        },

        handleCloseTag(keyword_id) {
            const indexFilterById = this.selected_hashtags.findIndex(
                (item) => item.keyword_id === keyword_id
            )

            if (indexFilterById > -1) {
                this.selected_hashtags.splice(indexFilterById, 1)
            }

            this.handleChangeSelected()
        },

        handleAddCustomTargetToList(data_config) {
            const temp = _.cloneDeep(JSON.parse(data_config))

            this.selected_hashtags = [
                ...this.selected_hashtags,
                ...temp.selected_hashtags
            ]

            this.selected_hashtags = [...this.selected_hashtags].filter(
                (element, index) => {
                    return (
                        this.selected_hashtags.findIndex(
                            (el) => el.keyword_id === element.keyword_id
                        ) === index
                    )
                }
            )

            this.fetchInitHashtags(
                this.selected_hashtags.map((item) => item.keyword_id)
            )
        },

        handleRemoveCustomTargetToList(data_config) {
            const temp = _.cloneDeep(JSON.parse(data_config))

            this.selected_hashtags = [...this.selected_hashtags].filter(
                (item) =>
                    !temp.selected_hashtags.some(
                        (it) => it.keyword_id === item.keyword_id
                    )
            )

            this.handleChangeSelected()
        },

        handleReplaceCustomTargetToList(data_config) {
            const temp = _.cloneDeep(JSON.parse(data_config))

            this.fetchInitHashtags(
                temp.selected_hashtags.map((item) => item.keyword_id)
            )
        }
    }
}
</script>
