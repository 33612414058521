<template>
    <div class="flex flex-col space-y-6">
        <div>
            <p class="component-label">
                {{ $t('common.operating_system') }}
            </p>
            <el-radio-group
                v-model="operating_systems"
                size="small"
                @change="handleChangeOperatingSystems"
            >
                <el-radio-button
                    v-for="item in operating_system_options"
                    :key="item.value"
                    :label="item.value"
                >
                    {{ item.label }}
                </el-radio-button>
            </el-radio-group>
        </div>

        <div>
            <p class="component-label">
                {{ $t('page.campaign.device_model') }}
            </p>

            <treeselect
                v-model="device_model_ids"
                multiple
                :options="device_model_options"
                :placeholder="$t('input.placeholder.select_all')"
                :normalizer="normalizer_device_model_option"
                @input="handleChangeDeviceModels"
            />
        </div>

        <div v-if="operating_systems !== 'ALL'">
            <p class="component-label">
                {{ $t('page.campaign.os_versions') }}
            </p>
            <el-select
                v-model="min_version"
                class="w-full"
                size="small"
                filterable
                :placeholder="$t('input.placeholder.please_select')"
                @change="handleChangeMinVersion"
            >
                <el-option
                    v-for="item in os_versions_by_operating_system"
                    :key="item.version"
                    :label="item.name"
                    :value="item.version"
                >
                </el-option>
            </el-select>
        </div>

        <div>
            <p class="component-label">
                {{ $t('page.campaign.connection_type') }}
            </p>
            <el-checkbox-group
                v-model="network_types"
                size="small"
                @change="
                    (val) =>
                        handleChangeCheckboxGroupWithAll(val, 'network_types')
                "
            >
                <el-checkbox-button
                    v-for="item in network_type_options"
                    :key="item.value"
                    :label="item.value"
                >
                    {{ item.label }}
                </el-checkbox-button>
            </el-checkbox-group>
        </div>

        <div>
            <p class="component-label">
                {{ $t('page.campaign.carriers') }}
            </p>
            <el-select
                v-model="carrier_ids"
                size="small"
                multiple
                :placeholder="$t('input.placeholder.select_all')"
                filterable
                class="w-full"
                @change="(val) => (selected_item_in_line.carrier_ids = val)"
            >
                <el-option
                    v-for="item in carrier_options"
                    :key="item.carrier_id"
                    :label="item.name"
                    :value="`${item.carrier_id}`"
                >
                </el-option>
            </el-select>
        </div>

        <div>
            <p class="component-label">
                {{ $t('page.campaign.isp') }}
            </p>
            <el-select
                v-model="isp_ids"
                size="small"
                multiple
                :placeholder="$t('input.placeholder.select_all')"
                filterable
                class="w-full"
                @change="(val) => (selected_item_in_line.isp_ids = val)"
            >
                <el-option
                    v-for="item in targeting_tag_list"
                    :key="item.isp.isp_id"
                    :label="item.name"
                    :value="`${item.isp.isp_id}`"
                >
                </el-option>
            </el-select>
        </div>

        <div>
            <p class="component-label">
                {{ $t('page.campaign.device_price') }}
            </p>
            <el-radio-group
                v-model="radio_device_price"
                class="flex flex-col space-y-3"
                @change="handleChangeDivicePriceOption"
            >
                <el-radio :label="1">
                    {{ $t('page.campaign.any_price') }}
                </el-radio>
                <el-radio :label="2">
                    {{ $t('page.campaign.specific_price') }}
                </el-radio>
            </el-radio-group>
        </div>

        <div v-if="radio_device_price === 2">
            <div
                v-if="s__end_domain === 'asia'"
                class="flex items-center mb-1 space-x-4"
            >
                <div class="w-[160px] pl-4">
                    <p>
                        {{ value_min_device_price_vnd }}
                    </p>
                </div>
                <p>-</p>
                <div class="w-[160px] pl-4">
                    <p>
                        {{ value_max_device_price_vnd }}
                    </p>
                </div>

                <p>VND</p>
            </div>
            <div class="flex space-x-4 items-center">
                <el-select
                    v-model="value_min_device_price"
                    size="small"
                    filterable
                    :placeholder="$t('input.placeholder.please_select')"
                    class="w-[160px]"
                    @change="handleChangeRangerDivicePrice"
                >
                    <el-option
                        v-for="item in lower_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
                <p>-</p>
                <el-select
                    v-model="value_max_device_price"
                    size="small"
                    filterable
                    class="w-[160px]"
                    :placeholder="$t('input.placeholder.please_select')"
                    @change="handleChangeRangerDivicePrice"
                >
                    <el-option
                        v-for="item in upper_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>

                <p>USD</p>
            </div>
        </div>
    </div>
</template>

<script>
import {
    getQueryTool,
    getAllDeviceModels
} from '@/services/atosa-tiktok-ads/tool'
import Treeselect from '@riophae/vue-treeselect'

const JUMP_PRICE = 50

export default {
    components: {
        Treeselect
    },

    props: [
        'selected_item',
        'tiktok_account_id',
        'advertiser_id',
        'objective_type'
    ],

    data() {
        const renderRanger = () => {
            return Array.from({ length: 20 }, (_, i) => ({
                label: (i + 1) * JUMP_PRICE,
                value: (i + 1) * JUMP_PRICE
            }))
        }

        return {
            normalizer_device_model_option(node) {
                return {
                    id: node.device_model_id,
                    label: node.device_model_name,
                    children: node.child
                }
            },
            value_min_device_price: 0,
            value_max_device_price: JUMP_PRICE,
            radio_device_price: 1,
            device_model_ids: [],
            device_model_options: [],
            operating_systems: '',
            operating_system_options: [
                {
                    label: this.$t('common.all'),
                    value: 'ALL'
                },
                {
                    label: 'ANDROID',
                    value: 'ANDROID'
                },
                {
                    label: 'IOS',
                    value: 'IOS'
                }
            ],
            min_version: '',
            os_version: {
                ios: [],
                android: []
            },
            network_types: [],
            network_type_options: [
                {
                    label: this.$t('common.all'),
                    value: 'ALL'
                },
                {
                    label: 'WIFI',
                    value: 'WIFI'
                },
                {
                    label: '2G',
                    value: '2G'
                },
                {
                    label: '3G',
                    value: '3G'
                },
                {
                    label: '4G',
                    value: '4G'
                },
                {
                    label: '5G',
                    value: '5G'
                }
            ],
            lower_options: [
                {
                    label: '0',
                    value: 0
                },
                ...renderRanger()
            ],
            upper_options: [
                ...renderRanger(),
                {
                    label: '1000+',
                    value: 10_000
                }
            ],
            isp_ids: [],
            carrier_ids: [],
            targeting_tag_list: [],
            carriers: []
        }
    },

    computed: {
        s__end_domain() {
            return this.$store.getters.app.s__end_domain
        },

        value_min_device_price_vnd() {
            return (this.value_min_device_price * 24_000).toLocaleString(
                'de-DE'
            )
        },

        value_max_device_price_vnd() {
            if (this.value_max_device_price === 10_000) {
                return `${(1_000 * 24_000).toLocaleString('de-DE')}+`
            }
            return (this.value_max_device_price * 24_000).toLocaleString(
                'de-DE'
            )
        },

        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        },

        os_versions_by_operating_system() {
            if (this.operating_systems === 'ANDROID') {
                return this.os_version.android
            }
            if (this.operating_systems === 'IOS') {
                return this.os_version.ios
            }

            return this.os_version.android
        },

        carrier_options() {
            const carrier_options = []

            if (!this.selected_item.location_ids.length) {
                return carrier_options
            }

            this.carriers.forEach((country) => {
                country.carriers.forEach((carrier) => {
                    if (
                        carrier.in_use &&
                        this.selected_item.extension.country_codes.some(
                            (code) => code === country.country_code
                        )
                    ) {
                        carrier_options.push(carrier)
                    }
                })
            })
            return carrier_options
        }
    },

    watch: {
        'selected_item.id'() {
            this.initData()
        },

        selected_item() {
            this.getInfoForState()
        },

        'selected_item.location_ids'(newState, oldState) {
            if (newState.length !== oldState.length) {
                this.carrier_ids = []
                this.selected_item_in_line.carrier_ids = []
            }

            this.fetchTargetingTags()
        }
    },

    mounted() {
        this.initData()
    },

    methods: {
        initData() {
            this.getInfoForState()

            this.fetchOSVersions()

            this.fetchCarriers()

            this.fetchTargetingTags()

            this.fetchDeviceModels()
        },

        getInfoForState() {
            this.gender = this.selected_item_in_line.gender

            if (this.selected_item_in_line?.device_price_ranges?.length) {
                this.radio_device_price = 2

                const min_divice_price =
                    this.selected_item_in_line.device_price_ranges[0]

                if (this.selected_item_in_line.device_price_ranges.length > 1) {
                    this.value_min_device_price = min_divice_price

                    const max_divice_price =
                        this.selected_item_in_line.device_price_ranges[
                            this.selected_item_in_line.device_price_ranges
                                .length - 1
                        ]
                    this.value_max_device_price =
                        max_divice_price === 10_000
                            ? max_divice_price
                            : max_divice_price + JUMP_PRICE
                } else {
                    this.value_min_device_price = min_divice_price
                    this.value_max_device_price = min_divice_price + JUMP_PRICE
                }
            } else {
                this.radio_device_price = 1
            }

            this.device_model_ids = this.selected_item_in_line?.device_model_ids
                ?.length
                ? this.selected_item_in_line.device_model_ids
                : []

            this.carrier_ids = this.selected_item_in_line?.carrier_ids?.length
                ? [...this.selected_item_in_line.carrier_ids]
                : []

            this.isp_ids = this.selected_item_in_line?.isp_ids?.length
                ? [...this.selected_item_in_line.isp_ids]
                : []

            if (!this.selected_item_in_line?.network_types?.length) {
                this.selected_item_in_line.network_types = ['ALL']
            }
            this.network_types = [...this.selected_item_in_line.network_types]

            this.operating_systems = this.selected_item_in_line
                ?.operating_systems?.length
                ? this.selected_item_in_line.operating_systems[0]
                : 'ALL'

            if (this.operating_systems === 'ANDROID') {
                this.min_version =
                    this.selected_item_in_line.min_android_version
            } else if (this.operating_systems === 'IOS') {
                this.min_version = this.selected_item_in_line.min_ios_version
            }
        },

        handleChangeOperatingSystems(val) {
            if (val === 'ALL') {
                this.selected_item_in_line.operating_systems = []
            } else {
                this.selected_item_in_line.operating_systems = [val]
            }
        },

        handleChangeRangerDivicePrice() {
            if (this.value_min_device_price >= this.value_max_device_price) {
                if (this.value_min_device_price === 1000) {
                    this.value_max_device_price = 10_000
                } else {
                    this.value_max_device_price =
                        this.value_min_device_price + 50
                }
            }
            const device_price_ranges = []
            let is_running = true
            let min_price = this.value_min_device_price
            device_price_ranges.push(min_price)

            while (is_running) {
                if (min_price === 1_000) {
                    device_price_ranges.push(10_000)
                    is_running = false
                } else {
                    min_price += JUMP_PRICE
                    if (min_price < this.value_max_device_price) {
                        device_price_ranges.push(min_price)
                    } else {
                        is_running = false
                    }
                }
            }
            this.selected_item_in_line.device_price_ranges = device_price_ranges
        },

        handleChangeDivicePriceOption() {
            if (this.radio_device_price === 1) {
                this.value_min_device_price = 0
                this.value_max_device_price = JUMP_PRICE

                this.selected_item_in_line.device_price_ranges = []
            }
        },

        handleChangeCheckboxGroupWithAll(val, name) {
            if (val.some((item) => item === 'ALL')) {
                if (
                    this.selected_item_in_line[name].some(
                        (item) => item === 'ALL'
                    )
                ) {
                    this.selected_item_in_line[name] = val.filter(
                        (item) => item !== 'ALL'
                    )
                    this[name] = val.filter((item) => item !== 'ALL')
                } else {
                    this.selected_item_in_line[name] = ['ALL']
                    this[name] = ['ALL']
                }
            } else {
                this.selected_item_in_line[name] = val
                this[name] = val
            }
        },

        handleChangeDeviceModels(val) {
            this.selected_item_in_line.device_model_ids = val
        },

        async fetchDeviceModels() {
            const response = await getAllDeviceModels()
            if (response.code === 0) {
                this.device_model_options = response.data
                    .filter((item) => item.is_active)
                    .map((item) => {
                        item.child = item.child
                            .filter((i) => i.is_active)
                            .map((it) => {
                                delete it.child

                                return it
                            })

                        return item
                    })
                    .sort((a, b) =>
                        a.device_model_name.localeCompare(b.device_model_name)
                    )
            }
        },

        async fetchCarriers() {
            const response = await getQueryTool(this.tiktok_account_id, {
                path: 'carrier/',
                params: {
                    advertiser_id: this.advertiser_id
                }
            })

            if (response.code === 0) {
                this.carriers = response.data.countries
            }
        },

        async fetchTargetingTags() {
            const response = await getQueryTool(this.tiktok_account_id, {
                path: 'targeting/list/',
                params: {
                    advertiser_id: this.advertiser_id,
                    location_ids: JSON.stringify(
                        this.selected_item.location_ids
                    ),
                    scene: 'ISP'
                }
            })

            if (response.code === 0) {
                this.targeting_tag_list = response.data.targeting_tag_list
            }
        },

        async handleChangeMinVersion(val) {
            if (this.operating_systems === 'IOS') {
                this.selected_item_in_line.min_ios_version = val
            } else if (this.operating_systems === 'ANDROID') {
                this.selected_item_in_line.min_android_version = val
            }
        },

        async fetchOSVersions() {
            const response_get_android_os = await getQueryTool(
                this.tiktok_account_id,
                {
                    path: 'os_version/',
                    params: {
                        advertiser_id: this.advertiser_id,
                        os_type: 'ANDROID'
                    }
                }
            )
            if (response_get_android_os.code === 0) {
                this.os_version.android =
                    response_get_android_os.data.os_versions
            }

            const response_get_ios = await getQueryTool(
                this.tiktok_account_id,
                {
                    path: 'os_version/',
                    params: {
                        advertiser_id: this.advertiser_id,
                        os_type: 'IOS'
                    }
                }
            )
            if (response_get_ios.code === 0) {
                this.os_version.ios = response_get_ios.data.os_versions
            }
        }
    }
}
</script>
