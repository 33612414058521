<template>
    <el-dialog
        :visible.sync="visible_inline"
        :close-on-click-modal="true"
        :append-to-body="true"
        width="520px"
    >
        <div slot="title">
            <p class="text-lg font-semibold">
                {{ $t('button.save_custom_target_name') }}
            </p>
        </div>

        <div>
            <p class="component-label">{{ $t('common.preview') }}</p>

            <p>
                {{ preview_name }}
            </p>
        </div>

        <div class="h-[1px] bg-border w-full my-6"></div>

        <div class="flex flex-col space-y-6">
            <div>
                <p class="component-label">
                    {{ $t('page.campaign.field_separator') }}
                </p>

                <el-select
                    v-model="field_separator"
                    size="small"
                    :placeholder="$t('input.placeholder.please_select')"
                    class="w-[200px]"
                >
                    <el-option
                        v-for="item in p__field_separator_template_name_options"
                        :key="item"
                        :label="item"
                        :value="item"
                    >
                    </el-option>
                </el-select>
            </div>

            <div>
                <p class="component-label">
                    {{ $t('page.campaign.add_fields_to_form') }}
                </p>

                <draggable
                    v-model="fields"
                    :options="{ handle: '.drag-handle' }"
                    class="flex gap-1 flex-wrap"
                >
                    <div
                        v-for="item in fields"
                        :key="item.id"
                        class="drag-item border-border border-[1px] rounded-lg py-1 px-2 bg-bg hover:bg-border drag-handle flex items-center"
                    >
                        <p class="cursor-pointer font-semibold">::</p>

                        <div
                            v-if="item.key === 'text'"
                            class="flex items-center"
                        >
                            <i class="el-icon-edit-outline ml-2"></i>

                            <input
                                v-model="item.value"
                                type="text"
                                class="ml-2 pl-2 w-[100px]"
                            />
                        </div>

                        <div
                            v-else-if="item.key === 'time'"
                            class="flex items-center"
                        >
                            <i class="el-icon-time mx-2"></i>

                            <el-select
                                v-model="item.value"
                                size="mini"
                                placeholder="Select"
                                class="w-[120px]"
                            >
                                <el-option
                                    v-for="it in p__time_template_name_options"
                                    :key="it.value"
                                    :label="it.label"
                                    :value="it.value"
                                >
                                </el-option>
                            </el-select>
                        </div>

                        <p v-else class="ml-2 text-xs">
                            {{ item.label }}
                        </p>

                        <i
                            class="el-icon-close ml-2 cursor-pointer"
                            @click="removeField(item.id)"
                        ></i>
                    </div>
                </draggable>

                <div class="mt-2">
                    <el-dropdown trigger="click">
                        <el-button icon="el-icon-plus" size="small">
                            {{ $t('page.campaign.add_field') }}
                        </el-button>
                        <el-dropdown-menu
                            slot="dropdown"
                            class="h-[240px] overflow-auto"
                        >
                            <el-dropdown-item
                                v-for="item in field_options"
                                :key="item.key"
                                ><div @click="handleAddField(item)">
                                    <span class="text-xs">
                                        {{ item.label }}
                                    </span>
                                </div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>

            <div>
                <p class="component-label">
                    {{ $t('common.name') }}
                </p>
                <el-input
                    v-model="config_name"
                    type="text"
                    size="small"
                    class="w-[200px]"
                    :placeholder="$t('input.placeholder.please_input')"
                >
                </el-input>
            </div>
        </div>

        <template slot="footer">
            <div class="flex items-center justify-end mt-4">
                <el-button size="small" @click="visible_inline = false">
                    {{ $t('button.close') }}
                </el-button>

                <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-check"
                    @click="handleEditCustomTarget"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
import { updateTemplateName } from '@/services/atosa-tiktok-ads/tool'
import draggable from 'vuedraggable'

export default {
    components: {
        draggable
    },

    props: [
        'visible',
        'field_options',
        'genPreviewName',
        'getTemplateNames',
        'selected_item'
    ],

    data() {
        return {
            config_name: '',
            field_separator: this.p__field_separator_template_name_options[0],
            fields: [
                { id: this.p__renderId(), key: 'location_ids', value: '' },
                { id: this.p__renderId(), key: 'languages', value: '' }
            ]
        }
    },

    computed: {
        visible_inline: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        },

        preview_name() {
            return this.genPreviewName(this.fields, this.field_separator)
        }
    },

    watch: {
        selected_item() {
            this.config_name = this.selected_item.config_name

            this.field_separator = this.selected_item.field_separator

            this.fields = this.selected_item.fields

            this.genLabelField()
        }
    },

    // mounted() {},

    methods: {
        removeField(id) {
            this.fields = this.fields.filter((item) => item.id !== id)
        },

        genLabelField() {
            this.fields = this.fields.map((item) => {
                const temp = this.field_options.find(
                    (it) => it.key === item.key
                )

                if (temp) {
                    item.label = temp.label
                }
                return item
            })
        },

        handleAddField(field) {
            this.fields.push({
                id: this.p__renderId(),
                key: field.key,
                value:
                    field.key === 'time'
                        ? this.p__time_template_name_options[0].value
                        : ''
            })

            this.genLabelField()
        },

        async handleEditCustomTarget() {
            try {
                const response = await updateTemplateName(
                    {
                        ...this.selected_item,
                        config_name: this.config_name,
                        field_separator: this.field_separator,
                        fields: this.fields,
                        type: 'adgroup'
                    },
                    this.selected_item._id
                )
                if (response.code === 0) {
                    this.getTemplateNames()

                    this.p__showNotify(
                        'success',
                        this.$t('page.campaign.edit_name_template_successfully')
                    )

                    this.visible_inline = false
                }
            } catch (error) {
                console.error(error)
            }
        }
    }
}
</script>
