var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex items-center"},[_c('p',{staticClass:"component-label"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.ad_delivery_schedule'))+" ")]),_c('el-button',{staticClass:"ml-auto",attrs:{"type":"text"},on:{"click":_vm.handleSelectAll}},[_c('p',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.distributed_all_day'))+" ")])])],1),_c('table',[_c('thead',[_c('tr',[_c('th',{staticClass:"border-border border-[1px]",attrs:{"rowspan":"8"}},[_c('div',{staticClass:"w-[68px] util-flex-center"},[_c('p',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.$t('common.day'))+"/"+_vm._s(_vm.$t('common.hour'))+" ")])])]),_vm._m(0),_vm._m(1)]),_c('tr',_vm._l((_vm.number_list),function(item){return _c('td',{key:item,staticClass:"border-border border-[1px]",attrs:{"colspan":"2"}},[_c('div',{staticClass:"util-flex-center"},[_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(item))])])])}),0)]),_c('tbody',[_vm._l((_vm.table_data),function(row,rowIndex){return _c('tr',{key:rowIndex,staticClass:"select-none"},[_c('td',{staticClass:"border-border border-[1px]"},[_c('div',{staticClass:"util-flex-center"},[_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.getDayByIndex(rowIndex)))])])]),_vm._l((row),function(cell,colIndex){return _c('td',{key:colIndex,staticClass:"h-5 w-3 border-border border-[1px]",class:{
                        'bg-[#2baf2b]': !cell.selected && cell.value === 1,
                        'bg-[#1a8e1a]': cell.selected
                    },on:{"mouseup":function($event){return _vm.endSelection(rowIndex, colIndex)},"mouseover":function($event){return _vm.handleCellHover(rowIndex, colIndex)},"mousedown":function($event){return _vm.startSelection(rowIndex, colIndex)},"click":function($event){return _vm.toggleCell(rowIndex, colIndex)}}})})],2)}),_c('tr',{staticClass:"border-border border-[1px]"},[_c('td',{attrs:{"colspan":"48"}},[_c('div',{staticClass:"p-3"},[(
                                _vm.dayparting ===
                                    _vm.p__default_dayparting_adgroup ||
                                _vm.dayparting ===
                                    _vm.all_not_set_dayparting_adgroup
                            )?_c('p',{staticClass:"text-xs text-desc-text"},[_vm._v(" "+_vm._s(_vm.$t( 'page.campaign.ads_will_be_delivered_all_day_long' ))+" ")]):_c('div',[_c('p',{staticClass:"text-xs text-desc-text mb-2"},[_vm._v(" "+_vm._s(_vm.$t( 'page.campaign.ad_delivery_time_frame' ))+" ")]),_vm._l((_vm.description_list),function(item){return _c('div',{key:item.index,staticClass:"flex flex-wrap gap-1 items-center text-xs mb-1"},[_c('p',{staticClass:"w-[60px] text-desc-text"},[_vm._v(" "+_vm._s(_vm.getDayByIndex(item.index))+" ")]),_vm._l((item.list),function(str,index){return _c('p',{key:index,staticClass:"leading-3 mr-2"},[_vm._v(" "+_vm._s(str)+" "),(index < item.list.length - 1)?_c('span',[_vm._v(",")]):_vm._e()])})],2)})],2)])])])],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"border-border border-[1px] h-8",attrs:{"colspan":"24"}},[_c('span',{staticClass:"text-xs"},[_vm._v("00:00 - 12:00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"border-border border-[1px] h-8",attrs:{"colspan":"24"}},[_c('span',{staticClass:"text-xs"},[_vm._v("12:00 - 24:00")])])
}]

export { render, staticRenderFns }