<template>
    <div class="flex flex-col space-y-6">
        <div>
            <!-- <div
                class="border-border border-[1px] rounded-lg mb-2 overflow-hidden"
            >
                <div class="bg-bg p-4 py-3">
                    <div class="flex items-center justify-between">
                        <p class="font-semibold text-sm">
                            <i class="el-icon-star-on"></i>
                            {{
                                $t('page.campaign.recommended_interest_keyword')
                            }}
                        </p>

                        <p
                            class="underline underline-offset-1 cursor-pointer text-brand-atosa text-xs"
                            @click="
                                is_show_view_recommened_interest_keywords_dialog = true
                            "
                        >
                            {{ $t('button.see_more') }}
                        </p>
                    </div>
                    <p class="text-desc-text text-xs">
                        {{
                            $t(
                                'page.campaign.desc_recommended_interest_keyword'
                            )
                        }}
                    </p>
                </div>
                <div class="p-4 max-h-[200px] overflow-auto">
                    <ul class="flex flex-wrap gap-1">
                        <li
                            v-for="(
                                item, index
                            ) in few_data_recommended_interest_keywords"
                            :key="index"
                            class="group cursor-pointer"
                            @click="handleAddKeyword([item])"
                        >
                            <el-tag class="cursor-pointer">
                                {{ item.keyword }}
                            </el-tag>
                        </li>
                    </ul>
                </div>
            </div> -->

            <div class="border-border border-[1px] rounded-lg p-4">
                <div
                    id="interest_keyword_wrapper"
                    class="flex items-center space-x-2"
                >
                    <p class="component-label">
                        {{ $t('page.campaign.interest_keywords') }}
                    </p>
                    <div class="!ml-auto"></div>

                    <p
                        class="underline underline-offset-1 cursor-pointer text-brand-atosa text-xs"
                        @click="is_show_create_target_dialog = true"
                    >
                        {{ $t('common.save') }}
                    </p>

                    <p
                        class="underline underline-offset-1 cursor-pointer text-brand-atosa text-xs select-none"
                        @click="
                            show_list_save_target_table =
                                !show_list_save_target_table
                        "
                    >
                        {{
                            show_list_save_target_table
                                ? $t('common.close_list')
                                : $t('common.list')
                        }}
                    </p>

                    <i
                        class="el-icon-delete text-sm cursor-pointer"
                        @click="handleUnselected"
                    ></i>
                </div>

                <p class="component-text-desc mt-0 mb-3">
                    {{ $t('page.campaign.desc_interest_keywords') }}
                </p>

                <div
                    v-if="selected_interest_keywords.length"
                    class="flex flex-wrap gap-1 mb-2"
                >
                    <el-tag
                        v-for="item in selected_interest_keywords"
                        :key="item.keyword_id"
                        size="small"
                        type="info"
                        closable
                        @close="handleCloseTag(item.keyword_id)"
                    >
                        {{ item.keyword }}
                    </el-tag>
                </div>
                <!--
                <el-autocomplete
                    ref="ref_autocomplete_recommended_interest_keyword"
                    v-model="value_interest_keyword"
                    size="small"
                    class="w-full"
                    :fetch-suggestions="querySearch"
                    :placeholder="$t('input.placeholder.please_select')"
                    :trigger-on-focus="true"
                    :highlight-first-item="true"
                    :select-when-unmatched="true"
                    @select="handleSelect"
                >
                    <template slot-scope="{ item }">
                        <div>{{ item.keyword }}</div>
                    </template>
                </el-autocomplete> -->

                <el-select
                    :value="value_interest_keyword"
                    multiple
                    filterable
                    clearable
                    size="small"
                    class="w-full"
                    remote
                    reserve-keyword
                    :default-first-option="true"
                    :remote-method="querySearch"
                    :loading="loading_query_keyword"
                    @change="handleSelect"
                >
                    <el-option
                        v-for="item in interest_keyword_options"
                        :key="item.keyword_id"
                        :disabled="
                            selected_interest_keywords.some(
                                (it) => it.keyword_id === item.keyword_id
                            )
                        "
                        :label="item.keyword"
                        :value="item"
                    >
                    </el-option>
                </el-select>

                <el-table
                    v-if="show_list_save_target_table"
                    max-height="400"
                    :data="list_custom_targets"
                    class="mt-2"
                >
                    <el-table-column width="260" :label="$t('common.name')">
                        <template #default="scope">
                            <div class="flex space-x-2">
                                <div>
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        :content="scope.row.custom_target_name"
                                        placement="top"
                                    >
                                        <p class="font-semibold truncate">
                                            {{ scope.row.custom_target_name }}
                                        </p>
                                    </el-tooltip>
                                </div>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column
                        :label="$t('table.header.action')"
                        min-width="160"
                    >
                        <template slot-scope="scope">
                            <div>
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="$t('common.add_to_target')"
                                    placement="top"
                                >
                                    <el-button
                                        size="small"
                                        icon="el-icon-plus"
                                        @click="
                                            handleAddCustomTargetToList(
                                                scope.row.data_config
                                            )
                                        "
                                    >
                                    </el-button>
                                </el-tooltip>

                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="$t('common.remove_to_target')"
                                    placement="top"
                                >
                                    <el-button
                                        size="small"
                                        icon="el-icon-minus"
                                        @click="
                                            handleRemoveCustomTargetToList(
                                                scope.row.data_config
                                            )
                                        "
                                    >
                                    </el-button>
                                </el-tooltip>

                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="$t('common.replate_to_target')"
                                    placement="top"
                                >
                                    <el-button
                                        size="small"
                                        icon="el-icon-aim"
                                        @click="
                                            handleReplaceCustomTargetToList(
                                                scope.row.data_config
                                            )
                                        "
                                    >
                                    </el-button>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column
                        :label="$t('common.option')"
                        fixed="right"
                        width="100"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="danger"
                                size="small"
                                @click="handleDeleteCustomTarget(scope.row._id)"
                            >
                                {{ $t('button.delete') }}
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <Interests
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :selected_item.sync="selected_item_in_line"
        ></Interests>

        <VideoInteractions
            :related_options="video_relateds"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :selected_item.sync="selected_item_in_line"
        ></VideoInteractions>

        <CreatorInteractions
            :related_options="creator_relateds"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :selected_item.sync="selected_item_in_line"
        ></CreatorInteractions>

        <FollowingHashtags
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :selected_item.sync="selected_item_in_line"
        ></FollowingHashtags>

        <el-dialog
            :visible.sync="is_show_create_target_dialog"
            :close-on-click-modal="true"
            :append-to-body="true"
            width="480px"
        >
            <div slot="title">
                <p class="text-lg font-semibold">
                    {{ $t('button.save_custom_target') }}
                </p>
            </div>

            <div>
                <p class="component-label">
                    {{ $t('common.name') }}
                </p>
                <el-input
                    v-model="custom_target_name"
                    type="text"
                    size="small"
                    :placeholder="$t('input.placeholder.please_input')"
                >
                </el-input>
            </div>

            <template slot="footer">
                <div class="flex items-center justify-end mt-4">
                    <el-button
                        size="small"
                        @click="is_show_create_target_dialog = false"
                    >
                        {{ $t('button.close') }}
                    </el-button>

                    <el-button
                        :disabled="
                            !custom_target_name ||
                            !selected_item.interest_keyword_ids.length
                        "
                        type="primary"
                        size="small"
                        icon="el-icon-check"
                        @click="handleCreateCustomTarget"
                    >
                        {{ $t('button.confirm') }}
                    </el-button>
                </div>
            </template>
        </el-dialog>

        <el-dialog
            :visible.sync="is_show_view_recommened_interest_keywords_dialog"
            :close-on-click-modal="true"
            :append-to-body="true"
            width="720px"
        >
            <div slot="title">
                <p class="text-lg font-semibold">
                    {{ $t('page.campaign.recommended_interest_keyword') }}
                </p>
            </div>

            <el-table
                :data="data_recommended_interest_keywords"
                :span-method="objectSpanMethod"
                max-height="400px"
                border
            >
                <el-table-column prop="value" label="Loại từ khóa" width="100">
                </el-table-column>
                <el-table-column
                    prop="document"
                    :label="$t('common.category')"
                    width="160"
                >
                </el-table-column>
                <el-table-column prop="values" label="Các từ khóa">
                    <template #default="scope">
                        <ul class="flex flex-wrap gap-1">
                            <li
                                v-for="(item, index) in scope.row.values"
                                :key="index"
                                class="group cursor-pointer"
                                @click="handleAddKeyword([item])"
                            >
                                <el-tag class="cursor-pointer">
                                    {{ item.keyword }}
                                </el-tag>
                            </li>
                        </ul>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import FollowingHashtags from './following-hashtags'
import CreatorInteractions from './creator-interactions'
import VideoInteractions from './video-interactions'
import Interests from './interests'
import {
    getQueryTool,
    getInterestKeywordsByIds,
    getActionQueries
} from '@/services/atosa-tiktok-ads/tool'
import _ from 'lodash'
import {
    createCustomTarget,
    getCustomTargets,
    deleteCustomTarget
} from '@/services/atosa-tiktok-ads/custom-target'

export default {
    components: {
        FollowingHashtags,
        VideoInteractions,
        Interests,
        CreatorInteractions
    },

    props: [
        'selected_item',
        'tiktok_account_id',
        'advertiser_id',
        'belong_to_atosa'
    ],

    data() {
        return {
            list_custom_targets: [],
            custom_target_name: '',
            is_show_create_target_dialog: false,
            show_list_save_target_table: false,
            recommended_interest_keywords: [
                {
                    value: 'Tìm kiếm nhiều',
                    options: [
                        {
                            document: 'Thời trang',
                            values: [
                                {
                                    keyword: 'Dép nữ',
                                    status: 'EFFECTIVE',
                                    language: 'vi',
                                    keyword_id: '2065261728'
                                },
                                {
                                    language: 'vi',
                                    status: 'EFFECTIVE',
                                    keyword_id: '2065265721',
                                    keyword: 'Dép nam'
                                },
                                {
                                    status: 'EFFECTIVE',
                                    language: 'vi',
                                    keyword: 'Dép',
                                    keyword_id: '2061838683'
                                },
                                {
                                    keyword_id: '2065497692',
                                    status: 'EFFECTIVE',
                                    language: 'vi',
                                    keyword: 'Túi xách nữ'
                                },
                                {
                                    language: 'vi',
                                    keyword_id: '2065267863',
                                    keyword: 'Áo khoác nữ',
                                    status: 'EFFECTIVE'
                                },
                                {
                                    keyword_id: '2050591242',
                                    status: 'EFFECTIVE',
                                    language: 'vi',
                                    keyword: 'Áo khoác nam'
                                },
                                {
                                    keyword_id: '2065598180',
                                    status: 'EFFECTIVE',
                                    language: 'vi',
                                    keyword: 'Áo khoác nam'
                                },
                                {
                                    keyword: 'Áo phông nữ',
                                    keyword_id: '2027779571',
                                    language: 'vi',
                                    status: 'EFFECTIVE'
                                },
                                {
                                    status: 'EFFECTIVE',
                                    language: 'vi',
                                    keyword: 'Áo phông',
                                    keyword_id: '2044272101'
                                },
                                {
                                    status: 'EFFECTIVE',
                                    language: 'vi',
                                    keyword_id: '1915597272',
                                    keyword: 'Giày'
                                },
                                {
                                    status: 'EFFECTIVE',
                                    language: 'vi',
                                    keyword_id: '2027779871',
                                    keyword: 'Giày'
                                },
                                {
                                    keyword: 'Giày thể thao nữ',
                                    keyword_id: '2061772210',
                                    status: 'EFFECTIVE',
                                    language: 'vi'
                                },
                                {
                                    keyword: 'Quần jean nữ',
                                    keyword_id: '2062901634',
                                    status: 'EFFECTIVE',
                                    language: 'vi'
                                },
                                {
                                    keyword: 'Áo khoác dù',
                                    keyword_id: '2030021393',
                                    status: 'EFFECTIVE',
                                    language: 'vi'
                                },
                                {
                                    keyword_id: '2027735919',
                                    language: 'vi',
                                    status: 'EFFECTIVE',
                                    keyword: 'Áo sơ mi nam'
                                },
                                {
                                    status: 'EFFECTIVE',
                                    keyword: 'Quần ống rộng',
                                    keyword_id: '2061948996',
                                    language: 'vi'
                                },
                                {
                                    status: 'EFFECTIVE',
                                    language: 'vi',
                                    keyword_id: '2065329619',
                                    keyword: 'Vợt cầu lông'
                                }
                            ]
                        },
                        {
                            document: 'Mỹ phẩm và làm đẹp',
                            values: [
                                {
                                    keyword_id: '1915738793',
                                    language: 'vi',
                                    keyword: 'Kem dưỡng ẩm',
                                    status: 'EFFECTIVE'
                                },
                                {
                                    language: 'vi',
                                    status: 'EFFECTIVE',
                                    keyword_id: '2063643113',
                                    keyword: 'Son'
                                },
                                {
                                    keyword_id: '2063623532',
                                    status: 'EFFECTIVE',
                                    language: 'en',
                                    keyword: 'Body mist'
                                },
                                {
                                    keyword: 'Sữa rửa mặt',
                                    status: 'EFFECTIVE',
                                    language: 'vi',
                                    keyword_id: '3012181677'
                                },
                                {
                                    keyword: 'Toner',
                                    keyword_id: '1915769161',
                                    status: 'EFFECTIVE',
                                    language: 'vi'
                                },
                                {
                                    status: 'EFFECTIVE',
                                    language: 'en',
                                    keyword: 'Retinol',
                                    keyword_id: '2061728635'
                                },
                                {
                                    keyword_id: '2065495211',
                                    language: 'vi',
                                    keyword: 'Kem chống nắng',
                                    status: 'EFFECTIVE'
                                },
                                {
                                    language: 'vi',
                                    status: 'EFFECTIVE',
                                    keyword_id: '2061801119',
                                    keyword: 'Mặt nạ đất sét'
                                },
                                {
                                    keyword_id: '1915734998',
                                    language: 'en',
                                    keyword: 'Cushion',
                                    status: 'EFFECTIVE'
                                },
                                {
                                    language: 'vi',
                                    keyword: 'Son bóng',
                                    status: 'EFFECTIVE',
                                    keyword_id: '1992695742'
                                },
                                {
                                    keyword_id: '2027739163',
                                    status: 'EFFECTIVE',
                                    language: 'vi',
                                    keyword: 'Nước hoa nam'
                                },
                                {
                                    keyword_id: '1915772477',
                                    language: 'vi',
                                    keyword: 'Phấn mắt',
                                    status: 'EFFECTIVE'
                                },
                                {
                                    keyword_id: '2027758784',
                                    language: 'en',
                                    keyword: 'Black rouge',
                                    status: 'EFFECTIVE'
                                }
                            ]
                        },
                        {
                            document: 'Sức khoẻ và chăm sóc cá nhân',
                            values: [
                                {
                                    keyword: 'Tinh dầu',
                                    keyword_id: '2038645901',
                                    status: 'EFFECTIVE',
                                    language: 'vi'
                                },
                                {
                                    status: 'EFFECTIVE',
                                    keyword_id: '2589646863',
                                    keyword: 'Máy uốn tóc',
                                    language: 'vi'
                                },
                                {
                                    keyword: 'Máy cạo râu',
                                    keyword_id: '2027768547',
                                    language: 'vi',
                                    status: 'EFFECTIVE'
                                },
                                {
                                    keyword_id: '2061654916',
                                    status: 'EFFECTIVE',
                                    language: 'en',
                                    keyword: 'Nail'
                                },
                                {
                                    status: 'EFFECTIVE',
                                    language: 'vi',
                                    keyword_id: '2027759880',
                                    keyword: 'Máy uốn tóc tự động'
                                },
                                {
                                    keyword: 'Kem tẩy lông',
                                    status: 'EFFECTIVE',
                                    language: 'vi',
                                    keyword_id: '2027761729'
                                },
                                {
                                    keyword: 'Dầu gội khô',
                                    language: 'vi',
                                    keyword_id: '2050983471',
                                    status: 'EFFECTIVE'
                                },
                                {
                                    language: 'vi',
                                    keyword_id: '2061844134',
                                    status: 'EFFECTIVE',
                                    keyword: 'Bàn chải điện'
                                },
                                {
                                    language: 'en',
                                    keyword: 'Vaseline',
                                    status: 'EFFECTIVE',
                                    keyword_id: '1915760444'
                                }
                            ]
                        },
                        {
                            document: 'Mẹ và bé',
                            values: [
                                {
                                    keyword_id: '2061788599',
                                    language: 'vi',
                                    keyword: 'Quần áo trẻ em',
                                    status: 'EFFECTIVE'
                                },
                                {
                                    status: 'EFFECTIVE',
                                    language: 'vi',
                                    keyword: 'Yếm',
                                    keyword_id: '2061910289'
                                }
                            ]
                        },
                        {
                            document: 'Nhà cửa và đời sống',
                            values: [
                                {
                                    status: 'EFFECTIVE',
                                    language: 'vi',
                                    keyword_id: '2057777359',
                                    keyword: 'Gấu bông'
                                },
                                {
                                    language: 'vi',
                                    keyword: 'Nến thơm',
                                    status: 'EFFECTIVE',
                                    keyword_id: '2064906776'
                                },
                                {
                                    language: 'vi',
                                    keyword: 'Bàn làm việc',
                                    keyword_id: '1915596201',
                                    status: 'EFFECTIVE'
                                },
                                {
                                    language: 'vi',
                                    keyword_id: '2027695163',
                                    status: 'EFFECTIVE',
                                    keyword: 'Đèn pin siêu sáng'
                                },
                                {
                                    keyword: 'Đèn ngủ',
                                    keyword_id: '2048706388',
                                    language: 'vi',
                                    status: 'EFFECTIVE'
                                }
                            ]
                        },
                        {
                            document: 'Điện tử',
                            values: [
                                {
                                    language: 'en',
                                    keyword_id: '2027687313',
                                    status: 'EFFECTIVE',
                                    keyword: 'Loa bluetooth'
                                },
                                {
                                    keyword: 'Tai nghe có dây',
                                    keyword_id: '2065842261',
                                    status: 'EFFECTIVE',
                                    language: 'vi'
                                },
                                {
                                    keyword: 'Tai nghe không dây',
                                    language: 'vi',
                                    keyword_id: '2505053345',
                                    status: 'EFFECTIVE'
                                },
                                {
                                    keyword_id: '2027779303',
                                    language: 'en',
                                    status: 'EFFECTIVE',
                                    keyword: 'Camera'
                                },
                                {
                                    keyword_id: '2061779309',
                                    status: 'EFFECTIVE',
                                    language: 'vi',
                                    keyword: 'Giá đỡ điện thoại'
                                },
                                {
                                    keyword_id: '2065147250',
                                    status: 'EFFECTIVE',
                                    language: 'vi',
                                    keyword: 'Giá đỡ điện thoại'
                                },
                                {
                                    keyword_id: '2054473792',
                                    status: 'EFFECTIVE',
                                    language: 'vi',
                                    keyword: 'Chuột không dây'
                                }
                            ]
                        },
                        {
                            document: 'Bách hóa',
                            values: [
                                {
                                    language: 'vi',
                                    status: 'EFFECTIVE',
                                    keyword_id: '1915596254',
                                    keyword: 'Kẹo'
                                },
                                {
                                    status: 'EFFECTIVE',
                                    keyword_id: '2063637999',
                                    keyword: 'Bánh tráng',
                                    language: 'vi'
                                },
                                {
                                    status: 'EFFECTIVE',
                                    language: 'vi',
                                    keyword: 'Kẹo dẻo',
                                    keyword_id: '1915724591'
                                }
                            ]
                        }
                    ]
                },
                {
                    value: 'Đang lên',
                    options: [
                        {
                            document: 'Nhà cửa và đời sống',
                            values: [
                                {
                                    keyword_id: '2027690613',
                                    language: 'vi',
                                    status: 'EFFECTIVE',
                                    keyword: 'Xe đạp điện'
                                }
                            ]
                        },
                        {
                            document: 'Điện tử',
                            values: [
                                {
                                    keyword: 'Mini phone',
                                    keyword_id: '2204105733',
                                    language: 'en',
                                    status: 'EFFECTIVE'
                                }
                            ]
                        }
                    ]
                }
            ],
            is_show_view_recommened_interest_keywords_dialog: false,
            value_interest_keyword: '',
            interest_keyword_options: [],
            selected_interest_keywords: [],
            creator_relateds: [],
            video_relateds: [],
            loading_query_keyword: false
        }
    },

    computed: {
        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        },

        data_recommended_interest_keywords() {
            const temp = []

            for (const iterator of this.recommended_interest_keywords) {
                for (const iterator_option of iterator.options) {
                    temp.push({
                        value: iterator.value,
                        document: iterator_option.document,
                        values: iterator_option.values
                    })
                }
            }

            return temp
        },

        few_data_recommended_interest_keywords() {
            const temp = this.data_recommended_interest_keywords.reduce(
                (acc, current) => {
                    return [...acc, ...current.values]
                },
                []
            )

            return _.sampleSize(temp, 16)
        }
    },

    watch: {
        'selected_item.id'() {
            this.initData()
        },

        selected_item() {
            this.initData()
        },

        is_show_create_target_dialog() {
            this.custom_target_name = ''
        },

        selected_interest_keywords() {
            this.selected_item_in_line.extension.interest_keyword_names =
                this.selected_interest_keywords.map((item) => item.keyword)
        }
    },

    mounted() {
        this.initData()

        this.fetchActionCategories()

        this.fetchCustomTargets()
    },

    methods: {
        async initData() {
            if (this.selected_item_in_line.interest_keyword_ids.length) {
                const response = await getInterestKeywordsByIds(
                    this.tiktok_account_id,
                    {
                        advertiser_id: this.advertiser_id,
                        belong_to_atosa: this.belong_to_atosa,
                        keyword_query:
                            this.selected_item_in_line.interest_keyword_ids.map(
                                (item) => ({
                                    keyword_id: item,
                                    language:
                                        localStorage.getItem('code_lang') ||
                                        'vi'
                                })
                            )
                    }
                )
                this.selected_interest_keywords = response.data.keywords.filter(
                    (item) => item.status === 'EFFECTIVE'
                )

                this.selected_item_in_line.interest_keyword_ids =
                    this.selected_interest_keywords.map(
                        (item) => item.keyword_id
                    )
            } else {
                this.selected_interest_keywords = []
            }
        },

        async fetchActionCategories() {
            const response = await getActionQueries(this.tiktok_account_id, {
                lang: this.$i18n.locale
            })
            if (response.code === 0) {
                const { creator_related, video_related } = response.data

                this.creator_relateds = this.p__sortArrByName(
                    creator_related,
                    'label'
                )
                this.video_relateds = this.p__sortArrByName(
                    video_related,
                    'label'
                )
            }
        },

        handleAddCustomTargetToList(data_config) {
            const temp = _.cloneDeep(JSON.parse(data_config))

            this.selected_item_in_line.interest_keyword_ids =
                this.p__removeDuplicateValueInArray([
                    ...this.selected_item_in_line.interest_keyword_ids,
                    ...temp.interest_keyword_ids
                ])

            this.initData()
        },

        handleRemoveCustomTargetToList(data_config) {
            const temp = _.cloneDeep(JSON.parse(data_config))

            this.selected_item_in_line.interest_keyword_ids = [
                ...this.selected_item_in_line.interest_keyword_ids
            ].filter(
                (item) => !temp.interest_keyword_ids.some((it) => it === item)
            )

            this.initData()
        },

        handleReplaceCustomTargetToList(data_config) {
            const temp = _.cloneDeep(JSON.parse(data_config))

            this.selected_item_in_line.interest_keyword_ids = [
                ...temp.interest_keyword_ids
            ]

            this.initData()
        },

        handleClear(...prop) {
            console.warn(prop)
        },

        async fetchInterestKeywords(value_interest_keyword) {
            this.loading_query_keyword = true

            try {
                const response = await getQueryTool(this.tiktok_account_id, {
                    path: 'interest_keyword/recommend/',
                    params: {
                        advertiser_id: this.advertiser_id,
                        keyword: value_interest_keyword,
                        language: 'vi'
                    }
                })

                this.interest_keyword_options =
                    response.data.recommended_keywords.filter(
                        (item) =>
                            item.status === 'EFFECTIVE' &&
                            this.selected_interest_keywords.every(
                                (i) => i.keyword_id !== item.keyword_id
                            )
                    )
            } catch (error) {
                console.error(error)
            }

            this.loading_query_keyword = false
        },

        querySearch(queryString) {
            if (!queryString) {
                this.interest_keyword_options = []
                return
            }

            this.fetchInterestKeywords(queryString)
        },

        handleSelect(items) {
            const item = items[0]

            if (
                this.selected_interest_keywords.some(
                    (i) => i.keyword_id === item.keyword_id
                )
            ) {
                return
            }

            this.selected_interest_keywords.push(item)

            this.selected_item_in_line.interest_keyword_ids =
                this.selected_interest_keywords.map((item) => item.keyword_id)

            // this.$nextTick(() =>
            //     this.$refs.ref_autocomplete_recommended_interest_keyword.focus()
            // )
        },

        handleCloseTag(keyword_id) {
            const indexFilterById = this.selected_interest_keywords.findIndex(
                (item) => item.keyword_id === keyword_id
            )

            if (indexFilterById > -1) {
                this.selected_interest_keywords.splice(indexFilterById, 1)
            }

            this.selected_item_in_line.interest_keyword_ids =
                this.selected_interest_keywords.map((item) => item.keyword_id)
        },

        handleAddKeyword(items) {
            this.handleSelect(items)

            this.is_show_view_recommened_interest_keywords_dialog = false

            // this.$nextTick(() =>
            //     this.$refs.ref_autocomplete_recommended_interest_keyword.focus()
            // )
        },

        async fetchCustomTargets() {
            try {
                const response = await getCustomTargets({
                    custom_target_name: '',
                    custom_target_type: 'INTEREST_KEYWORD',
                    tiktok_account_id: ''
                })
                this.list_custom_targets = response.data
            } catch (error) {
                console.error(error)
            }
        },

        async handleCreateCustomTarget() {
            if (!this.selected_item.interest_keyword_ids.length) {
                this.p__showNotify(
                    'warning',
                    this.$t(
                        'page.campaign.message_dont_choose_interest_keyword_ids'
                    )
                )
                return
            }

            if (!this.custom_target_name) {
                return
            }
            try {
                const data_config = JSON.stringify({
                    interest_keyword_ids:
                        this.selected_item.interest_keyword_ids
                })

                await createCustomTarget(this.tiktok_account_id, {
                    custom_target_name: this.custom_target_name,
                    custom_target_type: 'INTEREST_KEYWORD',
                    data_config
                })

                this.custom_target_name = ''
                this.is_show_create_target_dialog = false

                this.fetchCustomTargets()

                this.p__showNotify(
                    'success',
                    this.$t('common.create_data_success')
                )
            } catch (error) {
                console.error(error)
            }
        },

        async handleDeleteCustomTarget(ct_id) {
            try {
                await this.$confirm(
                    this.$t('common.delete_data_confirm'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                )
            } catch (e) {
                return
            }

            try {
                await deleteCustomTarget(this.tiktok_account_id, {
                    custom_target_id: ct_id
                })

                this.fetchCustomTargets()

                this.p__showNotify(
                    'success',
                    this.$t('common.delete_data_success')
                )
            } catch (error) {
                console.error(error)
            }
        },

        handleUnselected() {
            this.selected_interest_keywords = []

            this.selected_item_in_line.interest_keyword_ids =
                this.selected_interest_keywords.map((item) => item.keyword_id)
        },

        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                if (rowIndex === 0) {
                    return {
                        rowspan: 7,
                        colspan: 1
                    }
                }
                if (rowIndex === 7) {
                    return {
                        rowspan: 7,
                        colspan: 1
                    }
                }
                return {
                    rowspan: 0,
                    colspan: 0
                }
            }
        }
    }
}
</script>
