<template>
    <custom-drawer
        size="60%"
        :wrapper_closable="false"
        :visible.sync="visible_in_line"
        :confirm_to_close="false"
    >
        <template slot="title">
            <div class="items-center h-full flex pl-4 pr-8 space-x-2">
                <p class="font-semibold">
                    {{ $t('page.campaign.video_library') }}
                </p>

                <div class="!ml-auto"></div>

                <el-button
                    icon="el-icon-upload2"
                    size="small"
                    @click="is_upload_video_library_drawer_displayed = true"
                >
                    {{ $t('common.upload_file') }}
                </el-button>

                <el-button
                    icon="el-icon-refresh"
                    size="small"
                    @click="handleRefresh"
                ></el-button>

                <el-button
                    icon="el-icon-check"
                    type="primary"
                    size="small"
                    :disabled="multiple_selection.length === 0"
                    @click="handleChooseVideoForAd"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>

        <div
            v-loading="m__loading"
            class="h-full pt-4 pb-8 pl-[84px] pr-12 overflow-auto bg-white"
            @scroll="handleScroll"
        >
            <div
                v-if="m__data_for_pagination.length"
                class="flex flex-wrap gap-4"
            >
                <div
                    v-for="item in m__data_for_pagination"
                    :key="item.video_id"
                    class="w-[180px]"
                    :class="[
                        handleHasChoosen(item.video_id)
                            ? ''
                            : 'hover:opacity-80'
                    ]"
                >
                    <div
                        class="w-auto h-[180px] rounded-lg cursor-pointer relative border-[1px]"
                        :class="[
                            handleHasChoosen(item.video_id)
                                ? 'border-brand-atosa'
                                : 'border-transparent bg-bg'
                        ]"
                        @click="handleChooseVideo(item)"
                    >
                        <div
                            v-if="handleHasChoosen(item.video_id)"
                            class="absolute top-0 left-0 w-full h-full bg-brand-atosa opacity-20 z-20"
                        ></div>

                        <img
                            :src="item.video_cover_url"
                            class="w-full h-full object-contain"
                            alt=""
                        />

                        <div
                            v-if="item.displayable"
                            class="absolute top-1 right-2"
                        >
                            <el-checkbox
                                :value="handleHasChoosen(item.video_id)"
                            ></el-checkbox>
                        </div>

                        <div v-else>
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="$t('page.campaign.cannot_use_file')"
                                placement="top"
                            >
                                <i
                                    class="el-icon-warning-outline absolute top-1 right-2"
                                >
                                </i>
                            </el-tooltip>
                        </div>

                        <div
                            v-if="item.displayable"
                            class="absolute bottom-2 right-2 bg-black p-[2px] rounded-xl"
                        >
                            <p class="text-white text-xxs">
                                {{ p__renderDurationToMinute(item.duration) }}
                            </p>
                        </div>
                    </div>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.file_name"
                        placement="top"
                    >
                        <p class="truncate text-xs mt-1">
                            {{ item.file_name }}
                        </p>
                    </el-tooltip>
                </div>
            </div>

            <div v-else class="util-flex-center flex-col mt-14">
                <img
                    class="w-[140px] h-[140px]"
                    :src="require('@/assets/images/no_data.png')"
                />
                <p class="mt-4 text-desc-text text-sm">
                    {{ $t('common.no_data') }}
                </p>
            </div>
        </div>

        <UploadVideoLibraryDrawer
            :visible.sync="is_upload_video_library_drawer_displayed"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :belong_to_atosa="belong_to_atosa"
        ></UploadVideoLibraryDrawer>
    </custom-drawer>
</template>

<script>
import { getVideos } from '@/services/atosa-tiktok-ads/file'
import paginationDataMixin from '@/plugins/mixins/pagination-data'
import UploadVideoLibraryDrawer from '../upload-video-library-drawer'

export default {
    components: {
        UploadVideoLibraryDrawer
    },

    mixins: [paginationDataMixin],

    props: [
        'visible',
        'advertiser_id',
        'tiktok_account_id',
        'multiple',
        'belong_to_atosa'
    ],

    data() {
        return {
            is_upload_video_library_drawer_displayed: false,
            multiple_selection: []
        }
    },

    computed: {
        visible_in_line: {
            get: function () {
                return this.visible
            },
            set: function (val) {
                this.$emit('update:visible', val)
            }
        }
    },

    watch: {
        visible_in_line() {
            if (this.visible_in_line) {
                this.fetchVideos(true)
            }
        },

        is_upload_video_library_drawer_displayed() {
            if (!this.is_upload_video_library_drawer_displayed) {
                this.fetchVideos(true)
            }
        }
    },

    mounted() {
        this.fetchVideos(true)
    },

    methods: {
        async fetchVideos(refresh = false) {
            this.m__loading = true

            if (refresh) {
                this.m__page_info.page = 1
                this.m__data_for_pagination = []
                this.multiple_selection = []
            }

            try {
                const response = await getVideos(this.tiktok_account_id, {
                    advertiser_id: this.advertiser_id,
                    page: this.m__page_info.page,
                    page_size: 50
                })

                this.m__data_for_pagination = [
                    ...this.m__data_for_pagination,
                    ...response.data.list
                ]
                this.m__page_info = response.data.page_info
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        handleChooseVideo(material) {
            if (!material.displayable) {
                return
            }

            if (!this.multiple) {
                this.multiple_selection = [material]

                return
            }
            if (this.handleHasChoosen(material.video_id)) {
                this.multiple_selection = this.multiple_selection.filter(
                    (item) => item.video_id !== material.video_id
                )
            } else {
                this.multiple_selection = [...this.multiple_selection, material]
            }
        },

        handleRefresh() {
            this.fetchVideos(true)
        },

        handleHasChoosen(video_id) {
            return this.multiple_selection.some(
                (item) => item.video_id === video_id
            )
        },

        handleChooseVideoForAd() {
            if (!this.multiple_selection.length) {
                return
            }

            this.$emit('choose_video', this.multiple_selection)

            this.multiple_selection = []

            this.visible_in_line = false
        },

        handleScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
            if (this.m__loading) {
                return
            }

            if (scrollTop + clientHeight >= scrollHeight) {
                if (this.m__page_info.page < this.m__page_info.total_page) {
                    this.m__page_info.page++
                    this.fetchVideos()
                }
            }
        }
    }
}
</script>
