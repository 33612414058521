<template>
    <custom-drawer
        size="50%"
        :wrapper_closable="false"
        :visible.sync="visible_in_line"
        :confirm_to_close="false"
    >
        <template slot="title">
            <div class="items-center h-full flex pl-4 pr-8 space-x-2">
                <p class="font-semibold">
                    {{ $t('common.upload_file') }}
                </p>

                <div class="!ml-auto"></div>

                <el-button
                    icon="el-icon-check"
                    type="primary"
                    size="small"
                    :disabled="!video_file"
                    :loading="m__loading"
                    @click="handleUpload()"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>

        <div class="pl-4 pr-8 pt-4">
            <input
                id="uploadFile"
                ref="files"
                type="file"
                accept="video/mp4,video/x-m4v,video/*"
                style="display: none"
                @change="handleFileUpload"
            />

            <div v-if="video_file">
                <el-button
                    icon="el-icon-upload2"
                    size="small"
                    class="mb-2"
                    @click="handleOpenSelectFile"
                >
                    {{ $t('common.upload_file') }}
                </el-button>

                <div class="flex space-x-2">
                    <img
                        class="w-[68px] h-[68px]"
                        :src="require('@/assets/images/logo_tiktok.png')"
                    />
                    <div class="text-sm">
                        <p>
                            {{ video_file.name }}
                        </p>
                        <p class="text-desc-text">
                            {{ getVideoSize(video_file) }}
                        </p>
                        <p class="text-desc-text">
                            {{ p__renderDurationToMinute(video_duration) }}
                        </p>
                    </div>
                </div>
            </div>
            <div
                v-else
                class="w-full h-[160px] bg-white border-[1px] border-dashed border-border cursor-pointer"
                @click="handleOpenSelectFile"
            >
                <div class="util-flex-center flex-col h-full">
                    <i class="el-icon-upload2 text-xl"></i>
                    <p class="mt-2">
                        {{ $t('common.upload_file') }}
                    </p>
                </div>
            </div>

            <div class="text-sm mt-4 text-desc-text">
                <p v-html="$t(`page.campaign.desc_upload_video_1`)"></p>
                <p v-html="$t(`page.campaign.desc_upload_video_2`)"></p>
                <p v-html="$t(`page.campaign.desc_upload_video_3`)"></p>
                <p v-html="$t(`page.campaign.desc_upload_video_4`)"></p>

                <a
                    class="text-brand-atosa"
                    target="_blank"
                    href="https://ads.tiktok.com/help/article/video-ads-specifications?redirected=2"
                >
                    {{ $t('page.campaign.desc_upload_video_5') }}
                </a>
            </div>
        </div>
    </custom-drawer>
</template>

<script>
import { uploadVideo, getInfoVideo } from '@/services/atosa-tiktok-ads/file'
import paginationDataMixin from '@/plugins/mixins/pagination-data'

export default {
    mixins: [paginationDataMixin],

    props: ['visible', 'advertiser_id', 'tiktok_account_id', 'belong_to_atosa'],

    data() {
        return {
            video_file: null,
            video_duration: ''
        }
    },

    computed: {
        visible_in_line: {
            get: function () {
                return this.visible
            },
            set: function (val) {
                this.$emit('update:visible', val)
            }
        }
    },

    watch: {
        video_file() {
            if (this.video_file) {
                this.getVideoDuration(this.video_file)
            }
        }
    },

    methods: {
        handleOpenSelectFile() {
            document.getElementById('uploadFile').click()
        },

        handleFileUpload(event) {
            this.video_file = event.target.files[0]
        },

        async getVideoDuration(file) {
            const createPromiseVideoDuration = () => {
                return new Promise((resolve, reject) => {
                    if (!file || !file.type.startsWith('video/')) {
                        // Kiểm tra xem file có phải là video không
                        resolve(null) // Trả về null nếu không phải là video
                    } else {
                        // Tạo một phần tử video ẩn để lấy thông tin metadata của video
                        const videoElement = document.createElement('video')
                        videoElement.preload = 'metadata'

                        // Sự kiện được kích hoạt khi metadata của video đã được tải lên
                        videoElement.onloadedmetadata = function () {
                            // Lấy duration của video và trả về nó
                            resolve(videoElement.duration)
                        }

                        // Tạo URL cho file video
                        const fileURL = URL.createObjectURL(file)
                        videoElement.src = fileURL
                    }
                })
            }

            const response = await createPromiseVideoDuration()

            this.video_duration = response || ''
        },

        getVideoSize(videoFile) {
            const units = ['Bytes', 'KB', 'MB', 'GB', 'TB']
            let size = videoFile.size
            let unitIndex = 0

            while (size > 1024 && unitIndex < units.length - 1) {
                size /= 1024
                unitIndex++
            }

            const formattedSize = size.toFixed(2)
            const unit = units[unitIndex]

            return `${formattedSize} ${unit}`
        },

        async handleUpload() {
            if (!this.video_file) {
                return
            }

            this.m__loading = true

            try {
                const formData = new FormData()
                formData.append('advertiser_id', this.advertiser_id)
                formData.append('belong_to_atosa', this.belong_to_atosa)
                formData.append('upload_type', 'UPLOAD_BY_FILE')
                formData.append('video_file', this.video_file)
                formData.append(
                    'filename',
                    this.video_file.name + this.p__renderId(3)
                )

                const response = await uploadVideo(
                    this.tiktok_account_id,
                    formData
                )

                if (response.code === 0) {
                    this.visible_in_line = false

                    const response = await getInfoVideo(
                        this.tiktok_account_id,
                        {
                            advertiser_id: this.advertiser_id,
                            video_ids: [response.data[0].video_id]
                        }
                    )
                    if (response.code === 0 && response.data?.list.length > 0) {
                        this.info_video = response.data.list[0]
                    }

                    this.p__showNotify(
                        'success',
                        this.$t('page.campaign.upload_video_success')
                    )
                }
            } catch (error) {
                console.error(error)
            }
            this.m__loading = false
        }
    }
}
</script>
