import request from './_config'

const path = `ads_groups`

export function getAdGroups(tiktok_account_id, params = null) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'get',
        params
    })
}

export function createAdGroup(tiktok_account_id, data) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'post',
        data: {
            ...data,
            operation_status: 'ENABLE'
        }
        // data: {
        //     campaign_id: data.campaign_id,
        //     advertiser_id: data.advertiser_id,
        //     adgroup_name: data.adgroup_name,
        //     promotion_type: data.promotion_type,
        //     placement_type: data.placement_type,
        //     placements: data.placements,
        //     location_ids: data.location_ids,
        //     frequency: data.frequency,
        //     frequency_schedule: data.frequency_schedule,
        //     budget: data.budget,
        //     budget_mode: data.budget_mode,
        //     schedule_type: data.schedule_type,
        //     schedule_start_time: data.schedule_start_time,
        //     schedule_end_time: data.schedule_end_time,
        //     pacing: data.pacing,
        //     billing_event: data.billing_event,
        //     optimization_goal: data.optimization_goal,
        //     bid_price: data.bid_price,
        //     gender: data.gender,
        //     age_groups: data.age_groups
        // }
    })
}

export function getAudienceSize(tiktok_account_id, data) {
    return request({
        url: `${path}/audience_size/estimate/${tiktok_account_id}`,
        method: 'post',
        data
    })
}

export function updateAdgroupStatus(tiktok_account_id, data) {
    return request({
        url: `${path}/status/${tiktok_account_id}`,
        method: 'post',
        data: {
            type: data.type,
            advertiser_id: data.advertiser_id,
            adgroup_ids: data.adgroup_ids,
            belong_to_atosa: data.belong_to_atosa,
            operation_status: data.operation_status
        }
    })
}

export function updateAdgroup(tiktok_account_id, data) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'put',
        data: {
            ...data,
            advertiser_id: data.advertiser_id,
            adgroup_id: data.adgroup_id
        }
    })
}
