import request from './_config'
const path = `audience`

export function getAllAudiences(tiktok_account_id, params) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'get',
        params
    })
}
