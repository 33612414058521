import request from './_config'
const path = `event`

export function getPixels(tiktok_account_id, params) {
    return request({
        url: `${path}/pixel/${tiktok_account_id}`,
        method: 'get',
        params: {
            advertiser_id: params.advertiser_id
        }
    })
}

export function getInstantPages(tiktok_account_id, params) {
    return request({
        url: `${path}/instant_page/${tiktok_account_id}`,
        method: 'get',
        params: {
            advertiser_id: params.advertiser_id,
            objective_type: params.objective_type,
            optimization_goal: params.optimization_goal
        }
    })
}

export function getAppList(tiktok_account_id, params) {
    return request({
        url: `${path}/app_list/${tiktok_account_id}`,
        method: 'get',
        params: {
            advertiser_id: params.advertiser_id
        }
    })
}

export function getApps(tiktok_account_id, params) {
    return request({
        url: `${path}/app/${tiktok_account_id}`,
        method: 'get',
        params: {
            advertiser_id: params.advertiser_id,
            app_id: params.app_id,
            placement: params.placement,
            optimization_goal: params.optimization_goal,
            objective: params.objective
        }
    })
}
