<template>
    <div class="component-box">
        <p class="font-semibold mb-8">
            {{ $t('common.tracking') }}

            <span class="text-desc-text"> ({{ $t('common.optional') }}) </span>
        </p>

        <div
            v-if="objective_type !== 'PRODUCT_SALES'"
            class="border-border border-b-[1px] pb-6 mb-4"
        >
            <p class="component-label">Pixel</p>

            <el-select
                v-model="selected_item_in_line.tracking_pixel_id"
                v-el-select-loadmore="loadMore"
                size="small"
                filterable
                :placeholder="$t('input.placeholder.please_select')"
                class="w-full"
            >
                <el-option-group label="Pixel">
                    <el-option
                        v-for="item in m__data_for_pagination"
                        :key="item.pixel_id"
                        :label="item.pixel_name"
                        :value="item.pixel_id"
                    >
                        <div class="flex items-center space-x-2 py-1">
                            <div class="flex flex-col">
                                <span
                                    class="leading-5 text-xs font-semibold"
                                >
                                    {{ item.pixel_name }}
                                </span>
                                <span class="component-text-desc">
                                    ID : {{ item.pixel_code }}
                                </span>
                            </div>
                        </div>
                    </el-option>
                </el-option-group>
            </el-select>
        </div>

        <el-collapse v-model="toggle_collapse" class="custom-primary">
            <el-collapse-item name="1">
                <template slot="title">
                    <p>
                        {{ $t('page.campaign.third_party_tracking_settings') }}
                    </p>
                </template>

                <div>
                    <p class="component-label">
                        {{ $t('page.campaign.impression_tracking_url') }}
                    </p>

                    <el-input
                        v-model="selected_item_in_line.impression_tracking_url"
                        type="text"
                        size="small"
                        :placeholder="$t('input.placeholder.please_input')"
                    >
                    </el-input>
                </div>

                <div class="mt-6">
                    <p class="component-label">
                        {{ $t('page.campaign.click_tracking_url') }}
                    </p>
                    <el-input
                        v-model="selected_item_in_line.click_tracking_url"
                        type="text"
                        size="small"
                        :placeholder="$t('input.placeholder.please_input')"
                    >
                    </el-input>
                </div>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
import { getPixels } from '@/services/atosa-tiktok-ads/event'
import paginationDataMixin from '@/plugins/mixins/pagination-data'

export default {
    mixins: [paginationDataMixin],

    props: [
        'selected_item',
        'advertiser_id',
        'tiktok_account_id',
        'objective_type'
    ],

    data() {
        return {
            toggle_collapse: '',
            text: ''
        }
    },

    computed: {
        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        }
    },

    mounted() {
        this.fetchPixels()
    },

    methods: {
        async fetchPixels() {
            const response = await getPixels(this.tiktok_account_id, {
                advertiser_id: this.advertiser_id,
                page: this.m__page_info.page,
                page_size: this.m__page_info.page_size
            })

            this.m__data_for_pagination = [
                ...this.m__data_for_pagination,
                ...response.data.pixels
            ]

            this.m__page_info = response.data.page_info
        },

        loadMore() {
            if (this.m__loading) {
                return
            }

            if (this.m__page_info.page < this.m__page_info.total_page) {
                this.m__page_info.page++
                this.fetchPixels()
            }
        }
    }
}
</script>
