<template>
    <div class="component-box">
        <p class="font-semibold mb-8">
            {{ $t('common.product') }}
        </p>

        <div>
            <p class="component-label">
                {{ $t('common.catalog') }}
            </p>

            <el-select
                v-model="selected_item_in_line.catalog_id"
                v-el-select-loadmore="loadMore"
                size="small"
                class="w-full"
                :placeholder="$t('input.placeholder.please_select')"
            >
                <el-option
                    v-for="item in m__data_for_pagination"
                    :key="item.catalog_id"
                    :label="item.catalog_name"
                    :value="item.catalog_id"
                >
                </el-option>
            </el-select>
        </div>
    </div>
</template>

<script>
import { getCatalogs } from '@/services/atosa-tiktok-ads/catalog'
import paginationDataMixin from '@/plugins/mixins/pagination-data'

export default {
    mixins: [paginationDataMixin],

    props: ['selected_item', 'tiktok_account_id', 'advertiser_id', 'bc_id'],

    computed: {
        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        }
    },

    mounted() {
        this.fetchCatalogs()
    },

    methods: {
        async fetchCatalogs() {
            try {
                const response = await getCatalogs(this.tiktok_account_id, {
                    bc_id: this.bc_id,
                    page: this.this.m__page_info.page
                })
                this.m__data_for_pagination = [
                    ...this.m__data_for_pagination,
                    ...response.data.list
                ]

                this.m__page_info = response.data.page_info
            } catch (error) {
                console.error(error)
            }
        },

        loadMore() {
            if (this.m__loading) {
                return
            }

            if (this.m__page_info.page < this.m__page_info.total_page) {
                this.m__page_info.page++
                this.fetchCatalogs()
            }
        }
    }
}
</script>
