<template>
    <el-dialog
        title=""
        :visible="visible"
        :append-to-body="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        width="480px"
    >
        <div class="util-flex-center h-fit">
            <img
                :src="require('@/assets/images/loading.gif')"
                class="h-[120px]"
            />
        </div>

        <p class="mt-4 mb-2 break-words">
            {{ $t('page.campaign.process_take_a_few_minutes') }}
        </p>

        <el-progress
            :text-inside="true"
            :stroke-width="22"
            :percentage="percentage"
            status="success"
        ></el-progress>

        <p v-if="description">
            {{ description }}
        </p>
    </el-dialog>
</template>

<script>
export default {
    props: ['visible', 'index', 'amount', 'description'],

    computed: {
        percentage() {
            return +`${((this.index / this.amount) * 100).toFixed(0)}`
        }
    }
}
</script>
