import request from './_config'
const path = `asset`

export function getMembersByAsset(tiktok_account_id, params) {
    return request({
        url: `${path}/get_member_by_asset/${tiktok_account_id}`,
        method: 'get',
        params: {
            bc_id: params.bc_id,
            asset_type: params.asset_type,
            asset_id: params.asset_id
        }
    })
}

export function getAssets(tiktok_account_id, params) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'get',
        params: {
            bc_id: params.bc_id,
            asset_type: params.asset_type
        }
    })
}

export function assignAsset(tiktok_account_id, data) {
    return request({
        url: `${path}/assign_asset/${tiktok_account_id}`,
        method: 'post',
        data: {
            bc_id: data.bc_id,
            user_id: data.user_id,
            asset_type: data.asset_type,
            asset_id: data.asset_id,
            ...data
        }
    })
}
