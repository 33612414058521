import request from './_config'
const path = `auto_ads`

export function getAllConfigAds(tiktok_account_id, params) {
    return request({
        url: `${path}/get/${tiktok_account_id}`,
        method: 'get',
        params: {
            campaign_ids: JSON.stringify(params.campaign_ids),
            advertiser_id: params.advertiser_id
        }
    })
}

export function setConfigAds(tiktok_account_id, data) {
    return request({
        url: `${path}/set/${tiktok_account_id}`,
        method: 'post',
        data: {
            campaign_ids: data.campaign_ids,
            advertiser_id: data.advertiser_id,
            config_default: data.config_default,
            config_list: data.config_list,
            status: data.status
        }
    })
}

export function deleteConfigAds(tiktok_account_id, data) {
    return request({
        url: `${path}/delete/${tiktok_account_id}`,
        method: 'post',
        data: {
            campaign_ids: data.campaign_ids,
            advertiser_id: data.advertiser_id
        }
    })
}

export function getAutomatedRules(tiktok_account_id, params) {
    return request({
        url: `${path}/automated_rule/get_by_filter/${tiktok_account_id}`,
        method: 'get',
        params
    })
}

export function createAutomatedRule(tiktok_account_id, data) {
    return request({
        url: `${path}/automated_rule/create/${tiktok_account_id}`,
        method: 'post',
        data
    })
}

export function updateAutomatedRule(tiktok_account_id, data) {
    return request({
        url: `${path}/automated_rule/update/${tiktok_account_id}`,
        method: 'post',
        data
    })
}

export function updateStatusAutomatedRule(tiktok_account_id, data) {
    return request({
        url: `${path}/automated_rule/update_status/${tiktok_account_id}`,
        method: 'post',
        data: {
            advertiser_id: data.advertiser_id,
            rule_ids: data.rule_ids,
            operate_type: data.operate_type
        }
    })
}

export function getConfigScheduleBudget(tiktok_account_id, params) {
    return request({
        url: `${path}/config_budget/${tiktok_account_id}`,
        method: 'get',
        params: {
            advertiser_id: params.advertiser_id,
            adgroup_id: params.adgroup_id
        }
    })
}

export function createConfigScheduleBudget(tiktok_account_id, data) {
    return request({
        url: `${path}/config_budget/${tiktok_account_id}`,
        method: 'post',
        data: {
            advertiser_id: data.advertiser_id,
            adgroup_id: data.adgroup_id,
            config_list: data.config_list,
            active: true
        }
    })
}

export function updateConfigScheduleBudget(tiktok_account_id, data) {
    return request({
        url: `${path}/config_budget/${tiktok_account_id}`,
        method: 'put',
        data: {
            config_id: data.config_id,
            advertiser_id: data.advertiser_id,
            adgroup_id: data.adgroup_id,
            time: data.time,
            value: data.value,
            active: true
        }
    })
}

export function deleteConfigScheduleBudget(tiktok_account_id, config_id) {
    return request({
        url: `${path}/config_budget/${tiktok_account_id}`,
        method: 'delete',
        params: {
            config_id
        }
    })
}
