<template>
    <div
        v-if="
            selected_item.extension.shopping_ads_type === 'PRODUCT_SHOPPING_ADS'
        "
    >
        <p class="text-xs font-semibold mb-2">
            {{ $t('common.preview') }}
        </p>

        <div class="relative">
            <img
                :src="require('@/assets/images/spa_preview_search.png')"
                class="w-full rounded-lg"
                alt=""
            />

            <div
                class="absolute top-[137px] left-[12px] bg-[#ebebeb] w-[126px] h-[126px]"
            >
                <div v-if="selected_product" class="w-full">
                    <img
                        :src="selected_product.images[0].thumb_url_list[0]"
                        class="w-full h-[126px]"
                        alt=""
                    />

                    <p
                        class="text-xxs font-semibold util-max-2-text-lines leading-3"
                    >
                        {{ selected_product.product_name }}
                    </p>

                    <p class="text-xxs font-semibold text-desc-text">
                        {{ renderPrice(selected_product.skus) }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['selected_item', 'objective_type'],

    data() {
        return {
            all_products: [],
            selected_product: null
        }
    },

    mounted() {
        this.p__event_bus.$on(
            'eb_selected_products_for_preview_ad',
            this.handleSelectedProduct
        )

        this.p__event_bus.$on(
            'eb_all_products_for_preview_ad',
            this.selectProducts
        )
    },

    destroyed() {
        this.p__event_bus.$off(
            'eb_selected_products_for_preview_ad',
            this.handleSelectedProduct
        )

        this.p__event_bus.$off(
            'eb_all_products_for_preview_ad',
            this.selectProducts
        )
    },

    methods: {
        handleSelectedProduct(item_group_id) {
            const temp = this.all_products.find(
                (item) => item.item_group_id === item_group_id
            )

            if (temp) {
                this.selected_product = temp
            } else {
                this.selected_product = null
            }
        },

        selectProducts(products) {
            this.all_products = products
            this.selected_product = products[0]
        },

        renderPrice(skus) {
            if (skus.length === 0) {
                return '_'
            }

            let maxOriginalPrice = -Infinity
            let minOriginalPrice = Infinity

            for (const sku of skus) {
                const originalPrice = parseInt(sku.price.original_price)

                if (originalPrice > maxOriginalPrice) {
                    maxOriginalPrice = originalPrice
                }

                if (originalPrice < minOriginalPrice) {
                    minOriginalPrice = originalPrice
                }
            }

            if (minOriginalPrice === maxOriginalPrice) {
                return this.p__renderMoneyByCurrency(
                    minOriginalPrice,
                    skus[0].price.currency
                )
            }

            return `${this.p__renderMoneyByCurrency(
                minOriginalPrice,
                skus[0].price.currency
            )} - ${this.p__renderMoneyByCurrency(
                maxOriginalPrice,
                skus[0].price.currency
            )}`
        }
    }
}
</script>
