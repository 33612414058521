import request from './_config'

const path = `admin_config`

export function getAdminRole() {
    return request({
        url: `${path}/has_admin_role`,
        method: 'get'
    })
}
