<template>
    <div
        id="main-nav"
        class="top-[76px] bg-white left-0 fixed h-[calc(100vh-76px)] tablet:block flex flex-col rounded-tr-xl z-50 shadow-lg"
        :style="{ width: sidebar_opening ? '200px' : '72px' }"
    >
        <div class="h-[calc(100vh-128px)] overflow-y-auto">
            <el-menu
                :default-active="activeMenu"
                :collapse="!sidebar_opening"
                :collapse-transition="false"
                :unique-opened="true"
                mode="vertical"
            >
                <sidebar-item
                    v-for="route in permission.routes"
                    :key="route.path"
                    :item="route"
                    :base-path="route.path"
                    :collapse="!sidebar_opening"
                />
            </el-menu>
        </div>
        <div
            class="flex items-center justify-end flex-1 mr-4 transition-all duration-300"
        >
            <div id="tg_1" @click="handleToggleSidebar">
                <el-avatar
                    class="hover:opacity-90 opacity-50 cursor-pointer"
                    size="large"
                    :icon="
                        sidebar_opening ? 'el-icon-s-fold' : 'el-icon-s-unfold'
                    "
                ></el-avatar>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarItem from './sidebar-item'

export default {
    components: { SidebarItem },

    computed: {
        ...mapGetters(['permission', 'app']),

        activeMenu() {
            const route = this.$route
            const { meta, path } = route
            if (meta.activeMenu) {
                return meta.activeMenu
            }
            return path
        },

        sidebar_opening() {
            return this.app.s__is_sidebar_opening
        }
    },

    methods: {
        async handleToggleSidebar() {
            await this.$store.dispatch('app/s__toggleSidebar')
        }
    }
}
</script>
