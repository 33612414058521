<template>
    <el-dialog
        :visible.sync="visible_in_line"
        width="560px"
        class="el-dialog-center"
        :close-on-click-modal="true"
        :append-to-body="true"
        :show-close="false"
    >
        <div class="-mx-5 my-[-46px]">
            <img
                :src="require('@/assets/images/connect_tiktok_shop.png')"
                class="mx-full"
                alt=""
            />
        </div>

        <div class="mt-[62px] text-center">
            <p
                class="text-lg leading-[1.7rem] text-center font-semibold mt-6 break-word"
            >
                {{ $t('common.havent_connect_tiktok_shop') }}
            </p>

            <div class="mt-5 flex items-center">
                <el-checkbox
                    v-model="checked_never_show"
                    @change="handleChangeNeverShow"
                >
                    {{ $t('common.never_show_popup') }}
                </el-checkbox>

                <div class="!ml-auto"></div>

                <el-button
                    size="medium"
                    class="uppercase"
                    @click="visible_in_line = false"
                >
                    {{ $t('button.skip') }}
                </el-button>
                <el-button
                    type="primary"
                    size="medium"
                    class="uppercase"
                    @click="
                        $router.push({
                            path: `${p__group_route.ads_manager}/${p__route.tiktok_shop}`
                        }),
                            (visible_in_line = false)
                    "
                >
                    {{ $t('button.connect') }}
                </el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: ['visible'],

    data() {
        return {
            checked_never_show: false
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        }
    },

    methods: {
        handleChangeNeverShow() {
            if (this.checked_never_show) {
                localStorage.setItem(
                    'checked_never_show_connect_tiktok_shop',
                    1
                )
            } else {
                localStorage.removeItem(
                    'checked_never_show_connect_tiktok_shop'
                )
            }
        }
    }
}
</script>
