import request from './_config'

const path = `identity`

export function getIdentities(tiktok_account_id, params) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'get',
        params: {
            belong_to_atosa: params.belong_to_atosa,
            advertiser_id: params.advertiser_id,
            identity_type: params.identity_type,
            identity_authorized_bc_id: params.identity_authorized_bc_id
        }
    })
}

export function getIdentitiesFromAtosa(tiktok_account_id, params) {
    return request({
        url: `${path}/from_atosa/${tiktok_account_id}`,
        method: 'get',
        params: {
            advertiser_id: params.advertiser_id
        }
    })
}

export function getAllIdentitiesFromAtosa(tiktok_account_id, params) {
    return request({
        url: `${path}/from_atosa/get_all/${tiktok_account_id}`,
        method: 'get',
        params: {
            advertiser_id: params.advertiser_id
        }
    })
}

export function createIdentity(tiktok_account_id, data) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'post',
        data: {
            belong_to_atosa: data.belong_to_atosa,
            advertiser_id: data.advertiser_id,
            image_uri: data.image_uri,
            display_name: data.display_name
        }
    })
}

export function getRequestIdentity(tiktok_account_id) {
    return request({
        url: `${path}/request/${tiktok_account_id}`,
        method: 'get'
    })
}

export function requestAnIdentity(tiktok_account_id, data) {
    return request({
        url: `${path}/request/${tiktok_account_id}`,
        method: 'post',
        data: data
    })
}

export function deleteRequestIdentity(tiktok_account_id, tiktok_username) {
    return request({
        url:
            `${path}/request/${tiktok_account_id}?tiktok_username=` +
            tiktok_username,
        method: 'delete'
    })
}

export function getVideoFromIdentity(tiktok_account_id, params) {
    return request({
        url: `${path}/video/${tiktok_account_id}`,
        method: 'get',
        params: {
            belong_to_atosa: params.belong_to_atosa,
            advertiser_id: params.advertiser_id,
            identity_id: params.identity_id,
            identity_authorized_bc_id: params.identity_authorized_bc_id,
            identity_type: params.identity_type,
            cursor: params.cursor
        }
    })
}

export function commitRequestAnIdentity(tiktok_account_id, tiktok_username) {
    return request({
        url: `${path}/commit_request/${tiktok_account_id}`,
        method: 'post',
        data: { tiktok_username }
    })
}
