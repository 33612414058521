<template>
    <el-collapse
        v-model="collapse_target"
        class="custom-primary"
        :accordion="true"
    >
        <el-collapse-item
            :title="$t('page.campaign.targeting_summary')"
            name="1"
        >
            <div class="text-xs flex flex-col space-y-1 ml-[-20px]">
                <div
                    class="relative group cursor-pointer hover:text-brand-atosa"
                    @click="scrollToElement('input_adgroup_name_wrapper')"
                >
                    <p class="line-clamp-3 break-words">
                        <span class="text-desc-text">
                            {{ $t('table.header.ad_group_name') }}
                        </span>
                        :
                        {{ selected_item.adgroup_name }}
                    </p>

                    <i
                        class="el-icon-edit-outline text-sm hidden group-hover:block absolute -right-3 top-1/2 -translate-y-1/2"
                    ></i>
                </div>

                <div
                    class="relative group cursor-pointer hover:text-brand-atosa"
                    @click="scrollToElement('treeview_localtion_wrapper')"
                >
                    <p>
                        <span class="text-desc-text">
                            {{ $t('common.location') }}
                        </span>
                        :
                        {{ genLocations() }}
                    </p>

                    <i
                        class="el-icon-edit-outline text-sm hidden group-hover:block absolute -right-3 top-1/2 -translate-y-1/2"
                    ></i>
                </div>

                <div
                    class="relative group cursor-pointer hover:text-brand-atosa"
                    @click="scrollToElement('gender_wrapper')"
                >
                    <p>
                        <span class="text-desc-text">
                            {{ $t('common.gender') }}
                        </span>
                        :
                        {{ genGender() }}
                    </p>

                    <i
                        class="el-icon-edit-outline text-sm hidden group-hover:block absolute -right-3 top-1/2 -translate-y-1/2"
                    ></i>
                </div>

                <div
                    class="relative group cursor-pointer hover:text-brand-atosa"
                    @click="scrollToElement('age_wrapper')"
                >
                    <p>
                        <span class="text-desc-text">
                            {{ $t('common.age') }}
                        </span>
                        :
                        {{ genAge() }}
                    </p>

                    <i
                        class="el-icon-edit-outline text-sm hidden group-hover:block absolute -right-3 top-1/2 -translate-y-1/2"
                    ></i>
                </div>

                <div
                    class="relative group cursor-pointer hover:text-brand-atosa"
                    @click="scrollToElement('language_wrapper')"
                >
                    <p>
                        <span class="text-desc-text">
                            {{ $t('common.language') }}
                        </span>
                        :
                        {{ genLanguages() }}
                    </p>

                    <i
                        class="el-icon-edit-outline text-sm hidden group-hover:block absolute -right-3 top-1/2 -translate-y-1/2"
                    ></i>
                </div>

                <div
                    class="relative group cursor-pointer hover:text-brand-atosa"
                    @click="scrollToElement('interest_keyword_wrapper')"
                >
                    <p>
                        <span class="text-desc-text">
                            {{ $t('page.campaign.interest_keywords') }}
                        </span>
                        :
                        {{ genInterestKeywords() }}
                    </p>

                    <i
                        class="el-icon-edit-outline text-sm hidden group-hover:block absolute -right-3 top-1/2 -translate-y-1/2"
                    ></i>
                </div>

                <div
                    class="relative group cursor-pointer hover:text-brand-atosa"
                    @click="scrollToElement('interest_wrapper')"
                >
                    <p>
                        <span class="text-desc-text">
                            {{ $t('page.campaign.interests') }}
                        </span>
                        :
                        {{ genInterests() }}
                    </p>

                    <i
                        class="el-icon-edit-outline text-sm hidden group-hover:block absolute -right-3 top-1/2 -translate-y-1/2"
                    ></i>
                </div>

                <div
                    class="relative group cursor-pointer hover:text-brand-atosa"
                    @click="scrollToElement('video_interactions_wrapper')"
                >
                    <p>
                        <span class="text-desc-text">
                            {{ $t('page.campaign.video_interactions') }}
                        </span>
                        :
                        {{ genVideoInteractions() }}
                    </p>
                    <i
                        class="el-icon-edit-outline text-sm hidden group-hover:block absolute -right-3 top-1/2 -translate-y-1/2"
                    ></i>
                </div>

                <div
                    class="relative group cursor-pointer hover:text-brand-atosa"
                    @click="scrollToElement('creator_interactions_wrapper')"
                >
                    <p>
                        <span class="text-desc-text">
                            {{ $t('page.campaign.creator_interactions') }}
                        </span>
                        :
                        {{ genCreatorInteractions() }}
                    </p>
                    <i
                        class="el-icon-edit-outline text-sm hidden group-hover:block absolute -right-3 top-1/2 -translate-y-1/2"
                    ></i>
                </div>

                <div
                    class="relative group cursor-pointer hover:text-brand-atosa"
                    @click="scrollToElement('following_hashtag_wrapper')"
                >
                    <p>
                        <span class="text-desc-text">
                            {{ $t('page.campaign.following_hashtags') }}
                        </span>
                        :
                        {{ genFollowingHashtags() }}
                    </p>
                    <i
                        class="el-icon-edit-outline text-sm hidden group-hover:block absolute -right-3 top-1/2 -translate-y-1/2"
                    ></i>
                </div>

                <div
                    class="relative group cursor-pointer hover:text-brand-atosa"
                    @click="scrollToElement('input_budget_wrapper')"
                >
                    <p>
                        <span class="text-desc-text">
                            {{ $t('common.budget') }}
                        </span>
                        :
                        {{
                            p__renderMoneyByCurrency(
                                selected_item.budget,
                                currency
                            )
                        }}
                        ({{ p__formatBudgetMode(selected_item.budget_mode) }})
                    </p>
                    <i
                        class="el-icon-edit-outline text-sm hidden group-hover:block absolute -right-3 top-1/2 -translate-y-1/2"
                    ></i>
                </div>

                <div
                    class="relative group cursor-pointer hover:text-brand-atosa"
                    @click="scrollToElement('input_budget_wrapper')"
                >
                    <p>
                        <span class="text-desc-text">
                            {{ $t('common.schedule') }}
                        </span>
                        : {{ genSchedule() }}
                    </p>
                    <i
                        class="el-icon-edit-outline text-sm hidden group-hover:block absolute -right-3 top-1/2 -translate-y-1/2"
                    ></i>
                </div>
            </div>
        </el-collapse-item>
    </el-collapse>
</template>

<script>
export default {
    props: ['selected_item', 'currency'],

    data() {
        return {
            collapse_target: '1'
        }
    },

    methods: {
        genFollowingHashtags() {
            if (
                this.selected_item.extension.following_hashtag_names.length ===
                0
            ) {
                return this.$t('common.unknown')
            }

            return this.selected_item.extension.following_hashtag_names.join(
                ' , '
            )
        },

        genCreatorInteractions() {
            if (
                this.selected_item.extension.creator_interaction_names
                    .length === 0
            ) {
                return this.$t('common.unknown')
            }

            return this.selected_item.extension.creator_interaction_names.join(
                ' , '
            )
        },

        genVideoInteractions() {
            if (
                this.selected_item.extension.video_interactions_names.length ===
                0
            ) {
                return this.$t('common.unknown')
            }

            return this.selected_item.extension.video_interactions_names.join(
                ' , '
            )
        },

        genInterests() {
            if (this.selected_item.extension.interest_names.length === 0) {
                return this.$t('common.unknown')
            }

            return this.selected_item.extension.interest_names.join(' , ')
        },

        genInterestKeywords() {
            if (
                this.selected_item.extension.interest_keyword_names.length === 0
            ) {
                return this.$t('common.unknown')
            }

            return this.selected_item.extension.interest_keyword_names.join(
                ' , '
            )
        },

        genLocations() {
            if (this.selected_item.extension.location_names.length === 0) {
                return this.$t('common.unknown')
            }

            return this.selected_item.extension.location_names.join(' , ')
        },

        genSchedule() {
            let result = this.selected_item.schedule_start_time + ' - '

            result +=
                this.selected_item.schedule_type === 'SCHEDULE_FROM_NOW'
                    ? this.$t('common.invalid_period')
                    : this.selected_item.schedule_end_time

            return result
        },

        genLanguages() {
            if (this.selected_item.extension.languages.length === 0) {
                return this.$t('common.all')
            }

            return this.selected_item.extension.languages.join(' , ')
        },

        genAge() {
            if (
                !this.selected_item.age_groups ||
                (this.selected_item.age_groups.length === 1 &&
                    this.selected_item.age_groups[0] === 'ALL')
            ) {
                return this.$t('common.all')
            }
            return this.selected_item.age_groups
                .map((item) => {
                    const temp = this.p__age_options.find(
                        (it) => it.value === item
                    )

                    if (temp) {
                        return temp.label
                    }

                    return item
                })
                .join(' , ')
        },

        genGender() {
            const temp = this.p__gender_options.find(
                (it) => it.value === this.selected_item.gender
            )

            if (temp) {
                return temp.label
            }

            return this.selected_item.gender
        },

        scrollToElement(element_id) {
            document
                .getElementById(element_id)
                .scrollIntoView({ behavior: 'smooth' })
        }
    }
}
</script>
