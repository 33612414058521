<template>
    <el-dialog
        title="Tips"
        :visible.sync="visible_inline"
        :append-to-body="true"
        :close-on-click-modal="true"
        width="480px"
    >
        <div slot="title">
            <p class="text-lg font-semibold">
                {{ $t('button.save_custom_target') }}
            </p>
        </div>

        <div>
            <p class="component-label">
                {{ $t('common.name') }}
            </p>
            <el-input
                v-model="custom_target_name"
                type="text"
                size="small"
                :placeholder="$t('input.placeholder.please_input')"
            >
            </el-input>
        </div>

        <template slot="footer">
            <div class="flex items-center justify-end mt-4">
                <el-button size="small" @click="visible_inline = false">
                    {{ $t('button.close') }}
                </el-button>

                <el-button
                    :disabled="!custom_target_name"
                    type="primary"
                    icon="el-icon-check"
                    size="small"
                    @click="handleCreateCustomTarget"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
import { createCustomTarget } from '@/services/atosa-tiktok-ads/custom-target'

export default {
    props: ['visible', 'tiktok_account_id', 'items', 'objective_type'],

    data() {
        return {
            custom_target_name: ''
        }
    },

    computed: {
        visible_inline: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        }
    },

    watch: {
        visible_inline() {
            this.custom_target_name = ''
        }
    },

    methods: {
        async handleCreateCustomTarget() {
            if (!this.custom_target_name) {
                return
            }
            try {
                await createCustomTarget(this.tiktok_account_id, {
                    custom_target_name: this.custom_target_name,
                    objective_type: this.objective_type,
                    data_config: JSON.stringify(this.items),
                    custom_target_type: 'AD'
                })

                this.custom_target_name = ''
                this.visible_inline = false

                this.p__event_bus.$emit('eb_reload_custom_targets')

                this.p__showNotify(
                    'success',
                    this.$t('common.create_data_success')
                )
            } catch (error) {
                console.error(error)
            }
        }
    }
}
</script>
