<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    name: 'App',

    metaInfo: {
        meta: [
            { charset: 'utf-8' },
            {
                name: 'description',
                content: 'An example Vue application with vue-meta.'
            },
            { name: 'viewport', content: 'width=device-width, initial-scale=1' }
        ]
    },

    mounted() {
        this.checkEndDomain()
    },

    methods: {
        async checkEndDomain() {
            const current = window.location.hostname

            if (current.endsWith('.io')) {
                await this.$store.dispatch('app/s__setEndDomain', 'io')
            } else if (current.endsWith('.asia')) {
                await this.$store.dispatch('app/s__setEndDomain', 'asia')
            } else {
                await this.$store.dispatch('app/s__setEndDomain', 'asia')
            }
        }
    }
}
</script>

<style lang="scss">
html,
body {
    overscroll-behavior: none;
}

.span-label {
    font-size: 14px;
    display: block;
    margin-bottom: 5px;
}

.strong-label {
    font-size: 14px;
    display: block;
    margin-bottom: 5px;
}

.grecaptcha-badge {
    display: none !important;
}
</style>
