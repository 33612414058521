var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-box"},[_c('p',{staticClass:"font-semibold mb-8"},[_vm._v(" "+_vm._s(_vm.$t('common.identity'))+" ")]),(!_vm.only_use_spark_ads)?_c('el-switch',{staticClass:"mb-6",attrs:{"disabled":_vm.m__loading,"active-text":_vm.$t('page.campaign.use_spark_ads')},on:{"change":_vm.handleChangeUseSparkAds},model:{value:(_vm.use_spark_ads),callback:function ($$v) {_vm.use_spark_ads=$$v},expression:"use_spark_ads"}}):_vm._e(),_c('div',[(_vm.use_spark_ads)?_c('p',{staticClass:"component-label"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.use_account_owned_by_you'))+" ")]):_c('p',{staticClass:"component-label"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.set_custom_identity'))+" ")]),(_vm.m__data_for_pagination.length)?_c('div',[_c('el-select',{directives:[{name:"el-select-loadmore",rawName:"v-el-select-loadmore",value:(_vm.loadMore),expression:"loadMore"}],staticClass:"w-full",attrs:{"value-key":"identity_id","size":"small","placeholder":_vm.$t('input.placeholder.please_select')},on:{"change":_vm.handleChangeSelectedIdentity},model:{value:(_vm.selected_identity),callback:function ($$v) {_vm.selected_identity=$$v},expression:"selected_identity"}},_vm._l((_vm.m__data_for_pagination),function(item){return _c('el-option',{key:item.identity_id,attrs:{"label":item.display_name,"value":item}},[_c('div',{staticClass:"flex items-center space-x-2 py-1"},[_c('el-avatar',{attrs:{"size":32}},[_c('img',{attrs:{"src":item.profile_image
                                        ? item.profile_image
                                        : _vm.p__default_identity_image_uri}})]),_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"leading-5 text-xs font-semibold"},[_vm._v(" "+_vm._s(item.display_name)+" ")]),_c('span',{staticClass:"component-text-desc"},[_vm._v(" #"+_vm._s(item.identity_id)+" ")])])],1)])}),1),(!_vm.use_spark_ads)?_c('el-button',{staticClass:"mt-1",attrs:{"size":"small","icon":"el-icon-plus","type":"text"},on:{"click":function($event){_vm.visible = true}}},[_vm._v(" "+_vm._s(_vm.$t('button.create'))+" ")]):_vm._e()],1):(!_vm.use_spark_ads)?_c('div',[_c('div',{staticClass:"flex items-center space-x-2"},[_c('el-avatar',{staticClass:"min-w-[44px] cursor-pointer !bg-border",attrs:{"size":44}},[_c('img',{staticClass:"w-full h-full object-contain",attrs:{"src":_vm.selected_item_in_line.extension.identity
                                .image_uri,"alt":""}})]),_c('el-input',{ref:"el_input_identity_display_name",attrs:{"type":"text","size":"small","placeholder":_vm.$t('input.placeholder.please_input'),"maxlength":"40","show-word-limit":""},model:{value:(
                        _vm.selected_item_in_line.extension.identity
                            .display_name
                    ),callback:function ($$v) {_vm.$set(_vm.selected_item_in_line.extension.identity
                            , "display_name", $$v)},expression:"\n                        selected_item_in_line.extension.identity\n                            .display_name\n                    "}},[_c('template',{slot:"prepend"},[_vm._v("@")])],2)],1),_c('div',{staticClass:"flex"},[_c('el-button',{staticClass:"mt-2 mx-auto",attrs:{"size":"small","icon":"el-icon-plus"},on:{"click":_vm.handleCreateCustomizeIdentity}},[_vm._v(" "+_vm._s(_vm.$t('button.create'))+" ")])],1)]):_vm._e()]),_c('el-dialog',{attrs:{"title":"Tips","visible":_vm.visible,"close-on-click-modal":true,"append-to-body":true,"width":"480px"},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('p',{staticClass:"text-lg font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.create_identity'))+" ")])]),_c('div',{staticClass:"flex items-center space-x-2"},[_c('el-avatar',{staticClass:"min-w-[44px] cursor-pointer !bg-border",attrs:{"size":44}},[_c('img',{staticClass:"w-full h-full object-contain",attrs:{"src":_vm.selected_item_in_line.extension.identity.image_uri,"alt":""}})]),_c('el-input',{ref:"el_input_identity_display_name",attrs:{"type":"text","size":"small","placeholder":_vm.$t('input.placeholder.please_input'),"maxlength":"40","show-word-limit":""},model:{value:(
                    _vm.selected_item_in_line.extension.identity.display_name
                ),callback:function ($$v) {_vm.$set(_vm.selected_item_in_line.extension.identity, "display_name", $$v)},expression:"\n                    selected_item_in_line.extension.identity.display_name\n                "}},[_c('template',{slot:"prepend"},[_vm._v("@")])],2)],1),_c('template',{slot:"footer"},[_c('div',{staticClass:"flex items-center justify-end mt-4"},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){_vm.visible = false}}},[_vm._v(" "+_vm._s(_vm.$t('button.close'))+" ")]),_c('el-button',{attrs:{"type":"primary","icon":"el-icon-check","size":"small"},on:{"click":_vm.handleCreateCustomizeIdentity}},[_vm._v(" "+_vm._s(_vm.$t('button.confirm'))+" ")])],1)])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }