import request from './_config'

const path = `tool`

export function getQueryTool(tiktok_account_id, data) {
    return request({
        url: `${path}/query/${tiktok_account_id}`,
        method: 'post',
        data: {
            path: data.path,
            params: data.params
        }
    })
}

export function getRegions(tiktok_account_id, params) {
    return request({
        url: `${path}/regions/${tiktok_account_id}`,
        method: 'get',
        params
    })
}

export function getActionQueries(tiktok_account_id, params) {
    return request({
        url: `${path}/action_categories/${tiktok_account_id}`,
        method: 'get',
        params: {
            lang: params.lang
        }
    })
}

export function getInterestKeywordsByIds(tiktok_account_id, data) {
    return request({
        url: `${path}/interest_keyword/by_id/${tiktok_account_id}`,
        method: 'post',
        data
    })
}

export function getAllDeviceModels() {
    return request({
        url: `${path}/device_model`,
        method: 'get'
    })
}

export function getSuggestedBid(tiktok_account_id, data) {
    return request({
        url: `${path}/get_suggested_bid/${tiktok_account_id}`,
        method: 'post',
        data: {
            advertiser_id: data.advertiser_id,
            campaign_id: data.campaign_id,
            objective_type: data.objective_type,
            location_ids: data.location_ids,
            external_action: data.external_action
        }
    })
}

export function getRecommendedInterest(tiktok_account_id, params) {
    return request({
        url: `${path}/recommended_interest/${tiktok_account_id}`,
        method: 'get',
        params: {
            advertiser_id: params.advertiser_id,
            region_codes: params.region_codes
        }
    })
}

export function getTemplateNames() {
    return request({
        url: `${path}/name_template/`,
        method: 'get'
    })
}

export function createTemplateName(data) {
    return request({
        url: `${path}/name_template`,
        method: 'post',
        data
    })
}

export function updateTemplateName(data, id) {
    return request({
        url: `${path}/name_template/${id}`,
        method: 'put',
        data
    })
}

export function deleteTemplateName(id) {
    return request({
        url: `${path}/name_template/${id}`,
        method: 'delete'
    })
}
