<template>
    <div class="component-box">
        <p class="mb-8 font-semibold">
            {{ $t('page.campaign.bidding_optimization') }}
        </p>

        <div class="flex flex-col space-y-6">
            <div>
                <p class="component-label">
                    {{ $t('page.campaign.optimization_goal') }}
                </p>

                <div
                    v-if="objective_type === 'PRODUCT_SALES'"
                    class="grid grid-cols-2 gap-4"
                >
                    <div
                        v-for="(
                            item, index
                        ) in optimization_goal_for_live_shopping"
                        v-show="handleShowOptimizeGoal(item)"
                        :key="index"
                        class="select-none relative"
                    >
                        <el-tooltip
                            effect="dark"
                            :content="item.description"
                            placement="top"
                        >
                            <div
                                class="border-[1px] border-l-[4px] px-4 py-2 w-full h-[68px] bg-bg rounded-lg cursor-pointer"
                                :class="[
                                    selected_item_in_line.optimization_goal ===
                                        item.value_goal &&
                                    (selected_item_in_line.optimization_event ===
                                        item.value_event ||
                                        (!selected_item_in_line.optimization_event &&
                                            !item.value_event))
                                        ? 'border-brand-atosa'
                                        : 'hover:border-border border-transparent'
                                ]"
                                @click="
                                    handleSelectOptimizationForShoppingLive(
                                        item
                                    )
                                "
                            >
                                <div class="flex items-center space-x-4 h-full">
                                    <font-awesome-icon :icon="item.icon" />

                                    <p
                                        class="component-text-desc text-sm font-semibold"
                                    >
                                        {{ item.label }}
                                    </p>
                                </div>
                            </div>
                        </el-tooltip>

                        <el-tooltip
                            effect="dark"
                            :content="
                                $t('page.campaign.copy_adgroup_with_new_attr')
                            "
                            placement="top"
                        >
                            <div
                                v-if="type_form === 'create'"
                                class="absolute -top-2 -right-2 bg-white border-border border-[1px] shadow hover:shadow-lg w-8 h-8 util-flex-center rounded-full cursor-pointer flex items-"
                                @click="
                                    handleCopyAdgroupWithNewAttribute({
                                        optimization_goal: item.value_goal,
                                        optimization_event: item.value_event,
                                        billing_event: item.value_billing_event
                                    })
                                "
                            >
                                <i class="el-icon-document-copy text-sm"></i>
                            </div>
                        </el-tooltip>
                    </div>

                    <!-- <div
                        v-show="
                            selected_item_in_line.shopping_ads_type !== 'LIVE'
                        "
                        class="select-none relative"
                    >
                        <div
                            v-if="type_form === 'create'"
                            class="border-[1px] border-l-[4px] h-[68px] pl-4 w-full bg-bg rounded-lg cursor-pointer flex items-center space-x-4"
                            :class="[
                                selected_item_in_line.extension
                                    .event_is_external
                                    ? 'border-brand-atosa'
                                    : 'hover:border-border border-transparent'
                            ]"
                            @click="handleChooseExternalEvent"
                        >
                            <font-awesome-icon icon="fa-solid fa-hourglass" />

                            <p class="component-text-desc text-sm">
                                {{ $t('page.campaign.external_event') }}
                            </p>
                        </div>

                        <el-tooltip
                            effect="dark"
                            :content="
                                $t('page.campaign.copy_adgroup_with_new_attr')
                            "
                            placement="top"
                        >
                            <div
                                v-if="type_form === 'create'"
                                class="absolute -top-2 -right-2 bg-white border-border border-[1px] shadow hover:shadow-lg w-8 h-8 util-flex-center rounded-full cursor-pointer flex items-"
                                @click="
                                    handleCopyAdgroupWithNewAttribute({
                                        optimization_goal: 'CLICK',
                                        optimization_event: '',
                                        billing_event: '',
                                        extension: {
                                            ...selected_item_in_line.extension,
                                            event_is_external: true
                                        }
                                    })
                                "
                            >
                                <i
                                    class="el-icon-document-copy text-sm"
                                ></i>
                            </div>
                        </el-tooltip>
                    </div> -->
                </div>

                <span v-else class="text-sm">
                    {{
                        p__formatLabelOptimizationGoal({
                            optimization_goal
                        })
                    }}
                </span>

                <div
                    v-if="selected_item_in_line.extension.event_is_external"
                    class="bg-bg p-4 mt-4 rounded-lg"
                >
                    <TiktokPixel
                        :selected_item.sync="selected_item_in_line"
                        :tiktok_account_id="tiktok_account_id"
                        :advertiser_id="advertiser_id"
                    ></TiktokPixel>
                </div>
            </div>

            <div v-if="objective_type === 'REACH'">
                <p class="component-label">
                    {{ $t('page.campaign.frequency_cap') }}
                </p>
                <el-radio-group
                    v-model="radio_frequency_cap"
                    class="flex flex-col space-y-3"
                    @change="handleChangeFrequencyCap"
                >
                    <el-radio :label="1">
                        {{
                            $t(
                                'page.campaign.frequency_show_ads_3_times_7_days'
                            )
                        }}
                    </el-radio>
                    <el-radio :label="2">
                        {{
                            $t(
                                'page.campaign.frequency_show_ads_more_than_once_day'
                            )
                        }}</el-radio
                    >
                    <el-radio :label="3">
                        {{
                            $t(
                                'page.campaign.frequency_show_ads_custom_frequency_cap'
                            )
                        }}</el-radio
                    >
                </el-radio-group>

                <div
                    v-if="radio_frequency_cap === 3"
                    class="bg-bg p-6 mt-4 rounded-lg"
                >
                    <p class="component-label">
                        {{ $t('page.campaign.frequency_cap') }}
                    </p>
                    <div class="flex items-center space-x-4">
                        <el-input-number
                            v-model="frequency"
                            class="w-[120px]"
                            size="small"
                            :min="1"
                            :controls="false"
                            @blur="handleBlurFrequency"
                        ></el-input-number>

                        <span class="text-xs">
                            {{
                                this.$t('common.xxx_has_plural_noun', {
                                    xxx: this.$t(`common.impression`)
                                })
                            }}
                        </span>

                        <span class="text-xs">/</span>

                        <el-input-number
                            v-model="frequency_schedule"
                            class="w-[120px]"
                            size="small"
                            :min="1"
                            :max="7"
                            :controls="false"
                            @blur="handleBlurFrequency"
                        ></el-input-number>

                        <span class="text-xs">
                            {{ $t('common.day') }}
                        </span>
                    </div>
                </div>

                <div id="input_bid_wrapper" class="mt-2">
                    <p class="component-label">
                        {{ $t('page.campaign.bid_control') }}
                        <span class="text-desc-text">
                            ({{ $t('common.optional') }})
                        </span>
                    </p>
                    <div class="flex items-center space-x-2">
                        <el-input
                            id="input_bid"
                            v-model="selected_item_in_line.bid_price"
                            type="number"
                            class="w-[200px]"
                            size="small"
                        ></el-input>

                        <span class="component-text-desc">
                            {{ currency }}/1000
                            {{
                                this.$t('common.xxx_has_plural_noun', {
                                    xxx: this.$t(`common.impression`)
                                })
                            }}
                        </span>
                    </div>
                </div>
            </div>

            <div
                v-if="
                    [
                        'TRAFFIC',
                        'LEAD_GENERATION',
                        'ENGAGEMENT',
                        'APP_PROMOTION',
                        'PRODUCT_SALES',
                        'WEB_CONVERSIONS'
                    ].some((type) => type === objective_type)
                "
                id="input_bid_wrapper"
            >
                <p class="component-label">
                    {{ $t('page.campaign.bid_strategy') }}
                </p>
                <div v-if="selected_item_in_line.optimization_goal === 'VALUE'">
                    <p class="text-sm">
                        {{ $t('page.campaign.optimization_goal_highest_roas') }}
                    </p>
                    <p class="text-sm text-desc-text mb-2">
                        {{
                            $t(
                                'page.campaign.optimization_goal_desc_highest_roas'
                            )
                        }}
                    </p>
                    <el-checkbox
                        v-model="checked_set_minimum_roas"
                        :disabled="type_form === 'edit'"
                        @change="handleChangeSetMinimumRoas"
                    >
                        {{
                            $t('page.campaign.optimization_goal_minimum_roas')
                        }}</el-checkbox
                    >

                    <el-input-number
                        v-if="checked_set_minimum_roas"
                        v-model="selected_item_in_line.roas_bid"
                        :min="0.01"
                        size="mini"
                    ></el-input-number>
                </div>
                <el-radio-group
                    v-else
                    v-model="radio_bid_strategy"
                    class="flex flex-col space-y-3"
                    @change="handleChangeBidStrategy"
                >
                    <el-radio
                        v-if="selected_item_in_line.billing_event === 'OCPM'"
                        :disabled="type_form === 'edit'"
                        :label="3"
                    >
                        {{
                            $t('page.campaign.bid_strategy_cost_cap')
                        }}</el-radio
                    >

                    <el-radio
                        v-else
                        :disabled="type_form === 'edit'"
                        :label="1"
                    >
                        {{ $t('page.campaign.bid_strategy_bid_cap') }}</el-radio
                    >

                    <el-radio :disabled="type_form === 'edit'" :label="2">
                        {{ $t('page.campaign.bid_strategy_lowest_cost') }}
                    </el-radio>
                </el-radio-group>

                <div
                    v-if="radio_bid_strategy === 1"
                    class="flex items-center mt-6 space-x-2"
                >
                    <el-input
                        id="input_bid"
                        v-model="selected_item_in_line.bid_price"
                        type="number"
                        class="w-[200px]"
                        size="small"
                    ></el-input>

                    <span class="component-text-desc">
                        {{ currency }}/{{ $t('common.conversion') }}
                    </span>
                    <el-button size="small" @click="openGetCPA">
                        {{ $t('common.cpa_suggestion') }}
                    </el-button>
                </div>

                <div
                    v-if="radio_bid_strategy === 3"
                    class="flex items-center mt-6 space-x-2"
                >
                    <el-input
                        id="input_conversion_bid"
                        v-model="selected_item_in_line.conversion_bid_price"
                        type="number"
                        class="w-[200px]"
                        size="small"
                    ></el-input>

                    <span class="component-text-desc">
                        {{ currency }}/{{ $t('common.conversion') }}
                    </span>
                    <el-button size="small" @click="onOpenGetCPA">
                        {{ $t('common.cpa_suggestion') }}
                    </el-button>
                </div>
            </div>

            <div v-if="objective_type === 'VIDEO_VIEWS'" id="input_bid_wrapper">
                <p class="component-label">
                    {{ $t('page.campaign.bid_control') }}
                    <span class="text-desc-text">
                        ({{ $t('common.optional') }})
                    </span>
                </p>
                <div class="flex items-center space-x-2">
                    <el-input
                        id="input_bid"
                        v-model="selected_item_in_line.bid_price"
                        type="number"
                        class="w-[200px]"
                        size="small"
                    ></el-input>

                    <span class="component-text-desc">
                        {{ currency }}/{{
                            $t(
                                `page.campaign.column_${optimization_goal.toLowerCase()}`
                            )
                        }}
                    </span>
                </div>
            </div>

            <el-collapse
                v-model="toggle_collapse"
                class="custom-primary"
                accordion
            >
                <el-collapse-item name="1">
                    <template slot="title">
                        <p>
                            {{ $t('common.advanced_settings') }}
                        </p>
                    </template>
                    <div class="bg-bg p-6 rounded-lg">
                        <!-- <div v-if="optimization_goal" class="mb-6">
                            <p class="component-label">
                                {{ $t('page.campaign.billing_event') }}
                            </p>
                            <p>
                                {{ optimization_goal }} ({{
                                    selected_item_in_line.billing_event
                                }})
                            </p>
                        </div> -->

                        <div>
                            <p class="component-label">
                                {{ $t('page.campaign.delivery_type') }}
                            </p>

                            <div v-if="show_radio_pacing">
                                <el-radio
                                    v-model="radio_pacing"
                                    label="PACING_MODE_SMOOTH"
                                >
                                    {{ $t('common.standard') }}
                                </el-radio>
                                <p class="component-text-desc">
                                    {{
                                        $t(
                                            'page.campaign.desc_pacing_mode_smooth'
                                        )
                                    }}
                                </p>
                                <el-radio
                                    v-model="radio_pacing"
                                    class="mt-4"
                                    label="PACING_MODE_FAST"
                                >
                                    {{ $t('page.campaign.accelerated') }}
                                </el-radio>
                                <p class="component-text-desc">
                                    {{
                                        $t(
                                            'page.campaign.desc_pacing_mode_fast'
                                        )
                                    }}
                                </p>
                            </div>

                            <div v-else>
                                <p>{{ $t('common.standard') }}</p>
                                <p class="component-text-desc">
                                    {{
                                        $t(
                                            'page.campaign.desc_pacing_mode_smooth'
                                        )
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
    </div>
</template>

<script>
import TiktokPixel from '@/components/orgranisms/edit-adgroup-form/_components/tiktok-pixel'
import GetCpaDrawer from '../../../../views/campaign/campaign-wrapper/campaign-pane/get-cpa-drawer'

const NUM_IMPRESSIONS_EACH_DAYS = 3

export default {
    components: {
        TiktokPixel
    },

    props: [
        'type_form',
        'selected_item',
        'currency',
        'objective_type',
        'tiktok_account_id',
        'advertiser_id'
    ],

    data() {
        return {
            show_get_cpa_drawer: true,
            checked_set_minimum_roas: false,
            frequency: 1,
            frequency_schedule: 1,
            device_price_ranges: [],
            toggle_collapse: [],
            radio_frequency_cap: 1,
            radio_bid_strategy: 2,
            radio_pacing: 'PACING_MODE_SMOOTH',
            optimization_goal_for_live_shopping: [
                {
                    icon: 'fa-solid fa-mouse-pointer',
                    label: this.$t('common.click'),
                    description: this.$t(
                        'page.campaign.desc_optimization_goal_click'
                    ),
                    value_goal: 'CLICK',
                    value_event: '',
                    value_billing_event: 'CPC',
                    shopping_ads_types: [
                        'LIVE',
                        'VIDEO',
                        'PRODUCT_SHOPPING_ADS'
                    ]
                },
                {
                    icon: 'fa-solid fa-hourglass',
                    label: this.$t('page.campaign.viewer_retention'),
                    description: this.$t(
                        'page.campaign.desc_optimization_goal_viewer_retention'
                    ),
                    value_goal: 'MT_LIVE_ROOM',
                    value_event: 'LIVE_STAY_TIME',
                    value_billing_event: 'OCPM',
                    shopping_ads_types: ['LIVE']
                },
                {
                    icon: 'fa-solid fa-shopping-bag',
                    label: this.$t('page.campaign.product_click_in_live'),
                    description: this.$t(
                        'page.campaign.desc_optimization_goal_product_click_in_live'
                    ),
                    value_goal: 'PRODUCT_CLICK_IN_LIVE',
                    value_event: 'LIVE_CLICK_PRODUCT_ACTION',
                    value_billing_event: 'OCPM',
                    shopping_ads_types: ['LIVE']
                },
                {
                    icon: 'fa-solid fa-paper-plane',
                    label: this.$t('page.campaign.initiate_checkout'),
                    description: this.$t(
                        'page.campaign.desc_optimization_goal_initiate_checkout'
                    ),
                    value_goal: 'CONVERT',
                    value_event: 'INITIATE_ORDER',
                    value_billing_event: 'OCPM',
                    shopping_ads_types: ['VIDEO', 'LIVE']
                },
                {
                    icon: 'fa-solid fa-paper-plane',
                    label: this.$t('page.campaign.initiate_checkout'),
                    description: this.$t(
                        'page.campaign.desc_optimization_goal_initiate_checkout'
                    ),
                    value_goal: 'CONVERT',
                    value_event: 'SHOPPING',
                    value_billing_event: 'OCPM',
                    shopping_ads_types: ['PRODUCT_SHOPPING_ADS']
                },
                {
                    icon: 'fa-solid fa-wallet',
                    label: this.$t('page.campaign.complete_payment'),
                    description: this.$t(
                        'page.campaign.desc_optimization_goal_complete_payment'
                    ),
                    value_goal: 'CONVERT',
                    value_event: 'SHOPPING',
                    value_billing_event: 'OCPM',
                    shopping_ads_types: ['VIDEO', 'LIVE']
                },
                {
                    icon: 'fa-solid fa-wallet',
                    label: this.$t('page.campaign.optimization_goal_gmv'),
                    description: this.$t(
                        'page.campaign.desc_optimization_goal_gmv'
                    ),
                    value_goal: 'VALUE',
                    value_event: 'SHOPPING',
                    value_billing_event: 'OCPM',
                    shopping_ads_types: [
                        'VIDEO',
                        'LIVE',
                        'PRODUCT_SHOPPING_ADS'
                    ]
                }
                // {
                //     icon: 'fa-solid fa-cloud-bolt',
                //     label: this.$t('page.campaign.value'),
                //     value_goal: 'VALUE',
                //     value_event: '',
                //     value_billing_event: 'OCPM',
                //     shopping_ads_types: ['LIVE']
                // }
            ]
        }
    },

    computed: {
        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        },

        show_radio_pacing() {
            return this.radio_bid_strategy !== 2
        },

        optimization_goal() {
            return this.selected_item_in_line.optimization_goal
        }
    },

    watch: {
        'selected_item.id'() {
            this.getInfoForState()
        },

        selected_item() {
            this.getInfoForState()
        },

        radio_pacing() {
            this.selected_item_in_line.pacing = this.radio_pacing
        },

        frequency() {
            this.selected_item_in_line.frequency = this.frequency
        },

        frequency_schedule() {
            this.selected_item_in_line.frequency_schedule =
                this.frequency_schedule
        },

        'selected_item_in_line.location_ids'() {
            this.fetchSuggestBid()
        }
    },

    mounted() {
        this.getInfoForState()
    },

    methods: {
        getInfoForState() {
            this.radio_bid_strategy = 2
            this.frequency = 1
            this.frequency_schedule = 1
            this.device_price_ranges = []
            this.toggle_collapse = []
            this.radio_frequency_cap = 1

            if (this.type_form !== 'edit') {
                this.selected_item_in_line.pacing = 'PACING_MODE_SMOOTH'
            }
            this.radio_pacing = this.selected_item_in_line.pacing

            if (
                this.selected_item_in_line?.frequency &&
                this.selected_item_in_line?.frequency_schedule
            ) {
                if (
                    this.selected_item_in_line.frequency === 3 &&
                    this.selected_item_in_line.frequency_schedule === 7
                ) {
                    this.radio_frequency_cap = 1
                } else if (
                    this.selected_item_in_line.frequency === 1 &&
                    this.selected_item_in_line.frequency_schedule === 1
                ) {
                    this.radio_frequency_cap = 2
                } else {
                    this.radio_frequency_cap = 3
                    this.frequency = this.selected_item_in_line.frequency
                    this.frequency_schedule =
                        this.selected_item_in_line.frequency_schedule
                }
            }

            if (this.selected_item_in_line?.bid_type === 'BID_TYPE_NO_BID') {
                this.radio_bid_strategy = 2
            } else if (
                this.selected_item_in_line?.bid_type === 'BID_TYPE_CUSTOM'
            ) {
                this.radio_bid_strategy =
                    this.selected_item_in_line?.billing_event === 'OCPM' ? 3 : 1
            }

            if (this.selected_item_in_line?.optimization_goal === 'VALUE') {
                if (
                    this.selected_item_in_line?.deep_bid_type ===
                    'VO_HIGHEST_VALUE'
                ) {
                    this.checked_set_minimum_roas = false
                } else if (
                    this.selected_item_in_line?.deep_bid_type === 'VO_MIN_ROAS'
                ) {
                    this.checked_set_minimum_roas = true
                }
            }
        },

        handleChangeSetMinimumRoas() {
            if (this.checked_set_minimum_roas) {
                this.selected_item_in_line.deep_bid_type = 'VO_MIN_ROAS'
                this.selected_item_in_line.roas_bid = 0.01
            } else {
                this.selected_item_in_line.deep_bid_type = 'VO_HIGHEST_VALUE'
                this.selected_item_in_line.roas_bid = null
            }
        },

        handleShowOptimizeGoal(item) {
            if (this.type_form === 'edit') {
                return (
                    this.selected_item_in_line.optimization_goal ===
                        item.value_goal &&
                    this.selected_item_in_line.optimization_event ===
                        item.value_event &&
                    item.shopping_ads_types.some(
                        (i) =>
                            i === this.selected_item_in_line.shopping_ads_type
                    )
                )
            }

            return item.shopping_ads_types.some(
                (i) => i === this.selected_item_in_line.shopping_ads_type
            )
        },

        handleChooseExternalEvent() {
            this.selected_item_in_line.extension.event_is_external = true

            this.selected_item_in_line.pixel_id = ''

            this.selected_item_in_line.optimization_goal = ''
            this.selected_item_in_line.optimization_event = ''
            this.selected_item_in_line.billing_event = ''
        },

        handleSelectOptimizationForShoppingLive(item) {
            this.selected_item_in_line.extension.event_is_external = false

            this.selected_item_in_line.pixel_id = ''

            this.selected_item_in_line.optimization_goal = item.value_goal
            this.selected_item_in_line.optimization_event = item.value_event
            if (
                this.selected_item_in_line.billing_event !==
                item.value_billing_event
            ) {
                this.selected_item_in_line.bid_price = 0
                this.selected_item_in_line.conversion_bid_price = 0

                this.radio_bid_strategy = 2
            }
            this.selected_item_in_line.billing_event = item.value_billing_event

            if (this.selected_item_in_line.optimization_goal === 'VALUE') {
                this.selected_item_in_line.deep_bid_type = 'VO_HIGHEST_VALUE'
                this.checked_set_minimum_roas = false
                this.handleChangeSetMinimumRoas()
            } else {
                this.selected_item_in_line.deep_bid_type = null
                this.selected_item_in_line.roas_bid = null
            }
        },

        handleChangeFrequencyCap(val) {
            if (val === 1) {
                this.selected_item_in_line.frequency = 3
                this.selected_item_in_line.frequency_schedule = 7
                return
            }
            if (val === 2) {
                this.selected_item_in_line.frequency = 1
                this.selected_item_in_line.frequency_schedule = 1
                return
            }

            this.selected_item_in_line.frequency = 3
            this.selected_item_in_line.frequency_schedule = 1
            this.frequency = 3
            this.frequency_schedule = 1
        },

        handleBlurFrequency() {
            if (
                this.frequency >
                this.frequency_schedule * NUM_IMPRESSIONS_EACH_DAYS
            ) {
                this.frequency =
                    this.frequency_schedule * NUM_IMPRESSIONS_EACH_DAYS
            }
        },

        handleChangeBidStrategy(val) {
            this.radio_pacing = 'PACING_MODE_SMOOTH'
            if (val === 1) {
                this.selected_item_in_line.bid_type = 'BID_TYPE_CUSTOM'
                this.selected_item_in_line.bid_price = 1
                return
            }
            if (val === 2) {
                this.selected_item_in_line.bid_type = 'BID_TYPE_NO_BID'
                return
            }
            if (val === 3) {
                this.selected_item_in_line.bid_type = 'BID_TYPE_CUSTOM'
                this.selected_item_in_line.bid_price = 0
                return
            }
        },

        handleCopyAdgroupWithNewAttribute(item) {
            this.p__event_bus.$emit('eb_clone_adgroup_with_new_attr', item)
        },

        async fetchSuggestBid() {
            // const response = await getSuggestedBid(this.tiktok_account_id, {
            //     advertiser_id: this.advertiser_id,
            //     objective_type: this.objective_type,
            //     location_ids: this.selected_item_in_line.location_ids
            // })
        },
        openGetCPA() {
            this.$emit('openGetCPA')
        }
    }
}
</script>
