<!-- eslint-disable vue/attribute-hyphenation -->
<template>
    <a :href="href" class="component-tag-anchor" target="_blank">
        {{ $t('common.learn_more') }}
    </a>
</template>

<script>
export default {
    props: ['href']
}
</script>
