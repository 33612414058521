const state = {
    s__business_centers: [],
    s__selected_business_center: null
}

const mutations = {
    s__setBusinessCenters(state, data) {
        state.s__business_centers = data
    },
    s__setSelectedBusinessCenter(state, data) {
        state.s__selected_business_center = data
    }
}

const actions = {
    s__setBusinessCenters(context, data) {
        context.commit('s__setBusinessCenters', data)
    },
    s__setSelectedBusinessCenter(context, data) {
        context.commit('s__setSelectedBusinessCenter', data)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
