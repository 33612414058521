import moment from 'moment'

export default {
    methods: {
        m__changeScheduleTime(
            edited_adgroup,
            objective_type,
            format_tiktok = true
        ) {
            if (!edited_adgroup) {
                return
            }

            const { schedule_start_time, schedule_end_time } = edited_adgroup
            if (!schedule_start_time) {
                return
            }

            const moment_schedule_start_time = moment(schedule_start_time)
            if (!moment_schedule_start_time.isValid()) {
                return
            }

            if (!schedule_end_time) {
                return
            }

            const moment_schedule_end_time = moment(schedule_end_time)
            if (!moment_schedule_end_time.isValid()) {
                return
            }

            const now = moment()

            if (
                moment_schedule_start_time < moment_schedule_end_time &&
                moment_schedule_end_time <= now
            ) {
                const hours = format_tiktok ? 7 : 0
                if (
                    objective_type === 'PRODUCT_SALES' &&
                    edited_adgroup.shopping_ads_type === 'LIVE'
                ) {
                    edited_adgroup.schedule_start_time = now
                        .subtract(hours, 'hours')
                        .add(10, 'minutes')
                        .format(this.p__format_schedule_tiktok_time)

                    edited_adgroup.schedule_end_time = now
                        .subtract(hours, 'hours')
                        .add(3, 'hours')
                        .format(this.p__format_schedule_tiktok_time)
                } else {
                    edited_adgroup.schedule_start_time = now
                        .subtract(hours, 'hours')
                        .add(10, 'minutes')
                        .format(this.p__format_schedule_tiktok_time)

                    edited_adgroup.schedule_end_time = now
                        .subtract(hours, 'hours')
                        .add(10, 'days')
                        .format(this.p__format_schedule_tiktok_time)
                }
            }
        },

        m__changeFormatScheduleTime(edited_adgroup) {
            if (!edited_adgroup) {
                return
            }

            if (!edited_adgroup?.schedule_type) {
                edited_adgroup.schedule_type = 'SCHEDULE_START_END'
            }

            const { schedule_start_time, schedule_end_time } = edited_adgroup
            if (!schedule_start_time) {
                return
            }

            const moment_schedule_start_time = moment(schedule_start_time)
            if (!moment_schedule_start_time.isValid()) {
                return
            }

            edited_adgroup.schedule_start_time = moment(
                edited_adgroup.schedule_start_time
            )
                .subtract(7, 'hours')
                .format(this.p__format_schedule_tiktok_time)

            if (!schedule_end_time) {
                return
            }

            const moment_schedule_end_time = moment(schedule_end_time)
            if (!moment_schedule_end_time.isValid()) {
                return
            }

            edited_adgroup.schedule_end_time = moment(
                edited_adgroup.schedule_end_time
            )
                .subtract(7, 'hours')
                .format(this.p__format_schedule_tiktok_time)
        },

        m__changeActionCategories(adgroup) {
            if (adgroup?.actions?.length > 0) {
                adgroup.actions = adgroup.actions.map((item) => {
                    item.action_category_ids = item.action_category_ids.map(
                        (i) => `${i}`
                    )

                    return item
                })
            }
        },

        m__validAdgroup(
            adgroup,
            min_budget_adgroup,
            objective_type,
            type_form = 'create'
        ) {
            if (!adgroup.adgroup_name) {
                this.p__showNotify(
                    'warning',
                    this.$t('page.campaign.please_input_adgroup_name')
                )

                document
                    .getElementById('input_adgroup_name_wrapper')
                    .scrollIntoView({ behavior: 'smooth' })
                document.getElementById('input_adgroup_name').focus()
                document.getElementById('input_adgroup_name').className +=
                    ' border-red border-[1px] rounded-[8px]'

                return false
            }

            if (adgroup?.actions?.length > 0) {
                for (let index = 0; index < adgroup.actions.length; index++) {
                    const action = adgroup.actions[index]

                    if (
                        action.action_scene === 'VIDEO_RELATED' &&
                        (action.action_category_ids.length ||
                            action.video_user_actions.length ||
                            action.action_period > 0)
                    ) {
                        if (!action.video_user_actions.length) {
                            this.p__showNotify(
                                'warning',
                                this.$t(
                                    'page.campaign.select_at_least_one_type_of_action'
                                )
                            )

                            document
                                .getElementById('video_interactions_wrapper')
                                .scrollIntoView({ behavior: 'smooth' })

                            return false
                        }

                        if (action.action_period === 0) {
                            this.p__showNotify(
                                'warning',
                                this.$t('page.campaign.select_time_period')
                            )

                            document
                                .getElementById('video_interactions_wrapper')
                                .scrollIntoView({ behavior: 'smooth' })

                            return false
                        }

                        if (!action.action_category_ids.length) {
                            this.p__showNotify(
                                'warning',
                                this.$t(
                                    'page.campaign.select_at_least_one_category'
                                )
                            )

                            document
                                .getElementById('input_adgroup_name_wrapper')
                                .scrollIntoView({ behavior: 'smooth' })

                            return false
                        }
                    }

                    if (
                        action.action_scene === 'CREATOR_RELATED' &&
                        (action.action_category_ids.length ||
                            action.video_user_actions.length)
                    ) {
                        if (!action.video_user_actions.length) {
                            this.p__showNotify(
                                'warning',
                                this.$t(
                                    'page.campaign.select_at_least_one_type_of_action'
                                )
                            )

                            document
                                .getElementById('creator_interactions_wrapper')
                                .scrollIntoView({ behavior: 'smooth' })

                            return false
                        }
                        if (!action.action_category_ids.length) {
                            this.p__showNotify(
                                'warning',
                                this.$t(
                                    'page.campaign.select_at_least_one_category'
                                )
                            )

                            document
                                .getElementById('creator_interactions_wrapper')
                                .scrollIntoView({ behavior: 'smooth' })

                            return false
                        }
                    }
                }
            }

            if (['TRAFFIC'].some((type) => type === objective_type)) {
                if (
                    !adgroup.promotion_type ||
                    (!adgroup.app_id && adgroup.promotion_type !== 'WEBSITE')
                ) {
                    this.p__showNotify(
                        'warning',
                        this.$t('page.campaign.please_select_app')
                    )

                    return false
                }
            }

            if (
                (objective_type === 'WEB_CONVERSIONS' &&
                    adgroup.extension.selected_type_web ===
                        'EXTERNAL_WEBSITE') ||
                (objective_type === 'PRODUCT_SALES' &&
                    adgroup.extension.event_is_external)
            ) {
                if (!adgroup.pixel_id) {
                    this.p__showNotify(
                        'warning',
                        this.$t('page.campaign.please_select_pixel')
                    )

                    return false
                }

                if (!adgroup.optimization_event) {
                    this.p__showNotify(
                        'warning',
                        this.$t('page.campaign.please_select_event')
                    )

                    return false
                }
            }

            if (['CATALOG_SALES'].some((type) => type === objective_type)) {
                if (!adgroup.catalog_id) {
                    this.p__showNotify(
                        'warning',
                        this.$t('page.campaign.please_select_catalog')
                    )

                    return false
                }
            }

            if (objective_type === 'APP_PROMOTION') {
                if (!adgroup.app_id) {
                    this.p__showNotify(
                        'warning',
                        this.$t('page.campaign.please_select_app')
                    )

                    return false
                }
            }

            if (objective_type === 'PRODUCT_SALES') {
                if (
                    !adgroup.store_id &&
                    adgroup.product_source !== 'SHOWCASE'
                ) {
                    this.p__showNotify(
                        'warning',
                        this.$t('page.campaign.please_select_store_id')
                    )

                    document
                        .getElementById('input_adgroup_store_wrapper')
                        .scrollIntoView({ behavior: 'smooth' })
                    document.getElementById('input_adgroup_store').focus()
                    document.getElementById('input_adgroup_store').className +=
                        ' border-red border-[1px] rounded-[8px]'

                    return false
                }

                // if (
                //     !adgroup.identity_id &&
                //     adgroup.shopping_ads_type !== 'PRODUCT_SHOPPING_ADS'
                // ) {
                //     this.p__showNotify(
                //         'warning',
                //         this.$t('page.campaign.please_select_identity_id')
                //     )

                //     document
                //         .getElementById('input_adgroup_identity_wrapper')
                //         .scrollIntoView({ behavior: 'smooth' })
                //     document.getElementById('input_adgroup_identity').focus()
                //     document.getElementById(
                //         'input_adgroup_identity'
                //     ).className += ' border-red border-[1px] rounded-[8px]'

                //     return false
                // }
            }

            if (!adgroup.location_ids.length) {
                this.p__showNotify(
                    'warning',
                    this.$t('page.campaign.please_select_location')
                )

                document
                    .getElementById('treeview_localtion_wrapper')
                    .scrollIntoView({ behavior: 'smooth' })
                document.getElementById('treeview_localtion').className +=
                    ' border-red border-[1px] rounded-[8px]'

                return false
            }

            if (adgroup.budget < min_budget_adgroup) {
                this.p__showNotify(
                    'warning',
                    this.$t('page.campaign.please_budget_than_min', {
                        budget: this.p__renderMoneyByCurrency(
                            min_budget_adgroup,
                            this.currency
                        )
                    })
                )

                document
                    .getElementById('input_budget_wrapper')
                    .scrollIntoView({ behavior: 'smooth' })
                document.getElementById('input_budget').className +=
                    ' border-red border-[1px] rounded-[8px]'

                return false
            }

            if (
                adgroup.extension.config_list.length > 0 &&
                adgroup.budget_mode === 'BUDGET_MODE_DAY'
            ) {
                let has_error = false
                let error_message = ''

                adgroup.extension.config_list.forEach((item) => {
                    if (item.value < min_budget_adgroup) {
                        has_error = true
                        error_message =
                            'Ngân sách trong kế hoạch điều chỉnh ngân sách phải lớn hoặc bằng ' +
                            this.p__renderMoneyByCurrency(
                                min_budget_adgroup,
                                this.currency
                            )

                        return
                    }

                    const start_time = moment(item.time)
                    const now = moment()
                    if (start_time < now) {
                        has_error = true
                        error_message =
                            'Thời gian trong kế hoạch điều chỉnh ngân sách phải lớn hơn thời gian hiện tại'

                        return
                    }
                })

                if (has_error) {
                    document
                        .getElementById('config_budget_wrapper')
                        .scrollIntoView({ behavior: 'smooth' })

                    this.p__showNotify('warning', error_message)
                    return false
                }
            }

            if (adgroup.bid_type === 'BID_TYPE_CUSTOM') {
                if (adgroup.billing_event !== 'OCPM') {
                    if (adgroup.bid_price <= 0) {
                        this.p__showNotify(
                            'warning',
                            this.$t('page.campaign.please_input_bid_price')
                        )

                        document
                            .getElementById('input_bid_wrapper')
                            .scrollIntoView({ behavior: 'smooth' })
                        document.getElementById('input_bid').className +=
                            ' border-red border-[1px] rounded-[8px]'

                        return false
                    }

                    if (+adgroup.bid_price >= +adgroup.budget) {
                        this.p__showNotify(
                            'warning',
                            this.$t(
                                'page.campaign.please_input_bid_price_lowser_budget'
                            )
                        )

                        document
                            .getElementById('input_bid_wrapper')
                            .scrollIntoView({ behavior: 'smooth' })
                        document.getElementById('input_bid').className +=
                            ' border-red border-[1px] rounded-[8px]'

                        return false
                    }
                }
                if (adgroup.billing_event === 'OCPM') {
                    if (adgroup.conversion_bid_price <= 0) {
                        this.p__showNotify(
                            'warning',
                            this.$t('page.campaign.please_input_conversion_bid')
                        )

                        document
                            .getElementById('input_bid_wrapper')
                            .scrollIntoView({ behavior: 'smooth' })
                        document.getElementById(
                            'input_conversion_bid'
                        ).className += ' border-red border-[1px] rounded-[8px]'

                        return false
                    }
                    if (+adgroup.conversion_bid_price >= +adgroup.budget) {
                        this.p__showNotify(
                            'warning',
                            this.$t(
                                'page.campaign.please_input_bid_price_lowser_budget'
                            )
                        )

                        document
                            .getElementById('input_bid_wrapper')
                            .scrollIntoView({ behavior: 'smooth' })
                        document.getElementById(
                            'input_conversion_bid'
                        ).className += ' border-red border-[1px] rounded-[8px]'

                        return false
                    }
                }
            }

            if (type_form !== 'edit') {
                const start_time = moment(adgroup.schedule_start_time)
                const now = moment()
                if (start_time < now) {
                    this.p__showNotify(
                        'warning',
                        this.$t(
                            'page.campaign.please_input_start_time_more_than_current'
                        )
                    )

                    document
                        .getElementById('schedule_ad_wrapper')
                        .scrollIntoView({ behavior: 'smooth' })
                    return false
                }

                if (adgroup.schedule_type !== 'SCHEDULE_FROM_NOW') {
                    const end_time = moment(adgroup.schedule_end_time)
                    if (end_time <= start_time) {
                        this.p__showNotify(
                            'warning',
                            this.$t(
                                'page.campaign.please_input_end_time_more_than_start_time'
                            )
                        )

                        document
                            .getElementById('schedule_ad_wrapper')
                            .scrollIntoView({ behavior: 'smooth' })
                        return false
                    }
                }
            }

            return true
        }
    }
}
