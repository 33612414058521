<template>
    <div class="component-box">
        <p class="font-semibold mb-8">
            {{ $t('page.campaign.ad_setup') }}
        </p>

        <div class="grid grid-cols-2 gap-4">
            <div
                v-for="(item, index) in optimization_goal_for_live_shopping"
                :key="index"
                class="border-[1px] border-l-[4px] h-14 pl-4 w-full bg-bg rounded-lg cursor-pointer flex items-center space-x-4"
                :class="[
                    selected_item_in_line.extension.shopping_live_ad_setup ===
                    item.value
                        ? 'border-brand-atosa'
                        : 'hover:border-border border-transparent'
                ]"
                @click="handleChangeSelectedSetup(item.value)"
            >
                <font-awesome-icon :icon="item.icon" />

                <p class="component-text-desc">
                    {{ item.label }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import paginationDataMixin from '@/plugins/mixins/pagination-data'

export default {
    mixins: [paginationDataMixin],

    props: ['selected_item', 'tiktok_account_id', 'advertiser_id', 'items'],

    data() {
        return {
            visible: false,
            optimization_goal_for_live_shopping: [
                {
                    icon: 'fa-solid fa-film',
                    label: this.$t('page.campaign.standard_video'),
                    value: 'standard_video'
                },
                {
                    icon: 'fa-solid fa-desktop',
                    label: this.$t('page.campaign.live_video'),
                    value: 'live_video'
                }
            ]
        }
    },

    computed: {
        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        },

        items_in_line: {
            get: function () {
                return this.items
            },
            set: function (val) {
                this.$emit('update:items', val)
            }
        }
    },

    methods: {
        handleChangeSelectedSetup(val) {
            if (this.items_in_line.length > 1 && val === 'live_video') {
                this.$confirm(
                    this.$t('page.campaign.message_clear_ad_list'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                ).then(() => {
                    this.selected_item_in_line.tiktok_item_id = ''
                    this.selected_item_in_line.video_id = ''
                    this.selected_item_in_line.extension.shopping_ads_type =
                        'LIVE'
                    this.selected_item_in_line.extension.shopping_live_ad_setup =
                        val
                    this.selected_item_in_line.extension.video =
                        this.p__default_state_infor_ad.extension.video

                    this.items_in_line = [
                        {
                            ...this.selected_item_in_line
                        }
                    ]

                    this.selected_item_in_line = this.items_in_line[0]
                })
            } else {
                this.selected_item_in_line.extension.shopping_live_ad_setup =
                    val
            }
        }
    }
}
</script>
