<template>
    <el-dialog
        :visible.sync="visible_in_line"
        width="640px"
        class="el-dialog-center"
        :append-to-body="true"
        :close-on-click-modal="true"
    >
        <div class="p-4">
            <img
                :src="require('@/assets/images/tiktok_analyst.png')"
                class="mx-auto h-[170px]"
                alt=""
            />
            <p
                class="text-lg leading-[1.7rem] text-center mt-6 break-word text-secondary text-desc-text"
                v-html="
                    $t('message.expired_package', {
                        name,
                        license_to
                    })
                "
            ></p>
        </div>

        <div class="mt-8 text-center">
            <el-button
                type="primary"
                size="medium"
                class="uppercase"
                @click="handleBuyPackage"
            >
                {{ $t('button.buy_package') }}
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
import moment from 'moment'

export default {
    props: ['visible', 'current_package'],

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        },

        license_to() {
            if (this.current_package) {
                return moment(this.current_package.license_to).format(
                    this.$t('common.format_date')
                )
            }

            return ''
        },

        name() {
            if (this.current_package) {
                const item = this.current_package.license.find(
                    (item) => item.is_extra
                )
                return item.package_data.name || ''
            }

            return ''
        }
    },

    methods: {
        handleBuyPackage() {
            this.visible_in_line = false
            location.href = '#pricing'
        }
    }
}
</script>
