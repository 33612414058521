<template>
    <el-dialog
        :visible.sync="visible_in_line"
        width="600px"
        class="el-dialog-center"
        :close-on-click-modal="true"
        :append-to-body="true"
        :show-close="false"
    >
        <el-carousel
            class="-mx-5 mt-[-46px]"
            height="480px"
            :interval="4444"
            trigger="click"
            indicator-position="none"
        >
            <el-carousel-item>
                <div>
                    <img
                        :src="require('@/assets/images/atodake.jpg')"
                        class="w-full h-[320px]"
                        alt=""
                    />
                    <div class="p-4">
                        <p
                            class="text-lg leading-[1.7rem] text-center font-semibold break-word"
                        >
                            {{ $t('common.introduce_atodake') }}
                        </p>

                        <div class="flex mt-4">
                            <el-button
                                type="primary"
                                size="medium"
                                class="uppercase mx-auto"
                                @click="
                                    handleRedirectToAnotherWeb(
                                        `https://atosa.asia/phan-mem-quan-ly-ban-hang-da-kenh-atosa/`
                                    )
                                "
                            >
                                {{ $t('button.im_interested') }}
                            </el-button>
                        </div>
                    </div>
                </div>
            </el-carousel-item>
            <el-carousel-item>
                <div>
                    <img
                        :src="require('@/assets/images/atosaca.png')"
                        class="w-full h-[320px]"
                        alt=""
                    />
                    <div class="p-4">
                        <p
                            class="text-lg leading-[1.7rem] text-center font-semibold break-word"
                        >
                            {{ $t('common.introduce_atosaca') }}
                        </p>

                        <div class="flex mt-4">
                            <el-button
                                type="primary"
                                size="medium"
                                class="uppercase mx-auto"
                                @click="
                                    handleRedirectToAnotherWeb(
                                        `https://atosa.asia/atosa-chat/`
                                    )
                                "
                            >
                                {{ $t('button.im_interested') }}
                            </el-button>
                        </div>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>

        <p class="text-sm text-right mt-2 break-word">
            {{ $t('common.description_introduce_atodake') }}
        </p>

        <div class="mt-5 flex items-center">
            <el-checkbox
                v-model="checked_never_show"
                @change="handleChangeNeverShow"
            >
                {{ $t('common.never_show_popup') }}
            </el-checkbox>

            <div class="!ml-auto"></div>

            <el-button
                size="medium"
                class="uppercase"
                @click="visible_in_line = false"
            >
                {{ $t('button.skip') }}
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: ['visible'],

    data() {
        return {
            checked_never_show: false
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        }
    },

    methods: {
        handleChangeNeverShow() {
            if (this.checked_never_show) {
                localStorage.setItem('checked_never_show_introduce_atodake', 1)
            } else {
                localStorage.removeItem('checked_never_show_introduce_atodake')
            }
        },

        handleRedirectToAnotherWeb(url) {
            window.open(url, '_blank')

            // this.visible_in_line = false
        }
    }
}
</script>
