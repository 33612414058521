var render = function render(){var _vm=this,_c=_vm._self._c;return _c('custom-drawer',{attrs:{"size":"60%","wrapper_closable":false,"visible":_vm.visible_in_line,"confirm_to_close":false},on:{"update:visible":function($event){_vm.visible_in_line=$event}}},[_c('template',{slot:"title"},[_c('div',{staticClass:"items-center h-full flex pl-4 pr-8 space-x-2"},[_c('p',{staticClass:"font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.video_library'))+" ")]),_c('div',{staticClass:"!ml-auto"}),_c('el-button',{attrs:{"icon":"el-icon-upload2","size":"small"},on:{"click":function($event){_vm.is_upload_video_library_drawer_displayed = true}}},[_vm._v(" "+_vm._s(_vm.$t('common.upload_file'))+" ")]),_c('el-button',{attrs:{"icon":"el-icon-refresh","size":"small"},on:{"click":_vm.handleRefresh}}),_c('el-button',{attrs:{"icon":"el-icon-check","type":"primary","size":"small","disabled":_vm.multiple_selection.length === 0},on:{"click":_vm.handleChooseVideoForAd}},[_vm._v(" "+_vm._s(_vm.$t('button.confirm'))+" ")])],1)]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.m__loading),expression:"m__loading"}],staticClass:"h-full pt-4 pb-8 pl-[84px] pr-12 overflow-auto bg-white",on:{"scroll":_vm.handleScroll}},[(_vm.m__data_for_pagination.length)?_c('div',{staticClass:"flex flex-wrap gap-4"},_vm._l((_vm.m__data_for_pagination),function(item){return _c('div',{key:item.video_id,staticClass:"w-[180px]",class:[
                    _vm.handleHasChoosen(item.video_id)
                        ? ''
                        : 'hover:opacity-80'
                ]},[_c('div',{staticClass:"w-auto h-[180px] rounded-lg cursor-pointer relative border-[1px]",class:[
                        _vm.handleHasChoosen(item.video_id)
                            ? 'border-brand-atosa'
                            : 'border-transparent bg-bg'
                    ],on:{"click":function($event){return _vm.handleChooseVideo(item)}}},[(_vm.handleHasChoosen(item.video_id))?_c('div',{staticClass:"absolute top-0 left-0 w-full h-full bg-brand-atosa opacity-20 z-20"}):_vm._e(),_c('img',{staticClass:"w-full h-full object-contain",attrs:{"src":item.video_cover_url,"alt":""}}),(item.displayable)?_c('div',{staticClass:"absolute top-1 right-2"},[_c('el-checkbox',{attrs:{"value":_vm.handleHasChoosen(item.video_id)}})],1):_c('div',[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('page.campaign.cannot_use_file'),"placement":"top"}},[_c('i',{staticClass:"el-icon-warning-outline absolute top-1 right-2"})])],1),(item.displayable)?_c('div',{staticClass:"absolute bottom-2 right-2 bg-black p-[2px] rounded-xl"},[_c('p',{staticClass:"text-white text-xxs"},[_vm._v(" "+_vm._s(_vm.p__renderDurationToMinute(item.duration))+" ")])]):_vm._e()]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":item.file_name,"placement":"top"}},[_c('p',{staticClass:"truncate text-xs mt-1"},[_vm._v(" "+_vm._s(item.file_name)+" ")])])],1)}),0):_c('div',{staticClass:"util-flex-center flex-col mt-14"},[_c('img',{staticClass:"w-[140px] h-[140px]",attrs:{"src":require('@/assets/images/no_data.png')}}),_c('p',{staticClass:"mt-4 text-desc-text text-sm"},[_vm._v(" "+_vm._s(_vm.$t('common.no_data'))+" ")])])]),_c('UploadVideoLibraryDrawer',{attrs:{"visible":_vm.is_upload_video_library_drawer_displayed,"tiktok_account_id":_vm.tiktok_account_id,"advertiser_id":_vm.advertiser_id,"belong_to_atosa":_vm.belong_to_atosa},on:{"update:visible":function($event){_vm.is_upload_video_library_drawer_displayed=$event}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }