import i18n from '@/plugins/language'

const state = {
    s__is_sidebar_opening: localStorage.getItem('sidebar_opening')
        ? !!+localStorage.getItem('sidebar_opening')
        : true,
    s__end_domain: 'asia',
    s__channel: 'tiktok_ads',
    s__website: 'https://atosa.asia/',
    s__currency: 'VND',
    s__language: 'vi'
}

const mutations = {
    s__toggleSidebar(state) {
        const opening = !state.s__is_sidebar_opening
        state.s__is_sidebar_opening = opening
        localStorage.setItem('sidebar_opening', opening ? 1 : 0)
    },
    s__setEndDomain(state, data) {
        if (data === 'io') {
            state.s__end_domain = data
            state.s__channel = 'tiktok_ads_global'
            state.s__website = 'https://atosa.io/'
            state.s__currency = 'USD'
        }

        if (localStorage.getItem('code_lang')) {
            state.s__language = localStorage.getItem('code_lang')
        } else if (data === 'io') {
            localStorage.setItem('code_lang', 'en')
            state.s__language = 'en'
            i18n.locale = 'en'
        } else {
            localStorage.setItem('code_lang', 'vi')
            state.s__language = 'vi'
            i18n.locale = 'vi'
        }
    },
    s__setCurrency(state, data) {
        state.s__currency = data
    }
}

const actions = {
    s__toggleSidebar(context) {
        context.commit('s__toggleSidebar')
    },
    s__setEndDomain(context, data) {
        context.commit('s__setEndDomain', data)
    },
    s__setCurrency(context, data) {
        context.commit('s__setCurrency', data)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
