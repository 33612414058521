<template>
    <div class="component-box">
        <p class="font-semibold mb-4">App</p>
        <div class="flex">
            <el-select
                v-model="selected_item_in_line.app_id"
                v-el-select-loadmore="loadMore"
                size="small"
                class="w-[320px]"
                :placeholder="$t('input.placeholder.please_select')"
            >
                <el-option-group label="App">
                    <el-option
                        v-for="item in m__data_for_pagination"
                        :key="item.app_id"
                        :label="item.app_name"
                        :value="item.app_id"
                    >
                        <div class="flex items-center space-x-2 py-1">
                            <el-avatar :size="32">
                                <img
                                    :src="
                                        item.icon.web_uri
                                            ? item.icon.web_uri
                                            : p__default_identity_image_uri
                                    "
                                />
                            </el-avatar>

                            <div class="flex flex-col">
                                <span
                                    class="leading-5 text-xs font-semibold"
                                >
                                    {{ item.app_name }}
                                </span>
                                <span class="component-text-desc">
                                    ID : {{ item.app_id }}
                                </span>
                            </div>
                        </div>
                    </el-option>
                </el-option-group>
            </el-select>

            <el-button
                icon="el-icon-refresh"
                size="small"
                class="ml-2"
                @click="handleRefresh"
            ></el-button>
        </div>
    </div>
</template>

<script>
import { getAppList } from '@/services/atosa-tiktok-ads/event'
import paginationDataMixin from '@/plugins/mixins/pagination-data'

export default {
    mixins: [paginationDataMixin],

    props: ['selected_item', 'tiktok_account_id', 'advertiser_id', 'bc_id'],

    computed: {
        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        }
    },

    mounted() {
        this.fetchAppList()
    },

    methods: {
        handleRefresh() {
            this.m__page_info.page = 1
            this.m__data_for_pagination = []

            this.fetchAppList()
        },

        async fetchAppList() {
            try {
                const response = await getAppList(this.tiktok_account_id, {
                    advertiser_id: this.advertiser_id
                })

                this.m__data_for_pagination = [
                    ...this.m__data_for_pagination,
                    ...response.data.apps
                ]

                this.m__page_info = response.data.page_info
            } catch (error) {
                console.error(error)
            }
        },

        loadMore() {
            if (this.m__loading) {
                return
            }

            if (this.m__page_info.page < this.m__page_info.total_page) {
                this.m__page_info.page++
                this.fetchAppList()
            }
        }
    }
}
</script>
