import moment from 'moment'
import { p__getRangerDateFromThen } from '@/plugins/prototypes'

export default {
    data() {
        const getRangeDateYesterday = () => {
            const start_date = moment().subtract(1, 'd').format('YYYY-MM-DD')
            return [start_date, start_date]
        }

        const getRangeDateLastWeek = () => {
            const lastWeek = moment().subtract(7, 'days')

            const start_date = lastWeek.startOf('week').format('YYYY-MM-DD')

            const end_date = lastWeek.endOf('week').format('YYYY-MM-DD')

            return [start_date, end_date]
        }

        const getRangeDateThisWeek = () => {
            const end_date = moment().format('YYYY-MM-DD')
            const start_date = moment().startOf('week').format('YYYY-MM-DD')
            return [start_date, end_date]
        }

        const getRangeDateThisMonth = () => {
            const end_date = moment().format('YYYY-MM-DD')
            const start_date = moment().startOf('month').format('YYYY-MM-DD')
            return [start_date, end_date]
        }

        const getRangeDateLastMonth = () => {
            const start_date = moment()
                .subtract(1, 'months')
                .startOf('month')
                .format('YYYY-MM-DD')
            const end_date = moment()
                .subtract(1, 'months')
                .endOf('month')
                .format('YYYY-MM-DD')
            return [start_date, end_date]
        }

        const getRangerDateQuarterThisYear = (quarter) => {
            const startOfQuarter = moment().startOf('year').quarter(quarter)

            const endOfQuarter = moment().endOf('year').quarter(quarter)

            return [
                startOfQuarter.format('YYYY-MM-DD'),
                endOfQuarter.format('YYYY-MM-DD')
            ]
        }

        const getRangerDateQuarterLastYear = (quarter) => {
            const startOfQuarter = moment()
                .subtract(1, 'year')
                .startOf('year')
                .quarter(quarter)

            const endOfQuarter = moment()
                .subtract(1, 'year')
                .endOf('year')
                .quarter(quarter)

            return [
                startOfQuarter.format('YYYY-MM-DD'),
                endOfQuarter.format('YYYY-MM-DD')
            ]
        }

        return {
            m__from_date: null,
            m__ranger_date: this.p__getRangerDateFromThen(7),

            m__picker_options: {
                disabledDate: this.disabledEndDate,
                onPick: this.onPick,
                shortcuts: [
                    {
                        text: this.$t('common.today'),
                        onClick(picker) {
                            picker.$emit('pick', p__getRangerDateFromThen(0))
                        }
                    },
                    {
                        text: this.$t('common.yesterday'),
                        onClick(picker) {
                            picker.$emit('pick', getRangeDateYesterday())
                        }
                    },
                    {
                        text: this.$t('date_picker.options.last_xxx_days', {
                            xxx: 7
                        }),
                        onClick(picker) {
                            picker.$emit('pick', p__getRangerDateFromThen(7))
                        }
                    },
                    {
                        text: this.$t('date_picker.options.last_xxx_days', {
                            xxx: 14
                        }),
                        onClick(picker) {
                            picker.$emit('pick', p__getRangerDateFromThen(14))
                        }
                    },
                    {
                        text: this.$t('date_picker.options.last_xxx_days', {
                            xxx: 30
                        }),
                        onClick(picker) {
                            picker.$emit('pick', p__getRangerDateFromThen(30))
                        }
                    },
                    {
                        text: this.$t('common.this_week'),
                        onClick(picker) {
                            picker.$emit('pick', getRangeDateThisWeek())
                        }
                    },
                    {
                        text: this.$t('common.last_week'),
                        onClick(picker) {
                            picker.$emit('pick', getRangeDateLastWeek())
                        }
                    },
                    {
                        text: this.$t('common.this_month'),
                        onClick(picker) {
                            picker.$emit('pick', getRangeDateThisMonth())
                        }
                    },
                    {
                        text: this.$t('common.last_month'),
                        onClick(picker) {
                            picker.$emit('pick', getRangeDateLastMonth())
                        }
                    },
                    {
                        text: this.$t('common.this_month_last_year'),
                        onClick(picker) {
                            picker.$emit('pick', () => {
                                const startDate = moment().startOf('month')

                                const endDate = moment().endOf('month')

                                const ranger = {
                                    start: moment(startDate)
                                        .subtract(1, 'year')
                                        .format('YYYY-MM-DD'),
                                    end: moment(endDate)
                                        .subtract(1, 'year')
                                        .format('YYYY-MM-DD')
                                }

                                return [ranger.start, ranger.end]
                            })
                        }
                    },
                    {
                        text: this.$t('common.last_month_last_year'),
                        onClick(picker) {
                            picker.$emit('pick', () => {
                                const startDate = moment()
                                    .subtract(1, 'month')
                                    .subtract(1, 'year')
                                    .startOf('month')

                                const endDate = moment()
                                    .subtract(1, 'month')
                                    .subtract(1, 'year')
                                    .endOf('month')

                                return [
                                    startDate.format('YYYY-MM-DD'),
                                    endDate.format('YYYY-MM-DD')
                                ]
                            })
                        }
                    },
                    {
                        text: this.$t('common._1st_quarter'),
                        onClick(picker) {
                            picker.$emit(
                                'pick',
                                getRangerDateQuarterThisYear(1)
                            )
                        }
                    },
                    {
                        text: this.$t('common._2nd_quarter'),
                        onClick(picker) {
                            picker.$emit(
                                'pick',
                                getRangerDateQuarterThisYear(2)
                            )
                        }
                    },
                    {
                        text: this.$t('common._3rd_quarter'),
                        onClick(picker) {
                            picker.$emit(
                                'pick',
                                getRangerDateQuarterThisYear(3)
                            )
                        }
                    },
                    {
                        text: this.$t('common._4th_quarter'),

                        onClick(picker) {
                            picker.$emit(
                                'pick',
                                getRangerDateQuarterThisYear(4)
                            )
                        }
                    },
                    {
                        text: this.$t('common._1st_quarter_last_year'),
                        onClick(picker) {
                            picker.$emit(
                                'pick',
                                getRangerDateQuarterLastYear(1)
                            )
                        }
                    },
                    {
                        text: this.$t('common._2nd_quarter_last_year'),
                        onClick(picker) {
                            picker.$emit(
                                'pick',
                                getRangerDateQuarterLastYear(2)
                            )
                        }
                    },
                    {
                        text: this.$t('common._3rd_quarter_last_year'),
                        onClick(picker) {
                            picker.$emit(
                                'pick',
                                getRangerDateQuarterLastYear(3)
                            )
                        }
                    },
                    {
                        text: this.$t('common._4th_quarter_last_year'),
                        onClick(picker) {
                            picker.$emit(
                                'pick',
                                getRangerDateQuarterLastYear(4)
                            )
                        }
                    }
                ]
            }
        }
    },

    methods: {
        onPick({ maxDate, minDate }) {
            this.from_date = minDate
        },

        disabledEndDate(date) {
            const moment_date = moment(date)
            if (moment_date > moment()) {
                return true
            }

            if (this.from_date) {
                const moment_from_date = moment(this.from_date)
                const diff = moment_from_date.diff(moment_date, 'days')
                if (diff > 365 || diff < -365) {
                    return true
                }
            }
            return false
        }
    }
}
