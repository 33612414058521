import request from './_config'

const path = `tiktok_accounts`

export function getTiktokAccounts() {
    return request({
        url: path,
        method: 'get'
    })
}

export function getTiktokAccountsNotPackage() {
    return request({
        url: `${path}/not_package`,
        method: 'get'
    })
}

// export function createTiktokAccountSeller(data) {
//     return request({
//         url: `${path}/create_tiktok_account_seller`,
//         method: 'post',
//         data: {
//             auth_code: data.auth_code,
//             tiktok_shop_id: data.tiktok_shop_id
//         }
//     })
// }

export function editCustomInfoTiktokAccount(tiktok_account_id, formData) {
    return request({
        url: `${path}/custom_info/${tiktok_account_id}`,
        method: 'post',
        headers: { 'Content-Type': 'multipart/form-data' },
        data: formData
    })
}

export function authenTiktokAccount(data) {
    return request({
        url: path,
        method: 'post',
        data: {
            auth_code: data.auth_code
        }
    })
}

export function getAuthenticationLink() {
    return request({
        url: `${path}/get_authentication_link`,
        method: 'get'
    })
}

export function getSellerAuthenticationLink() {
    return request({
        url: `${path}/get_seller_authentication_link`,
        method: 'get'
    })
}

export function disconnectTiktokAccount(data) {
    return request({
        url: `${path}`,
        method: 'delete',
        data: {
            tiktok_account_id: data.tiktok_account_id
        }
    })
}
