const state = {
    s__has_fetch: false,
    s__tiktok_businesses: [],
    s__selected_tiktok_business: null,
    s__advertiser_accounts: [],
    s__selected_advertiser_account: null
}

const mutations = {
    s__setTiktokBusinesses(state, data) {
        state.s__tiktok_businesses = data
        state.s__has_fetch = true
    },
    s__setSelectedTiktokBusiness(state, data) {
        state.s__selected_tiktok_business = data
    },
    s__setAdvertiserAccounts(state, data) {
        state.s__advertiser_accounts = data
    },
    s__setSelectedAdvertiserAccount(state, data) {
        state.s__selected_advertiser_account = data
    }
}

const actions = {
    s__setTiktokBusinesses(context, data) {
        context.commit('s__setTiktokBusinesses', data)
    },
    s__setSelectedTiktokBusiness(context, data) {
        context.commit('s__setSelectedTiktokBusiness', data)
    },
    s__setAdvertiserAccounts(context, data) {
        context.commit('s__setAdvertiserAccounts', data)
    },
    s__setSelectedAdvertiserAccount(context, data) {
        context.commit('s__setSelectedAdvertiserAccount', data)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
