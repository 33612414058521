<template>
    <div class="flex flex-col space-y-6">
        <div>
            <p class="component-label">
                {{ $t('common.include') }}
            </p>
            <div class="flex items-center space-x-1">
                <el-select
                    v-model="audience_ids"
                    size="small"
                    multiple
                    :placeholder="$t('input.placeholder.please_select')"
                    filterable
                    class="w-full"
                    @change="
                        (val) => (selected_item_in_line.audience_ids = val)
                    "
                >
                    <el-option
                        v-for="item in audience_options"
                        :key="item.audience_id"
                        :label="item.name"
                        :value="item.audience_id"
                        :disabled="
                            excluded_audience_ids.some(
                                (i) => i === item.audience_id
                            )
                        "
                    >
                    </el-option>
                </el-select>

                <el-button
                    icon="el-icon-refresh"
                    size="small"
                    @click="fetchAudiences"
                ></el-button>
            </div>
        </div>

        <div>
            <p class="component-label">
                {{ $t('common.exclude') }}
            </p>

            <div class="flex items-center space-x-1">
                <el-select
                    v-model="excluded_audience_ids"
                    size="small"
                    multiple
                    :placeholder="$t('input.placeholder.please_select')"
                    filterable
                    class="w-full"
                    @change="
                        (val) =>
                            (selected_item_in_line.excluded_audience_ids = val)
                    "
                >
                    <el-option
                        v-for="item in audience_options"
                        :key="item.audience_id"
                        :label="item.name"
                        :value="item.audience_id"
                        :disabled="
                            audience_ids.some((i) => i === item.audience_id)
                        "
                    >
                    </el-option>
                </el-select>

                <el-button
                    icon="el-icon-refresh"
                    size="small"
                    @click="fetchAudiences"
                ></el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { getAllAudiences } from '@/services/atosa-tiktok-ads/audience'

export default {
    props: ['selected_item', 'tiktok_account_id', 'advertiser_id'],

    data() {
        return {
            audience_ids: [],
            excluded_audience_ids: [],
            audience_options: []
        }
    },

    computed: {
        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        }
    },

    watch: {
        'selected_item.id'() {
            this.initData()
        },

        selected_item() {
            this.getInfoForState()
        }
    },

    mounted() {
        this.initData()
    },

    methods: {
        initData() {
            this.getInfoForState()
            this.fetchAudiences()
        },

        handleChangeGender(val) {
            this.selected_item_in_line.gender = val
        },

        getInfoForState() {
            this.audience_ids = this.selected_item_in_line?.audience_ids?.length
                ? this.selected_item_in_line.audience_ids
                : []
            this.excluded_audience_ids = this.selected_item_in_line
                ?.excluded_audience_ids?.length
                ? this.selected_item_in_line.excluded_audience_ids
                : []
        },

        async fetchAudiences() {
            const response = await getAllAudiences(this.tiktok_account_id, {
                advertiser_id: this.advertiser_id,
                page: 1,
                page_size: 100
            })

            if (response.code === 0) {
                this.audience_options = response.data.list.filter(
                    (item) => item.is_valid
                )

                // if (this.audience_options) {
                //     if (this.audience_ids.length) {
                //         this.audience_ids = this.audience_ids.filter((item) => {
                //             const temp = this.audience_options.find(
                //                 (i) => i.audience_id === item.audience_id
                //             )

                //             if (temp) {
                //                 return true
                //             }

                //             return false
                //         })
                //     }

                //     if (this.audience_ids.length) {
                //         this.excluded_audience_ids =
                //             this.excluded_audience_ids.filter((item) => {
                //                 const temp = this.audience_options.find(
                //                     (i) => i.audience_id === item.audience_id
                //                 )

                //                 if (temp) {
                //                     return true
                //                 }

                //                 return false
                //             })
                //     }
                // }
            }
        }
    }
}
</script>
