import request from './_config'

const path = `showcase`

export function getIdentityShowcase(tiktok_account_id, params) {
    return request({
        url: `${path}/identity/${tiktok_account_id}`,
        method: 'get',
        params: {
            advertiser_id: params.advertiser_id
        }
    })
}

export function getProductShowcase(tiktok_account_id, params) {
    return request({
        url: `${path}/product/${tiktok_account_id}`,
        method: 'get',
        params: {
            advertiser_id: params.advertiser_id,
            identity_id: params.identity_id,
            region_codes: params.region_codes,
            identity_type: params.identity_type,
            identity_authorized_bc_id: params.identity_authorized_bc_id,
            page: params.page,
            page_size: params.page_size
        }
    })
}
