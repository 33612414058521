<template>
    <div class="flex flex-col space-y-6">
        <div
            v-if="objective_type !== 'CATALOG_SALES'"
            id="treeview_localtion_wrapper"
        >
            <p class="component-label">
                {{ $t('common.location') }}
            </p>

            <treeselect
                id="treeview_localtion"
                v-model="location_ids"
                multiple
                :options="location_options"
                :placeholder="$t('input.placeholder.please_select')"
                :normalizer="normalizer_location_option"
                @input="handleChangeLocations"
                @select="handleSelectLocations"
                @deselect="handleDeselectLocations"
            />
        </div>

        <div
            v-if="selected_item_in_line.creative_material_mode !== 'DYNAMIC'"
            id="gender_wrapper"
        >
            <p class="component-label">{{ $t('common.gender') }}</p>
            <el-radio-group
                v-model="gender"
                size="small"
                @change="handleChangeGender"
            >
                <el-radio-button
                    v-for="item in p__gender_options"
                    :key="item.value"
                    :label="item.value"
                >
                    {{ item.label }}
                </el-radio-button>
            </el-radio-group>
        </div>

        <div
            v-if="selected_item_in_line.creative_material_mode !== 'DYNAMIC'"
            id="age_wrapper"
        >
            <p class="component-label">
                {{ $t('common.age') }}
            </p>
            <el-checkbox-group
                v-model="age_groups"
                size="small"
                @change="
                    (val) => handleChangeCheckboxGroupWithAll(val, 'age_groups')
                "
            >
                <el-checkbox-button
                    v-for="item in age_group_options"
                    :key="item.value"
                    :label="item.value"
                >
                    {{ item.label }}
                </el-checkbox-button>
            </el-checkbox-group>
        </div>

        <div id="language_wrapper">
            <p class="component-label">
                {{ $t('common.language') }}
            </p>
            <el-select
                v-model="languages"
                size="small"
                multiple
                :placeholder="$t('input.placeholder.select_all')"
                filterable
                class="w-full"
                @change="handleChangeLanguages"
            >
                <el-option
                    v-for="item in language_options"
                    :key="item.code"
                    :label="item.name"
                    :value="`${item.code}`"
                >
                </el-option>
            </el-select>
        </div>
    </div>
</template>

<script>
import { getQueryTool } from '@/services/atosa-tiktok-ads/tool'
import Treeselect from '@riophae/vue-treeselect'
// import { getCatalogs } from '@/services/atosa-tiktok-ads/catalog'

export default {
    components: {
        Treeselect
    },

    props: [
        'objective_type',
        'selected_item',
        'tiktok_account_id',
        'bc_id',
        'advertiser_id'
    ],

    data() {
        return {
            normalizer_location_option(node) {
                return {
                    id: node.location_id,
                    label: node.name,
                    children: node.sub_locations
                }
            },
            languages: [],
            language_options: [],
            location_ids: [],
            location_options: [],
            gender: '',

            age_groups: [],
            age_group_options: [
                {
                    label: this.$t('common.all'),
                    value: 'ALL'
                },
                ...this.p__age_options
            ]
        }
    },

    computed: {
        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        }
    },

    watch: {
        'selected_item.id'() {
            this.initData()
        },

        selected_item() {
            this.getInfoForState()

            this.fetchLocations()
        },

        'selected_item.brand_safety_type'() {
            this.location_ids = []

            this.handleChangeLocations([])

            this.fetchLocations()
        },

        'selected_item.shopping_ads_type'() {
            if (
                this.selected_item.shopping_ads_type === 'PRODUCT_SHOPPING_ADS'
            ) {
                this.age_group_options = [...this.p__age_options.slice(1)]
                this.age_groups = this.age_group_options.map(
                    (item) => item.value
                )

                this.handleChangeCheckboxGroupWithAll(
                    this.age_groups,
                    'age_groups'
                )
            } else {
                this.age_group_options = [
                    {
                        label: this.$t('common.all'),
                        value: 'ALL'
                    },
                    ...this.p__age_options
                ]

                this.age_groups = ['ALL']

                this.handleChangeCheckboxGroupWithAll(['ALL'], 'age_groups')
            }
        },

        objective_type() {
            this.fetchLocations()
        },

        'selected_item.creative_material_mode'() {
            this.location_ids = []
            this.handleChangeLocations([])

            this.languages = []
            this.selected_item_in_line.languages = []
        }
    },

    mounted() {
        this.initData()
    },

    methods: {
        initData() {
            this.getInfoForState()
            this.fetchLocations()
            this.fetchLanguage()
        },

        getInfoForState() {
            this.gender = this.selected_item_in_line.gender

            this.languages = this.selected_item_in_line?.languages?.length
                ? this.selected_item_in_line.languages
                : []

            if (!this.selected_item_in_line?.age_groups?.length) {
                this.selected_item_in_line.age_groups = ['ALL']
            }
            this.age_groups = [...this.selected_item_in_line.age_groups]

            this.location_ids = this.selected_item_in_line?.location_ids?.length
                ? [...this.selected_item_in_line.location_ids]
                : []
        },

        // async fetchDetailCatalog() {
        //     const response = await getCatalogs(this.tiktok_account_id, {
        //         bc_id: this.bc_id,
        //         catalog_id: '7494705164330043649',
        //         page: 1
        //     })

        // },

        handleChangeLanguages(val) {
            this.selected_item_in_line.languages = val

            this.selected_item_in_line.extension.languages = val.map((item) => {
                const temp = this.language_options.find(
                    (it) => it.code === item
                )

                if (temp) {
                    return temp.name
                }
                return item
            })
        },

        handleChangeGender(val) {
            this.selected_item_in_line.gender = val
        },

        handleSelectLocations(val) {
            this.selected_item_in_line.extension.location_names = [
                ...new Set([
                    ...this.selected_item_in_line.extension.location_names,
                    val.name
                ])
            ]

            this.selected_item_in_line.extension.country_codes = [
                ...new Set([
                    ...this.selected_item_in_line.extension.country_codes,
                    val.region_code
                ])
            ]
        },

        handleDeselectLocations(val) {
            this.selected_item_in_line.extension.location_names =
                this.selected_item_in_line.extension.location_names.filter(
                    (item) => item !== val.name
                )

            this.selected_item_in_line.extension.country_codes =
                this.selected_item_in_line.extension.country_codes.filter(
                    (item) => item !== val.region_code
                )
        },

        handleChangeCheckboxGroupWithAll(val, name) {
            if (val.some((item) => item === 'ALL')) {
                if (
                    this.selected_item_in_line[name].some(
                        (item) => item === 'ALL'
                    )
                ) {
                    this.selected_item_in_line[name] = val.filter(
                        (item) => item !== 'ALL'
                    )
                    this[name] = val.filter((item) => item !== 'ALL')
                } else {
                    this.selected_item_in_line[name] = ['ALL']
                    this[name] = ['ALL']
                }
            } else {
                this.selected_item_in_line[name] = val
                this[name] = val
            }
        },

        async fetchLanguage() {
            const response = await getQueryTool(this.tiktok_account_id, {
                path: 'language/',
                params: {
                    advertiser_id: this.advertiser_id
                }
            })

            if (response.code === 0) {
                this.language_options = this.p__swapItemInArrayByIndex(
                    this.p__sortArrByName(response.data.languages),
                    'code',
                    'vi'
                )

                // this.p__event_bus.$emit(
                //     'eb_get_language_options',
                //     this.language_options
                // )
            }
        },

        handleChangeLocations(val) {
            this.selected_item_in_line.location_ids = val
        },

        async fetchLocations() {
            if (!this.objective_type) {
                return
            }

            const response = await getQueryTool(this.tiktok_account_id, {
                path: 'region/',
                params: {
                    advertiser_id: this.advertiser_id,
                    placements: JSON.stringify(
                        this.selected_item_in_line.placements
                    ),
                    objective_type:
                        this.objective_type === 'SHOP_PURCHASES'
                            ? 'PRODUCT_SALES'
                            : this.objective_type,
                    brand_safety_type:
                        this.selected_item_in_line.brand_safety_type
                }
            })

            const location_options = []
            const country_codes = []

            const { region_info } = response.data

            region_info.forEach((item) => {
                if (this.location_ids.some((id) => id === item.location_id)) {
                    country_codes.push(item.region_code)
                }
                if (item.level !== 'COUNTRY') {
                    return
                }

                if (item.next_level_ids?.length) {
                    const sub_locations = this.getSubLocations(
                        item.next_level_ids,
                        region_info,
                        country_codes
                    )

                    if (sub_locations.length > 0) {
                        item.sub_locations = sub_locations
                    }
                }

                location_options.push(item)
            })

            if (country_codes.length) {
                this.selected_item_in_line.extension.country_codes =
                    country_codes
            }

            this.location_options = this.p__swapItemInArrayByIndex(
                this.p__sortArrByName(location_options),
                'region_code',
                'VN'
            )
        },

        getSubLocations(next_level_ids, locations, country_codes) {
            const sub_locations = []
            next_level_ids.forEach((id) => {
                const location = locations.find((c) => c.location_id === id)

                if (location) {
                    if (
                        this.location_ids.some(
                            (id) => id === location.location_id
                        )
                    ) {
                        country_codes.push(location.region_code)
                    }

                    if (location.next_level_ids?.length) {
                        location.sub_locations = null

                        const sub_locations = this.getSubLocations(
                            location.next_level_ids,
                            locations,
                            country_codes
                        )

                        location.sub_locations = sub_locations
                    }

                    sub_locations.push(location)
                }
            })

            return this.p__sortArrByName(sub_locations, 'name')
        }
    }
}
</script>
