<template>
    <div class="component-box w-full">
        <div class="flex justify-between items-center">
            <p class="text-xs font-semibold">
                {{ $t('page.campaign.list_custom_target') }}
            </p>

            <el-button
                size="mini"
                class="ml-auto"
                @click="is_list_template_drawer_displayed = true"
            >
                {{ $t('button.see_details') }}
            </el-button>
        </div>

        <p class="text-xs text-desc-text mt-2 italic">
            * {{ $t('page.campaign.desc_custom_target') }}
        </p>

        <div class="flex mt-4">
            <el-button
                icon="el-icon-plus"
                size="small"
                class="mx-auto"
                @click="is_show_create_custom_target_dialog = true"
            >
                {{ $t('button.save_custom_target') }}
            </el-button>
        </div>

        <CreateCustomTargetDialog
            :visible.sync="is_show_create_custom_target_dialog"
            :tiktok_account_id="tiktok_account_id"
            :items="items_inline"
            :objective_type="objective_type"
        ></CreateCustomTargetDialog>

        <ListAdgroupCustomTargetDrawer
            :visible.sync="is_list_template_drawer_displayed"
            :tiktok_account_id="tiktok_account_id"
            :objective_type="objective_type"
            :items.sync="items_inline"
            :selected_item.sync="selected_item_inline"
        ></ListAdgroupCustomTargetDrawer>
    </div>
</template>

<script>
import ListAdgroupCustomTargetDrawer from './list-adgroup-custom-target-drawer'
import CreateCustomTargetDialog from './create-custom-target-dialog'

export default {
    components: {
        ListAdgroupCustomTargetDrawer,
        CreateCustomTargetDialog
    },

    props: ['tiktok_account_id', 'objective_type', 'items', 'selected_item'],

    data() {
        return {
            is_show_create_custom_target_dialog: false,
            is_list_template_drawer_displayed: false
        }
    },

    computed: {
        items_inline: {
            get() {
                return this.items
            },
            set(value) {
                this.$emit('update:items', value)
            }
        },

        selected_item_inline: {
            get() {
                return this.selected_item
            },
            set(value) {
                this.$emit('update:selected_item', value)
            }
        }
    }
}
</script>
