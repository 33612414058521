<template>
    <div
        id="video_interactions_wrapper"
        class="border-border border-[1px] rounded-lg p-4"
    >
        <div class="flex items-center space-x-2">
            <p class="component-label">
                {{ $t('page.campaign.video_interactions') }}
            </p>

            <div class="!ml-auto"></div>

            <p
                class="underline underline-offset-1 cursor-pointer text-brand-atosa text-xs"
                @click="is_show_create_target_dialog = true"
            >
                {{ $t('common.save') }}
            </p>

            <p
                class="underline underline-offset-1 cursor-pointer text-brand-atosa text-xs"
                @click="
                    show_list_save_target_table = !show_list_save_target_table
                "
            >
                {{
                    show_list_save_target_table
                        ? $t('common.close_list')
                        : $t('common.list')
                }}
            </p>

            <i
                class="el-icon-delete text-sm cursor-pointer"
                @click="handleUnselected"
            ></i>
        </div>

        <p class="component-text-desc mt-0 mb-3">
            {{ $t('page.campaign.desc_video_interaction') }}
        </p>

        <treeselect
            v-model="action_category_ids"
            multiple
            :options="related_options"
            :placeholder="$t('input.placeholder.please_select')"
            :normalizer="normalizer_option"
            @input="handleChangeVideoInteraction"
        />

        <el-table
            v-if="show_list_save_target_table"
            max-height="400"
            :data="list_custom_targets"
            class="mt-2"
        >
            <el-table-column width="260" :label="$t('common.name')">
                <template #default="scope">
                    <div class="flex space-x-2">
                        <div>
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="scope.row.custom_target_name"
                                placement="top"
                            >
                                <p class="font-semibold truncate">
                                    {{ scope.row.custom_target_name }}
                                </p>
                            </el-tooltip>
                        </div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column :label="$t('table.header.action')" min-width="160">
                <template slot-scope="scope">
                    <div>
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="$t('common.add_to_target')"
                            placement="top"
                        >
                            <el-button
                                size="small"
                                icon="el-icon-plus"
                                @click="
                                    handleAddCustomTargetToList(
                                        scope.row.data_config
                                    )
                                "
                            >
                            </el-button>
                        </el-tooltip>

                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="$t('common.remove_to_target')"
                            placement="top"
                        >
                            <el-button
                                size="small"
                                icon="el-icon-minus"
                                @click="
                                    handleRemoveCustomTargetToList(
                                        scope.row.data_config
                                    )
                                "
                            >
                            </el-button>
                        </el-tooltip>

                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="$t('common.replate_to_target')"
                            placement="top"
                        >
                            <el-button
                                size="small"
                                icon="el-icon-aim"
                                @click="
                                    handleReplaceCustomTargetToList(
                                        scope.row.data_config
                                    )
                                "
                            >
                            </el-button>
                        </el-tooltip>
                    </div>
                </template>
            </el-table-column>

            <el-table-column
                :label="$t('common.option')"
                fixed="right"
                width="100"
            >
                <template slot-scope="scope">
                    <el-button
                        type="danger"
                        size="small"
                        @click="handleDeleteCustomTarget(scope.row._id)"
                    >
                        {{ $t('button.delete') }}
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <p class="component-label text-desc-text mt-4">
            {{ $t('page.campaign.desc_video_interactions') }}
        </p>
        <div class="flex space-x-4">
            <el-checkbox
                v-model="checked_watched_till_end"
                @change="handleChangeSelected"
            >
                {{ $t('page.campaign.video_interactions_watch_till_end') }}
            </el-checkbox>
            <el-checkbox v-model="checked_liked" @change="handleChangeSelected">
                {{ $t('page.campaign.video_interactions_liked') }}
            </el-checkbox>
            <el-checkbox
                v-model="checked_commented"
                @change="handleChangeSelected"
            >
                {{ $t('page.campaign.video_interactions_commented') }}
            </el-checkbox>
            <el-checkbox
                v-model="checked_shared"
                @change="handleChangeSelected"
            >
                {{ $t('page.campaign.video_interactions_shared') }}
            </el-checkbox>
        </div>

        <p class="component-label text-desc-text mt-4">
            {{ $t('page.campaign.video_interactions_period') }}
        </p>
        <el-radio
            v-model="radio_period_day"
            :label="7"
            @change="handleChangeSelected"
            >7 {{ $t('common.days') }}</el-radio
        >
        <el-radio
            v-model="radio_period_day"
            :label="15"
            @change="handleChangeSelected"
            >15 {{ $t('common.days') }}</el-radio
        >

        <el-dialog
            :visible.sync="is_show_create_target_dialog"
            :close-on-click-modal="true"
            :append-to-body="true"
            width="480px"
        >
            <div slot="title">
                <p class="text-lg font-semibold">
                    {{ $t('button.save_custom_target') }}
                </p>
            </div>

            <div>
                <p class="component-label">
                    {{ $t('common.name') }}
                </p>
                <el-input
                    v-model="custom_target_name"
                    type="text"
                    size="small"
                    :placeholder="$t('input.placeholder.please_input')"
                >
                </el-input>
            </div>

            <template slot="footer">
                <div class="flex items-center justify-end mt-4">
                    <el-button
                        size="small"
                        @click="is_show_create_target_dialog = false"
                    >
                        {{ $t('button.close') }}
                    </el-button>

                    <el-button
                        :disabled="!custom_target_name"
                        type="primary"
                        size="small"
                        icon="el-icon-check"
                        @click="handleCreateCustomTarget"
                    >
                        {{ $t('button.confirm') }}
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import {
    createCustomTarget,
    getCustomTargets,
    deleteCustomTarget
} from '@/services/atosa-tiktok-ads/custom-target'
import _ from 'lodash'

export default {
    components: {
        Treeselect
    },

    props: [
        'selected_item',
        'tiktok_account_id',
        'advertiser_id',
        'related_options'
    ],

    data() {
        return {
            normalizer_option(node) {
                return {
                    id: node.id,
                    label: node.label,
                    children: node.children
                }
            },
            list_custom_targets: [],
            custom_target_name: '',
            is_show_create_target_dialog: false,
            show_list_save_target_table: false,
            action_category_ids: [],
            checked_watched_till_end: false,
            checked_liked: false,
            checked_commented: false,
            checked_shared: false,
            radio_period_day: 0
        }
    },

    computed: {
        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        }
    },

    watch: {
        'selected_item.id'() {
            this.initData()
        },

        selected_item() {
            this.initData()
        },

        action_category_ids() {
            this.selected_item_in_line.extension.video_interactions_names =
                this.action_category_ids.map((id) => {
                    const temp = this.related_options.find((i) => i.id === id)
                    if (temp) {
                        return temp.label
                    }
                    return id
                })
        }
    },

    mounted() {
        this.fetchCustomTargets()

        this.initData()
    },

    methods: {
        handleChangeVideoInteraction() {
            this.handleChangeSelected()

            this.selected_item_in_line.actions =
                this.selected_item_in_line.actions.map((item) => {
                    if (item.action_scene === 'VIDEO_RELATED') {
                        if (item.action_category_ids.length > 0) {
                            return {
                                ...item,
                                action_period: 15,
                                video_user_actions: [
                                    'WATCHED_TO_END',
                                    'LIKED',
                                    'COMMENTED',
                                    'SHARED'
                                ]
                            }
                        }

                        return {
                            ...item,
                            action_period: 0,
                            video_user_actions: []
                        }
                    }

                    return item
                })

            this.initData()
        },

        initData() {
            this.action_category_ids = []
            this.checked_watched_till_end = false
            this.checked_liked = false
            this.checked_commented = false
            this.checked_shared = false
            this.radio_period_day = 0

            if (this.selected_item_in_line?.actions.length > 0) {
                const action = this.selected_item_in_line.actions.find(
                    (item) => item.action_scene === 'VIDEO_RELATED'
                )

                if (!action) {
                    return
                }

                if (
                    action.video_user_actions.some(
                        (item) => item === 'WATCHED_TO_END'
                    )
                ) {
                    this.checked_watched_till_end = true
                }

                if (
                    action.video_user_actions.some((item) => item === 'LIKED')
                ) {
                    this.checked_liked = true
                }

                if (
                    action.video_user_actions.some(
                        (item) => item === 'COMMENTED'
                    )
                ) {
                    this.checked_commented = true
                }

                if (
                    action.video_user_actions.some((item) => item === 'SHARED')
                ) {
                    this.checked_shared = true
                }

                this.action_category_ids = action.action_category_ids

                this.radio_period_day = action.action_period
            }
        },

        handleUnselected() {
            this.action_category_ids = []
            this.radio_period_day = 0
            this.checked_watched_till_end = false
            this.checked_liked = false
            this.checked_commented = false
            this.checked_shared = false

            this.handleChangeSelected()
        },

        async handleCreateCustomTarget() {
            if (!this.action_category_ids.length) {
                this.p__showNotify(
                    'warning',
                    this.$t(
                        'page.campaign.message_dont_choose_interest_keyword_ids'
                    )
                )
                return
            }

            if (!this.custom_target_name) {
                return
            }

            try {
                const data_config = JSON.stringify({
                    action_category_ids: this.action_category_ids
                })

                await createCustomTarget(this.tiktok_account_id, {
                    custom_target_name: this.custom_target_name,
                    custom_target_type: 'VIDEO_RELATED',
                    data_config
                })

                this.custom_target_name = ''
                this.is_show_create_target_dialog = false

                this.fetchCustomTargets()

                this.p__showNotify(
                    'success',
                    this.$t('common.create_data_success')
                )
            } catch (error) {
                console.error(error)
            }
        },

        handleChangeSelected() {
            if (
                !this.action_category_ids.length &&
                !this.radio_period_day &&
                !this.checked_watched_till_end &&
                !this.checked_liked &&
                !this.checked_commented &&
                !this.checked_shared
            ) {
                this.selected_item_in_line.actions =
                    this.selected_item_in_line.actions.filter(
                        (ac) => ac.action_scene !== 'VIDEO_RELATED'
                    )

                return
            }

            const video_user_actions = []
            if (this.checked_watched_till_end) {
                video_user_actions.push('WATCHED_TO_END')
            }
            if (this.checked_liked) {
                video_user_actions.push('LIKED')
            }
            if (this.checked_commented) {
                video_user_actions.push('COMMENTED')
            }
            if (this.checked_shared) {
                video_user_actions.push('SHARED')
            }

            const action = {
                action_scene: 'VIDEO_RELATED',
                action_period: this.radio_period_day,
                action_category_ids: this.action_category_ids,
                video_user_actions
            }

            if (
                this.selected_item_in_line.actions.some(
                    (action) => action.action_scene === 'VIDEO_RELATED'
                )
            ) {
                this.selected_item_in_line.actions =
                    this.selected_item_in_line.actions.map((ac) => {
                        if (ac.action_scene === 'VIDEO_RELATED') {
                            return action
                        }

                        return ac
                    })
            } else {
                this.selected_item_in_line.actions = [
                    ...this.selected_item_in_line.actions,
                    action
                ]
            }
        },

        async fetchCustomTargets() {
            this.radio_select_product = null

            try {
                const response = await getCustomTargets({
                    custom_target_name: '',
                    custom_target_type: 'VIDEO_RELATED',
                    tiktok_account_id: ''
                })

                this.list_custom_targets = response.data
            } catch (error) {
                console.error(error)
            }
        },

        async handleDeleteCustomTarget(ct_id) {
            try {
                await this.$confirm(
                    this.$t('common.delete_data_confirm'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                )
            } catch (e) {
                return
            }

            try {
                await deleteCustomTarget(this.tiktok_account_id, {
                    custom_target_id: ct_id
                })

                this.fetchCustomTargets()

                this.p__showNotify(
                    'success',
                    this.$t('common.delete_data_success')
                )
            } catch (error) {
                console.error(error)
            }
        },

        handleAddCustomTargetToList(data_config) {
            const temp = _.cloneDeep(JSON.parse(data_config))

            this.action_category_ids = this.p__removeDuplicateValueInArray([
                ...this.action_category_ids,
                ...temp.action_category_ids
            ])

            this.handleChangeSelected()
        },

        handleRemoveCustomTargetToList(data_config) {
            const temp = _.cloneDeep(JSON.parse(data_config))

            this.action_category_ids = [...this.action_category_ids].filter(
                (item) => !temp.action_category_ids.some((it) => it === item)
            )

            this.handleChangeSelected()
        },

        handleReplaceCustomTargetToList(data_config) {
            const temp = _.cloneDeep(JSON.parse(data_config))

            this.action_category_ids = [...temp.action_category_ids]

            this.handleChangeSelected()
        }
    }
}
</script>
