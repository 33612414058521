import router from './router'
import store from './store'
// import { Message } from 'element-ui'
import NProgress from 'nprogress'
import { getInfo } from '@/services/atosa/auth'
import i18n from '@/plugins/language'
import { p__route, p__group_route } from '@/plugins/prototypes'
import moment from 'moment'
import {
    getUserRole,
    getUserRoleList
} from '@/services/atosa-tiktok-ads/user-role'

const title = 'ATAME - Atosa Tiktok Ads'

function getPageTitle(pageTitle) {
    if (pageTitle) {
        return `${pageTitle} - ${title}`
    }
    return `${title}`
}

NProgress.configure({ showSpinner: false }) // NProgress Configuration

router.beforeEach(async (to, from, next) => {
    // start progress bar
    NProgress.start()
    document.title = getPageTitle(to.meta.title)
    document.description = getPageTitle(to.meta.title)

    if (to?.query?.lang) {
        if (['vi', 'en'].some((ln) => ln === to.query.lang)) {
            localStorage.setItem('code_lang', to.query.lang)
            i18n.locale = to.query.lang
        }
    }

    if (
        [
            '/login',
            '/register',
            '/reset-password',
            '/forget-password',
            '/verify-tiktok-account',
            '/verify-tiktok-shop'
        ].some((item) => item === to.path)
    ) {
        return next()
    }

    if (
        to.path === `${p__group_route.ads_manager}/${p__route.tiktok_shop}` &&
        to?.query?.state
    ) {
        const arr = to.query.state.split('-----')
        if (arr.length === 2) {
            localStorage.setItem('token', arr[1])
        }
    } else if (
        to.path ===
            `${p__group_route.ads_manager}/${p__route.tiktok_business}/connect` &&
        to?.query?.state
    ) {
        const arr = to.query.state.split('-----')
        if (arr.length === 2) {
            localStorage.setItem('token', arr[1])
        }
    } else {
        const token = localStorage.getItem('token')
        if (!token) {
            // Message({
            //     message: i18n.t('error.cannot_access'),
            //     type: 'error',
            //     duration: 5 * 300
            // })

            if (['/login'].indexOf(to.path) !== -1) {
                return next()
            } else {
                return next(`/login?redirect=${to.path}`)
            }
        }
    }

    if (!store.getters.permission.has_sync) {
        try {
            const response = await getInfo()
            if (response?.data) {
                let temp = { ...response.data }
                if (!response.data.user.is_manager) {
                    const response_user_role = await getUserRole()
                    if (response_user_role?.data) {
                        temp = {
                            ...temp,
                            advertiser_list:
                                response_user_role.data.advertiser_list
                        }
                    }
                }
                await store.dispatch('user/s__setUserInfo', temp)
            }

            let accessRoutes = await store.dispatch('permission/generateRoutes')

            if (!response.data.user.is_manager) {
                accessRoutes = accessRoutes.map((item) => {
                    item.children = item.children.filter((i) =>
                        [
                            '/',
                            p__route.campaign,
                            `${p__route.campaign}/create`,
                            p__route.payment,
                            p__route.tiktok_shop,
                            p__route.tiktok_identity,
                            p__route.package,
                            '/doi-soat'
                        ].some((path) => i.path === path)
                    )
                    return item
                })
            }

            router.addRoutes(accessRoutes)
            next({ ...to, replace: true })
        } catch (error) {
            return next(`/login?redirect=${to.path}`)
        }
    } else {
        const main_package = store.getters.currentPackage.s__main_package

        if (main_package) {
            const license_time = moment(main_package.license_to * 1000)

            const expiry = license_time.diff(moment()) > 0

            if (
                !expiry &&
                [
                    `${p__group_route.general}/${p__route.package}`,
                    `${p__group_route.ads_manager}/${p__route.payment}`,
                    `/doi-soat`
                ].every((item) => item !== to.path)
            ) {
                window.open(
                    `${p__group_route.general}/${p__route.package}`,
                    '_self'
                )
            } else {
                const connect_url = `${p__group_route.ads_manager}/${p__route.tiktok_business}/connect`

                if (
                    store.getters.tiktokBusiness.s__has_fetch &&
                    store.getters.tiktokBusiness.s__tiktok_businesses.length ===
                        0 &&
                    to.path !== connect_url &&
                    to.path !== `${p__group_route.general}/${p__route.package}`
                ) {
                    window.open(connect_url, '_self')
                } else {
                    next()
                }
            }
        } else {
            next()
        }
    }
})

router.afterEach(() => {
    NProgress.done()
})
