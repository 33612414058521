import request from './_config'
const path = `catalog`

export function getCatalogs(tiktok_account_id, params) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'get',
        params: {
            bc_id: params.bc_id,
            catalog_id: params.catalog_id
        }
    })
}

export function getProductCatalog(tiktok_account_id, params) {
    return request({
        url: `${path}/product/${tiktok_account_id}`,
        method: 'get',
        params: {
            bc_id: params.bc_id,
            catalog_id: params.catalog_id,
            belong_to_atosa: params.belong_to_atosa,
            page: params.page,
            page_size: params.page_size
        }
    })
}
