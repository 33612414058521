import request from './_config'
const path = `custom_target`

export function getCustomTargets(params) {
    return request({
        url: path,
        method: 'get',
        params: {
            objective_type: params.objective_type,
            custom_target_name: params.custom_target_name,
            tiktok_account_id: params.tiktok_account_id,
            custom_target_type: params.custom_target_type
        }
    })
}

export function createCustomTarget(tiktok_account_id, data) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'post',
        data: {
            custom_target_name: data.custom_target_name,
            objective_type: data.objective_type,
            data_config: data.data_config,
            custom_target_type: data.custom_target_type
        }
    })
}

export function updateCustomTarget(tiktok_account_id, data) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'put',
        data
    })
}

export function deleteCustomTarget(tiktok_account_id, params) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'delete',
        params: {
            custom_target_id: params.custom_target_id
        }
    })
}
