var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-box"},[_c('p',{staticClass:"font-semibold mb-8"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.content_exclusions'))+" ")]),_c('p',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.desc_content_exclusions'))+" ")]),_c('div',{staticClass:"flex space-x-3 mt-4 mb-2"},_vm._l((_vm.content_exclusion_options),function(item){return _c('div',{key:item.value,staticClass:"border-[1px] border-l-[4px] p-4 w-[280px] bg-bg rounded-lg",class:[
                _vm.selected_item_in_line.brand_safety_type === item.value
                    ? 'border-brand-atosa'
                    : 'hover:border-border border-transparent',
                _vm.type_form !== 'create'
                    ? 'cursor-not-allowed'
                    : 'cursor-pointer'
            ],on:{"click":function($event){return _vm.handleSelect(item.value)}}},[_c('p',{staticClass:"font-semibold text-sm mb-2"},[_vm._v(" "+_vm._s(item.label)+" ")]),_c('p',{staticClass:"component-text-desc"},[_vm._v(" "+_vm._s(item.desc)+" ")])])}),0),_c('p',{staticClass:"component-text-desc leading-5"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.noti_desc_full_inventory_1'))+" "),_c('learn-more-anchor',{attrs:{"href":"https://ads.tiktok.com/help/article?aid=10011439"}}),_c('br'),_vm._v(" "+_vm._s(_vm.$t('page.campaign.noti_desc_full_inventory_2'))+" ")],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }