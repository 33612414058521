<template>
    <div class="component-box w-full">
        <div class="flex justify-between">
            <p class="text-xs font-semibold">
                {{ $t('page.campaign.ad_list') }}
                <el-tooltip
                    class="item"
                    effect="dark"
                    :content="
                        $t('page.campaign.desc_max_create_xxx_ad', {
                            xxx: max_ad
                        })
                    "
                    placement="top"
                >
                    <i class="header-icon el-icon-info text-desc-text"></i>
                </el-tooltip>
            </p>
            <p class="text-xs font-semibold text-desc-text">
                {{ items.length }}
            </p>
        </div>

        <ul
            v-if="selected_item"
            class="border-t-[1px] border-b-[1px] border-border my-4 pb-3 pt-1 overflow-y-scroll max-h-[320px]"
        >
            <li
                v-for="item in items_in_line"
                :key="item.extension.id"
                class="relative"
            >
                <div
                    class="flex mt-2 space-x-3 cursor-pointer overflow-hidden p-2 rounded border-[1px]"
                    :class="[
                        item.extension.id === selected_item.extension.id
                            ? 'border-brand-atosa'
                            : 'hover:border-border border-transparent'
                    ]"
                    @click="handleSelectAd(item)"
                >
                    <div
                        class="min-w-[72px] w-[72px] h-[72px] bg-bg rounded overflow-hidden relative"
                    >
                        <div
                            v-if="
                                objective_type !== 'PRODUCT_SALES' ||
                                selected_item.extension.shopping_ads_type !==
                                    'LIVE' ||
                                selected_item.extension
                                    .shopping_live_ad_setup !== 'live_video'
                            "
                            class="w-full h-full"
                        >
                            <img
                                v-if="
                                    item.extension.video &&
                                    item.extension.video.poster_url
                                "
                                :src="item.extension.video.poster_url"
                                class="w-full h-full object-contain"
                                alt=""
                            />
                            <img
                                v-if="
                                    item.extension.video &&
                                    item.extension.video.video_cover_url
                                "
                                :src="item.extension.video.video_cover_url"
                                class="w-full h-full object-contain"
                                alt=""
                            />
                        </div>

                        <div
                            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                        >
                            <i
                                class="el-icon-caret-right text-white text-xl"
                            ></i>
                        </div>
                    </div>

                    <div
                        v-if="
                            objective_type !== 'PRODUCT_SALES' ||
                            selected_item.extension.shopping_ads_type !==
                                'LIVE' ||
                            selected_item.extension.shopping_live_ad_setup !==
                                'live_video'
                        "
                        class="flex-1 flex flex-col"
                    >
                        <p class="text-xs font-semibold truncate w-[90%]">
                            {{
                                item.ad_name ? item.ad_name : $t('common.name')
                            }}
                        </p>

                        <p
                            class="util-max-2-text-lines leading-3 text-desc-text text-xs font-semibold"
                        >
                            {{
                                item.extension.video &&
                                item.extension.video.file_name
                                    ? item.extension.video.file_name
                                    : ''
                            }}
                        </p>
                    </div>
                </div>

                <div
                    class="flex mt-auto space-x-2 absolute bottom-3 left-[90px]"
                >
                    <i
                        v-if="
                            objective_type !== 'PRODUCT_SALES' ||
                            (objective_type === 'PRODUCT_SALES' &&
                                item.extension.shopping_ads_type === 'LIVE' &&
                                item.extension.shopping_live_ad_setup !==
                                    'live_video') ||
                            (objective_type === 'PRODUCT_SALES' &&
                                item.extension.shopping_ads_type !== 'LIVE')
                        "
                        class="el-icon-copy-document cursor-pointer"
                        @click="handleCloneAd(item)"
                    ></i>

                    <i
                        v-if="items_in_line.length > 1"
                        class="el-icon-delete cursor-pointer"
                        @click="handleDeleteAd(item.extension.id)"
                    ></i>
                </div>
            </li>

            <li id="bottom_select_ad"></li>
        </ul>

        <div class="flex">
            <el-button
                v-if="
                    objective_type !== 'PRODUCT_SALES' ||
                    (objective_type === 'PRODUCT_SALES' &&
                        items_in_line[0].extension.shopping_ads_type ===
                            'LIVE' &&
                        items_in_line[0].extension.shopping_live_ad_setup !==
                            'live_video') ||
                    (objective_type === 'PRODUCT_SALES' &&
                        items_in_line[0].extension.shopping_ads_type !== 'LIVE')
                "
                size="small"
                class="mx-auto"
                :disabled="items_in_line.length === max_ad"
                @click="handleCreateNewAd"
            >
                {{ $t('button.create') }}
            </el-button>
        </div>
    </div>
</template>

<script>
export default {
    props: ['items', 'selected_item', 'objective_type', 'handleSelectAd'],

    data() {
        return {
            max_ad: 20
        }
    },

    computed: {
        items_in_line: {
            get: function () {
                return this.items
            },
            set: function (val) {
                this.$emit('update:items', val)
            }
        }
    },

    beforeMount() {
        this.p__event_bus.$on('eb_select_ad_item', this.selectAdItem)
    },

    destroyed() {
        this.p__event_bus.$off('eb_select_ad_item', this.selectAdItem)
    },

    methods: {
        selectAdItem(index) {
            this.handleSelectAd(this.items_in_line[index])
        },

        handleCreateNewAd() {
            if (this.items_in_line.length < this.max_ad) {
                const new_item = JSON.parse(
                    JSON.stringify(this.items_in_line[0])
                )
                new_item.extension.id = this.p__renderId()
                this.items_in_line.push(new_item)

                this.handleSelectAd(new_item)

                this.scrollToElement('bottom_select_ad')
            }
        },

        handleDeleteAd(ad_id) {
            const objWithIdIndex = this.items_in_line.findIndex(
                (obj) => obj.extension.id === ad_id
            )

            if (objWithIdIndex > -1) {
                this.items_in_line.splice(objWithIdIndex, 1)

                this.handleSelectAd(
                    this.items_in_line[this.items_in_line.length - 1]
                )

                this.scrollToElement('bottom_select_ad')
            }
        },

        scrollToElement(element_id) {
            this.$nextTick(() => {
                document
                    .getElementById(element_id)
                    .scrollIntoView({ behavior: 'smooth' })
            })
        },

        handleCloneAd(ad) {
            if (this.items_in_line.length < this.max_ad) {
                const new_item = JSON.parse(JSON.stringify(ad))
                new_item.extension.id = this.p__renderId()

                this.items_in_line.push(new_item)

                this.handleSelectAd(new_item)

                this.scrollToElement('bottom_select_ad')
            }
        }
    }
}
</script>
