<template>
    <div class="component-box">
        <p class="mb-8 font-semibold">
            {{ $t('common.budget') }} & {{ $t('common.schedule') }}
        </p>

        <div id="input_budget_wrapper">
            <p class="component-label">
                {{ $t('common.budget') }}
            </p>
            <div class="flex space-x-2">
                <el-select
                    v-model="selected_item_in_line.budget_mode"
                    size="small"
                    filterable
                    :disabled="type_form !== 'create'"
                >
                    <el-option
                        v-for="item in p__budget_mode_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>

                <CustomCommasNumber
                    class="w-[200px]"
                    :num.sync="selected_item_in_line.budget"
                ></CustomCommasNumber>

                <!-- <el-input
                    id="input_budget"
                    v-model="selected_item_in_line.budget"
                    class="flex-1"
                    type="number"
                    size="small"
                    :placeholder="placeholder"
                >
                    <template slot="append">
                        {{ currency }}
                    </template>
                </el-input> -->
            </div>

            <div
                v-if="selected_item_in_line.budget_mode === 'BUDGET_MODE_DAY'"
                id="config_budget_wrapper"
                class="mt-4 border-border border-[1px] rounded-lg p-4"
            >
                <p class="component-label">
                    {{ $t('page.campaign.budget_adjustment_plan') }}
                </p>
                <p class="component-text-desc mt-0">
                    {{ $t('page.campaign.desc_budget_adjustment_plan') }}
                </p>

                <div
                    v-for="item in show_config_list"
                    :key="item.id"
                    class="flex mt-2 space-x-2"
                >
                    <el-date-picker
                        v-model="item.time"
                        type="datetime"
                        size="small"
                        :format="
                            $t('common.format_value_date_picker_with_time')
                        "
                        :picker-options="picker_options_for_now"
                    >
                    </el-date-picker>

                    <el-input
                        id="input_budget"
                        v-model="item.value"
                        class="flex-1"
                        type="number"
                        size="small"
                        :placeholder="placeholder"
                    >
                        <template slot="append">
                            {{ currency }}
                        </template>
                    </el-input>

                    <el-button
                        size="small"
                        type="danger"
                        icon="el-icon-close"
                        plain
                        @click="handleRemoveConfigBudget(item.id)"
                    >
                    </el-button>
                </div>

                <el-button
                    size="small"
                    type="success"
                    icon="el-icon-plus"
                    plain
                    class="w-full mt-4"
                    @click="handleAddConfigBudget"
                >
                    {{ $t('page.campaign.add_plan') }}
                </el-button>
            </div>
        </div>

        <div class="mt-6">
            <p class="component-label">
                {{ $t('common.schedule') }}
                <span class="text-desc-text inline-block ml-1 text-xs">
                    {{ $t('common.timezone') }} : {{ time_timezone }}
                </span>
            </p>

            <p
                v-if="selected_item_in_line.shopping_ads_type === 'LIVE'"
                class="component-text-desc mb-4"
            >
                {{ $t('page.campaign.desc_shopping_ads_type') }}
            </p>

            <el-radio-group
                v-model="selected_item_in_line.schedule_type"
                class="flex flex-col space-y-3"
            >
                <el-radio
                    v-if="selected_item_in_line.shopping_ads_type === 'LIVE'"
                    label="SCHEDULE_START_END"
                >
                    {{
                        $t('page.campaign.desc_schedule_from_end_shopping_live')
                    }}
                </el-radio>
                <el-radio
                    :disabled="
                        selected_item_in_line.budget_mode ===
                        'BUDGET_MODE_TOTAL'
                    "
                    label="SCHEDULE_FROM_NOW"
                >
                    {{ $t('page.campaign.desc_schedule_from_now') }}
                </el-radio>
                <el-radio
                    v-if="selected_item_in_line.shopping_ads_type !== 'LIVE'"
                    label="SCHEDULE_START_END"
                >
                    {{ $t('page.campaign.desc_schedule_from_end') }}
                </el-radio>
            </el-radio-group>

            <div id="schedule_ad_wrapper" class="mt-4">
                <div
                    v-if="
                        selected_item_in_line.schedule_type ===
                        'SCHEDULE_FROM_NOW'
                    "
                    class="flex items-center space-x-4"
                >
                    <el-date-picker
                        v-model="selected_item_in_line.schedule_start_time"
                        type="datetime"
                        placeholder="Select date and time"
                        size="small"
                        :format="
                            $t('common.format_value_date_picker_with_time')
                        "
                        :picker-options="picker_options_for_now"
                        @change="handleChangeScheduleStartDate"
                    >
                    </el-date-picker>
                </div>
                <div v-else class="flex items-center space-x-4">
                    <el-date-picker
                        v-model="selected_item_in_line.schedule_start_time"
                        :disabled="type_form === 'edit'"
                        type="datetime"
                        placeholder="Select date and time"
                        size="small"
                        :format="
                            $t('common.format_value_date_picker_with_time')
                        "
                        :picker-options="picker_options_for_now"
                        @change="handleChangeScheduleStartDate"
                    >
                    </el-date-picker>

                    <span>-</span>

                    <el-date-picker
                        v-model="selected_item_in_line.schedule_end_time"
                        type="datetime"
                        placeholder="Select date and time"
                        size="small"
                        :format="
                            $t('common.format_value_date_picker_with_time')
                        "
                        :picker-options="picker_options_for_now"
                        @change="handleChangeScheduleEndDate"
                    >
                    </el-date-picker>
                </div>
            </div>

            <div
                v-if="
                    selected_item_in_line.schedule_type === 'SCHEDULE_START_END'
                "
                class="mt-4"
            >
                <p class="text-desc-text text-xs">
                    {{
                        $t(
                            'page.campaign.adjust_end_time_to_xxx_from_start_time',
                            {
                                xxx: `${number_period} ${renderTitlePeriod()}`
                            }
                        )
                    }}
                </p>
                <div class="flex items-center space-x-2">
                    <el-input-number
                        v-model="number_period"
                        class="w-[120px]"
                        size="small"
                        :min="1"
                    ></el-input-number>
                    <el-select
                        v-model="selected_period"
                        class="w-[120px]"
                        size="small"
                    >
                        <el-option
                            v-for="item in period_options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>

                    <el-button
                        size="small"
                        @click="handleChangeScheduleEndtime"
                        >{{ $t('common.adjust') }}</el-button
                    >
                </div>
            </div>
        </div>

        <TableSchedule
            v-if="
                selected_item_in_line.shopping_ads_type !==
                'PRODUCT_SHOPPING_ADS'
            "
            :dayparting.sync="selected_item_in_line.dayparting"
            class="mt-6"
        ></TableSchedule>

        <!-- <div class="mt-6">
            <p class="component-label">
                {{ $t('page.campaign.dayparting') }}
            </p>
            <el-radio-group v-model="radio_dayparting" disabled size="small">
                <el-radio-button :label="1">
                    {{ $t('page.campaign.all_day') }}</el-radio-button
                >
                <el-radio-button :label="2">
                    {{ $t('page.campaign.select_specific_time') }}
                </el-radio-button>
            </el-radio-group>
        </div> -->
    </div>
</template>

<script>
import moment from 'moment'
import { getConfigScheduleBudget } from '@/services/atosa-tiktok-ads/auto-ads'
import TableSchedule from './table-schedule'

export default {
    components: {
        TableSchedule
    },

    props: [
        'tiktok_account_id',
        'advertiser_id',
        'selected_item',
        'currency',
        'timezone',
        'min_budget_adgroup',
        'type_form'
    ],

    data() {
        return {
            number_period: 30,
            selected_period: 'minutes',
            period_options: [
                {
                    value: 'minutes',
                    label: this.$t('common.minute')
                },
                {
                    value: 'hours',
                    label: this.$t('common.hour')
                },
                {
                    value: 'days',
                    label: this.$t('common.day')
                },
                {
                    value: 'weeks',
                    label: this.$t('common.week')
                },
                {
                    value: 'months',
                    label: this.$t('common.year')
                }
            ],
            radio_dayparting: 2,
            radio_schedule_type: 'SCHEDULE_FROM_NOW',
            picker_options_for_now: {
                disabledDate: (date) => this.disabledDateForNow(date)
            }
        }
    },

    computed: {
        show_config_list() {
            return this.selected_item_in_line.extension.config_list.filter(
                (item) => item.type !== 'delete'
            )
        },

        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        },

        placeholder() {
            return ''
        },

        time_timezone() {
            const result = this.p__tiktok_timezones.find(
                (item) => item.code === this.timezone
            )

            if (result) {
                return result.time
            }

            return this.timezone
        }
    },

    watch: {

        'selected_item.id'() {
            this.getInfoForState()

            this.fetchConfigScheduleBudgets()
        },

        'selected_item_in_line.budget_mode'() {
            if (
                this.selected_item_in_line.budget_mode === 'BUDGET_MODE_TOTAL'
            ) {
                this.selected_item_in_line.schedule_type = 'SCHEDULE_START_END'
            }
        },

        selected_item() {
            this.getInfoForState()
        },

        min_budget_adgroup() {
            if (
                this.min_budget_adgroup &&
                this.type_form === 'create' &&
                this.selected_item_in_line.extension.reset_budget
            ) {
                this.selected_item_in_line.budget = this.min_budget_adgroup
            }
        }
    },

    mounted() {
        if (
            this.min_budget_adgroup &&
            this.type_form === 'create' &&
            this.selected_item_in_line.extension.reset_budget
        ) {
            this.selected_item_in_line.budget = this.min_budget_adgroup
        }

        this.getInfoForState()

        this.fetchConfigScheduleBudgets()
    },

    methods: {
        handleChangeScheduleEndtime() {
            this.selected_item_in_line.schedule_end_time = moment(
                this.selected_item_in_line.schedule_start_time
            )
                .add(this.number_period, this.selected_period)
                .format('YYYY-MM-DD HH:mm')
        },

        renderTitlePeriod() {
            const item = this.period_options.find(
                (i) => i.value === this.selected_period
            )

            if (item) {
                return item.label
            }

            return ''
        },

        async fetchConfigScheduleBudgets() {
            if (
                this.type_form === 'create' ||
                this.selected_item_in_line.budget_mode !== 'BUDGET_MODE_DAY'
            ) {
                return
            }

            try {
                const response = await getConfigScheduleBudget(
                    this.tiktok_account_id,
                    {
                        advertiser_id: this.advertiser_id,
                        adgroup_id: this.selected_item_in_line.adgroup_id
                    }
                )
                if (response.code === 0 && response.data.length > 0) {
                    this.selected_item_in_line.extension.config_list =
                        response.data.map((item) => ({
                            ...item,
                            type: 'old',
                            time: new Date(item.time * 1000),
                            id: item._id
                        }))
                } else {
                    this.selected_item_in_line.extension.config_list = []
                }
            } catch (error) {
                console.error(error)
            }
        },

        handleRemoveConfigBudget(id) {
            this.selected_item_in_line.extension.config_list =
                this.selected_item_in_line.extension.config_list.map((item) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            type: 'delete'
                        }
                    }

                    return item
                })
        },

        handleAddConfigBudget() {
            const date = new Date()

            this.selected_item_in_line.extension.config_list.push({
                id: this.p__renderId(),
                time: date.setHours(date.getHours() + 1),
                value: this.min_budget_adgroup,
                type: 'new'
            })
        },

        handleChangeScheduleStartDate(val) {
            this.selected_item_in_line.schedule_start_time =
                moment(val).format('YYYY-MM-DD HH:mm')
        },

        handleChangeScheduleEndDate(val) {
            this.selected_item_in_line.schedule_end_time =
                moment(val).format('YYYY-MM-DD HH:mm')
        },

        getInfoForState() {
            this.radio_dayparting = 2
            this.radio_schedule_type = 'SCHEDULE_FROM_NOW'

            if (this.selected_item_in_line?.dayparting) {
                if (
                    this.selected_item_in_line.dayparting ===
                    this.p__default_dayparting_adgroup
                ) {
                    this.radio_dayparting = 1
                } else {
                    this.radio_dayparting = 2
                }
            }
            if (this.selected_item_in_line?.schedule_type) {
                this.radio_schedule_type =
                    this.selected_item_in_line?.schedule_type
            }
        },

        disabledDateForNow(date) {
            return moment(date) <= moment().subtract(1, 'days')
        }
    }
}
</script>
