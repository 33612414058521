<template>
    <div class="component-box">
        <p class="font-semibold mb-8">
            {{ $t('common.identity') }}
        </p>

        <el-switch
            v-if="!only_use_spark_ads"
            v-model="use_spark_ads"
            :disabled="m__loading"
            class="mb-6"
            :active-text="$t('page.campaign.use_spark_ads')"
            @change="handleChangeUseSparkAds"
        >
        </el-switch>

        <!-- <el-switch
            v-model="use_spark_ads"
            :disabled="m__loading"
            class="mb-6"
            :active-text="$t('page.campaign.use_spark_ads')"
            @change="handleChangeUseSparkAds"
        >
        </el-switch> -->

        <div>
            <p v-if="use_spark_ads" class="component-label">
                {{ $t('page.campaign.use_account_owned_by_you') }}
            </p>

            <p v-else class="component-label">
                {{ $t('page.campaign.set_custom_identity') }}
            </p>
            <div v-if="m__data_for_pagination.length">
                <el-select
                    v-model="selected_identity"
                    v-el-select-loadmore="loadMore"
                    value-key="identity_id"
                    size="small"
                    class="w-full"
                    :placeholder="$t('input.placeholder.please_select')"
                    @change="handleChangeSelectedIdentity"
                >
                    <el-option
                        v-for="item in m__data_for_pagination"
                        :key="item.identity_id"
                        :label="item.display_name"
                        :value="item"
                    >
                        <div class="flex items-center space-x-2 py-1">
                            <el-avatar :size="32">
                                <img
                                    :src="
                                        item.profile_image
                                            ? item.profile_image
                                            : p__default_identity_image_uri
                                    "
                                />
                            </el-avatar>
                            <div class="flex flex-col">
                                <span class="leading-5 text-xs font-semibold">
                                    {{ item.display_name }}
                                </span>
                                <span class="component-text-desc">
                                    #{{ item.identity_id }}
                                </span>
                            </div>
                        </div>
                    </el-option>
                </el-select>

                <el-button
                    v-if="!use_spark_ads"
                    class="mt-1"
                    size="small"
                    icon="el-icon-plus"
                    type="text"
                    @click="visible = true"
                >
                    {{ $t('button.create') }}
                </el-button>
            </div>

            <div v-else-if="!use_spark_ads">
                <div class="flex items-center space-x-2">
                    <el-avatar
                        :size="44"
                        class="min-w-[44px] cursor-pointer !bg-border"
                    >
                        <img
                            class="w-full h-full object-contain"
                            :src="
                                selected_item_in_line.extension.identity
                                    .image_uri
                            "
                            alt=""
                        />
                    </el-avatar>

                    <el-input
                        ref="el_input_identity_display_name"
                        v-model="
                            selected_item_in_line.extension.identity
                                .display_name
                        "
                        type="text"
                        size="small"
                        :placeholder="$t('input.placeholder.please_input')"
                        maxlength="40"
                        show-word-limit
                    >
                        <template slot="prepend">@</template>
                    </el-input>
                </div>
                <div class="flex">
                    <el-button
                        class="mt-2 mx-auto"
                        size="small"
                        icon="el-icon-plus"
                        @click="handleCreateCustomizeIdentity"
                    >
                        {{ $t('button.create') }}
                    </el-button>
                </div>
            </div>
        </div>

        <el-dialog
            title="Tips"
            :visible.sync="visible"
            :close-on-click-modal="true"
            :append-to-body="true"
            width="480px"
        >
            <div slot="title">
                <p class="text-lg font-semibold">
                    {{ $t('page.campaign.create_identity') }}
                </p>
            </div>
            <div class="flex items-center space-x-2">
                <el-avatar
                    :size="44"
                    class="min-w-[44px] cursor-pointer !bg-border"
                >
                    <img
                        class="w-full h-full object-contain"
                        :src="
                            selected_item_in_line.extension.identity.image_uri
                        "
                        alt=""
                    />
                </el-avatar>

                <el-input
                    ref="el_input_identity_display_name"
                    v-model="
                        selected_item_in_line.extension.identity.display_name
                    "
                    type="text"
                    size="small"
                    :placeholder="$t('input.placeholder.please_input')"
                    maxlength="40"
                    show-word-limit
                >
                    <template slot="prepend">@</template>
                </el-input>
            </div>

            <template slot="footer">
                <div class="flex items-center justify-end mt-4">
                    <el-button size="small" @click="visible = false">
                        {{ $t('button.close') }}
                    </el-button>

                    <el-button
                        type="primary"
                        icon="el-icon-check"
                        size="small"
                        @click="handleCreateCustomizeIdentity"
                    >
                        {{ $t('button.confirm') }}
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {
    getIdentities,
    createIdentity
} from '@/services/atosa-tiktok-ads/identity'
import paginationDataMixin from '@/plugins/mixins/pagination-data'
import { uploadImage } from '@/services/atosa-tiktok-ads/file'

export default {
    mixins: [paginationDataMixin],

    props: [
        'selected_item',
        'tiktok_account_id',
        'objective_type',
        'advertiser_id',
        'belong_to_atosa'
    ],

    data() {
        return {
            visible: false,
            use_spark_ads: false,
            list_author_identities: [],
            selected_identity: null
        }
    },

    computed: {
        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        },

        only_use_spark_ads() {
            console.log('====================================')
            console.log(this.objective_type, 'this.objective_type dáads')
            console.log('====================================')
            return ['ENGAGEMENT'].some((item) => this.objective_type === item)
        }
    },

    watch: {
        'selected_item_in_line.extension.use_spark_ads'() {
            this.use_spark_ads =
                this.selected_item_in_line.extension.use_spark_ads
        },

        'selected_item_in_line.extension.id'() {
            this.use_spark_ads = this.only_use_spark_ads
            this.selected_item_in_line.extension.use_spark_ads =
                this.only_use_spark_ads

            if (!this.only_use_spark_ads) {
                this.fetchIdentity(false)
            }
        }
    },

    mounted() {
        this.use_spark_ads = this.only_use_spark_ads

        this.selected_item_in_line.extension.use_spark_ads =
            this.only_use_spark_ads

        this.fetchIdentity(false)

        // if (!this.only_use_spark_ads) {
        //     this.fetchIdentity(false)
        // }
    },

    methods: {
        handleChangeSelectedIdentity(identity) {
            this.selected_item_in_line.extension.video = null

            this.selected_item_in_line.identity_id = identity.identity_id
            this.selected_item_in_line.identity_type = identity.identity_type
            this.selected_item_in_line.identity_authorized_bc_id =
                identity.identity_authorized_bc_id

            this.selected_item_in_line.extension.identity.identity_id =
                identity.identity_id
            this.selected_item_in_line.extension.identity.identity_type =
                identity.identity_type
            this.selected_item_in_line.extension.identity.identity_authorized_bc_id =
                identity.identity_authorized_bc_id
        },

        async fetchIdentity(append = true) {
            this.m__loading = true

            try {
                const response = await getIdentities(this.tiktok_account_id, {
                    advertiser_id: this.advertiser_id,
                    belong_to_atosa: this.belong_to_atosa,
                    identity_type: this.use_spark_ads
                        ? 'BC_AUTH_TT'
                        : 'CUSTOMIZED_USER'
                })

                if (!append) {
                    this.m__data_for_pagination = []
                }

                if (response.data.identity_list.length > 0) {
                    this.m__data_for_pagination = [
                        ...this.m__data_for_pagination,
                        ...response.data.identity_list
                    ]

                    this.selected_identity = this.m__data_for_pagination[0]

                    if (!append) {
                        this.handleChangeSelectedIdentity(
                            this.selected_identity
                        )
                    }
                }

                this.m__page_info = response.data.page_info
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        loadMore() {
            if (this.m__loading) {
                return
            }

            if (this.m__page_info.page < this.m__page_info.total_page) {
                this.m__page_info.page++
                this.fetchIdentity(true)
            }
        },

        async handleChangeUseSparkAds() {
            await this.fetchIdentity(false)
        },

        async handleCreateCustomizeIdentity() {
            if (!this.selected_item_in_line.extension.identity.display_name) {
                this.p__showNotify(
                    'warning',
                    this.$t('page.campaign.please_input_identity_display_name')
                )

                this.$refs.el_input_identity_display_name.focus()
                return
            }

            try {
                const formData = new FormData()
                formData.append('advertiser_id', this.advertiser_id)
                formData.append('belong_to_atosa', this.belong_to_atosa)
                formData.append('upload_type', 'UPLOAD_BY_URL')
                formData.append(
                    'file_name',
                    `avatar_identity_${this.p__renderId(9)}`
                )
                formData.append(
                    'image_url',
                    this.selected_item_in_line.extension.identity.image_uri ||
                        this.p__default_identity_image_uri
                )

                const response_upload_image = await uploadImage(
                    this.tiktok_account_id,
                    formData
                )

                const response = await createIdentity(this.tiktok_account_id, {
                    belong_to_atosa: this.belong_to_atosa,
                    advertiser_id: this.advertiser_id,
                    image_uri: response_upload_image.data.image_id,
                    display_name:
                        this.selected_item_in_line.extension.identity
                            .display_name
                })

                if (
                    this.m__data_for_pagination.every(
                        (item) => item.identity_id !== response.data.identity_id
                    )
                ) {
                    this.m__data_for_pagination = [
                        {
                            available_status: null,
                            can_pull_video: null,
                            can_push_video: null,
                            can_use_live_ads: null,
                            display_name:
                                this.selected_item_in_line.extension.identity
                                    .display_name,
                            identity_authorized_bc_id: null,
                            identity_id: response.data.identity_id,
                            identity_type: 'CUSTOMIZED_USER',
                            profile_image:
                                this.selected_item_in_line.extension.identity
                                    .image_url
                        },
                        ...this.m__data_for_pagination
                    ]
                }

                this.p__showNotify(
                    'success',
                    this.$t('message.xxx_success', {
                        xxx: `${this.$t(`button.create`)} ${this.$t(
                            `common.identity`
                        )}`
                    })
                )

                this.selected_item_in_line.extension.identity.display_name = ''

                this.visible = false
            } catch (error) {
                this.p__showNotify(
                    'error',
                    this.$t('message.xxx_fail', {
                        xxx: `${this.$t(`button.create`)} ${this.$t(
                            `common.identity`
                        )}`
                    })
                )

                console.error(error)
            }
        }
    }
}
</script>
