import Vue from 'vue'
import VueI18n from 'vue-i18n'

// element-ui built-in lang
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import elementViLocale from 'element-ui/lib/locale/lang/vi'

// User defined lang
import enLocale from './translate/en'
import viLocale from './translate/vi'

Vue.use(VueI18n)

const messages = {
    en: {
        ...elementEnLocale,
        ...enLocale
    },
    vi: {
        ...elementViLocale,
        ...viLocale
    }
}

const getLanguage = () => localStorage.getItem('code_lang')

const getLocale = () => {
    const cookieLanguage = getLanguage()
    if (cookieLanguage) {
        document.documentElement.lang = cookieLanguage
        return cookieLanguage
    }

    const language = navigator.language.toLowerCase()
    const locales = Object.keys(messages)
    for (const locale of locales) {
        if (language.indexOf(locale) > -1) {
            document.documentElement.lang = locale
            return locale
        }
    }
    return 'vi'
}

const i18n = new VueI18n({
    locale: getLocale(),
    fallbackLocale: 'vi',
    silentFallbackWarn: true,
    isGlobal: true,
    messages
})

export default i18n
