<template>
    <div class="component-box">
        <p class="font-semibold mb-8">
            {{ $t('page.campaign.promotion_type') }}
        </p>

        <div class="flex space-x-6">
            <div
                v-for="item in promotion_type_options"
                :key="item.value"
                class="border-l-[3px] h-[88px] w-[160px] bg-bg flex items-center pl-6 rounded-lg space-x-2 cursor-pointer"
                :class="[
                    active_promotion_type === item.value
                        ? 'border-brand-atosa'
                        : 'hover:border-border border-transparent'
                ]"
                @click="active_promotion_type = item.value"
            >
                <i class="text-2xl" :class="[item.icon]" />

                <p class="font-semibold text-sm">
                    {{ item.label }}
                </p>
            </div>
        </div>

        <div v-if="active_promotion_type === 'APP'" class="mt-4">
            <p class="component-label">App</p>
            <div class="flex items-center">
                <el-select
                    v-model="selected_item_in_line.app_id"
                    size="small"
                    filterable
                    :placeholder="$t('input.placeholder.please_select')"
                    class="w-[320px]"
                    @change="handleChangeApp"
                >
                    <el-option-group label="App">
                        <el-option
                            v-for="item in app_options"
                            :key="item.app_id"
                            :value="item.app_id"
                            :label="item.app_name"
                        >
                            <div class="flex items-center space-x-2 py-1">
                                <el-avatar :size="32">
                                    <img
                                        v-if="item.icon.web_uri"
                                        :src="item.icon.web_uri"
                                    />
                                    <span v-else>
                                        {{ item.app_name }}
                                    </span>
                                </el-avatar>
                                <div class="flex flex-col">
                                    <span
                                        class="leading-5 text-xs font-semibold"
                                    >
                                        {{ item.app_name }}
                                    </span>
                                    <span class="component-text-desc">
                                        #{{ item.app_id }}
                                    </span>
                                </div>
                            </div>
                        </el-option>
                    </el-option-group>
                </el-select>

                <el-button
                    icon="el-icon-refresh"
                    size="small"
                    class="ml-2"
                    @click="fetchApps"
                ></el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { getAppList } from '@/services/atosa-tiktok-ads/event'

export default {
    props: ['selected_item', 'tiktok_account_id', 'advertiser_id'],

    data() {
        return {
            active_promotion_type: 'WEBSITE',
            app_options: [],
            promotion_type_options: [
                {
                    label: 'App',
                    value: 'APP',
                    icon: 'el-icon-mobile-phone'
                },
                {
                    label: 'Website',
                    value: 'WEBSITE',
                    icon: 'el-icon-postcard'
                }
            ]
        }
    },

    computed: {
        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        }
    },

    watch: {
        'selected_item.id'() {
            this.fetchApps()
        },

        'selected_item.promotion_type'() {
            this.selected_item_in_line.app_id = ''
        },

        active_promotion_type() {
            if (this.active_promotion_type === 'WEBSITE') {
                this.selected_item_in_line.promotion_type = 'WEBSITE'
            } else {
                this.selected_item_in_line.promotion_type = ''
            }
        }
    },

    mounted() {
        this.fetchApps()
    },

    methods: {
        async fetchApps() {
            try {
                const response = await getAppList(this.tiktok_account_id, {
                    advertiser_id: this.advertiser_id
                })

                this.app_options = response.data.apps
            } catch (error) {
                console.error(error)
            }
        },

        handleChangeApp(item_id) {
            this.selected_item_in_line.app_id = item_id

            const item = this.app_options.find((i) => i.app_id === item_id)
            if (!item) {
                return
            }
            if (item.platform === 'ANDROID') {
                this.selected_item_in_line.promotion_type = 'APP_ANDROID'
            } else if (item.platform === 'IOS') {
                this.selected_item_in_line.promotion_type = 'APP_IOS'
            }
        }
    }
}
</script>
