<!-- eslint-disable vue/attribute-hyphenation -->
<template>
    <el-input
        v-model="qty"
        size="small"
        :placeholder="$t('input.placeholder.please_input')"
    />
</template>

<script>
export default {
    props: ['num'],

    data() {
        return {
            qty: '0'
        }
    },

    computed: {
        num_in_line: {
            get() {
                return this.num
            },
            set(value) {
                this.$emit('update:num', value)
            }
        }
    },

    watch: {
        qty: function (newValue) {
            const result = newValue
                .replace(/\D/g, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

            this.num_in_line = newValue.replace(/\D/g, '')

            this.$nextTick(() => (this.qty = result))
        },
        num() {
            this.qty = `${this.num}`
                .replace(/\D/g, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
    },

    mounted() {
        this.qty = `${this.num}`
            .replace(/\D/g, '')
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
}
</script>
