import axios from 'axios'
import { Message } from 'element-ui'
import { p__formatMessageFromAtosaCore } from '@/plugins/prototypes'

const service = axios.create({
    baseURL: process.env.VUE_APP_ATOSA_API,
    timeout: 18000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    (config) => {
        config.headers['authorization'] = localStorage.getItem('token')
        config.headers['Accept-Language'] = localStorage.getItem('code_lang')

        return config
    },
    (error) => {
        console.error(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    (response) => {
        return response.data
    },
    (error) => {
        if (
            error.response &&
            error.response.data &&
            error.response.data.data === 'credit_card_required'
        ) {
            return Promise.reject(error.response)
        }

        const message =
            error.response && error.response.data
                ? error.response.data.message
                : error.message
        Message({
            message: p__formatMessageFromAtosaCore(message),
            type: 'error',
            duration: 5 * 300
        })
        return Promise.reject(error)
    }
)

export default service
