const state = {
    s__user_info: null,
    s__is_admin: false
}

const mutations = {
    s__setUserInfo(state, data) {
        state.s__user_info = data
    },
    s__setIsAdmin(state, data) {
        state.s__is_admin = data
    }
}

const actions = {
    s__setUserInfo(context, data) {
        context.commit('s__setUserInfo', data)
    },
    s__setIsAdmin(context, data) {
        context.commit('s__setIsAdmin', data)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
