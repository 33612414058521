<template>
    <div>
        <p class="component-label">Pixel</p>
        <div class="flex">
            <el-select
                v-el-select-loadmore="loadMore"
                :value="selected_item_in_line.pixel_id"
                size="small"
                filterable
                :placeholder="$t('input.placeholder.please_select')"
                class="w-[320px]"
                @change="handleChangePixel"
            >
                <el-option-group label="Pixel">
                    <el-option
                        v-for="item in pixel_options"
                        :key="item.pixel_id"
                        :label="item.pixel_name"
                        :value="item.pixel_id"
                    >
                        <div class="flex items-center space-x-2 py-1">
                            <div class="flex flex-col">
                                <span
                                    class="leading-5 text-xs font-semibold"
                                >
                                    {{ item.pixel_name }}
                                </span>
                                <span class="component-text-desc">
                                    ID : {{ item.pixel_id }}
                                </span>
                            </div>
                        </div>
                    </el-option>
                </el-option-group>
            </el-select>
            <el-button
                icon="el-icon-refresh"
                size="small"
                class="ml-2"
                @click="handleRefresh"
            ></el-button>
        </div>

        <div v-if="selected_item_in_line.pixel_id" class="mt-4">
            <p class="component-label">
                {{ $t('page.campaign.optimization_event') }}
            </p>
            <div class="flex">
                <el-select
                    v-model="selected_item_in_line.optimization_event"
                    size="small"
                    filterable
                    :placeholder="$t('input.placeholder.please_select')"
                    class="w-[320px]"
                >
                    <el-option-group
                        :label="$t('page.campaign.optimization_event')"
                    >
                        <el-option
                            v-for="item in event_options"
                            :key="item.optimization_event"
                            :label="
                                p__formatPixelEvent(item.optimization_event)
                            "
                            :value="item.optimization_event"
                        >
                        </el-option>
                    </el-option-group>
                </el-select>
            </div>
        </div>
    </div>
</template>

<script>
import { getPixels } from '@/services/atosa-tiktok-ads/event'
import paginationDataMixin from '@/plugins/mixins/pagination-data'

export default {
    mixins: [paginationDataMixin],

    props: ['selected_item', 'advertiser_id', 'tiktok_account_id'],

    data() {
        return {
            event_options: [],

            pixel_options: [],
            pixel_page_info: {
                page: 1,
                page_size: 10,
                total_number: 0,
                total_page: 1
            }
        }
    },

    computed: {
        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        }
    },

    mounted() {
        this.fetchPixels()
    },

    methods: {
        handleRefresh() {
            this.pixel_options = []
            this.pixel_page_info.page = 1

            this.fetchPixels()
        },

        handleChangePixel(pixel_id) {
            this.selected_item_in_line.pixel_id = pixel_id

            const item = this.pixel_options.find(
                (pixel) => pixel.pixel_id === pixel_id
            )

            if (item && item.events.length) {
                this.event_options = item.events.filter(
                    (item) => item.optimization_event
                )
            }
        },

        async fetchPixels() {
            const response = await getPixels(this.tiktok_account_id, {
                advertiser_id: this.advertiser_id,
                page: this.m__page_info.page,
                page_size: this.m__page_info.page_size
            })

            this.pixel_options = [
                ...this.pixel_options,
                ...response.data.pixels.filter((item) => item.events.length)
            ]

            this.pixel_page_info = response.data.page_info
        },

        loadMore() {
            if (this.m__loading) {
                return
            }

            if (this.pixel_page_info.page < this.pixel_page_info.total_page) {
                this.pixel_page_info.page++
                this.fetchPixels()
            }
        }
    }
}
</script>
