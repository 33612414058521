import Layout from '@/layout'
import { p__route, p__group_route } from '@/plugins/prototypes'
import i18n from '@/plugins/language'

export const toolRoutes = {
    path: p__group_route.tool,
    component: Layout,
    name: i18n.t('common.tool'),
    isGroup: true,
    children: [
        {
            path: p__route.audience,
            component: () => import('@/views/audience'),
            meta: {
                title: i18n.t('common.audience'),
                icon: 'el-icon-takeaway-box'
            }
        }
    ]
}
