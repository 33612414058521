export const p__default_currency_app = 'VND'

export function p__formatCurrency(value, defaultZero = true) {
    return value && !Number.isNaN(+value)
        ? Math.floor(value)
              .toString()
              .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
        : defaultZero
        ? 0
        : value
}

export const p__renderMoneyByCurrency = (money, currency) => {
    if (!currency) {
        return money
    }

    if (currency === p__default_currency_app) {
        const temp = +money
        if (temp <= 0) {
            return '0 đ'
        }
        return temp
            .toLocaleString('it-IT', {
                style: 'currency',
                currency: p__default_currency_app
            })
            .replace(p__default_currency_app, 'đ')
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency
    })

    return formatter.format(+money)
}

const findTiktokCurrency = (code) => {
    const item = p__tiktok_currency.find((i) => i.code === code)

    if (item) {
        return item
    }

    return p__tiktok_currency[0]
}

export const p__findMinimumDailyBudgetProductSalesCampaign = (code) => {
    const cur = findTiktokCurrency(code)

    return cur.campaign.minimum_daily_budget_product_sales_with_store
}

export const p__findMinimumDailyBudgetCampaign = (code) => {
    const cur = findTiktokCurrency(code)

    return cur.campaign.minimum_daily_budget
}

export const p__findMaximumDailyBudgetCampaign = (code) => {
    const cur = findTiktokCurrency(code)

    return cur.campaign.maximum_daily_budget
}

export const p__findMinimumDailyBudgetProductSalesAdgroup = (code) => {
    const cur = findTiktokCurrency(code)

    return cur.adgroup.minimum_daily_budget_product_sales_with_store_or_showcase
}

export const p__findMinimumDailyBudgetAdgroup = (code) => {
    const cur = findTiktokCurrency(code)

    return cur.adgroup.minimum_daily_budget
}

export const p__findMaximumDailyBudgetAdgroup = (code) => {
    const cur = findTiktokCurrency(code)

    return cur.adgroup.maximum_daily_budget
}

export const p__tiktok_currency = [
    {
        currency: 'Vietnamese Dong',
        code: 'VND',
        budget_verification_ratio: 1,
        precision: 10_000,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 500_000,
            minimum_daily_budget: 500_000,
            maximum_daily_budget: 100_000_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 100_000,
            minimum_daily_budget: 100_000,
            maximum_daily_budget: 100_000_000_000,
            max_bid_pricefor_cpc: 300_000,
            max_bid_pricefor_cpm: 1_200_000,
            max_bid_pricefor_cpv: 1_200_000,
            max_bid_pricefor_ocpc: 15_000_000,
            max_bid_pricefor_gd: 300_000,
            max_bid_pricefor_ocpm: 15_000_000
        }
    },
    {
        currency: 'US Dollar',
        code: 'USD',
        budget_verification_ratio: 1,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 10,
            minimum_daily_budget: 50,
            maximum_daily_budget: 10_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 10,
            minimum_daily_budget: 20,
            maximum_daily_budget: 10_000_000,
            max_bid_pricefor_cpc: 30,
            max_bid_pricefor_cpm: 120,
            max_bid_pricefor_cpv: 120,
            max_bid_pricefor_ocpc: 1500,
            max_bid_pricefor_gd: 30,
            max_bid_pricefor_ocpm: 1500
        }
    },
    {
        currency: 'Euro',
        code: 'EUR',
        budget_verification_ratio: 1,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 10,
            minimum_daily_budget: 50,
            maximum_daily_budget: 10_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 10,
            minimum_daily_budget: 20,
            maximum_daily_budget: 10_000_000,
            max_bid_pricefor_cpc: 30,
            max_bid_pricefor_cpm: 120,
            max_bid_pricefor_cpv: 120,
            max_bid_pricefor_ocpc: 1_500,
            max_bid_pricefor_gd: 30,
            max_bid_pricefor_ocpm: 1_500
        }
    },
    {
        currency: 'Japanese Yen',
        code: 'JPY',
        budget_verification_ratio: 100,
        precision: 1,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 1_000,
            minimum_daily_budget: 5_000,
            maximum_daily_budget: 1_000_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 1_000,
            minimum_daily_budget: 2_000,
            maximum_daily_budget: 1_000_000_000,
            max_bid_pricefor_cpc: 3_000,
            max_bid_pricefor_cpm: 12_000,
            max_bid_pricefor_cpv: 12_000,
            max_bid_pricefor_ocpc: 150_000,
            max_bid_pricefor_gd: 3_000,
            max_bid_pricefor_ocpm: 150_000
        }
    },
    {
        currency: 'Hong Kong Dollar',
        code: 'HKD',
        budget_verification_ratio: 10,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 100,
            minimum_daily_budget: 500,
            maximum_daily_budget: 100_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 100,
            minimum_daily_budget: 200,
            maximum_daily_budget: 100_000_000,
            max_bid_pricefor_cpc: 300,
            max_bid_pricefor_cpm: 1_200,
            max_bid_pricefor_cpv: 1_200,
            max_bid_pricefor_ocpc: 15_000,
            max_bid_pricefor_gd: 300,
            max_bid_pricefor_ocpm: 15_000
        }
    },
    {
        currency: 'British Pound',
        code: 'GBP',
        budget_verification_ratio: 1,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 10,
            minimum_daily_budget: 50,
            maximum_daily_budget: 10_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 10,
            minimum_daily_budget: 20,
            maximum_daily_budget: 10_000_000,
            max_bid_pricefor_cpc: 30,
            max_bid_pricefor_cpm: 120,
            max_bid_pricefor_cpv: 120,
            max_bid_pricefor_ocpc: 1_500,
            max_bid_pricefor_gd: 30,
            max_bid_pricefor_ocpm: 1_500
        }
    },
    {
        currency: 'Malaysian Ringgit',
        code: 'MYR',
        budget_verification_ratio: 1,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 10,
            minimum_daily_budget: 50,
            maximum_daily_budget: 10_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 10,
            minimum_daily_budget: 20,
            maximum_daily_budget: 10_000_000,
            max_bid_pricefor_cpc: 30,
            max_bid_pricefor_cpm: 120,
            max_bid_pricefor_cpv: 120,
            max_bid_pricefor_ocpc: 1_500,
            max_bid_pricefor_gd: 30,
            max_bid_pricefor_ocpm: 1_500
        }
    },
    {
        currency: 'Russian Ruble',
        code: 'RUB',
        budget_verification_ratio: 100,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 1_000,
            minimum_daily_budget: 5_000,
            maximum_daily_budget: 1_000_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 1_000,
            minimum_daily_budget: 2_000,
            maximum_daily_budget: 1_000_000_000,
            max_bid_pricefor_cpc: 3_000,
            max_bid_pricefor_cpm: 12_000,
            max_bid_pricefor_cpv: 12_000,
            max_bid_pricefor_ocpc: 150_000,
            max_bid_pricefor_gd: 3_000,
            max_bid_pricefor_ocpm: 150_000
        }
    },
    {
        currency: 'South African Rand',
        code: 'ZAR',
        budget_verification_ratio: 10,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 100,
            minimum_daily_budget: 500,
            maximum_daily_budget: 100_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 100,
            minimum_daily_budget: 200,
            maximum_daily_budget: 100_000_000,
            max_bid_pricefor_cpc: 300,
            max_bid_pricefor_cpm: 1_200,
            max_bid_pricefor_cpv: 1_200,
            max_bid_pricefor_ocpc: 15_000,
            max_bid_pricefor_gd: 300,
            max_bid_pricefor_ocpm: 15_000
        }
    },
    {
        currency: 'South Korean Won',
        code: 'KRW',
        budget_verification_ratio: 1_000,
        precision: 1,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 10_000,
            minimum_daily_budget: 50_000,
            maximum_daily_budget: 10_000_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 10_000,
            minimum_daily_budget: 20_000,
            maximum_daily_budget: 10_000_000_000,
            max_bid_pricefor_cpc: 30_000,
            max_bid_pricefor_cpm: 120_000,
            max_bid_pricefor_cpv: 120_000,
            max_bid_pricefor_ocpc: 150_000_000,
            max_bid_pricefor_gd: 30_000,
            max_bid_pricefor_ocpm: 150_000_000
        }
    },
    {
        currency: 'UAE Dirham',
        code: 'AED',
        budget_verification_ratio: 1,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 10,
            minimum_daily_budget: 50,
            maximum_daily_budget: 10_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 10,
            minimum_daily_budget: 20,
            maximum_daily_budget: 10_000_000,
            max_bid_pricefor_cpc: 30,
            max_bid_pricefor_cpm: 120,
            max_bid_pricefor_cpv: 120,
            max_bid_pricefor_ocpc: 1_500,
            max_bid_pricefor_gd: 30,
            max_bid_pricefor_ocpm: 1_500
        }
    },
    {
        currency: 'Saudi Riyal',
        code: 'SAR',
        budget_verification_ratio: 1,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 10,
            minimum_daily_budget: 50,
            maximum_daily_budget: 10_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 10,
            minimum_daily_budget: 20,
            maximum_daily_budget: 10_000_000,
            max_bid_pricefor_cpc: 30,
            max_bid_pricefor_cpm: 120,
            max_bid_pricefor_cpv: 120,
            max_bid_pricefor_ocpc: 1_500,
            max_bid_pricefor_gd: 30,
            max_bid_pricefor_ocpm: 1_500
        }
    },
    {
        currency: 'Hungarian Forint',
        code: 'HUF',
        budget_verification_ratio: 1,
        precision: 1,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 10,
            minimum_daily_budget: 50,
            maximum_daily_budget: 10_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 10,
            minimum_daily_budget: 20,
            maximum_daily_budget: 10_000_000,
            max_bid_pricefor_cpc: 30,
            max_bid_pricefor_cpm: 120,
            max_bid_pricefor_cpv: 120,
            max_bid_pricefor_ocpc: 1_500,
            max_bid_pricefor_gd: 30,
            max_bid_pricefor_ocpm: 1_500
        }
    },
    {
        currency: 'Polish Zloty',
        code: 'PLN',
        budget_verification_ratio: 4,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 40,
            minimum_daily_budget: 200,
            maximum_daily_budget: 40_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 40,
            minimum_daily_budget: 80,
            maximum_daily_budget: 40_000_000,
            max_bid_pricefor_cpc: 120,
            max_bid_pricefor_cpm: 480,
            max_bid_pricefor_cpv: 480,
            max_bid_pricefor_ocpc: 6_000,
            max_bid_pricefor_gd: 120,
            max_bid_pricefor_ocpm: 6_000
        }
    },
    {
        currency: 'Danish Krone',
        code: 'DKK',
        budget_verification_ratio: 10,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 100,
            minimum_daily_budget: 500,
            maximum_daily_budget: 100_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 100,
            minimum_daily_budget: 200,
            maximum_daily_budget: 100_000_000,
            max_bid_pricefor_cpc: 300,
            max_bid_pricefor_cpm: 1_200,
            max_bid_pricefor_cpv: 1_200,
            max_bid_pricefor_ocpc: 15_000,
            max_bid_pricefor_gd: 300,
            max_bid_pricefor_ocpm: 15_000
        }
    },
    {
        currency: 'Swedish Krona',
        code: 'SEK',
        budget_verification_ratio: 10,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 100,
            minimum_daily_budget: 500,
            maximum_daily_budget: 100_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 100,
            minimum_daily_budget: 200,
            maximum_daily_budget: 100_000_000,
            max_bid_pricefor_cpc: 300,
            max_bid_pricefor_cpm: 1_200,
            max_bid_pricefor_cpv: 1_200,
            max_bid_pricefor_ocpc: 15_000,
            max_bid_pricefor_gd: 300,
            max_bid_pricefor_ocpm: 15_000
        }
    },
    {
        currency: 'Norwegian Krone',
        code: 'NOK',
        budget_verification_ratio: 10,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 100,
            minimum_daily_budget: 500,
            maximum_daily_budget: 100_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 100,
            minimum_daily_budget: 200,
            maximum_daily_budget: 100_000_000,
            max_bid_pricefor_cpc: 300,
            max_bid_pricefor_cpm: 1_200,
            max_bid_pricefor_cpv: 1_200,
            max_bid_pricefor_ocpc: 15_000,
            max_bid_pricefor_gd: 300,
            max_bid_pricefor_ocpm: 15_000
        }
    },
    {
        currency: 'Turkish Lira',
        code: 'TRY',
        budget_verification_ratio: 10,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 100,
            minimum_daily_budget: 500,
            maximum_daily_budget: 100_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 100,
            minimum_daily_budget: 200,
            maximum_daily_budget: 100_000_000,
            max_bid_pricefor_cpc: 300,
            max_bid_pricefor_cpm: 1_200,
            max_bid_pricefor_cpv: 1_200,
            max_bid_pricefor_ocpc: 15_000,
            max_bid_pricefor_gd: 300,
            max_bid_pricefor_ocpm: 15_000
        }
    },
    {
        currency: 'Mexican Peso',
        code: 'MXN',
        budget_verification_ratio: 10,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 100,
            minimum_daily_budget: 500,
            maximum_daily_budget: 100_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 100,
            minimum_daily_budget: 200,
            maximum_daily_budget: 100_000_000,
            max_bid_pricefor_cpc: 300,
            max_bid_pricefor_cpm: 1_200,
            max_bid_pricefor_cpv: 1_200,
            max_bid_pricefor_ocpc: 15_000,
            max_bid_pricefor_gd: 300,
            max_bid_pricefor_ocpm: 15_000
        }
    },
    {
        currency: 'Thai Baht',
        code: 'THB',
        budget_verification_ratio: 10,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 100,
            minimum_daily_budget: 500,
            maximum_daily_budget: 100_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 100,
            minimum_daily_budget: 200,
            maximum_daily_budget: 100_000_000,
            max_bid_pricefor_cpc: 300,
            max_bid_pricefor_cpm: 1_200,
            max_bid_pricefor_cpv: 1_200,
            max_bid_pricefor_ocpc: 15_000,
            max_bid_pricefor_gd: 300,
            max_bid_pricefor_ocpm: 15_000
        }
    },
    {
        currency: 'Australian Dollar',
        code: 'AUD',
        budget_verification_ratio: 1,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 10,
            minimum_daily_budget: 50,
            maximum_daily_budget: 10_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 10,
            minimum_daily_budget: 20,
            maximum_daily_budget: 10_000_000,
            max_bid_pricefor_cpc: 30,
            max_bid_pricefor_cpm: 120,
            max_bid_pricefor_cpv: 120,
            max_bid_pricefor_ocpc: 1_500,
            max_bid_pricefor_gd: 30,
            max_bid_pricefor_ocpm: 1_500
        }
    },
    {
        currency: 'Canadian Dollar',
        code: 'CAD',
        budget_verification_ratio: 1,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 10,
            minimum_daily_budget: 50,
            maximum_daily_budget: 10_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 10,
            minimum_daily_budget: 20,
            maximum_daily_budget: 10_000_000,
            max_bid_pricefor_cpc: 30,
            max_bid_pricefor_cpm: 120,
            max_bid_pricefor_cpv: 120,
            max_bid_pricefor_ocpc: 1_500,
            max_bid_pricefor_gd: 30,
            max_bid_pricefor_ocpm: 1_500
        }
    },
    {
        currency: 'New Zealand Dollar',
        code: 'NZD',
        budget_verification_ratio: 1,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 10,
            minimum_daily_budget: 50,
            maximum_daily_budget: 10_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 10,
            minimum_daily_budget: 20,
            maximum_daily_budget: 10_000_000,
            max_bid_pricefor_cpc: 30,
            max_bid_pricefor_cpm: 120,
            max_bid_pricefor_cpv: 120,
            max_bid_pricefor_ocpc: 1_500,
            max_bid_pricefor_gd: 30,
            max_bid_pricefor_ocpm: 1_500
        }
    },
    {
        currency: 'Singapore Dollar',
        code: 'SGD',
        budget_verification_ratio: 1,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 10,
            minimum_daily_budget: 50,
            maximum_daily_budget: 10_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 10,
            minimum_daily_budget: 20,
            maximum_daily_budget: 10_000_000,
            max_bid_pricefor_cpc: 30,
            max_bid_pricefor_cpm: 120,
            max_bid_pricefor_cpv: 120,
            max_bid_pricefor_ocpc: 1_500,
            max_bid_pricefor_gd: 30,
            max_bid_pricefor_ocpm: 1_500
        }
    },
    {
        currency: 'Swiss Franc',
        code: 'CHF',
        budget_verification_ratio: 1,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 10,
            minimum_daily_budget: 50,
            maximum_daily_budget: 10_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 10,
            minimum_daily_budget: 20,
            maximum_daily_budget: 10_000_000,
            max_bid_pricefor_cpc: 30,
            max_bid_pricefor_cpm: 120,
            max_bid_pricefor_cpv: 120,
            max_bid_pricefor_ocpc: 1_500,
            max_bid_pricefor_gd: 30,
            max_bid_pricefor_ocpm: 1_500
        }
    },
    {
        currency: 'Chinese Yuan',
        code: 'CNY',
        budget_verification_ratio: 10,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 100,
            minimum_daily_budget: 500,
            maximum_daily_budget: 100_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 100,
            minimum_daily_budget: 200,
            maximum_daily_budget: 100_000_000,
            max_bid_pricefor_cpc: 300,
            max_bid_pricefor_cpm: 1_200,
            max_bid_pricefor_cpv: 1_200,
            max_bid_pricefor_ocpc: 15_000,
            max_bid_pricefor_gd: 300,
            max_bid_pricefor_ocpm: 15_000
        }
    },
    {
        currency: 'Algerian Dinar',
        code: 'DZD',
        budget_verification_ratio: 100,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 1_000,
            minimum_daily_budget: 5_000,
            maximum_daily_budget: 1_000_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 1_000,
            minimum_daily_budget: 2_000,
            maximum_daily_budget: 1_000_000_000,
            max_bid_pricefor_cpc: 3_000,
            max_bid_pricefor_cpm: 12_000,
            max_bid_pricefor_cpv: 12_000,
            max_bid_pricefor_ocpc: 150_000,
            max_bid_pricefor_gd: 3_000,
            max_bid_pricefor_ocpm: 150_000
        }
    },
    {
        currency: 'Argentine Peso',
        code: 'ARS',
        budget_verification_ratio: 1,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 10,
            minimum_daily_budget: 50,
            maximum_daily_budget: 10_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 10,
            minimum_daily_budget: 20,
            maximum_daily_budget: 10_000_000,
            max_bid_pricefor_cpc: 30,
            max_bid_pricefor_cpm: 120,
            max_bid_pricefor_cpv: 120,
            max_bid_pricefor_ocpc: 1_500,
            max_bid_pricefor_gd: 30,
            max_bid_pricefor_ocpm: 1_500
        }
    },
    {
        currency: 'Bangladeshi Taka',
        code: 'BDT',
        budget_verification_ratio: 100,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 1_000,
            minimum_daily_budget: 5_000,
            maximum_daily_budget: 1_000_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 1_000,
            minimum_daily_budget: 2_000,
            maximum_daily_budget: 1_000_000_000,
            max_bid_pricefor_cpc: 3_000,
            max_bid_pricefor_cpm: 12_000,
            max_bid_pricefor_cpv: 12_000,
            max_bid_pricefor_ocpc: 150_000,
            max_bid_pricefor_gd: 3_000,
            max_bid_pricefor_ocpm: 150_000
        }
    },
    {
        currency: 'Bolivian Boliviano',
        code: 'BOB',
        budget_verification_ratio: 10,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 100,
            minimum_daily_budget: 500,
            maximum_daily_budget: 100_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 100,
            minimum_daily_budget: 200,
            maximum_daily_budget: 100_000_000,
            max_bid_pricefor_cpc: 300,
            max_bid_pricefor_cpm: 1_200,
            max_bid_pricefor_cpv: 1_200,
            max_bid_pricefor_ocpc: 150_000,
            max_bid_pricefor_gd: 300,
            max_bid_pricefor_ocpm: 150_000
        }
    },
    {
        currency: 'Brazilian Real',
        code: 'BRL',
        budget_verification_ratio: 1,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 10,
            minimum_daily_budget: 50,
            maximum_daily_budget: 10_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 10,
            minimum_daily_budget: 20,
            maximum_daily_budget: 10_000_000,
            max_bid_pricefor_cpc: 30,
            max_bid_pricefor_cpm: 120,
            max_bid_pricefor_cpv: 120,
            max_bid_pricefor_ocpc: 1_500,
            max_bid_pricefor_gd: 30,
            max_bid_pricefor_ocpm: 1_500
        }
    },
    {
        currency: 'Chilean Peso',
        code: 'CLP',
        budget_verification_ratio: 1_000,
        precision: 1,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 10_000,
            minimum_daily_budget: 50_000,
            maximum_daily_budget: 10_000_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 10_000,
            minimum_daily_budget: 20_000,
            maximum_daily_budget: 10_000_000_000,
            max_bid_pricefor_cpc: 30_000,
            max_bid_pricefor_cpm: 120_000,
            max_bid_pricefor_cpv: 120_000,
            max_bid_pricefor_ocpc: 1_500_000,
            max_bid_pricefor_gd: 30_000,
            max_bid_pricefor_ocpm: 1_500_000
        }
    },
    {
        currency: 'Colombian Peso',
        code: 'COP',
        budget_verification_ratio: 1_000,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 10_000,
            minimum_daily_budget: 50_000,
            maximum_daily_budget: 10_000_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 10_000,
            minimum_daily_budget: 20_000,
            maximum_daily_budget: 10_000_000_000,
            max_bid_pricefor_cpc: 30_000,
            max_bid_pricefor_cpm: 120_000,
            max_bid_pricefor_cpv: 120_000,
            max_bid_pricefor_ocpc: 1_500_000,
            max_bid_pricefor_gd: 30_000,
            max_bid_pricefor_ocpm: 1_500_000
        }
    },
    {
        currency: 'Costa Rican Colon',
        code: 'CRC',
        budget_verification_ratio: 1_000,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 10_000,
            minimum_daily_budget: 50_000,
            maximum_daily_budget: 10_000_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 10_000,
            minimum_daily_budget: 20_000,
            maximum_daily_budget: 10_000_000_000,
            max_bid_pricefor_cpc: 30_000,
            max_bid_pricefor_cpm: 120_000,
            max_bid_pricefor_cpv: 120_000,
            max_bid_pricefor_ocpc: 1_500_000,
            max_bid_pricefor_gd: 30_000,
            max_bid_pricefor_ocpm: 1_500_000
        }
    },
    {
        currency: 'Czech Koruna',
        code: 'CZK',
        budget_verification_ratio: 10,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 100,
            minimum_daily_budget: 500,
            maximum_daily_budget: 100_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 100,
            minimum_daily_budget: 200,
            maximum_daily_budget: 100_000_000,
            max_bid_pricefor_cpc: 300,
            max_bid_pricefor_cpm: 1_200,
            max_bid_pricefor_cpv: 1_200,
            max_bid_pricefor_ocpc: 150_000,
            max_bid_pricefor_gd: 300,
            max_bid_pricefor_ocpm: 150_000
        }
    },
    {
        currency: 'Egyptian Pound',
        code: 'EGP',
        budget_verification_ratio: 10,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 100,
            minimum_daily_budget: 500,
            maximum_daily_budget: 100_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 100,
            minimum_daily_budget: 200,
            maximum_daily_budget: 100_000_000,
            max_bid_pricefor_cpc: 300,
            max_bid_pricefor_cpm: 1_200,
            max_bid_pricefor_cpv: 1_200,
            max_bid_pricefor_ocpc: 150_000,
            max_bid_pricefor_gd: 300,
            max_bid_pricefor_ocpm: 150_000
        }
    },
    {
        currency: 'Guatemalan Quetzal',
        code: 'GTQ',
        budget_verification_ratio: 10,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 100,
            minimum_daily_budget: 500,
            maximum_daily_budget: 100_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 100,
            minimum_daily_budget: 200,
            maximum_daily_budget: 100_000_000,
            max_bid_pricefor_cpc: 300,
            max_bid_pricefor_cpm: 1_200,
            max_bid_pricefor_cpv: 1_200,
            max_bid_pricefor_ocpc: 150_000,
            max_bid_pricefor_gd: 300,
            max_bid_pricefor_ocpm: 15_000
        }
    },
    {
        currency: 'Honduran Lempira',
        code: 'HNL',
        budget_verification_ratio: 10,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 100,
            minimum_daily_budget: 500,
            maximum_daily_budget: 100_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 100,
            minimum_daily_budget: 200,
            maximum_daily_budget: 100_000_000,
            max_bid_pricefor_cpc: 300,
            max_bid_pricefor_cpm: 1_200,
            max_bid_pricefor_cpv: 1_200,
            max_bid_pricefor_ocpc: 15_000,
            max_bid_pricefor_gd: 30,
            max_bid_pricefor_ocpm: 15_000
        }
    },
    {
        currency: 'Icelandic Krona',
        code: 'ISK',
        budget_verification_ratio: 100,
        precision: 1,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 1_000,
            minimum_daily_budget: 5_000,
            maximum_daily_budget: 1_000_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 1_000,
            minimum_daily_budget: 2_000,
            maximum_daily_budget: 1_000_000_000,
            max_bid_pricefor_cpc: 3_000,
            max_bid_pricefor_cpm: 12_000,
            max_bid_pricefor_cpv: 12_000,
            max_bid_pricefor_ocpc: 150_000,
            max_bid_pricefor_gd: 3_000,
            max_bid_pricefor_ocpm: 150_000
        }
    },
    {
        currency: 'Indian Rupee',
        code: 'INR',
        budget_verification_ratio: 100,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 1_000,
            minimum_daily_budget: 5_000,
            maximum_daily_budget: 1_000_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 1_000,
            minimum_daily_budget: 2_000,
            maximum_daily_budget: 1_000_000_000,
            max_bid_pricefor_cpc: 3_000,
            max_bid_pricefor_cpm: 12_000,
            max_bid_pricefor_cpv: 12_000,
            max_bid_pricefor_ocpc: 150_000,
            max_bid_pricefor_gd: 3_000,
            max_bid_pricefor_ocpm: 150_000
        }
    },
    {
        currency: 'Indonesian Rupiah',
        code: 'IDR',
        budget_verification_ratio: 10_000,
        precision: 1,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 100_000,
            minimum_daily_budget: 500_000,
            maximum_daily_budget: 100_000_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 100_000,
            minimum_daily_budget: 200_000,
            maximum_daily_budget: 100_000_000_000,
            max_bid_pricefor_cpc: 300_000,
            max_bid_pricefor_cpm: 1_200_000,
            max_bid_pricefor_cpv: 1_200_000,
            max_bid_pricefor_ocpc: 15_000_000,
            max_bid_pricefor_gd: 300_000,
            max_bid_pricefor_ocpm: 15_000_000
        }
    },
    {
        currency: 'Israeli New Shekel',
        code: 'ILS',
        budget_verification_ratio: 1,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 10,
            minimum_daily_budget: 50,
            maximum_daily_budget: 10_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 10,
            minimum_daily_budget: 20,
            maximum_daily_budget: 10_000_000,
            max_bid_pricefor_cpc: 30,
            max_bid_pricefor_cpm: 120,
            max_bid_pricefor_cpv: 120,
            max_bid_pricefor_ocpc: 1_500,
            max_bid_pricefor_gd: 30,
            max_bid_pricefor_ocpm: 1_500
        }
    },
    {
        currency: 'Kenyan Shilling',
        code: 'KES',
        budget_verification_ratio: 100,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 1_000,
            minimum_daily_budget: 5_000,
            maximum_daily_budget: 1_000_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 1_000,
            minimum_daily_budget: 2_000,
            maximum_daily_budget: 1_000_000_000,
            max_bid_pricefor_cpc: 3_000,
            max_bid_pricefor_cpm: 12_000,
            max_bid_pricefor_cpv: 12_000,
            max_bid_pricefor_ocpc: 150_000,
            max_bid_pricefor_gd: 3_000,
            max_bid_pricefor_ocpm: 150_000
        }
    },
    {
        currency: 'Macanese Pataca',
        code: 'MOP',
        budget_verification_ratio: 10,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 100,
            minimum_daily_budget: 500,
            maximum_daily_budget: 100_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 100,
            minimum_daily_budget: 200,
            maximum_daily_budget: 100_000_000,
            max_bid_pricefor_cpc: 300,
            max_bid_pricefor_cpm: 1_200,
            max_bid_pricefor_cpv: 1_200,
            max_bid_pricefor_ocpc: 15_000,
            max_bid_pricefor_gd: 300,
            max_bid_pricefor_ocpm: 15_000
        }
    },
    {
        currency: 'New Taiwan Dollar',
        code: 'TWD',
        budget_verification_ratio: 10,
        precision: 1,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 100,
            minimum_daily_budget: 500,
            maximum_daily_budget: 100_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 100,
            minimum_daily_budget: 200,
            maximum_daily_budget: 100_000_000,
            max_bid_pricefor_cpc: 300,
            max_bid_pricefor_cpm: 1_200,
            max_bid_pricefor_cpv: 1_200,
            max_bid_pricefor_ocpc: 15_000,
            max_bid_pricefor_gd: 300,
            max_bid_pricefor_ocpm: 15_000
        }
    },
    {
        currency: 'Nicaraguan Cordoba',
        code: 'NIO',
        budget_verification_ratio: 10,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 100,
            minimum_daily_budget: 500,
            maximum_daily_budget: 100_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 100,
            minimum_daily_budget: 200,
            maximum_daily_budget: 100_000_000,
            max_bid_pricefor_cpc: 300,
            max_bid_pricefor_cpm: 1_200,
            max_bid_pricefor_cpv: 1_200,
            max_bid_pricefor_ocpc: 15_000,
            max_bid_pricefor_gd: 300,
            max_bid_pricefor_ocpm: 15_000
        }
    },
    {
        currency: 'Nigerian Naira',
        code: 'NGN',
        budget_verification_ratio: 100,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 1_000,
            minimum_daily_budget: 5_000,
            maximum_daily_budget: 1_000_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 1_000,
            minimum_daily_budget: 2_000,
            maximum_daily_budget: 1_000_000_000,
            max_bid_pricefor_cpc: 3_000,
            max_bid_pricefor_cpm: 12_000,
            max_bid_pricefor_cpv: 12_000,
            max_bid_pricefor_ocpc: 1_500_000,
            max_bid_pricefor_gd: 3_000,
            max_bid_pricefor_ocpm: 150_000
        }
    },
    {
        currency: 'Pakistani Rupee',
        code: 'PKR',
        budget_verification_ratio: 100,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 1_000,
            minimum_daily_budget: 5_000,
            maximum_daily_budget: 1_000_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 1_000,
            minimum_daily_budget: 2_000,
            maximum_daily_budget: 1_000_000_000,
            max_bid_pricefor_cpc: 3_000,
            max_bid_pricefor_cpm: 12_000,
            max_bid_pricefor_cpv: 12_000,
            max_bid_pricefor_ocpc: 150_000,
            max_bid_pricefor_gd: 3_000,
            max_bid_pricefor_ocpm: 150_000
        }
    },
    {
        currency: 'Paraguayan Guarani',
        code: 'PYG',
        budget_verification_ratio: 10_000,
        precision: 1,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 100_000,
            minimum_daily_budget: 500_000,
            maximum_daily_budget: 100_000_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 100_000,
            minimum_daily_budget: 200_000,
            maximum_daily_budget: 100_000_000_000,
            max_bid_pricefor_cpc: 300_000,
            max_bid_pricefor_cpm: 1_200_000,
            max_bid_pricefor_cpv: 1_200_000,
            max_bid_pricefor_ocpc: 15_000_000,
            max_bid_pricefor_gd: 300_000,
            max_bid_pricefor_ocpm: 15_000_000
        }
    },
    {
        currency: 'Peruvian Nuevo Sol',
        code: 'PEN',
        budget_verification_ratio: 1,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 10,
            minimum_daily_budget: 50,
            maximum_daily_budget: 10_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 10,
            minimum_daily_budget: 20,
            maximum_daily_budget: 10_000_000,
            max_bid_pricefor_cpc: 30,
            max_bid_pricefor_cpm: 120,
            max_bid_pricefor_cpv: 120,
            max_bid_pricefor_ocpc: 1_500,
            max_bid_pricefor_gd: 30,
            max_bid_pricefor_ocpm: 1_500
        }
    },
    {
        currency: 'Philippine Peso',
        code: 'PHP',
        budget_verification_ratio: 50,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 500,
            minimum_daily_budget: 2_500,
            maximum_daily_budget: 500_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 500,
            minimum_daily_budget: 1_000,
            maximum_daily_budget: 500_000_000,
            max_bid_pricefor_cpc: 1_500,
            max_bid_pricefor_cpm: 6_000,
            max_bid_pricefor_cpv: 6_000,
            max_bid_pricefor_ocpc: 75_000,
            max_bid_pricefor_gd: 1_500,
            max_bid_pricefor_ocpm: 75_000
        }
    },
    {
        currency: 'Qatari Riyal',
        code: 'QAR',
        budget_verification_ratio: 1,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 10,
            minimum_daily_budget: 50,
            maximum_daily_budget: 10_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 10,
            minimum_daily_budget: 20,
            maximum_daily_budget: 10_000_000,
            max_bid_pricefor_cpc: 30,
            max_bid_pricefor_cpm: 120,
            max_bid_pricefor_cpv: 120,
            max_bid_pricefor_ocpc: 1_500,
            max_bid_pricefor_gd: 30,
            max_bid_pricefor_ocpm: 1_500
        }
    },
    {
        currency: 'Romanian Leu',
        code: 'RON',
        budget_verification_ratio: 1,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 10,
            minimum_daily_budget: 50,
            maximum_daily_budget: 10_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 10,
            minimum_daily_budget: 20,
            maximum_daily_budget: 10_000_000,
            max_bid_pricefor_cpc: 30,
            max_bid_pricefor_cpm: 120,
            max_bid_pricefor_cpv: 120,
            max_bid_pricefor_ocpc: 1_500,
            max_bid_pricefor_gd: 30,
            max_bid_pricefor_ocpm: 1_500
        }
    },
    {
        currency: 'Uruguayan Peso',
        code: 'UYU',
        budget_verification_ratio: 10,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 100,
            minimum_daily_budget: 500,
            maximum_daily_budget: 100_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 100,
            minimum_daily_budget: 200,
            maximum_daily_budget: 100_000_000,
            max_bid_pricefor_cpc: 300,
            max_bid_pricefor_cpm: 1_200,
            max_bid_pricefor_cpv: 1_200,
            max_bid_pricefor_ocpc: 15_000,
            max_bid_pricefor_gd: 300,
            max_bid_pricefor_ocpm: 15_000
        }
    },
    {
        currency: 'Venezuelan Bolivar',
        code: 'VEF',
        budget_verification_ratio: 100_000,
        precision: 0.01,
        campaign: {
            minimum_daily_budget_product_sales_with_store: 1_000_000,
            minimum_daily_budget: 5_000_000,
            maximum_daily_budget: 1_000_000_000_000
        },
        adgroup: {
            minimum_daily_budget_product_sales_with_store_or_showcase: 1_000_000,
            minimum_daily_budget: 2_000_000,
            maximum_daily_budget: 1_000_000_000_000,
            max_bid_pricefor_cpc: 3_000_000,
            max_bid_pricefor_cpm: 12_000_000,
            max_bid_pricefor_cpv: 12_000_000,
            max_bid_pricefor_ocpc: 150_000_000,
            max_bid_pricefor_gd: 3_000_000,
            max_bid_pricefor_ocpm: 150_000_000
        }
    }
]
