<template>
    <div id="interest_wrapper">
        <div
            v-if="recommened_interest_categories.length"
            class="border-border border-[1px] rounded-lg mb-2 overflow-hidden"
        >
            <div class="bg-bg p-4 py-3">
                <div class="flex items-center justify-between">
                    <p class="font-semibold text-sm">
                        <i class="el-icon-s-opportunity"></i>
                        {{
                            $t(
                                'page.campaign.recommended_interest_behavior_categories'
                            )
                        }}
                    </p>

                    <p
                        class="underline underline-offset-1 cursor-pointer text-brand-atosa text-xs"
                        @click="handleChooseAllRecommendInterest()"
                    >
                        {{ $t('button.select_all') }}
                    </p>
                </div>

                <p class="text-desc-text text-xs">
                    {{
                        $t(
                            'page.campaign.try_xxx_interest_behavior_categories',
                            {
                                xxx: recommened_interest_categories.length
                            }
                        )
                    }}
                </p>
            </div>
            <div class="p-4 max-h-[200px] overflow-auto">
                <ul class="flex flex-wrap gap-1">
                    <li
                        v-for="(item, index) in recommened_interest_categories"
                        :key="index"
                        @click="
                            handleChooseRecommendInterest(
                                item.interest_category_id
                            )
                        "
                    >
                        <el-tag class="cursor-pointer">
                            {{ item.interest_category_name }}
                        </el-tag>
                    </li>
                </ul>
            </div>
        </div>

        <div class="border-border border-[1px] rounded-lg p-4">
            <div class="flex items-center space-x-2">
                <p class="component-label mb-0">
                    {{ $t('page.campaign.interests') }}
                </p>

                <div class="!ml-auto"></div>

                <p
                    class="underline underline-offset-1 cursor-pointer text-brand-atosa text-xs"
                    @click="is_show_create_target_dialog = true"
                >
                    {{ $t('common.save') }}
                </p>

                <p
                    class="underline underline-offset-1 cursor-pointer text-brand-atosa text-xs"
                    @click="
                        show_list_save_target_table =
                            !show_list_save_target_table
                    "
                >
                    {{
                        show_list_save_target_table
                            ? $t('common.close_list')
                            : $t('common.list')
                    }}
                </p>

                <i
                    class="el-icon-delete text-sm cursor-pointer"
                    @click="handleUnselected"
                ></i>
            </div>
            <p class="component-text-desc mt-0 mb-3">
                {{ $t('page.campaign.desc_interests') }}
            </p>

            <treeselect
                v-model="interest_category_ids"
                multiple
                :options="interest_category_options"
                :placeholder="$t('input.placeholder.please_select')"
                :normalizer="normalizer_option"
                @input="handleChangeSelected"
            />

            <el-table
                v-if="show_list_save_target_table"
                max-height="400"
                :data="list_custom_targets"
                class="mt-2"
            >
                <el-table-column width="260" :label="$t('common.name')">
                    <template #default="scope">
                        <div class="flex space-x-2">
                            <div>
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="scope.row.custom_target_name"
                                    placement="top"
                                >
                                    <p class="font-semibold truncate">
                                        {{ scope.row.custom_target_name }}
                                    </p>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    :label="$t('table.header.action')"
                    min-width="160"
                >
                    <template slot-scope="scope">
                        <div>
                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="$t('common.add_to_target')"
                                placement="top"
                            >
                                <el-button
                                    size="small"
                                    icon="el-icon-plus"
                                    @click="
                                        handleAddCustomTargetToList(
                                            scope.row.data_config
                                        )
                                    "
                                >
                                </el-button>
                            </el-tooltip>

                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="$t('common.remove_to_target')"
                                placement="top"
                            >
                                <el-button
                                    size="small"
                                    icon="el-icon-minus"
                                    @click="
                                        handleRemoveCustomTargetToList(
                                            scope.row.data_config
                                        )
                                    "
                                >
                                </el-button>
                            </el-tooltip>

                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="$t('common.replate_to_target')"
                                placement="top"
                            >
                                <el-button
                                    size="small"
                                    icon="el-icon-aim"
                                    @click="
                                        handleReplaceCustomTargetToList(
                                            scope.row.data_config
                                        )
                                    "
                                >
                                </el-button>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    :label="$t('common.option')"
                    fixed="right"
                    width="100"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="danger"
                            size="small"
                            @click="handleDeleteCustomTarget(scope.row._id)"
                        >
                            {{ $t('button.delete') }}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <el-dialog
            :visible.sync="is_show_create_target_dialog"
            :close-on-click-modal="true"
            :append-to-body="true"
            width="480px"
        >
            <div slot="title">
                <p class="text-lg font-semibold">
                    {{ $t('button.save_custom_target') }}
                </p>
            </div>

            <div>
                <p class="component-label">
                    {{ $t('common.name') }}
                </p>
                <el-input
                    v-model="custom_target_name"
                    type="text"
                    size="small"
                    :placeholder="$t('input.placeholder.please_input')"
                >
                </el-input>
            </div>

            <template slot="footer">
                <div class="flex items-center justify-end mt-4">
                    <el-button
                        size="small"
                        @click="is_show_create_target_dialog = false"
                    >
                        {{ $t('button.close') }}
                    </el-button>

                    <el-button
                        :disabled="!custom_target_name"
                        type="primary"
                        size="small"
                        icon="el-icon-check"
                        @click="handleCreateCustomTarget"
                    >
                        {{ $t('button.confirm') }}
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {
    getQueryTool,
    getRecommendedInterest
} from '@/services/atosa-tiktok-ads/tool'
import Treeselect from '@riophae/vue-treeselect'
import {
    createCustomTarget,
    getCustomTargets,
    deleteCustomTarget
} from '@/services/atosa-tiktok-ads/custom-target'
import _ from 'lodash'

export default {
    components: {
        Treeselect
    },

    props: ['selected_item', 'tiktok_account_id', 'advertiser_id'],

    data() {
        return {
            normalizer_option(node) {
                return {
                    id: node.interest_category_id,
                    label: node.interest_category_name,
                    children: node.sub_categories
                }
            },
            list_custom_targets: [],
            custom_target_name: '',
            is_show_create_target_dialog: false,
            show_list_save_target_table: false,
            recommened_interest_categories: [],
            interest_category_options: [],
            origin_interest_category_options: [],
            interest_category_ids: []
        }
    },

    computed: {
        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        }
    },

    watch: {
        'selected_item.id'() {
            this.initData()
        },

        selected_item() {
            this.initData()
        },

        'selected_item_in_line.extension.country_codes'() {
            this.fetchRecommendedInterests()
        },

        is_show_create_target_dialog() {
            this.custom_target_name = ''
        },

        interest_category_ids() {
            this.selected_item_in_line.extension.interest_names =
                this.interest_category_ids.map((id) => {
                    const temp = this.origin_interest_category_options.find(
                        (i) => i.interest_category_id === id
                    )
                    if (temp) {
                        return temp.interest_category_name
                    }
                    return id
                })
        }
    },

    async mounted() {
        this.initData()

        await this.fetchInterests()

        this.fetchRecommendedInterests()

        this.fetchCustomTargets()
    },

    methods: {
        handleChooseAllRecommendInterest() {
            this.interest_category_ids = [
                ...new Set(
                    this.recommened_interest_categories.map(
                        (item) => item.interest_category_id
                    )
                )
            ]

            this.handleChangeSelected()
        },

        handleChooseRecommendInterest(interest_category_id) {
            this.interest_category_ids = [
                ...new Set([
                    ...this.interest_category_ids,
                    interest_category_id
                ])
            ]

            this.handleChangeSelected()
        },

        initData() {
            this.interest_category_ids = []

            if (this.selected_item_in_line?.interest_category_ids.length > 0) {
                this.interest_category_ids =
                    this.selected_item_in_line.interest_category_ids
            }
        },

        async fetchCustomTargets() {
            try {
                const response = await getCustomTargets({
                    custom_target_name: '',
                    custom_target_type: 'INTEREST_CATEGORY',
                    tiktok_account_id: ''
                })

                this.list_custom_targets = response.data
            } catch (error) {
                console.error(error)
            }
        },

        handleUnselected() {
            this.interest_category_ids = []

            this.handleChangeSelected()
        },

        async fetchInterests() {
            const response = await getQueryTool(this.tiktok_account_id, {
                path: 'interest_category/',
                params: {
                    advertiser_id: this.advertiser_id,
                    language: this.$i18n.locale
                }
            })
            if (response.code === 0) {
                const temp = []
                const { interest_categories } = response.data
                this.origin_interest_category_options = interest_categories
                interest_categories.forEach((item) => {
                    if (item.level !== 1) {
                        return
                    }

                    if (item.sub_category_ids?.length) {
                        item.sub_categories = null

                        const sub_categories = this.getSubCategories(
                            item.sub_category_ids,
                            interest_categories
                        )

                        item.sub_categories = sub_categories
                    }

                    temp.push(item)
                })

                this.interest_category_options = this.p__sortArrByName(
                    temp,
                    'interest_category_name'
                )
            }
        },

        getSubCategories(sub_category_ids, categories) {
            const sub_categories = []

            sub_category_ids.forEach((id) => {
                const category = categories.find(
                    (c) => c.interest_category_id === id
                )

                if (category) {
                    if (category.sub_category_ids?.length) {
                        category.sub_categories = null

                        const sub_categories = this.getSubCategories(
                            category.sub_category_ids,
                            categories
                        )

                        category.sub_categories = sub_categories
                    }

                    sub_categories.push(category)
                }
            })

            return this.p__sortArrByName(
                sub_categories,
                'interest_category_name'
            )
        },

        async fetchRecommendedInterests() {
            if (!this.selected_item_in_line.extension.country_codes.length) {
                return
            }
            try {
                const response = await getRecommendedInterest(
                    this.tiktok_account_id,
                    {
                        advertiser_id: this.advertiser_id,
                        region_codes:
                            this.selected_item_in_line.extension.country_codes,
                        language: 'vi'
                    }
                )

                if (this.origin_interest_category_options.length) {
                    this.recommened_interest_categories =
                        response.data.interest_categories.map((item) => {
                            const temp =
                                this.origin_interest_category_options.find(
                                    (i) =>
                                        i.interest_category_id ===
                                        item.interest_category_id
                                )
                            if (temp) {
                                return temp
                            }
                            return item
                        })
                } else {
                    this.recommened_interest_categories =
                        response.data.interest_categories
                }
            } catch (error) {
                console.error(error)
            }
        },

        async handleCreateCustomTarget() {
            if (!this.selected_item.interest_category_ids.length) {
                this.p__showNotify(
                    'warning',
                    this.$t(
                        'page.campaign.message_dont_choose_interest_keyword_ids'
                    )
                )
                return
            }

            if (!this.custom_target_name) {
                return
            }
            try {
                const data_config = JSON.stringify({
                    interest_category_ids:
                        this.selected_item.interest_category_ids
                })

                await createCustomTarget(this.tiktok_account_id, {
                    custom_target_name: this.custom_target_name,
                    custom_target_type: 'INTEREST_CATEGORY',
                    data_config
                })

                this.custom_target_name = ''
                this.is_show_create_target_dialog = false

                this.fetchCustomTargets()

                this.p__showNotify(
                    'success',
                    this.$t('common.create_data_success')
                )
            } catch (error) {
                console.error(error)
            }
        },

        async handleDeleteCustomTarget(ct_id) {
            try {
                await this.$confirm(
                    this.$t('common.delete_data_confirm'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                )
            } catch (e) {
                return
            }

            try {
                await deleteCustomTarget(this.tiktok_account_id, {
                    custom_target_id: ct_id
                })

                this.fetchCustomTargets()

                this.p__showNotify(
                    'success',
                    this.$t('common.delete_data_success')
                )
            } catch (error) {
                console.error(error)
            }
        },

        handleChangeSelected() {
            this.selected_item_in_line.interest_category_ids =
                this.interest_category_ids
        },

        handleAddCustomTargetToList(data_config) {
            const temp = _.cloneDeep(JSON.parse(data_config))

            this.selected_item_in_line.interest_category_ids =
                this.p__removeDuplicateValueInArray([
                    ...this.selected_item_in_line.interest_category_ids,
                    ...temp.interest_category_ids
                ])

            this.initData()
        },

        handleRemoveCustomTargetToList(data_config) {
            const temp = _.cloneDeep(JSON.parse(data_config))

            this.selected_item_in_line.interest_category_ids = [
                ...this.selected_item_in_line.interest_category_ids
            ].filter(
                (item) => !temp.interest_category_ids.some((it) => it === item)
            )

            this.initData()
        },

        handleReplaceCustomTargetToList(data_config) {
            const temp = _.cloneDeep(JSON.parse(data_config))

            this.selected_item_in_line.interest_category_ids = [
                ...temp.interest_category_ids
            ]

            this.initData()
        }
    }
}
</script>
