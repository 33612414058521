<template>
    <custom-drawer
        v-loading="m__loading"
        size="50%"
        :wrapper_closable="false"
        :confirm_to_close="false"
        :visible.sync="visible_in_line"
    >
        <template slot="title">
            <div class="flex items-center justify-between flex-1 h-full pr-5">
                <div>
                    <p class="font-semibold text-sm">
                        {{ $t('page.campaign.list_custom_target') }}
                    </p>
                    <p v-if="objective_type" class="component-text-desc">
                        {{ $t('common.objective_type') }} :
                        {{ p__formatObjectiveType(objective_type) }}
                    </p>
                </div>
                <el-button
                    icon="el-icon-check"
                    type="primary"
                    :disabled="!radio_select_product || !data_config"
                    size="small"
                    @click="handleChooseCustomTarget()"
                >
                    {{ $t('button.select_custom_target') }}
                </el-button>
            </div>
        </template>
        <div class="px-4 py-8">
            <div
                class="component-box flex mb-4 space-x-4"
                :style="{ width: 'fit-content' }"
            >
                <div class="w-[320px]">
                    <p class="component-label">
                        {{ $t('common.name') }}
                    </p>
                    <el-input
                        v-model="search_custom_name"
                        size="small"
                        clearable
                        @clear="fetchCustomTargets"
                    >
                        <el-button
                            slot="append"
                            icon="el-icon-search"
                            @click="fetchCustomTargets"
                        ></el-button>
                    </el-input>
                </div>

                <div class="w-[240px]">
                    <p class="component-label">
                        {{ $t('common.tiktok_accounts') }}
                    </p>

                    <el-select
                        :value="selected_tiktok_business"
                        value-key="id"
                        size="small"
                        filterable
                        :placeholder="
                            $t('input.placeholder.select_tiktok_account')
                        "
                        class="w-full"
                        clearable
                        @change="handleChangeTiktokBusiness"
                    >
                        <el-option-group :label="$t('common.tiktok_accounts')">
                            <el-option
                                v-for="item in tiktok_businesses"
                                :key="item.core_user_id"
                                :label="item.custom_info.display_name"
                                :value="item"
                            >
                                <div class="flex items-center space-x-2 py-1">
                                    <el-avatar :size="36">
                                        <img
                                            v-if="item.custom_info.avatar_url"
                                            class="object-cover"
                                            :src="`${VUE_APP_ATOSA_TIKTOK_SERVER_STATIC}/${item.custom_info.avatar_url}`"
                                            alt=""
                                        />
                                        <p v-else>{{ item.user_name }}</p>
                                    </el-avatar>
                                    <div class="flex flex-col">
                                        <span
                                            class="leading-5 text-xs font-semibold"
                                        >
                                            {{
                                                item.custom_info.display_name ||
                                                item.user_name
                                            }}
                                        </span>
                                        <span class="component-text-desc">
                                            #{{ item.core_user_id }}
                                        </span>
                                    </div>
                                </div>
                            </el-option>
                        </el-option-group>
                    </el-select>
                </div>
            </div>

            <div class="component-box">
                <el-table
                    ref="multipleTable"
                    v-loading="m__loading"
                    :data="list_custom_targets"
                >
                    <el-table-column width="48" label="">
                        <template #default="scope">
                            <div class="flex space-x-2">
                                <el-radio
                                    v-model="radio_select_product"
                                    :label="scope.row._id"
                                    @change="
                                        data_config = scope.row.data_config
                                    "
                                >
                                    <p class="hidden">hello</p>
                                </el-radio>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column min-width="160" :label="$t('common.name')">
                        <template #default="scope">
                            <div class="flex space-x-2">
                                <div>
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        :content="scope.row.custom_target_name"
                                        placement="top"
                                    >
                                        <p class="font-semibold truncate">
                                            {{ scope.row.custom_target_name }}
                                        </p>
                                    </el-tooltip>
                                </div>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column
                        min-width="200"
                        :label="$t('table.header.username')"
                    >
                        <template #default="scope">
                            <div>
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="
                                        scope.row.custom_info.display_name
                                    "
                                    placement="top"
                                >
                                    <p class="truncate">
                                        {{ scope.row.custom_info.display_name }}
                                    </p>
                                </el-tooltip>
                            </div>
                            <p class="text-desc-text">
                                #{{ scope.row.tiktok_account_id }}
                            </p>
                        </template>
                    </el-table-column>

                    <el-table-column
                        min-width="160"
                        :label="$t('common.created_at')"
                    >
                        <template #default="scope">
                            <div class="flex space-x-2">
                                <div>
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        :content="
                                            scope.row.created_at
                                                | f__format_moment(
                                                    $t('common.format_fulldate')
                                                )
                                        "
                                        placement="top"
                                    >
                                        <p class="truncate">
                                            {{
                                                scope.row.created_at
                                                    | f__format_moment(
                                                        $t(
                                                            'common.format_fulldate'
                                                        )
                                                    )
                                            }}
                                        </p>
                                    </el-tooltip>
                                </div>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column
                        :label="$t('common.option')"
                        fixed="right"
                        min-width="160"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="danger"
                                size="small"
                                @click="handleDeleteCustomTarget(scope.row._id)"
                            >
                                {{ $t('button.delete') }}
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </custom-drawer>
</template>

<script>
import {
    getCustomTargets,
    deleteCustomTarget
} from '@/services/atosa-tiktok-ads/custom-target'
import _ from 'lodash'

export default {
    name: 'ListAdgroupCustomTargetDrawer',

    props: [
        'visible',
        'tiktok_account_id',
        'items',
        'objective_type',
        'selected_item'
    ],

    data() {
        return {
            search_custom_name: '',
            data_config: null,
            selected_tiktok_business: null,
            list_custom_targets: [],
            radio_select_product: null,
            VUE_APP_ATOSA_TIKTOK_SERVER_STATIC:
                process.env.VUE_APP_ATOSA_TIKTOK_SERVER_STATIC
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        },

        items_inline: {
            get() {
                return this.items
            },
            set(value) {
                this.$emit('update:items', value)
            }
        },

        selected_item_inline: {
            get() {
                return this.selected_item
            },
            set(value) {
                this.$emit('update:selected_item', value)
            }
        },

        tiktok_businesses() {
            return this.$store.getters.tiktokBusiness.s__tiktok_businesses
        }
    },

    mounted() {
        this.fetchCustomTargets()

        this.p__event_bus.$on(
            'eb_reload_custom_targets',
            this.fetchCustomTargets
        )
    },

    destroyed() {
        this.p__event_bus.$off(
            'eb_reload_custom_targets',
            this.fetchCustomTargets
        )
    },

    methods: {
        handleChangeTiktokBusiness(value) {
            this.selected_tiktok_business = value

            this.fetchCustomTargets()
        },

        async fetchCustomTargets() {
            this.radio_select_product = null

            try {
                const response = await getCustomTargets({
                    objective_type: this.objective_type,
                    custom_target_name: this.search_custom_name,
                    custom_target_type: 'AD',
                    tiktok_account_id: this.selected_tiktok_business
                        ? this.selected_tiktok_business.core_user_id
                        : ''
                })
                this.list_custom_targets = response.data
            } catch (error) {
                console.error(error)
            }
        },

        async handleDeleteCustomTarget(ct_id) {
            try {
                await this.$confirm(
                    this.$t('common.delete_data_confirm'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                )
            } catch (e) {
                return
            }

            try {
                await deleteCustomTarget(this.tiktok_account_id, {
                    custom_target_id: ct_id
                })

                this.fetchCustomTargets()

                this.p__showNotify(
                    'success',
                    this.$t('common.delete_data_success')
                )
            } catch (error) {
                console.error(error)
            }
        },

        handleChooseCustomTarget() {
            if (!this.radio_select_product || !this.data_config) {
                return
            }

            this.items_inline = _.cloneDeep(JSON.parse(this.data_config))
            this.selected_item_inline = _.cloneDeep(
                JSON.parse(this.data_config)
            )[0]

            this.radio_select_product = null
            this.search_custom_name = ''
            this.selected_tiktok_business = null
            this.data_config = null

            this.visible_in_line = false
        }
    }
}
</script>
