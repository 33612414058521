<template>
    <div class="component-box">
        <p class="font-semibold mb-8">
            {{ $t('common.website') }}
        </p>

        <div class="flex space-x-6">
            <div
                v-for="item in options"
                :key="item.value"
                class="border-l-[3px] h-[52px] w-[200px] bg-bg flex items-center pl-6 rounded-lg space-x-2 cursor-pointer"
                :class="[
                    selected_type_web === item.value
                        ? 'border-brand-atosa'
                        : 'hover:border-border border-transparent'
                ]"
                @click="selected_type_web = item.value"
            >
                <p class="font-semibold text-sm">
                    {{ item.label }}
                </p>
            </div>
        </div>

        <div class="mt-6">
            <div v-if="selected_type_web === 'EXTERNAL_WEBSITE'">
                <TiktokPixel
                    :selected_item.sync="selected_item_in_line"
                    :tiktok_account_id="tiktok_account_id"
                    :advertiser_id="advertiser_id"
                ></TiktokPixel>
            </div>

            <div v-else>
                <p class="component-label">
                    {{ $t('page.campaign.optimization_event') }}
                </p>
                <div class="flex">
                    <el-select
                        v-model="selected_item_in_line.optimization_event"
                        size="small"
                        filterable
                        :placeholder="$t('input.placeholder.please_select')"
                        class="w-[320px]"
                    >
                        <el-option-group
                            :label="$t('page.campaign.optimization_event')"
                        >
                            <el-option
                                v-for="item in tiktok_instant_page_event_options"
                                :key="item"
                                :label="p__formatPixelEvent(item)"
                                :value="item"
                            >
                            </el-option>
                        </el-option-group>
                    </el-select>
                    <el-button
                        icon="el-icon-refresh"
                        size="small"
                        class="ml-2"
                        @click="fetchInstantPages"
                    ></el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getInstantPages } from '@/services/atosa-tiktok-ads/event'
import paginationDataMixin from '@/plugins/mixins/pagination-data'
import TiktokPixel from '@/components/orgranisms/edit-adgroup-form/_components/tiktok-pixel'

export default {
    components: {
        TiktokPixel
    },

    mixins: [paginationDataMixin],

    props: [
        'selected_item',
        'advertiser_id',
        'tiktok_account_id',
        'objective_type'
    ],

    data() {
        return {
            tiktok_instant_page_event_options: [],
            selected_type_web: 'EXTERNAL_WEBSITE',
            options: [
                {
                    label: this.$t('page.campaign.external_website'),
                    value: 'EXTERNAL_WEBSITE'
                },
                {
                    label: this.$t('page.campaign.tiktok_instant_page'),
                    value: 'TIKTOK_INSTANT_PAGE'
                }
            ]
        }
    },

    computed: {
        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        }
    },

    watch: {
        selected_type_web() {
            if (this.selected_type_web === 'EXTERNAL_WEBSITE') {
                this.selected_item_in_line.pixel_id = ''
                this.selected_item_in_line.optimization_event = ''
                this.selected_item_in_line.promotion_website_type = ''
            } else {
                if (this.tiktok_instant_page_event_options.length) {
                    this.selected_item_in_line.optimization_event =
                        this.tiktok_instant_page_event_options[0]
                    this.selected_item_in_line.promotion_website_type =
                        'TIKTOK_NATIVE_PAGE'
                }
            }
        },

        selected_item() {
            this.getInfoForState()
        }
    },

    mounted() {
        this.getInfoForState()

        this.fetchInstantPages()
    },

    methods: {
        getInfoForState() {
            this.selected_type_web =
                this.selected_item_in_line.extension.selected_type_web
        },

        async fetchInstantPages() {
            const response = await getInstantPages(this.tiktok_account_id, {
                advertiser_id: this.advertiser_id,
                objective_type: this.objective_type,
                optimization_goal: this.selected_item_in_line.optimization_goal
            })

            const tiktok_instant_pages = response.data.list.find(
                (item) => item.business_type === 'TIKTOK_INSTANT_PAGE'
            )

            if (tiktok_instant_pages) {
                this.tiktok_instant_page_event_options =
                    tiktok_instant_pages.instant_page_events.objective_types[0].optimization_goals[0].optimization_events

                if (this.tiktok_instant_page_event_options.length) {
                    this.selected_item_in_line.optimization_event =
                        this.tiktok_instant_page_event_options[0]
                    this.selected_item_in_line.promotion_website_type =
                        'TIKTOK_NATIVE_PAGE'
                }
            }
        }
    }
}
</script>
