export default {
    data() {
        return {
            m__view_layout: null,
            m__view_layout_has_scroll: false
        }
    },

    computed: {
        m__class_top() {
            if (this.m__view_layout_has_scroll) {
                return 'sticky top-3'
            }

            return 'sticky top-0'
        }
    },

    mounted() {
        this.m__view_layout = document.getElementById('view_layout')

        this.m__view_layout.addEventListener(
            'scroll',
            this.addStyleToProcessBar
        )
    },

    beforeDestroy() {
        this.m__view_layout.removeEventListener(
            'scroll',
            this.addStyleToProcessBar
        )
    },

    methods: {
        addStyleToProcessBar(event) {
            this.m__view_layout_has_scroll = event.target.scrollTop !== 0
        }
    }
}
