import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
const ls = new SecureLS({ isCompression: false })

Vue.use(Vuex)

const modulesFiles = require.context('./modules', true, /\.js$/)

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    const value = modulesFiles(modulePath)
    modules[moduleName] = value.default
    return modules
}, {})

const getters = {
    app: (state) => state.app,
    user: (state) => state.user,
    businessCenter: (state) => state.businessCenter,
    tiktokBusiness: (state) => state.tiktokBusiness,
    currentPackage: (state) => state.currentPackage,
    permission: (state) => state.permission
}

export default new Vuex.Store({
    // plugins: [createPersistedState()],
    modules,
    getters
})
