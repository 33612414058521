var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"interest_wrapper"}},[(_vm.recommened_interest_categories.length)?_c('div',{staticClass:"border-border border-[1px] rounded-lg mb-2 overflow-hidden"},[_c('div',{staticClass:"bg-bg p-4 py-3"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('p',{staticClass:"font-semibold text-sm"},[_c('i',{staticClass:"el-icon-s-opportunity"}),_vm._v(" "+_vm._s(_vm.$t( 'page.campaign.recommended_interest_behavior_categories' ))+" ")]),_c('p',{staticClass:"underline underline-offset-1 cursor-pointer text-brand-atosa text-xs",on:{"click":function($event){return _vm.handleChooseAllRecommendInterest()}}},[_vm._v(" "+_vm._s(_vm.$t('button.select_all'))+" ")])]),_c('p',{staticClass:"text-desc-text text-xs"},[_vm._v(" "+_vm._s(_vm.$t( 'page.campaign.try_xxx_interest_behavior_categories', { xxx: _vm.recommened_interest_categories.length } ))+" ")])]),_c('div',{staticClass:"p-4 max-h-[200px] overflow-auto"},[_c('ul',{staticClass:"flex flex-wrap gap-1"},_vm._l((_vm.recommened_interest_categories),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.handleChooseRecommendInterest(
                            item.interest_category_id
                        )}}},[_c('el-tag',{staticClass:"cursor-pointer"},[_vm._v(" "+_vm._s(item.interest_category_name)+" ")])],1)}),0)])]):_vm._e(),_c('div',{staticClass:"border-border border-[1px] rounded-lg p-4"},[_c('div',{staticClass:"flex items-center space-x-2"},[_c('p',{staticClass:"component-label mb-0"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.interests'))+" ")]),_c('div',{staticClass:"!ml-auto"}),_c('p',{staticClass:"underline underline-offset-1 cursor-pointer text-brand-atosa text-xs",on:{"click":function($event){_vm.is_show_create_target_dialog = true}}},[_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")]),_c('p',{staticClass:"underline underline-offset-1 cursor-pointer text-brand-atosa text-xs",on:{"click":function($event){_vm.show_list_save_target_table =
                        !_vm.show_list_save_target_table}}},[_vm._v(" "+_vm._s(_vm.show_list_save_target_table ? _vm.$t('common.close_list') : _vm.$t('common.list'))+" ")]),_c('i',{staticClass:"el-icon-delete text-sm cursor-pointer",on:{"click":_vm.handleUnselected}})]),_c('p',{staticClass:"component-text-desc mt-0 mb-3"},[_vm._v(" "+_vm._s(_vm.$t('page.campaign.desc_interests'))+" ")]),_c('treeselect',{attrs:{"multiple":"","options":_vm.interest_category_options,"placeholder":_vm.$t('input.placeholder.please_select'),"normalizer":_vm.normalizer_option},on:{"input":_vm.handleChangeSelected},model:{value:(_vm.interest_category_ids),callback:function ($$v) {_vm.interest_category_ids=$$v},expression:"interest_category_ids"}}),(_vm.show_list_save_target_table)?_c('el-table',{staticClass:"mt-2",attrs:{"max-height":"400","data":_vm.list_custom_targets}},[_c('el-table-column',{attrs:{"width":"260","label":_vm.$t('common.name')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"flex space-x-2"},[_c('div',[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":scope.row.custom_target_name,"placement":"top"}},[_c('p',{staticClass:"font-semibold truncate"},[_vm._v(" "+_vm._s(scope.row.custom_target_name)+" ")])])],1)])]}}],null,false,645516358)}),_c('el-table-column',{attrs:{"label":_vm.$t('table.header.action'),"min-width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('common.add_to_target'),"placement":"top"}},[_c('el-button',{attrs:{"size":"small","icon":"el-icon-plus"},on:{"click":function($event){return _vm.handleAddCustomTargetToList(
                                        scope.row.data_config
                                    )}}})],1),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('common.remove_to_target'),"placement":"top"}},[_c('el-button',{attrs:{"size":"small","icon":"el-icon-minus"},on:{"click":function($event){return _vm.handleRemoveCustomTargetToList(
                                        scope.row.data_config
                                    )}}})],1),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('common.replate_to_target'),"placement":"top"}},[_c('el-button',{attrs:{"size":"small","icon":"el-icon-aim"},on:{"click":function($event){return _vm.handleReplaceCustomTargetToList(
                                        scope.row.data_config
                                    )}}})],1)],1)]}}],null,false,174380422)}),_c('el-table-column',{attrs:{"label":_vm.$t('common.option'),"fixed":"right","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"danger","size":"small"},on:{"click":function($event){return _vm.handleDeleteCustomTarget(scope.row._id)}}},[_vm._v(" "+_vm._s(_vm.$t('button.delete'))+" ")])]}}],null,false,2431645675)})],1):_vm._e()],1),_c('el-dialog',{attrs:{"visible":_vm.is_show_create_target_dialog,"close-on-click-modal":true,"append-to-body":true,"width":"480px"},on:{"update:visible":function($event){_vm.is_show_create_target_dialog=$event}}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('p',{staticClass:"text-lg font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('button.save_custom_target'))+" ")])]),_c('div',[_c('p',{staticClass:"component-label"},[_vm._v(" "+_vm._s(_vm.$t('common.name'))+" ")]),_c('el-input',{attrs:{"type":"text","size":"small","placeholder":_vm.$t('input.placeholder.please_input')},model:{value:(_vm.custom_target_name),callback:function ($$v) {_vm.custom_target_name=$$v},expression:"custom_target_name"}})],1),_c('template',{slot:"footer"},[_c('div',{staticClass:"flex items-center justify-end mt-4"},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){_vm.is_show_create_target_dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('button.close'))+" ")]),_c('el-button',{attrs:{"disabled":!_vm.custom_target_name,"type":"primary","size":"small","icon":"el-icon-check"},on:{"click":_vm.handleCreateCustomTarget}},[_vm._v(" "+_vm._s(_vm.$t('button.confirm'))+" ")])],1)])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }