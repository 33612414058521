export default {
    bind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
            '.el-select-dropdown .el-select-dropdown__wrap'
        )
        if (SELECTWRAP_DOM) {
            SELECTWRAP_DOM.addEventListener('scroll', function () {
                const condition =
                    this.scrollHeight - this.scrollTop <= this.clientHeight
                if (condition) {
                    binding.value()
                }
            })
        }
    },

    unbind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
            '.el-select-dropdown .el-select-dropdown__wrap'
        )
        if (SELECTWRAP_DOM) {
            SELECTWRAP_DOM.removeEventListener('scroll', function () {
                const condition =
                    this.scrollHeight - this.scrollTop <= this.clientHeight
                if (condition) {
                    binding.value()
                }
            })
        }
    }
}
