import request from './_config'
import { p__basic_metrics } from '@/plugins/prototypes'

const path = `report`

export function exportExcel(data) {
    return request({
        url: `${path}/export/excel`,
        method: 'post',
        data,
        responseType: 'blob'
    })
}

export function getReportCampaigns(tiktok_account_id, params) {
    return request({
        url: `${path}/get/${tiktok_account_id}`,
        method: 'get',
        params
    })
}

export function getSyncReport(tiktok_account_id, data) {
    return request({
        url: `${path}/sync_report/${tiktok_account_id}`,
        method: 'post',
        data
    })
}

export function getReportFromTTA(tiktok_account_id, params) {
    return request({
        url: `${path}/get_from_tta/${tiktok_account_id}`,
        method: 'get',
        params
    })
}

export function getReportOverviewMulti(tiktok_account_id, data) {
    return request({
        url: `${path}/over_view_multi/${tiktok_account_id}`,
        method: 'post',
        data: {
            ...data
        }
    })
}

export function getReportOverview(tiktok_account_id, params) {
    return request({
        url: `${path}/over_view/${tiktok_account_id}`,
        method: 'get',
        params: {
            order_field: params.order_field,
            order_type: params.order_type,
            start_date: params.start_date,
            end_date: params.end_date,
            query_lifetime: params.query_lifetime,
            advertiser_id: params.advertiser_id,
            report_type: 'BASIC',
            filtering: JSON.stringify(params.filtering),
            metrics: JSON.stringify(p__basic_metrics)
        }
    })
}

export function getConfigAdsLives(tiktok_account_id, params) {
    return request({
        url: `${path}/config_watch_ads_live/${tiktok_account_id}`,
        method: 'get',
        params: {
            advertiser_id: params.advertiser_id,
            campaign_ids: params.campaign_ids
        }
    })
}

export function setConfigAdsLive(tiktok_account_id, data) {
    return request({
        url: `${path}/config_watch_ads_live/${tiktok_account_id}`,
        method: 'post',
        data: {
            advertiser_id: data.advertiser_id,
            campaign_ids: data.campaign_ids,
            start_time: data.start_time,
            end_time: data.end_time,
            config_list: data.config_list,
            status: data.status
        }
    })
}

export function deleteConfigAdsLive(tiktok_account_id, data) {
    return request({
        url: `${path}/delete_config_watch_ads_live/${tiktok_account_id}`,
        method: 'post',
        data: {
            advertiser_id: data.advertiser_id,
            campaign_ids: data.campaign_ids
        }
    })
}

export function exportReportingExcel(tiktok_account_id, params) {
    return request({
        url: `${path}/export_excel/${tiktok_account_id}`,
        method: 'get',
        params,
        responseType: 'blob'
    })
}
