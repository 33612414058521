import request from './_config'
const path = `user_role`

export function getUserRole() {
    return request({
        url: `${path}`,
        method: 'get'
    })
}

export function getUserRoleList() {
    return request({
        url: `${path}/user_list`,
        method: 'get'
    })
}

export function updateUserRole(tiktok_account_id, data) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'post',
        data
    })
}
