import { p__default_identity_image_uri } from './index'

export const p__default_dayparting_adgroup =
    '111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111'

export const p__default_state_infor_adgroup = {
    actions: [],
    adgroup_app_profile_page_state: null,
    adgroup_name: '',
    age_groups: null,
    app_download_url: null,
    app_id: '',
    audience_ids: [],
    bid_display_mode: 'CPMV',
    bid_price: 1,
    bid_type: 'BID_TYPE_NO_BID',
    billing_event: '',
    brand_safety_partner: null,
    brand_safety_type: 'NO_BRAND_SAFETY',
    budget: 0,
    budget_mode: 'BUDGET_MODE_DAY',
    carrier_ids: [],
    catalog_id: '',
    category_id: '0',
    comment_disabled: false,
    contextual_tag_ids: [],
    conversion_bid_price: 0,
    conversion_window: null,
    creative_material_mode: 'CUSTOM',
    dayparting: p__default_dayparting_adgroup,
    deep_bid_type: null,
    roas_bid: null,
    deep_cpa_bid: 0,
    delivery_mode: null,
    device_model_ids: [],
    device_price_ranges: [],
    excluded_audience_ids: [],
    excluded_custom_actions: [],
    feed_type: null,
    frequency: 3,
    frequency_schedule: 7,
    gender: 'GENDER_UNLIMITED',
    household_income: [],
    included_custom_actions: [],
    interest_category_ids: [],
    interest_keyword_ids: [],
    inventory_filter_enabled: false,
    ios14_quota_type: 'UNOCCUPIED',
    is_hfss: false,
    is_new_structure: true,
    isp_ids: [],
    identity_id: '',
    keywords: null,
    languages: [],
    location_ids: ['1562822'],
    min_android_version: '',
    network_types: [],
    next_day_retention: null,
    operating_systems: [],
    operation_status: 'ENABLE',
    optimization_event: '',
    optimization_goal: 'CLICK',
    promotion_website_type: '',
    pacing: 'PACING_MODE_SMOOTH',
    pixel_id: '',
    placement_type: 'PLACEMENT_TYPE_NORMAL',
    placements: ['PLACEMENT_TIKTOK'],
    promotion_type: 'WEBSITE',
    promotion_target_type: 'UNSET',
    purchased_impression: null,
    purchased_reach: null,
    product_source: '',
    rf_estimated_cpr: null,
    rf_estimated_frequency: null,
    rf_purchased_type: null,
    schedule_end_time: '',
    schedule_infos: null,
    schedule_start_time: '',
    schedule_type: 'SCHEDULE_START_END',
    scheduled_budget: 0,
    search_result_enabled: false,
    secondary_optimization_event: '',
    secondary_status: 'ADGROUP_STATUS_ALL',
    share_disabled: false,
    skip_learning_phase: false,
    spending_power: 'ALL',
    statistic_type: null,
    store_id: '',
    store_authorized_bc_id: '',
    shopping_ads_type: '',
    tracking_pixel_id: '',
    video_download_disabled: false,
    extension: {
        id: '',
        infor_ads: [],
        country_codes: [],
        event_is_external: false,
        selected_type_web: 'TIKTOK_INSTANT_PAGE',
        reset_budget: true,
        identity: {
            identity_id: '',
            identity_authorized_bc_id: '',
            identity_type: '',
            display_name: '',
            image_uri: p__default_identity_image_uri
        },
        config_list: [],
        location_names: ['Vietnam'],
        languages: [],
        interest_keyword_names: [],
        interest_names: [],
        video_interactions_names: [],
        creator_interaction_names: [],
        following_hashtag_names: []
    }
}
