<template>
    <div
        v-if="selected_item_in_line"
        id="input_adgroup_name_wrapper"
        class="component-box"
    >
        <p class="component-label">
            {{ $t('table.header.ad_group_name') }}
        </p>

        <div class="flex space-x-2">
            <el-tooltip
                class="item"
                effect="dark"
                :content="preview_name"
                placement="top"
            >
                <el-tag
                    v-if="selected_template_name"
                    effect="plain"
                    class="flex items-center"
                    closable
                    @close="selected_template_name = null"
                >
                    <p class="truncate max-w-[160px]">{{ preview_name }}</p>
                </el-tag>
            </el-tooltip>

            <el-input
                id="input_adgroup_name"
                v-model="adgroup_name"
                class="flex-1"
                size="small"
            ></el-input>

            <div v-if="type_form === 'create'">
                <el-button
                    v-if="template_name_list.length === 0"
                    icon="el-icon-postcard"
                    @click="show_create_dialog = true"
                    size="small"
                >
                    {{ $t('common.name_template') }}
                </el-button>

                <el-dropdown v-else trigger="click">
                    <el-button icon="el-icon-postcard" size="small">
                        {{ $t('common.name_template') }}
                    </el-button>
                    <el-dropdown-menu
                        slot="dropdown"
                        class="overflow-auto max-h-[280px]"
                    >
                        <el-dropdown-item
                            v-for="item in template_name_list"
                            :key="item._id"
                            class="relative"
                        >
                            <div
                                @click="handleSelected(item)"
                                class="py-2 pr-8"
                            >
                                <p class="leading-5 text-sm">
                                    {{ item.config_name }}
                                </p>
                                <p
                                    class="text-desc-text truncate max-w-[200px] leading-5 text-xs"
                                >
                                    {{ genDescriptionTemplateName(item) }}
                                </p>
                            </div>

                            <div
                                class="absolute top-1/2 -translate-y-1/2 right-2 flex space-x-1"
                            >
                                <i
                                    class="el-icon-edit-outline text-base"
                                    @click="handleEdit(item)"
                                ></i>
                                <i
                                    class="el-icon-delete text-base"
                                    @click="handleRemove(item._id)"
                                ></i>
                            </div>
                        </el-dropdown-item>
                        <el-dropdown-item divided
                            ><div @click="show_create_dialog = true">
                                <i class="el-icon-plus"></i>
                                {{ $t('button.create') }}
                            </div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>

        <p
            v-if="selected_template_name"
            class="text-xs text-desc-text mt-2"
        >
            {{ $t('common.preview') }} :
            {{ selected_item_in_line.adgroup_name }}
        </p>

        <CreateTemplateNameDialog
            :visible.sync="show_create_dialog"
            :field_options="field_options"
            :genPreviewName="genPreviewName"
            :getTemplateNames="getTemplateNames"
        ></CreateTemplateNameDialog>

        <EditTemplateNameDialog
            :visible.sync="show_edit_dialog"
            :field_options="field_options"
            :selected_item="selected_edit_item"
            :genPreviewName="genPreviewName"
            :getTemplateNames="getTemplateNames"
        ></EditTemplateNameDialog>
    </div>
</template>

<script>
import CreateTemplateNameDialog from './create-template-name-dialog'
import EditTemplateNameDialog from './edit-template-name-dialog'
import {
    getTemplateNames,
    deleteTemplateName
} from '@/services/atosa-tiktok-ads/tool'
import moment from 'moment'

export default {
    components: {
        CreateTemplateNameDialog,
        EditTemplateNameDialog
    },

    props: ['selected_item', 'type_form'],

    data() {
        return {
            selected_edit_item: null,
            adgroup_name: '',
            show_create_dialog: false,
            show_edit_dialog: false,
            selected_template_name: null,
            template_name_list: [],
            field_options: [
                {
                    label: this.$t('common.location'),
                    key: 'location_ids'
                },
                {
                    label: this.$t('common.language'),
                    key: 'languages'
                },
                {
                    label: this.$t('common.gender'),
                    key: 'gender'
                },
                {
                    label: this.$t('common.operating_system'),
                    key: 'operating_systems'
                },
                {
                    label: this.$t('page.campaign.optimization_goal'),
                    key: 'optimization_goal'
                },
                // {
                //     label: this.$t('page.campaign.bid_strategy'),
                //     key: 'bid_strategy'
                // },
                {
                    label: this.$t('common.start_time'),
                    key: 'schedule_start_time'
                },
                {
                    label: this.$t('common.end_time'),
                    key: 'schedule_end_time'
                },
                {
                    label: this.$t('common.age'),
                    key: 'age_groups'
                },
                {
                    label: this.$t('common.time'),
                    key: 'time'
                },
                {
                    label: this.$t('common.text'),
                    key: 'text'
                }
            ]
        }
    },

    computed: {
        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        },

        preview_name() {
            if (this.selected_template_name) {
                return this.genPreviewName(
                    this.selected_template_name.fields,
                    this.selected_template_name.field_separator
                )
            }

            return ''
        }
    },

    watch: {
        preview_name() {
            this.selected_item_in_line.adgroup_name =
                this.preview_name + ' ' + this.adgroup_name
        },

        adgroup_name() {
            if (this.selected_template_name) {
                this.selected_item_in_line.adgroup_name =
                    this.preview_name + ' ' + this.adgroup_name
            } else {
                this.selected_item_in_line.adgroup_name = this.adgroup_name
            }
        },

        selected_item() {
            this.selected_template_name = null

            this.adgroup_name = this.selected_item_in_line.adgroup_name
        }
    },

    mounted() {
        this.adgroup_name = this.selected_item_in_line.adgroup_name

        this.getTemplateNames()
    },

    methods: {
        handleEdit(item) {
            this.selected_edit_item = item

            this.show_edit_dialog = true
        },

        async handleRemove(id) {
            try {
                await this.$confirm(
                    this.$t('common.delete_data_confirm'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                )
            } catch (e) {
                return
            }

            try {
                const response = await deleteTemplateName(id)
                if (response.code === 0) {
                    this.getTemplateNames()
                }
            } catch (error) {
                console.error(error)
            }
        },

        async getTemplateNames() {
            try {
                const response = await getTemplateNames()
                if (response.code === 0) {
                    this.template_name_list = response.data.filter(
                        (item) => item.type === 'adgroup'
                    )
                }
            } catch (error) {
                console.error(error)
            }
        },

        genDescriptionTemplateName(item) {
            const temp = item.fields.map((item) => {
                const temp = this.field_options.find(
                    (it) => it.key === item.key
                )

                if (temp) {
                    return temp.label
                }

                return item.key
            })
            return temp.join(` ${item.field_separator} `)
        },

        handleSelected(item) {
            this.selected_template_name = item
        },

        genPreviewName(fields, field_separator) {
            const temp = fields.map((item) => {
                const { key, value } = item
                if (key === 'age_groups') {
                    if (
                        this.selected_item.age_groups.length === 1 &&
                        this.selected_item.age_groups[0] === 'ALL'
                    ) {
                        return this.$t('common.all')
                    }
                    return this.selected_item.age_groups
                        .map((item) => {
                            const temp = this.p__age_options.find(
                                (it) => it.value === item
                            )

                            if (temp) {
                                return temp.label
                            }

                            return item
                        })
                        .join('&')
                }

                if (key === 'gender') {
                    const temp = this.p__gender_options.find(
                        (it) => it.value === this.selected_item.gender
                    )

                    if (temp) {
                        return temp.label
                    }

                    return this.selected_item.gender
                }

                if (key === 'schedule_start_time') {
                    return this.selected_item.schedule_start_time
                }

                if (key === 'schedule_end_time') {
                    return this.selected_item.schedule_end_time
                }

                if (key === 'operating_systems') {
                    if (this.selected_item.operating_systems.length === 0) {
                        return this.$t('common.all')
                    }
                    return this.selected_item.operating_systems[0]
                }

                if (key === 'languages') {
                    if (this.selected_item.extension.languages.length === 0) {
                        return this.$t('common.all')
                    }

                    return this.selected_item.extension.languages.join(' & ')
                }

                if (key === 'location_ids') {
                    return this.selected_item.extension.location_names.join('&')
                }

                if (key === 'text') {
                    return value
                }

                if (key === 'optimization_goal') {
                    return this.p__formatLabelOptimizationGoal(
                        this.selected_item
                    )
                }

                if (key === 'time') {
                    const date = moment().format(value)
                    return date
                }

                return item.key
            })

            return temp.join(`${field_separator}`)
        }
    }
}
</script>
