<template>
    <div>
        <div class="flex items-center">
            <p class="component-label">
                {{ $t('page.campaign.ad_delivery_schedule') }}
            </p>
            <el-button class="ml-auto" type="text" @click="handleSelectAll">
                <p class="text-xs">
                    {{ $t('page.campaign.distributed_all_day') }}
                </p></el-button
            >
        </div>
        <table>
            <thead>
                <tr>
                    <th rowspan="8" class="border-border border-[1px]">
                        <div class="w-[68px] util-flex-center">
                            <p class="text-xs">
                                {{ $t('common.day') }}/{{ $t('common.hour') }}
                            </p>
                        </div>
                    </th>
                    <th colspan="24" class="border-border border-[1px] h-8">
                        <span class="text-xs">00:00 - 12:00</span>
                    </th>
                    <th colspan="24" class="border-border border-[1px] h-8">
                        <span class="text-xs">12:00 - 24:00</span>
                    </th>
                </tr>
                <tr>
                    <td
                        v-for="item in number_list"
                        :key="item"
                        colspan="2"
                        class="border-border border-[1px]"
                    >
                        <div class="util-flex-center">
                            <span class="text-xs">{{ item }}</span>
                        </div>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(row, rowIndex) in table_data"
                    :key="rowIndex"
                    class="select-none"
                >
                    <td class="border-border border-[1px]">
                        <div class="util-flex-center">
                            <span class="text-xs">{{
                                getDayByIndex(rowIndex)
                            }}</span>
                        </div>
                    </td>
                    <td
                        v-for="(cell, colIndex) in row"
                        :key="colIndex"
                        class="h-5 w-3 border-border border-[1px]"
                        :class="{
                            'bg-[#2baf2b]': !cell.selected && cell.value === 1,
                            'bg-[#1a8e1a]': cell.selected
                        }"
                        @mouseup="endSelection(rowIndex, colIndex)"
                        @mouseover="handleCellHover(rowIndex, colIndex)"
                        @mousedown="startSelection(rowIndex, colIndex)"
                        @click="toggleCell(rowIndex, colIndex)"
                    ></td>
                </tr>

                <tr class="border-border border-[1px]">
                    <td colspan="48">
                        <div class="p-3">
                            <p
                                v-if="
                                    dayparting ===
                                        p__default_dayparting_adgroup ||
                                    dayparting ===
                                        all_not_set_dayparting_adgroup
                                "
                                class="text-xs text-desc-text"
                            >
                                {{
                                    $t(
                                        'page.campaign.ads_will_be_delivered_all_day_long'
                                    )
                                }}
                            </p>
                            <div v-else>
                                <p class="text-xs text-desc-text mb-2">
                                    {{
                                        $t(
                                            'page.campaign.ad_delivery_time_frame'
                                        )
                                    }}
                                </p>

                                <div
                                    v-for="item in description_list"
                                    :key="item.index"
                                    class="flex flex-wrap gap-1 items-center text-xs mb-1"
                                >
                                    <p class="w-[60px] text-desc-text">
                                        {{ getDayByIndex(item.index) }}
                                    </p>

                                    <p
                                        v-for="(str, index) in item.list"
                                        :key="index"
                                        class="leading-3 mr-2"
                                    >
                                        {{ str }}
                                        <span
                                            v-if="index < item.list.length - 1"
                                            >,</span
                                        >
                                    </p>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: ['dayparting'],

    data() {
        return {
            number_list: [...Array(24).keys()],
            table_data: Array.from({ length: 7 }, () =>
                Array(48).fill({
                    value: 1,
                    selected: false
                })
            ),
            all_not_set_dayparting_adgroup: '0'.repeat(48 * 7),
            is_mouse_down: false,
            start_row: 0,
            start_col: 0,
            description_list: []
        }
    },

    computed: {
        dayparting_in_line: {
            get: function () {
                return this.dayparting
            },
            set: function (val) {
                this.$emit('update:dayparting', val)
            }
        }
    },

    watch: {
        dayparting() {
            if (this.dayparting === this.p__default_dayparting_adgroup) {
                this.initData()
            }
        }
    },

    mounted() {
        window.addEventListener('mouseup', this.endSelection)

        this.initData()
    },

    beforeDestroy() {
        window.removeEventListener('mouseup', this.endSelection)
    },

    methods: {
        getDayByIndex(index) {
            if (index === 0) {
                return this.$t('common.day_monday')
            }
            if (index === 1) {
                return this.$t('common.day_tuesday')
            }
            if (index === 2) {
                return this.$t('common.day_wednesday')
            }
            if (index === 3) {
                return this.$t('common.day_thursday')
            }
            if (index === 4) {
                return this.$t('common.day_friday')
            }
            if (index === 5) {
                return this.$t('common.day_saturday')
            }
            if (index === 6) {
                return this.$t('common.day_sunday')
            }
        },

        initData() {
            const table_data = []

            for (let index = 0; index < 7; index++) {
                const str = this.dayparting.slice(index * 48, (index + 1) * 48)

                table_data.push(
                    str.split('').map((item) => ({
                        value: parseInt(item),
                        selected: false
                    }))
                )
            }

            this.table_data = table_data

            const description_list = []
            for (let index = 0; index < 7; index++) {
                const str = this.dayparting.slice(index * 48, (index + 1) * 48)

                const temp = this.convertBinaryStringToTimeRanges(str)

                if (temp.length) {
                    description_list.push({
                        index,
                        list: temp
                    })
                }
            }

            this.description_list = description_list
        },

        setDayparting() {
            let dayparting = ''

            this.table_data.forEach((list) => {
                list.forEach((cell) => {
                    if (cell.value === 1) {
                        dayparting += '1'
                    } else {
                        dayparting += '0'
                    }
                })
            })
            this.dayparting_in_line = dayparting

            if (
                dayparting === this.p__default_dayparting_adgroup ||
                dayparting === this.all_not_set_dayparting_adgroup
            ) {
                return
            }

            const description_list = []
            for (let index = 0; index < 7; index++) {
                const str = dayparting.slice(index * 48, (index + 1) * 48)

                const temp = this.convertBinaryStringToTimeRanges(str)

                if (temp.length) {
                    description_list.push({
                        index,
                        list: temp
                    })
                }
            }

            this.description_list = description_list
        },

        handleSelectAll() {
            this.table_data = Array.from({ length: 7 }, () =>
                Array(48).fill({
                    value: 1,
                    selected: false
                })
            )

            this.setDayparting()
        },

        handleCellHover(end_row, end_col) {
            if (!this.is_mouse_down) {
                return
            }

            for (
                let index_row = 0;
                index_row < this.table_data.length;
                index_row++
            ) {
                for (
                    let index_col = 0;
                    index_col < this.table_data[index_row].length;
                    index_col++
                ) {
                    this.$set(this.table_data[index_row], index_col, {
                        value: this.table_data[index_row][index_col].value,
                        selected: false
                    })
                }
            }

            const min_row = this.start_row < end_row ? this.start_row : end_row
            const min_col = this.start_col < end_col ? this.start_col : end_col

            const max_row = this.start_row > end_row ? this.start_row : end_row
            const max_col = this.start_col > end_col ? this.start_col : end_col

            for (let index_row = min_row; index_row <= max_row; index_row++) {
                for (
                    let index_col = min_col;
                    index_col <= max_col;
                    index_col++
                ) {
                    this.$set(this.table_data[index_row], index_col, {
                        value: this.table_data[index_row][index_col].value,
                        selected: true
                    })
                }
            }
        },

        startSelection(rowIndex, colIndex) {
            this.is_mouse_down = true

            this.start_row = rowIndex
            this.start_col = colIndex
        },

        endSelection(end_row, end_col) {
            if (!this.is_mouse_down) {
                return
            }

            for (
                let index_row = 0;
                index_row < this.table_data.length;
                index_row++
            ) {
                for (
                    let index_col = 0;
                    index_col < this.table_data[index_row].length;
                    index_col++
                ) {
                    this.$set(this.table_data[index_row], index_col, {
                        value: this.table_data[index_row][index_col].value,
                        selected: false
                    })
                }
            }

            this.is_mouse_down = false

            const start_cell = this.table_data[this.start_row][this.start_col]

            const min_row = this.start_row < end_row ? this.start_row : end_row
            const min_col = this.start_col < end_col ? this.start_col : end_col

            const max_row = this.start_row > end_row ? this.start_row : end_row
            const max_col = this.start_col > end_col ? this.start_col : end_col

            for (let index_row = min_row; index_row <= max_row; index_row++) {
                for (
                    let index_col = min_col;
                    index_col <= max_col;
                    index_col++
                ) {
                    this.$set(this.table_data[index_row], index_col, {
                        value: start_cell.value === 1 ? 0 : 1,
                        selected: false
                    })
                }
            }

            this.setDayparting()
        },

        toggleCell(rowIndex, colIndex) {
            this.is_mouse_down = false
            const cellValue = this.table_data[rowIndex][colIndex]

            this.$set(this.table_data[rowIndex], colIndex, {
                value: cellValue.value === 1 ? 1 : 0,
                selected: false
            })

            this.setDayparting()
        },

        convertBinaryStringToTimeRanges(binaryString) {
            if (
                binaryString ===
                '000000000000000000000000000000000000000000000000'
            ) {
                return []
            }

            if (
                binaryString ===
                '1111111111111111111111111111111111111111111111111111111111111111111111111111111111'
            ) {
                return ['00:00 - 24:00']
            }

            const timeRanges = []
            let start = null

            for (let i = 0; i < binaryString.length; i++) {
                if (binaryString[i] === '1' && start === null) {
                    start = i * 30
                } else if (binaryString[i] === '0' && start !== null) {
                    const end = i * 30
                    timeRanges.push(this.formatTimeRange(start, end))
                    start = null
                }
            }

            if (start !== null) {
                const end = binaryString.length * 30
                timeRanges.push(this.formatTimeRange(start, end))
            }

            return timeRanges
        },

        formatTimeRange(startMinutes, endMinutes) {
            const formatTime = (minutes) => {
                const hours = Math.floor(minutes / 60)
                const mins = minutes % 60
                return `${hours.toString().padStart(2, '0')}:${mins
                    .toString()
                    .padStart(2, '0')}`
            }

            return `${formatTime(startMinutes)} - ${formatTime(endMinutes)}`
        }
    }
}
</script>

<style>
table {
    border-collapse: collapse;
}
</style>
