import Vue from 'vue'

// atoms
import LearnMoreAnchor from './atoms/learn-more-anchor'

// molecules
import CustomDrawer from './molecules/custom-drawer'
import LabelOutsideInputSelect from './molecules/label-outside-input-select'
import CustomCommasNumber from './molecules/custom-commas-number'

// orgranisms
import InfoVideoDialog from './orgranisms/info-video-dialog'
import ProcessDialog from './orgranisms/process-dialog'
import EditAdForm from './orgranisms/edit-ad-form'
import EditAdGroupForm from './orgranisms/edit-adgroup-form'
import AvailableAudienceAdgroup from './orgranisms/available-audience-adgroup'
import PreviewAdgroup from './orgranisms/preview-adgroup'
import CustomTargetAdgroupBox from './orgranisms/custom-target-adgroup-box'
import NoData from './orgranisms/no-data'

Vue.component('custom-drawer', CustomDrawer)
Vue.component('learn-more-anchor', LearnMoreAnchor)
Vue.component('label-outside-input-select', LabelOutsideInputSelect)
Vue.component('ProcessDialog', ProcessDialog)
Vue.component('EditAdForm', EditAdForm)
Vue.component('EditAdGroupForm', EditAdGroupForm)
Vue.component('CustomTargetAdgroupBox', CustomTargetAdgroupBox)
Vue.component('AvailableAudienceAdgroup', AvailableAudienceAdgroup)
Vue.component('PreviewAdgroup', PreviewAdgroup)
Vue.component('InfoVideoDialog', InfoVideoDialog)
Vue.component('NoData', NoData)
Vue.component('CustomCommasNumber', CustomCommasNumber)
