import request from './_config'

export function getBusinessCenters(tiktok_account_id) {
    return request({
        url: `bc/${tiktok_account_id}`,
        method: 'get'
    })
}

export function getBusinessCenters2(tiktok_account_id, params) {
    return request({
        url: `bc/tiktok/${tiktok_account_id}`,
        method: 'get',
        params
    })
}

export function getAtosaBusinessCenters() {
    return request({
        url: `bc/system_bc/get`,
        method: 'get'
    })
}
