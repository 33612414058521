import moment from 'moment'

export function f__pluralize(time, label) {
    if (time === 1) {
        return time + label
    }
    return time + label + 's'
}

export function f__format_currency_vnd(money) {
    const temp = +money
    if (temp <= 0) {
        return '0 đ'
    }
    return temp
        .toLocaleString('it-IT', {
            style: 'currency',
            currency: 'VND'
        })
        .replace('VND', 'đ')
}

export function f__format_moment(value, format = 'DD-MM-YYYY') {
    if (!value) return ''

    return moment(new Date(value)).format(format)
}

export function f__diff_moment(value, unit = 'day') {
    if (!value) return ''

    const diff = moment(new Date(value)).diff(moment(), unit)
    return diff > -1 ? diff : 0
}
