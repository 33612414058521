export const p__tiktok_timezones = [
    {
        code: 'Asia/Ho_Chi_Minh',
        time: 'UTC+07:00',
        offset: 7
    },
    {
        code: 'Asia/Amman',
        time: 'UTC+02:00',
        offset: 2
    },
    {
        code: 'Asia/Baghdad',
        time: 'UTC+03:00',
        offset: 3
    },
    {
        code: 'Asia/Bahrain',
        time: 'UTC+03:00',
        offset: 3
    },
    {
        code: 'Asia/Bangkok',
        time: 'UTC+07:00',
        offset: 7
    },
    {
        code: 'Asia/Beirut',
        time: 'UTC+02:00',
        offset: 2
    },
    {
        code: 'Asia/Colombo',
        time: 'UTC+05:30',
        offset: 5.5
    },
    {
        code: 'Asia/Dhaka',
        time: 'UTC+06:00',
        offset: 6
    },
    {
        code: 'Asia/Dubai',
        time: 'UTC+04:00',
        offset: 4
    },
    {
        code: 'Asia/Gaza',
        time: 'UTC+02:00',
        offset: 2
    },
    {
        code: 'Asia/Hong_Kong',
        time: 'UTC+08:00',
        offset: 8
    },
    {
        code: 'Asia/Irkutsk',
        time: 'UTC+08:00',
        offset: 8
    },
    {
        code: 'Asia/Jakarta',
        time: 'UTC+07:00',
        offset: 7
    },
    {
        code: 'Asia/Jayapura',
        time: 'UTC+09:00',
        offset: 9
    },
    {
        code: 'Asia/Jerusalem',
        time: 'UTC+02:00',
        offset: 2
    },
    {
        code: 'Asia/Kamchatka',
        time: 'UTC+11:00',
        offset: 11
    },
    {
        code: 'Asia/Karachi',
        time: 'UTC+05:00',
        offset: 5
    },
    {
        code: 'Asia/Kolkata',
        time: 'UTC+05:30',
        offset: 5.5
    },
    {
        code: 'Asia/Krasnoyarsk',
        time: 'UTC+07:00',
        offset: 7
    },
    {
        code: 'Asia/Kuala_Lumpur',
        time: 'UTC+08:00',
        offset: 8
    },
    {
        code: 'Asia/Kuwait',
        time: 'UTC+03:00',
        offset: 3
    },
    {
        code: 'Asia/Magadan',
        time: 'UTC+11:00',
        offset: 11
    },
    {
        code: 'Asia/Makassar',
        time: 'UTC+08:00',
        offset: 8
    },
    {
        code: 'Asia/Manila',
        time: 'UTC+08:00',
        offset: 8
    },
    {
        code: 'Asia/Muscat',
        time: 'UTC+04:00',
        offset: 4
    },
    {
        code: 'Asia/Nicosia',
        time: 'UTC+02:00',
        offset: 2
    },
    {
        code: 'Asia/Omsk',
        time: 'UTC+06:00',
        offset: 6
    },
    {
        code: 'Asia/Qatar',
        time: 'UTC+03:00',
        offset: 3
    },
    {
        code: 'Asia/Riyadh',
        time: 'UTC+03:00',
        offset: 3
    },
    {
        code: 'Asia/Seoul',
        time: 'UTC+09:00',
        offset: 9
    },
    {
        code: 'Asia/Shanghai',
        time: 'UTC+08:00',
        offset: 8
    },
    {
        code: 'Asia/Singapore',
        time: 'UTC+08:00',
        offset: 8
    },
    {
        code: 'Asia/Taipei',
        time: 'UTC+08:00',
        offset: 8
    },
    {
        code: 'Asia/Tokyo',
        time: 'UTC+09:00',
        offset: 9
    },
    {
        code: 'Asia/Vladivostok',
        time: 'UTC+10:00',
        offset: 10
    },
    {
        code: 'Asia/Yakutsk',
        time: 'UTC+09:00',
        offset: 9
    },
    {
        code: 'Asia/Yekaterinburg',
        time: 'UTC+05:00',
        offset: 5
    },
    {
        code: 'Africa/Accra',
        time: 'UTC+00:00',
        offset: 0
    },
    {
        code: 'Africa/Cairo',
        time: 'UTC+02:00',
        offset: 2
    },
    {
        code: 'Africa/Casablanca',
        time: 'UTC+00:00',
        offset: 0
    },
    {
        code: 'Africa/Johannesburg',
        time: 'UTC+02:00',
        offset: 2
    },
    {
        code: 'Africa/Lagos',
        time: 'UTC+01:00',
        offset: 1
    },
    {
        code: 'Africa/Nairobi',
        time: 'UTC+03:00',
        offset: 3
    },
    {
        code: 'Africa/Tunis',
        time: 'UTC+01:00',
        offset: 1
    },
    {
        code: 'America/Anchorage',
        time: 'UTC-09:00',
        offset: -9
    },
    {
        code: 'America/Argentina/Buenos_Aires',
        time: 'UTC-03:00',
        offset: -3
    },
    {
        code: 'America/Argentina/Salta',
        time: 'UTC-03:00',
        offset: -3
    },
    {
        code: 'America/Argentina/San_Luis',
        time: 'UTC-03:00',
        offset: -3
    },
    {
        code: 'America/Asuncion',
        time: 'UTC-04:00',
        offset: -4
    },
    {
        code: 'America/Atikokan',
        time: 'UTC-05:00',
        offset: -5
    },
    {
        code: 'America/Belem',
        time: 'UTC-03:00',
        offset: -3
    },
    {
        code: 'America/Blanc-Sablon',
        time: 'UTC-04:00',
        offset: -4
    },
    {
        code: 'America/Bogota',
        time: 'UTC-05:00',
        offset: -5
    },
    {
        code: 'America/Campo_Grande',
        time: 'UTC-04:00',
        offset: -4
    },
    {
        code: 'America/Caracas',
        time: 'UTC-04:30',
        offset: -4.5
    },
    {
        code: 'America/Chicago',
        time: 'UTC-06:00',
        offset: -6
    },
    {
        code: 'America/Costa_Rica',
        time: 'UTC-06:00',
        offset: -6
    },
    {
        code: 'America/Dawson',
        time: 'UTC-08:00',
        offset: -8
    },
    {
        code: 'America/Dawson_Creek',
        time: 'UTC-07:00',
        offset: -7
    },
    {
        code: 'America/Denver',
        time: 'UTC-07:00',
        offset: -7
    },
    {
        code: 'America/Edmonton',
        time: 'UTC-07:00',
        offset: -7
    },
    {
        code: 'America/El_Salvador',
        time: 'UTC-06:00',
        offset: -6
    },
    {
        code: 'America/Guatemala',
        time: 'UTC-06:00',
        offset: -6
    },
    {
        code: 'America/Guayaquil',
        time: 'UTC-05:00',
        offset: -5
    },
    {
        code: 'America/Halifax',
        time: 'UTC-04:00',
        offset: -4
    },
    {
        code: 'America/Hermosillo',
        time: 'UTC-07:00',
        offset: -7
    },
    {
        code: 'America/Iqaluit',
        time: 'UTC-05:00',
        offset: -5
    },
    {
        code: 'America/Jamaica',
        time: 'UTC-05:00',
        offset: -5
    },
    {
        code: 'America/La_Paz',
        time: 'UTC-04:00',
        offset: -4
    },
    {
        code: 'America/Lima',
        time: 'UTC-05:00',
        offset: -5
    },
    {
        code: 'America/Los_Angeles',
        time: 'UTC-08:00',
        offset: -8
    },
    {
        code: 'America/Managua',
        time: 'UTC-06:00',
        offset: -6
    },
    {
        code: 'America/Mazatlan',
        time: 'UTC-07:00',
        offset: -7
    },
    {
        code: 'America/Mexico_City',
        time: 'UTC-06:00',
        offset: -6
    },
    {
        code: 'America/Montevideo',
        time: 'UTC-03:00',
        offset: -3
    },
    {
        code: 'America/Nassau',
        time: 'UTC-05:00',
        offset: -5
    },
    {
        code: 'America/New_York',
        time: 'UTC-05:00',
        offset: -5
    },
    {
        code: 'America/Noronha',
        time: 'UTC-02:00',
        offset: -2
    },
    {
        code: 'America/Panama',
        time: 'UTC-05:00',
        offset: -5
    },
    {
        code: 'America/Phoenix',
        time: 'UTC-07:00',
        offset: -7
    },
    {
        code: 'America/Port_of_Spain',
        time: 'UTC-04:00',
        offset: -4
    },
    {
        code: 'America/Puerto_Rico',
        time: 'UTC-04:00',
        offset: -4
    },
    {
        code: 'America/Rainy_River',
        time: 'UTC-06:00',
        offset: -6
    },
    {
        code: 'America/Regina',
        time: 'UTC-06:00',
        offset: -6
    },
    {
        code: 'America/Santiago',
        time: 'UTC-04:00',
        offset: -4
    },
    {
        code: 'America/Santo_Domingo',
        time: 'UTC-04:00',
        offset: -4
    },
    {
        code: 'America/Sao_Paulo',
        time: 'UTC-03:00',
        offset: -3
    },
    {
        code: 'America/St_Johns',
        time: 'UTC-03:30',
        offset: -3.5
    },
    {
        code: 'America/Tegucigalpa',
        time: 'UTC-06:00',
        offset: -6
    },
    {
        code: 'America/Tijuana',
        time: 'UTC-08:00',
        offset: -8
    },
    {
        code: 'America/Toronto',
        time: 'UTC-05:00',
        offset: -5
    },
    {
        code: 'America/Vancouver',
        time: 'UTC-08:00',
        offset: -8
    },
    {
        code: 'Atlantic/Azores',
        time: 'UTC-01:00',
        offset: -1
    },
    {
        code: 'Atlantic/Canary',
        time: 'UTC+00:00',
        offset: 0
    },
    {
        code: 'Atlantic/Reykjavik',
        time: 'UTC+00:00',
        offset: 0
    },
    {
        code: 'Australia/Broken_Hill',
        time: 'UTC+09:30',
        offset: 9.5
    },
    {
        code: 'Australia/Perth',
        time: 'UTC+08:00',
        offset: 8
    },
    {
        code: 'Australia/Sydney',
        time: 'UTC+10:00',
        offset: 10
    },
    {
        code: 'Europe/Amsterdam',
        time: 'UTC+01:00',
        offset: 1
    },
    {
        code: 'Europe/Athens',
        time: 'UTC+02:00',
        offset: 2
    },
    {
        code: 'Europe/Belgrade',
        time: 'UTC+01:00',
        offset: 1
    },
    {
        code: 'Europe/Berlin',
        time: 'UTC+01:00',
        offset: 1
    },
    {
        code: 'Europe/Bratislava',
        time: 'UTC+01:00',
        offset: 1
    },
    {
        code: 'Europe/Brussels',
        time: 'UTC+01:00',
        offset: 1
    },
    {
        code: 'Europe/Bucharest',
        time: 'UTC+02:00',
        offset: 2
    },
    {
        code: 'Europe/Budapest',
        time: 'UTC+01:00',
        offset: 1
    },
    {
        code: 'Europe/Copenhagen',
        time: 'UTC+01:00',
        offset: 1
    },
    {
        code: 'Europe/Dublin',
        time: 'UTC+00:00',
        offset: 0
    },
    {
        code: 'Europe/Helsinki',
        time: 'UTC+02:00',
        offset: 2
    },
    {
        code: 'Europe/Istanbul',
        time: 'UTC+02:00',
        offset: 2
    },
    {
        code: 'Europe/Kaliningrad',
        time: 'UTC+02:00',
        offset: 2
    },
    {
        code: 'Europe/Kiev',
        time: 'UTC+02:00',
        offset: 2
    },
    {
        code: 'Europe/Lisbon',
        time: 'UTC+00:00',
        offset: 0
    },
    {
        code: 'Europe/Ljubljana',
        time: 'UTC+01:00',
        offset: 1
    },
    {
        code: 'Europe/London',
        time: 'UTC+00:00',
        offset: 0
    },
    {
        code: 'Europe/Luxembourg',
        time: 'UTC+01:00',
        offset: 1
    },
    {
        code: 'Europe/Madrid',
        time: 'UTC+01:00',
        offset: 1
    },
    {
        code: 'Europe/Malta',
        time: 'UTC+01:00',
        offset: 1
    },
    {
        code: 'Europe/Moscow',
        time: 'UTC+03:00',
        offset: 3
    },
    {
        code: 'Europe/Oslo',
        time: 'UTC+01:00',
        offset: 1
    },
    {
        code: 'Europe/Paris',
        time: 'UTC+01:00',
        offset: 1
    },
    {
        code: 'Europe/Prague',
        time: 'UTC+01:00',
        offset: 1
    },
    {
        code: 'Europe/Riga',
        time: 'UTC+02:00',
        offset: 2
    },
    {
        code: 'Europe/Rome',
        time: 'UTC+01:00',
        offset: 1
    },
    {
        code: 'Europe/Samara',
        time: 'UTC+03:00',
        offset: 3
    },
    {
        code: 'Europe/Sarajevo',
        time: 'UTC+01:00',
        offset: 1
    },
    {
        code: 'Europe/Skopje',
        time: 'UTC+01:00',
        offset: 1
    },
    {
        code: 'Europe/Sofia',
        time: 'UTC+02:00',
        offset: 2
    },
    {
        code: 'Europe/Stockholm',
        time: 'UTC+01:00',
        offset: 1
    },
    {
        code: 'Europe/Tallinn',
        time: 'UTC+02:00',
        offset: 2
    },
    {
        code: 'Europe/Vienna',
        time: 'UTC+01:00',
        offset: 1
    },
    {
        code: 'Europe/Vilnius',
        time: 'UTC+02:00',
        offset: 2
    },
    {
        code: 'Europe/Warsaw',
        time: 'UTC+01:00',
        offset: 1
    },
    {
        code: 'Europe/Zagreb',
        time: 'UTC+01:00',
        offset: 1
    },
    {
        code: 'Europe/Zurich',
        time: 'UTC+01:00',
        offset: 1
    },
    {
        code: 'Indian/Maldives',
        time: 'UTC+05:00',
        offset: 5
    },
    {
        code: 'Indian/Mauritius',
        time: 'UTC+04:00',
        offset: 4
    },
    {
        code: 'Pacific/Apia',
        time: 'UTC-11:00',
        offset: -11
    },
    {
        code: 'Pacific/Auckland',
        time: 'UTC+12:00',
        offset: 12
    },
    {
        code: 'Pacific/Easter',
        time: 'UTC-06:00',
        offset: -6
    },
    {
        code: 'Pacific/Galapagos',
        time: 'UTC-06:00',
        offset: -6
    },
    {
        code: 'Pacific/Honolulu',
        time: 'UTC-10:00',
        offset: -10
    },
    {
        code: 'Pacific/Majuro',
        time: 'UTC-12:00',
        offset: -12
    },
    {
        code: 'Pacific/Midway',
        time: 'UTC-11:00',
        offset: -11
    },
    {
        code: 'Pacific/Niue',
        time: 'UTC-11:00',
        offset: -11
    },
    {
        code: 'Pacific/Pago_Pago',
        time: 'UTC-11:00',
        offset: -11
    }
]

export const p__findOffsetTimezone = (code) => {
    const item = p__tiktok_timezones.find((it) => it.code === code)

    if (item) {
        return item.offset
    }

    return 7
}
