export const p__route = {
    bc: 'business-center',
    shop: 'shop',
    tiktok_shop: 'tiktok-shop',
    tiktok_business: 'tiktok-business',
    audience: 'audience',
    campaign: 'campaign',
    create_campaign: 'campaign/create',
    catalog: 'catalog',
    ads_account: 'advertiser-account',
    payment: 'payment',
    payment_webview: '/payment/webview',
    package: 'package',
    affilate: 'affilate',
    package_pricing: 'package/pricing',
    tiktok_identity: 'tiktok-identity',
    autobot: 'autobot',
    affiliate: 'affiliate',
    permission: 'permission',
    statistical: 'statistical',
    credit_card: 'credit-card',
    admin_payment: 'admin/payment'
}

export const p__group_route = {
    tool: '/tool',
    ads_manager: '/ads-manager',
    asset: '/asset',
    general: '/general'
}
