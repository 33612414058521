<!-- eslint-disable vue/attribute-hyphenation -->
<template>
    <el-drawer
        direction="rtl"
        :size="size"
        :visible.sync="visible_in_line"
        :with-header="false"
        :append-to-body="true"
        :close-on-press-escape="close_on_press_escape"
        :wrapperClosable="wrapper_closable"
        :destroy-on-close="true"
    >
        <section class="bg-bg flex flex-col w-full h-full">
            <div
                class="h-[64px] min-h-[64px] flex w-full bg-white border-b-[1px] border-border"
            >
                <div class="w-[68px] h-full util-flex-center">
                    <div
                        class="bg-red util-flex-center opacity-70 hover:opacity-100 w-8 h-8 rounded-lg cursor-pointer"
                        @click="handleCloseDrawer"
                    >
                        <i class="el-icon-close !text-white"></i>
                    </div>
                </div>
                <div class="flex-1">
                    <slot name="title" />
                </div>
            </div>
            <div class="h-[calc(100vh-64px)] overflow-auto">
                <slot></slot>
            </div>
        </section>
    </el-drawer>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean
        },

        confirm_to_close: {
            type: Boolean,
            default: true
        },

        destroy_on_close: {
            type: Boolean,
            default: true
        },

        close_on_press_escape: {
            type: Boolean,
            default: false
        },

        size: {
            type: [String, Number]
        },

        wrapper_closable: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        }
    },

    methods: {
        handleCloseDrawer() {
            if (!this.confirm_to_close) {
                this.visible_in_line = false
                return
            }

            this.$confirm(
                this.$t('message.close_wont_be_save'),
                this.$t('common.warning'),
                {
                    confirmButtonText: this.$t('button.confirm'),
                    cancelButtonText: this.$t('button.close'),
                    type: 'warning'
                }
            ).then(() => {
                this.visible_in_line = false
            })
        }
    }
}
</script>
