import Vue from 'vue'
import moment from 'moment'
import i18n from '@/plugins/language'
import { Notification, MessageBox, Message } from 'element-ui'
import { p__tiktok_timezones } from './timezone'
import {
    p__default_state_infor_adgroup,
    p__default_dayparting_adgroup
} from './default-state-infor-adgroup'
import { p__default_state_infor_ad } from './default-state-infor-ad'
import { p__route, p__group_route } from './route'
import {
    p__tiktok_currency,
    p__default_currency_app,
    p__formatCurrency,
    p__renderMoneyByCurrency,
    p__findMinimumDailyBudgetCampaign,
    p__findMaximumDailyBudgetCampaign,
    p__findMinimumDailyBudgetProductSalesCampaign,
    p__findMinimumDailyBudgetAdgroup,
    p__findMaximumDailyBudgetAdgroup,
    p__findMinimumDailyBudgetProductSalesAdgroup
} from './currency'

import {
    p__getNameByTrend,
    p__trend_chart_options_for_audience,
    p__trend_chart_options_2,
    p__trend_basic_chart_options,
    p__trend_basic_chart_options_gmv,
    p__trend_result_chart_options,
    p__trend_real_time_chart_options,
    p__trend_video_play_chart_options,
    p__basic_metrics,
    p__basic_metrics_gmv,
    p__view_data_metric_options,
    p__view_data_metric_options_gmv,
    p__trend_onsite_options
} from './metrics'

export {
    p__tiktok_timezones,
    p__tiktok_currency,
    p__default_currency_app,
    p__formatCurrency,
    p__renderMoneyByCurrency,
    p__findMinimumDailyBudgetCampaign,
    p__findMaximumDailyBudgetCampaign,
    p__findMinimumDailyBudgetProductSalesCampaign,
    p__findMinimumDailyBudgetAdgroup,
    p__findMaximumDailyBudgetAdgroup,
    p__findMinimumDailyBudgetProductSalesAdgroup,
    p__route,
    p__group_route,
    p__default_state_infor_ad,
    p__default_state_infor_adgroup,
    p__default_dayparting_adgroup,
    p__getNameByTrend,
    p__trend_chart_options_for_audience,
    p__trend_chart_options_2,
    p__trend_basic_chart_options,
    p__trend_basic_chart_options_gmv,
    p__trend_result_chart_options,
    p__trend_real_time_chart_options,
    p__trend_video_play_chart_options,
    p__basic_metrics,
    p__basic_metrics_gmv,
    p__view_data_metric_options,
    p__view_data_metric_options_gmv,
    p__trend_onsite_options
}

export const p__renderId = (length = 6) => {
    let result = ''
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    let counter = 0
    while (counter < length) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        )
        counter += 1
    }
    return result
}

export const p__isValidUrl = (string) => {
    try {
        new URL(string)
        return true
    } catch (err) {
        return false
    }
}

export function p__wait(miliseconds) {
    return new Promise((resolve) => {
        setTimeout(resolve, miliseconds)
    })
}

export const p__isObjectNotEmpty = (obj) => {
    return Object.keys(obj).length !== 0
}

export const p__renderDurationToMinute = (duration) => {
    const minutes = Math.floor(duration / 60)
    const seconds = Math.round(duration % 60)
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
        2,
        '0'
    )}`
}

export const p__formatDate = (
    date,
    format = p__format_schedule_tiktok_time,
    hours = 7
) => {
    return moment(date).add(hours, 'hours').format(format)
}

export const p__getRangerDateFromThen = (day) => {
    const end_date = moment().format('YYYY-MM-DD')
    const start_date = moment().subtract(day, 'd').format('YYYY-MM-DD')
    return [start_date, end_date]
}

export const p__event_bus = new Vue()

export const p__showNotify = (type, message) => {
    Notification({
        type,
        message,
        title: i18n.t(`common.${type}`)
    })
}

export const p__showConfirm = (title, message) => {
    return MessageBox.confirm(
        i18n.t(message).toString(),
        i18n.t(title).toString()
    )
}

export function p__removeDuplicateValueInArray(arr) {
    return arr.filter((element, index) => {
        return arr.indexOf(element) === index
    })
}

export function p__removeDuplicatesByProperty(arr, key) {
    const uniqueMap = new Map()

    arr.forEach((item) => {
        uniqueMap.set(item[key], item)
    })

    const uniqueArray = Array.from(uniqueMap.values())

    return uniqueArray
}

export const p__formatLabelOptimizationGoal = (adgroup) => {
    const { optimization_goal } = adgroup
    if (!optimization_goal) {
        return i18n.t('common.unknown')
    }

    if (optimization_goal === 'CLICK') {
        return i18n.t('common.click')
    }
    if (optimization_goal === 'CONVERT') {
        const { optimization_event } = adgroup

        if (optimization_event === 'INITIATE_ORDER') {
            return i18n.t('page.campaign.initiate_checkout')
        }
        if (optimization_event === 'SHOPPING') {
            return i18n.t('page.campaign.complete_payment')
        }
        return i18n.t('common.conversion')
    }
    if (optimization_goal === 'INSTALL') {
        return i18n.t('page.campaign.install')
    }
    if (optimization_goal === 'IN_APP_EVENT') {
        return i18n.t('page.campaign.in_app_event')
    }
    if (optimization_goal === 'SHOW') {
        return i18n.t('common.impression')
    }
    if (optimization_goal === 'VIDEO_VIEW') {
        return i18n.t('page.campaign.video_view')
    }
    if (optimization_goal === 'REACH') {
        return i18n.t('common.reach')
    }
    if (optimization_goal === 'LEAD_GENERATION') {
        return i18n.t('page.campaign.optimization_goal_lead_generation')
    }
    if (optimization_goal === 'FOLLOWERS') {
        return i18n.t('page.campaign.followers')
    }
    if (optimization_goal === 'PROFILE_VIEWS') {
        return i18n.t('page.campaign.profile_views')
    }
    if (optimization_goal === 'MT_LIVE_ROOM') {
        return i18n.t('page.campaign.viewer_retention')
    }
    if (optimization_goal === 'PRODUCT_CLICK_IN_LIVE') {
        return i18n.t('page.campaign.product_click_in_live')
    }
    if (optimization_goal === 'ENGAGED_VIEW') {
        return i18n.t('page.campaign.column_engaged_view')
    }
    if (optimization_goal === 'ENGAGED_VIEW_FIFTEEN') {
        return i18n.t('page.campaign.column_engaged_view_15s')
    }
    if (optimization_goal === 'TRAFFIC_LANDING_PAGE_VIEW') {
        return i18n.t('page.campaign.traffic_landing_page_view')
    }
    if (optimization_goal === 'VALUE') {
        const { deep_bid_type } = adgroup

        if (deep_bid_type === 'VO_HIGHEST_VALUE') {
            return i18n.t('page.campaign.optimization_goal_highest_roas')
        }

        if (deep_bid_type === 'VO_MIN_ROAS') {
            return i18n.t('page.campaign.optimization_goal_minimum_roas')
        }

        return optimization_goal
    }

    return optimization_goal
}

export const p__formatObjectiveType = (objective) => {
    if (objective === 'REACH') {
        return i18n.t('common.reach')
    }
    if (objective === 'TRAFFIC') {
        return i18n.t('common.traffic')
    }
    if (objective === 'VIDEO_VIEWS') {
        return i18n.t('page.campaign.video_views')
    }
    if (objective === 'LEAD_GENERATION') {
        return i18n.t('page.campaign.lead_generation')
    }
    if (objective === 'ENGAGEMENT') {
        return i18n.t('page.campaign.community_interaction')
    }
    if (objective === 'APP_PROMOTION') {
        return i18n.t('page.campaign.app_promotion')
    }
    if (objective === 'WEB_CONVERSIONS') {
        return i18n.t('page.campaign.website_conversions')
    }
    if (objective === 'CATALOG_SALES') {
        return i18n.t('page.campaign.catalog_sales')
    }
    if (objective === 'PRODUCT_SALES') {
        return i18n.t('page.campaign.product_sales')
    }
    if (objective === 'SHOP_PURCHASES') {
        return i18n.t('page.campaign.product_sales')
    }
    if (objective === 'CONVERSIONS') {
        return i18n.t('common.conversion')
    }

    return objective
}

export const p__formatBudgetMode = (mode) => {
    if (mode === 'BUDGET_MODE_DAY') {
        return i18n.t(`common.day`)
    }
    if (mode === 'BUDGET_MODE_DAILY') {
        return i18n.t(`common.daily`)
    }
    if (mode === 'BUDGET_MODE_DYNAMIC_DAILY_BUDGET') {
        return i18n.t(`common.dynamic_daily_budget`)
    }
    if (mode === 'BUDGET_MODE_INFINITE') {
        return i18n.t(`common.budget_mode_infinite`)
    }
    if (mode === 'BUDGET_MODE_TOTAL') {
        return i18n.t(`common.total`)
    }

    return mode
}

export const p__formatPixelEvent = (event) => {
    if (event === 'ADD_BILLING') {
        return i18n.t('page.campaign.pixel_event_add_payment_info')
    }

    if (event === 'BUTTON') {
        return i18n.t('page.campaign.pixel_event_click_button')
    }

    if (event === 'CONSULT') {
        return i18n.t('page.campaign.pixel_event_consult')
    }

    if (event === 'DOWNLOAD_START') {
        return i18n.t('page.campaign.pixel_event_download')
    }

    if (event === 'FORM') {
        return i18n.t('page.campaign.pixel_event_submit_form')
    }

    if (event === 'INITIATE_ORDER') {
        return i18n.t('page.campaign.pixel_event_initiate_checkout')
    }

    if (event === 'ON_WEB_ADD_TO_WISHLIST') {
        return i18n.t('page.campaign.pixel_event_add_to_wishlist')
    }

    if (event === 'ON_WEB_CART') {
        return i18n.t('page.campaign.pixel_event_add_to_cart')
    }

    if (event === 'ON_WEB_DETAIL') {
        return i18n.t('page.campaign.pixel_event_view_content')
    }

    if (event === 'ON_WEB_ORDER') {
        return i18n.t('page.campaign.pixel_event_place_an_order')
    }

    if (event === 'ON_WEB_REGISTER') {
        return i18n.t('page.campaign.pixel_event_complete_registration')
    }

    if (event === 'ON_WEB_SEARCH') {
        return i18n.t('page.campaign.pixel_event_on_search')
    }

    if (event === 'ON_WEB_SUBSCRIBE') {
        return i18n.t('page.campaign.pixel_event_subscribe')
    }

    if (event === 'SHOPPING') {
        return i18n.t('page.campaign.pixel_event_complete_payment')
    }

    return event
}

export const p__formatCallToAction = (cal) => {
    if (cal === 'APPLY_NOW') {
        return i18n.t('page.campaign.call_to_action_apply_now')
    }

    if (cal === 'BOOK_NOW') {
        return i18n.t('page.campaign.call_to_action_book_now')
    }

    if (cal === 'CONTACT_US') {
        return i18n.t('page.campaign.call_to_action_contact_us')
    }

    if (cal === 'DOWNLOAD_NOW') {
        return i18n.t('page.campaign.call_to_action_download_now')
    }

    if (cal === 'EXPERIENCE_NOW') {
        return i18n.t('page.campaign.call_to_action_expertise_now')
    }

    if (cal === 'GET_QUOTE') {
        return i18n.t('page.campaign.call_to_action_get_quote')
    }

    if (cal === 'GET_SHOWTIMES') {
        return i18n.t('page.campaign.call_to_action_get_showtimes')
    }

    if (cal === 'GET_TICKETS_NOW') {
        return i18n.t('page.campaign.call_to_action_get_tickets_now')
    }

    if (cal === 'INSTALL_NOW') {
        return i18n.t('page.campaign.call_to_action_install_now')
    }

    if (cal === 'INTERESTED') {
        return i18n.t('page.campaign.call_to_action_interested')
    }

    if (cal === 'LEARN_MORE') {
        return i18n.t('page.campaign.call_to_action_learn_more')
    }

    if (cal === 'LISTEN_NOW') {
        return i18n.t('page.campaign.call_to_action_listen_now')
    }

    if (cal === 'ORDER_NOW') {
        return i18n.t('page.campaign.call_to_action_order_now')
    }

    if (cal === 'PLAY_GAME') {
        return i18n.t('page.campaign.call_to_action_play_game')
    }

    if (cal === 'PREORDER_NOW') {
        return i18n.t('page.campaign.call_to_action_preorder_now')
    }

    if (cal === 'READ_MORE') {
        return i18n.t('page.campaign.call_to_action_read_more')
    }

    if (cal === 'SHOP_NOW') {
        return i18n.t('page.campaign.call_to_action_shop_now')
    }

    if (cal === 'SIGN_UP') {
        return i18n.t('page.campaign.call_to_action_sign_up')
    }

    if (cal === 'SUBSCRIBE') {
        return i18n.t('page.campaign.call_to_action_subscribe')
    }

    if (cal === 'VIEW_NOW') {
        return i18n.t('page.campaign.call_to_action_view_now')
    }

    // if (cal === 'VIEW_PROFILE') {
    //     return i18n.t('page.campaign.call_to_action_view_profile')
    // }

    if (cal === 'VISIT_STORE') {
        return i18n.t('page.campaign.call_to_action_visit_store')
    }

    // if (cal === 'WATCH_LIVE') {
    //     return i18n.t('page.campaign.call_to_action_watch_live')
    // }

    if (cal === 'WATCH_NOW') {
        return i18n.t('page.campaign.call_to_action_watch_now')
    }

    // if (cal === 'JOIN_THIS_HASHTAG') {
    //     return i18n.t('page.campaign.call_to_action_join_this_hastag')
    // }

    // if (cal === 'SHOOT_WITH_THIS_EFFECT') {
    //     return i18n.t('page.campaign.call_to_action_shoot_with_this_effect')
    // }

    // if (cal === 'VIEW_VIDEO_WITH_THIS_EFFECT') {
    //     return i18n.t(
    //         'page.campaign.call_to_action_view_video_with_this_effect'
    //     )
    // }

    return cal
}

export const p__getArr30days = (ranger_date) => {
    if (ranger_date[0] === ranger_date[1]) {
        return [ranger_date]
    }

    // Chuyển đổi chuỗi thành đối tượng Moment
    const startMoment = moment(ranger_date[0])
    const endMoment = moment(ranger_date[1])

    // Mảng lưu trữ các mảng con của khoảng thời gian
    const result = []

    // Tạo một bản sao của ngày bắt đầu để sử dụng trong vòng lặp
    const currentMoment = startMoment.clone()

    // Lặp qua từng ngày, mỗi lần lặp thêm 30 ngày cho đến khi đạt được ngày kết thúc
    while (currentMoment.isBefore(endMoment)) {
        // Tạo một mảng con mới chứa ngày bắt đầu và ngày cuối của mỗi phần
        const subArray = [currentMoment.format('YYYY-MM-DD')]
        currentMoment.add(30, 'days')
        // Nếu ngày kết thúc của phần lớn hơn ngày kết thúc tổng thì sử dụng ngày kết thúc tổng
        const endDateSub = currentMoment.isAfter(endMoment)
            ? endMoment
            : currentMoment
        subArray.push(endDateSub.format('YYYY-MM-DD'))

        // Thêm mảng con vào mảng chính
        result.push(subArray)

        // Di chuyển ngày bắt đầu sang ngày kết thúc của phần trước
        currentMoment.add(1, 'days')
    }

    return result
}

export const p__calculateMetrics = (arr) => {
    let result = null

    if (arr.length === 0) {
        return result
    }

    arr.forEach((item) => {
        if (result) {
            Object.keys(item).forEach((key) => {
                result[key] = Number(result[key]) + Number(item[key])
            })
        } else {
            result = { ...item }
        }
    })

    Object.keys(result).forEach((key) => {
        if (key === 'cpc') {
            if (result.clicks > 0) {
                result[key] = (result.spend / result.clicks).toFixed(2)
            } else {
                result[key] = 0
            }
        }
        if (key === 'cost_per_conversion') {
            if (result.conversion > 0) {
                result[key] = (result.spend / result.conversion).toFixed(2)
            }
        }
        if (key === 'cpm') {
            if (result.impressions > 1_000) {
                result[key] = (
                    result.spend /
                    (result.impressions / 1_000)
                ).toFixed(2)
            }
        }
        if (key === 'ctr') {
            if (result.impressions > 0) {
                result[key] = (
                    (result.clicks / result.impressions) *
                    100
                ).toFixed(2)
            } else {
                result[key] = 0
            }
        }
        if (key === 'conversion_rate') {
            if (result.clicks > 0) {
                result[key] = (
                    (result.conversion / result.clicks) *
                    100
                ).toFixed(2)
            } else {
                result[key] = 0
            }
        }
    })

    return result
}

export const p__formatNumberHasCommas = (number) => {
    const temp = +number
    if (temp >= 1000) {
        return `${number.toString().split('.')[0]}`.replace(
            /(\d)(?=(\d\d\d)+(?!\d))/g,
            '$1.'
        )
    }

    return temp
}

export const p__sortArrByName = (arr, property = 'name') => {
    return arr.sort((a, b) =>
        a[property].toLowerCase().localeCompare(b[property].toLowerCase())
    )
}

export const p__swapItemInArrayByIndex = (
    arr,
    property,
    value,
    newIndex = 0
) => {
    const oldIndex = arr.findIndex((item) => item[property] === value)

    if (oldIndex > 0) {
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
    }

    return arr
}

export async function p__OpenLinkOnNewWindow(url) {
    return new Promise((resolve) => {
        window.callback = (data) => {
            resolve(data)
        }

        const left = window.innerWidth / 2 - 1200 / 2
        const top = window.innerHeight / 2 - 900 / 2
        const features =
            'width=1200,height=900,scrollbars=yes,menubar=no,toolbar=no,location=no,status=yes,titlebar=no,left=' +
            left +
            ',top=' +
            top
        const popupWindow = window.open(url, '', features)
        popupWindow.focus()
        const handleWindowClose = setInterval(() => {
            if (popupWindow.closed) {
                clearInterval(handleWindowClose)
                resolve(popupWindow)
            }
        }, 500)
    })
}

export async function p__CopyText(text) {
    try {
        await navigator.clipboard.writeText(text)
        Message({
            message: i18n.t('common.copied'),
            type: 'success',
            duration: 4000
        })
    } catch (e) {
        console.error(e)
    }
}

export async function p__exportExcelByBlob(blob, filename) {
    try {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${filename}.xlsx`)
        document.body.appendChild(link)
        link.click()
        link.remove()
    } catch (e) {
        console.error(e)
    }
}

export function p__formatMessageFromAtosaCore(message) {
    if (
        message ===
        'Định dạng email không hợp lệ, vui lòng kiểm tra va thử lại sau'
    ) {
        return i18n.t(`atosa_core.message.valid_email`)
    }

    if (
        message ===
        'Định dạng số điện thoại không hợp lệ,   vui lòng kiểm tra và thử lại sau!'
    ) {
        return i18n.t(`atosa_core.message.valid_phone`)
    }

    if (
        message ===
        'Địa chỉ email đã được sử dụng để đăng ký trước đó, vui lòng kiểm tra và thử lại sau!'
    ) {
        return i18n.t(`atosa_core.message.email_has_registered`)
    }

    if (
        message ===
        'Tài khoản hoặc mật khẩu không đúng, vui lòng kiểm tra và thử lại sau!'
    ) {
        return i18n.t(`atosa_core.message.password_wrong`)
    }

    return message
}

// -------------------------------------- VALUES --------------------------------
export const p__field_separator_template_name_options = [
    '_',
    '__',
    '-',
    '--',
    '---',
    '|',
    '/',
    '.',
    '+',
    '~',
    '*'
]
export const p__time_template_name_options = [
    {
        value: 'HH:mm DD-MM-YYYY',
        label: 'HH:mm DD-MM-YYYY'
    },
    {
        value: 'YYYYMMDDHHmm',
        label: 'YYYYMMDDHHmm'
    },
    {
        value: 'YYYY-MM-DD HH:mm',
        label: 'YYYY-MM-DD HH:mm'
    },
    {
        value: 'YYYYMMDD',
        label: 'YYYYMMDD'
    },
    {
        value: 'DDMMYYYY',
        label: 'DDMMYYYY'
    },
    {
        value: 'DD-MM-YYYY',
        label: 'DD-MM-YYYY'
    },
    {
        value: 'YYYY-MM-DD',
        label: 'YYYY-MM-DD'
    }
]

export const p__format_schedule_tiktok_time = 'YYYY-MM-DD HH:mm'

export const p__number_max_products_in_product_shopping_ads = 50

export const p__number_max_copy_adgroup = 100

export const p__number_max_products_in_video_shopping_ads = 20

export const p__seo_keywords = [
    i18n.t('page.login.seo_keyword_1'),
    i18n.t('page.login.seo_keyword_2'),
    i18n.t('page.login.seo_keyword_3'),
    i18n.t('page.login.seo_keyword_4'),
    i18n.t('page.login.seo_keyword_5'),
    i18n.t('page.login.seo_keyword_6'),
    i18n.t('page.login.seo_keyword_7'),
    i18n.t('page.login.seo_keyword_8'),
    i18n.t('page.login.seo_keyword_9')
]

export const p__default_identity_image_uri =
    'https://c1.alamy.com/thumbs/2e2fxe8/tiktok-logo-symbol-icon-over-white-background-silhouette-design-vector-illustration-2e2fxe8.jpg'

export const p__budget_mode_options = [
    {
        value: 'BUDGET_MODE_DAY',
        label: i18n.t(`common.daily_budget`)
    },
    {
        value: 'BUDGET_MODE_TOTAL',
        label: i18n.t(`common.lifetime_budget`)
    }
]

export const p__age_options = [
    {
        label: '13-17',
        value: 'AGE_13_17'
    },
    {
        label: '18-24',
        value: 'AGE_18_24'
    },
    {
        label: '24-34',
        value: 'AGE_25_34'
    },
    {
        label: '35-44',
        value: 'AGE_35_44'
    },
    {
        label: '45-54',
        value: 'AGE_45_54'
    },
    {
        label: '55+',
        value: 'AGE_55_100'
    }
]

export const p__gender_options = [
    {
        label: i18n.t('common.all'),
        value: 'GENDER_UNLIMITED'
    },
    {
        label: i18n.t('common.male'),
        value: 'GENDER_MALE'
    },
    {
        label: i18n.t('common.female'),
        value: 'GENDER_FEMALE'
    }
]

export const p__list_ads_objectives = [
    {
        title: i18n.t('page.campaign.awareness'),
        items: [
            {
                title: i18n.t('common.reach'),
                description: i18n.t('page.campaign.desc_reach'),
                value: 'REACH',
                icon: 'fa-regular fa-flag'
            }
        ]
    },
    {
        title: i18n.t('page.campaign.consideration'),
        items: [
            {
                title: i18n.t('common.traffic'),
                description: i18n.t('page.campaign.desc_traffic'),
                value: 'TRAFFIC',
                icon: 'fa-solid fa-arrow-pointer'
            },
            {
                title: i18n.t('page.campaign.video_views'),
                description: i18n.t('page.campaign.desc_video_views'),
                value: 'VIDEO_VIEWS',
                icon: 'fa-solid fa-film'
            },
            // {
            //     title: i18n.t('page.campaign.lead_generation'),
            //     description: i18n.t(
            //         'page.campaign.desc_lead_generation'
            //     ),
            //     value: 'LEAD_GENERATION',
            //     icon: 'fa-solid fa-circle-nodes'
            // },
            {
                title: i18n.t('page.campaign.community_interaction'),
                description: i18n.t('page.campaign.desc_community_interaction'),
                value: 'ENGAGEMENT',
                icon: 'fa-solid fa-shuffle'
            }
        ]
    },
    {
        title: i18n.t('common.conversion'),
        items: [
            {
                title: i18n.t('page.campaign.app_promotion'),
                description: i18n.t('page.campaign.desc_app_promotion'),
                value: 'APP_PROMOTION',
                icon: 'fa-regular fa-square'
            },
            {
                title: i18n.t('page.campaign.website_conversions'),
                description: i18n.t('page.campaign.desc_website_conversions'),
                value: 'WEB_CONVERSIONS',
                icon: 'fa-solid fa-chart-line'
            },
            // {
            //     title: i18n.t('page.campaign.catalog_sales'),
            //     description: i18n.t('page.campaign.desc_catalog_sales'),
            //     value: 'CATALOG_SALES',
            //     icon: 'fa-solid fa-cart-shopping'
            // },
            {
                title: i18n.t('page.campaign.PRODUCT_SALES'),
                description: i18n.t('page.campaign.desc_PRODUCT_SALES'),
                value: 'PRODUCT_SALES',
                icon: 'fa-solid fa-shop'
            }
        ]
    }
]

export const p__call_to_action_options = [
    {
        value: 'APPLY_NOW',
        label: i18n.t('page.campaign.call_to_action_apply_now')
    },
    {
        value: 'BOOK_NOW',
        label: i18n.t('page.campaign.call_to_action_book_now')
    },
    {
        value: 'CONTACT_US',
        label: i18n.t('page.campaign.call_to_action_contact_us')
    },
    {
        value: 'DOWNLOAD_NOW',
        label: i18n.t('page.campaign.call_to_action_download_now')
    },
    {
        value: 'EXPERIENCE_NOW',
        label: i18n.t('page.campaign.call_to_action_experience_now')
    },
    {
        value: 'GET_QUOTE',
        label: i18n.t('page.campaign.call_to_action_get_quote')
    },
    {
        value: 'GET_SHOWTIMES',
        label: i18n.t('page.campaign.call_to_action_get_showtimes')
    },
    {
        value: 'GET_TICKETS_NOW',
        label: i18n.t('page.campaign.call_to_action_get_tickets_now')
    },
    {
        value: 'INSTALL_NOW',
        label: i18n.t('page.campaign.call_to_action_install_now')
    },
    {
        value: 'INTERESTED',
        label: i18n.t('page.campaign.call_to_action_interested')
    },
    {
        value: 'LEARN_MORE',
        label: i18n.t('page.campaign.call_to_action_learn_more')
    },
    {
        value: 'LISTEN_NOW',
        label: i18n.t('page.campaign.call_to_action_listen_now')
    },
    {
        value: 'ORDER_NOW',
        label: i18n.t('page.campaign.call_to_action_order_now')
    },
    {
        value: 'PLAY_GAME',
        label: i18n.t('page.campaign.call_to_action_play_game')
    },
    {
        value: 'PREORDER_NOW',
        label: i18n.t('page.campaign.call_to_action_preorder_now')
    },
    {
        value: 'READ_MORE',
        label: i18n.t('page.campaign.call_to_action_read_more')
    },
    {
        value: 'SHOP_NOW',
        label: i18n.t('page.campaign.call_to_action_shop_now')
    },
    {
        value: 'SIGN_UP',
        label: i18n.t('page.campaign.call_to_action_sign_up')
    },
    {
        value: 'SUBSCRIBE',
        label: i18n.t('page.campaign.call_to_action_subscribe')
    },
    {
        value: 'VIEW_NOW',
        label: i18n.t('page.campaign.call_to_action_view_now')
    },
    // {
    //     value: 'VIEW_PROFILE',
    //     label: i18n.t('page.campaign.call_to_action_view_profile')
    // },
    {
        value: 'VISIT_STORE',
        label: i18n.t('page.campaign.call_to_action_visit_store')
    },
    // {
    //     value: 'WATCH_LIVE',
    //     label: i18n.t('page.campaign.call_to_action_watch_live')
    // },
    {
        value: 'WATCH_NOW',
        label: i18n.t('page.campaign.call_to_action_watch_now')
    }
    // {
    //     value: 'JOIN_THIS_HASHTAG',
    //     label: i18n.t('page.campaign.call_to_action_join_this_hastag')
    // },
    // {
    //     value: 'SHOOT_WITH_THIS_EFFECT',
    //     label: i18n.t('page.campaign.call_to_action_shoot_with_this_effect')
    // },
    // {
    //     value: 'VIEW_VIDEO_WITH_THIS_EFFECT',
    //     label: i18n.t(
    //         'page.campaign.call_to_action_view_video_with_this_effect'
    //     )
    // }
]

export const p__list_select_languages = [
    {
        code: 'vi',
        image: require('@/assets/images/flags/vn.gif'),
        description: 'Việt Nam'
    },
    {
        code: 'en',
        image: require('@/assets/images/flags/en.gif'),
        description: 'English'
    }
]

export const p__list_advertiser_status = {
    STATUS_DELETE: 'STATUS_DELETE',
    STATUS_DELIVERY_OK: 'STATUS_DELIVERY_OK',
    STATUS_DISABLE: 'STATUS_DISABLE',
    STATUS_NOT_DELIVERY: 'STATUS_NOT_DELIVERY',
    STATUS_TIME_DONE: 'STATUS_TIME_DONE',
    STATUS_RF_CLOSED: 'STATUS_RF_CLOSED',
    STATUS_FROZEN: 'STATUS_FROZEN',
    STATUS_ALL: 'STATUS_ALL',
    STATUS_NOT_DELETE: 'STATUS_NOT_DELETE'
}
