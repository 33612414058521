const state = {
    s__current_package: null,
    s__main_package: null,
    s__custom_package: null
}

const mutations = {
    s__setCurrentPackage(state, data) {
        state.s__current_package = data
        state.s__main_package = data.license.find((item) => item.is_extra)
        state.s__custom_package = data.license.find((item) => !item.is_extra)
    }
}

const actions = {
    s__setCurrentPackage(context, data) {
        context.commit('s__setCurrentPackage', data)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
