const globalMixin = {
    data() {
        return {
            m__loading: false
        }
    },

    mounted() {
        this.changeIconInputNumber()
    },

    methods: {
        changeIconInputNumber() {
            try {
                const left_icon_list = document.querySelectorAll(
                    '.el-input-number__decrease i'
                )
                for (const item of left_icon_list) {
                    item.className = 'el-icon-arrow-left'
                }

                const right_icon_list = document.querySelectorAll(
                    '.el-input-number__increase i'
                )
                for (const item of right_icon_list) {
                    item.className = 'el-icon-arrow-right'
                }
            } catch (e) {
                console.error(e)
            }
        }
    }
}

export default globalMixin
