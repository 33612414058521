import request from './_config'

export function login(data) {
    return request({
        url: 'shop-manager/sign',
        method: 'post',
        data: { ...data, version_no: 'atosa-client' }
    })
}

export function getInfo() {
    return request({
        url: 'shop-manager/me',
        method: 'get'
    })
}

export function register(data) {
    return request({
        url: 'input/sign/register',
        method: 'post',
        data: data
    })
}

export function forgetPassword(data) {
    return request({
        url: 'input/sign/forgot-password',
        method: 'post',
        data: data
    })
}

export function verifyTokenForgetPassword(token) {
    return request({
        url: 'input/sign/forgot-password/get',
        method: 'get',
        params: {
            token
        }
    })
}

export function resetPassword(data) {
    return request({
        url: 'input/sign/forgot-password/set',
        method: 'post',
        data
    })
}

export function changePassword(data) {
    return request({
        url: 'store-account/change-password',
        method: 'post',
        data: {
            current_password: data.current_password,
            new_password: data.new_password,
            confirm_password: data.confirm_password
        }
    })
}
