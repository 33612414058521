import i18n from '@/plugins/language'

const m__list_pagesize = [
    {
        label: `30/${i18n.t('common.page')}`,
        value: 30
    },
    {
        label: `50/${i18n.t('common.page')}`,
        value: 50
    },
    {
        label: `100/${i18n.t('common.page')}`,
        value: 100
    }
]

const paginationDataMixin = {
    data() {
        return {
            m__data_for_pagination: [],
            m__list_pagesize,
            m__page_info: {
                page: 1,
                page_size: m__list_pagesize[0].value,
                total_number: 0,
                total_page: 1
            }
        }
    },

    methods: {
        m__handleChangePagesize(cb) {
            this.m__page_info.page = 1
            cb()
        }
    }
}

export default paginationDataMixin
