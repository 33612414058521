import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import i18n from '@/plugins/language'
import router from '@/router'
import store from './store'
import './permission' // permission control
import '@/plugins/prototypes'
import '@/components'
import * as filters from '@/plugins/filters' // global filters
import * as prototypes from '@/plugins/prototypes' // global filters
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import globalMxin from '@/plugins/mixins/global'
import elSelectLoadmore from '@/plugins/directives/el-select-loadmore'
// import VueShepherdPlugin from 'vue-shepherd'
import VueMeta from 'vue-meta'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import ExportingHighcharts from 'highcharts/modules/exporting'

ExportingHighcharts(Highcharts)
Vue.use(HighchartsVue)

import 'shepherd.js/dist/css/shepherd.css'
import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css'
import '@/assets/styles/variables.scss'
import '@/assets/styles/tailwind.css'
import '@/assets/styles/custom-element-ui.scss'
import '@/assets/styles/custom-nprocess.scss'
import '@/assets/styles/custom-shepherd.scss'
import 'nprogress/nprogress.css' // progress bar style
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import '@/assets/styles/custom-treeselect.scss'
import '@/assets/styles/custom-hightchart.scss'

Vue.use(ElementUI, {
    i18n: (key, value) => i18n.t(key, value)
})

Object.keys(filters).forEach((key) => {
    Vue.filter(key, filters[key])
})

Object.keys(prototypes).forEach((key) => {
    Vue.prototype[key] = prototypes[key]
})

library.add(fas)
library.add(far)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.mixin(globalMxin)

Vue.directive('el-select-loadmore', elSelectLoadmore)

Vue.config.productionTip = false

Vue.use(VueMeta)
Vue.use(HighchartsVue)

// Vue.use(VueShepherdPlugin).mount('#app')

new Vue({
    el: '#app',
    i18n,
    router,
    render: (h) => h(App),
    store
}).$mount('#app')
