<template>
    <div class="component-box">
        <p class="font-semibold mb-8">
            {{ $t('page.campaign.placements') }}
        </p>

        <div class="flex items-center space-x-2">
            <el-switch
                :value="!selected_item_in_line.comment_disabled"
                @change="
                    (val) => (selected_item_in_line.comment_disabled = !val)
                "
            >
            </el-switch>
            <p class="text-xs">
                {{ $t('page.campaign.user_comment') }}
            </p>
        </div>
        <p class="component-text-desc">
            {{ $t('page.campaign.desc_user_comment') }}
            <a
                class="component-tag-anchor"
                target="_blank"
                href="https://ads.tiktok.com/help/article/manage-comments-tiktok-ads-manager?redirected=1"
            >
                {{ $t('page.campaign.manage_comment') }}
            </a>
        </p>
        <div class="flex items-center space-x-2 mt-6">
            <el-switch
                :value="!selected_item_in_line.video_download_disabled"
                :disabled="type_form !== 'create'"
                @change="
                    (val) =>
                        (selected_item_in_line.video_download_disabled = !val)
                "
            >
            </el-switch>

            <p class="text-xs">
                {{ $t('page.campaign.video_download') }}
            </p>

            <el-tooltip
                class="item"
                effect="dark"
                :content="$t('page.campaign.desc_video_download')"
                placement="top"
            >
                <i class="header-icon el-icon-info text-desc-text"></i>
            </el-tooltip>
        </div>

        <div
            v-if="
                ['CATALOG_SALES', 'PRODUCT_SALES'].every(
                    (item) => item !== objective_type
                )
            "
            class="flex items-center space-x-2 mt-6"
        >
            <el-switch
                :value="!selected_item_in_line.share_disabled"
                @change="(val) => (selected_item_in_line.share_disabled = !val)"
            >
            </el-switch>

            <p class="text-xs">
                {{ $t('page.campaign.video_sharing') }}
            </p>

            <el-tooltip
                class="item"
                :content="$t('page.campaign.desc_video_sharing')"
                effect="dark"
                placement="top"
            >
                <i class="header-icon el-icon-info text-desc-text"></i>
            </el-tooltip>
        </div>

        <!-- <div class="flex space-x-3 items-center">
            <img
                class="w-12 h-12"
                :src="require('@/assets/images/logo_tiktok.png')"
            />
            <p class="text-sm">Tiktok</p>
        </div>

        <el-collapse
            v-model="toggle_collapse"
            class="mt-6 custom-primary"
            accordion
        >
            <el-collapse-item name="1">
                <template slot="title">
                    <p>
                        {{ $t('common.advanced_settings') }}
                    </p>
                </template>
                <div class="flex items-center space-x-2">
                    <el-switch
                        :value="!selected_item_in_line.comment_disabled"
                        @change="
                            (val) =>
                                (selected_item_in_line.comment_disabled = !val)
                        "
                    >
                    </el-switch>
                    <p class="text-xs">
                        {{ $t('page.campaign.user_comment') }}
                    </p>
                </div>
                <p class="component-text-desc">
                    {{ $t('page.campaign.desc_user_comment') }}
                    <a
                        class="component-tag-anchor"
                        target="_blank"
                        href="https://ads.tiktok.com/help/article/manage-comments-tiktok-ads-manager?redirected=1"
                    >
                        {{ $t('page.campaign.manage_comment') }}
                    </a>
                </p>
                <div class="flex items-center space-x-2 mt-6">
                    <el-switch
                        :value="!selected_item_in_line.video_download_disabled"
                        :disabled="type_form !== 'create'"
                        @change="
                            (val) =>
                                (selected_item_in_line.video_download_disabled =
                                    !val)
                        "
                    >
                    </el-switch>

                    <p class="text-xs">
                        {{ $t('page.campaign.video_download') }}
                    </p>

                    <el-tooltip
                        class="item"
                        effect="dark"
                        :content="$t('page.campaign.desc_video_download')"
                        placement="top"
                    >
                        <i class="header-icon el-icon-info text-desc-text"></i>
                    </el-tooltip>
                </div>

                <div
                    v-if="
                        ['CATALOG_SALES', 'PRODUCT_SALES'].every(
                            (item) => item !== objective_type
                        )
                    "
                    class="flex items-center space-x-2 mt-6"
                >
                    <el-switch
                        :value="!selected_item_in_line.share_disabled"
                        @change="
                            (val) =>
                                (selected_item_in_line.share_disabled = !val)
                        "
                    >
                    </el-switch>

                    <p class="text-xs">
                        {{ $t('page.campaign.video_sharing') }}
                    </p>

                    <el-tooltip
                        class="item"
                        :content="$t('page.campaign.desc_video_sharing')"
                        effect="dark"
                        placement="top"
                    >
                        <i class="header-icon el-icon-info text-desc-text"></i>
                    </el-tooltip>
                </div>
            </el-collapse-item>
        </el-collapse> -->
    </div>
</template>

<script>
export default {
    props: ['selected_item', 'type_form', 'objective_type'],

    data() {
        return {
            toggle_collapse: ['1']
        }
    },

    computed: {
        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        }
    }
}
</script>
