<template>
    <div class="flex h-auto space-x-4">
        <div class="max-w-[680px] w-auto flex flex-col space-y-4">
            <Name
                :type_form="type_form"
                :selected_item.sync="selected_item_in_line"
            ></Name>

            <App
                v-if="objective_type === 'APP_PROMOTION'"
                :tiktok_account_id="tiktok_account_id"
                :bc_id="bc_id"
                :advertiser_id="advertiser_id"
                :selected_item.sync="selected_item_in_line"
            ></App>

            <Website
                v-if="objective_type === 'WEB_CONVERSIONS'"
                :tiktok_account_id="tiktok_account_id"
                :advertiser_id="advertiser_id"
                :objective_type="objective_type"
                :selected_item.sync="selected_item_in_line"
            ></Website>

            <ShopSettings
                v-if="show_shop_settings"
                :selected_item.sync="selected_item_in_line"
                :tiktok_account_id="tiktok_account_id"
                :belong_to_atosa="belong_to_atosa"
                :type_form="type_form"
                :advertiser_id="advertiser_id"
                :bc_id="bc_id"
            ></ShopSettings>

            <Products
                v-if="objective_type === 'CATALOG_SALES'"
                :tiktok_account_id="tiktok_account_id"
                :bc_id="bc_id"
                :advertiser_id="advertiser_id"
                :selected_item.sync="selected_item_in_line"
            ></Products>

            <PromotionType
                v-if="show_promotion_type"
                :selected_item.sync="selected_item_in_line"
                :tiktok_account_id="tiktok_account_id"
                :advertiser_id="advertiser_id"
            ></PromotionType>

            <Placements
                v-if="
                    selected_item_in_line.shopping_ads_type !==
                    'PRODUCT_SHOPPING_ADS'
                "
                :type_form="type_form"
                :objective_type="objective_type"
                :selected_item.sync="selected_item_in_line"
            ></Placements>

            <Targeting
                :type_form="type_form"
                :tiktok_account_id="tiktok_account_id"
                :objective_type="objective_type"
                :advertiser_id="advertiser_id"
                :selected_item.sync="selected_item_in_line"
                :bc_id="bc_id"
                :belong_to_atosa="belong_to_atosa"
            ></Targeting>

            <!--
            <ContentExclusions
                v-if="show_content_exclusions"
                :type_form="type_form"
                :selected_item.sync="selected_item_in_line"
            ></ContentExclusions> -->

            <BudgetSchedule
                :type_form="type_form"
                :min_budget_adgroup="min_budget_adgroup"
                :currency="currency"
                :timezone="timezone"
                :selected_item.sync="selected_item_in_line"
                :tiktok_account_id="tiktok_account_id"
                :advertiser_id="advertiser_id"
            ></BudgetSchedule>

            <BiddingOptimization
                :type_form="type_form"
                :tiktok_account_id="tiktok_account_id"
                :currency="currency"
                :advertiser_id="advertiser_id"
                :objective_type="objective_type"
                :selected_item.sync="selected_item_in_line"
                @openGetCPA="emit_openGetCPA"
            ></BiddingOptimization>

            <div class="component-box invisible !-mt-4"></div>
        </div>

        <!-- <AvailableAudience
        ></AvailableAudience> -->
    </div>
</template>

<script>
import { getAudienceSize } from '@/services/atosa-tiktok-ads/adgroup'
import Placements from './placements'
import App from './app'
import Targeting from './targeting'
import Products from './products'
import Website from './website'
import BiddingOptimization from './bidding-optimization'
import PromotionType from './promotion-type'
import BudgetSchedule from './budget-schedule'
import ShopSettings from './shop-settings'
import ContentExclusions from './content-exclusions'
import moment from 'moment'
import EditAdgroupFormMixin from '@/components/orgranisms/edit-adgroup-form/_mixin'
import Name from './name'

export default {
    name: 'EditAdGroupForm',

    components: {
        App,
        Placements,
        PromotionType,
        BiddingOptimization,
        BudgetSchedule,
        ShopSettings,
        // ContentExclusions,
        Products,
        Website,
        // AvailableAudience,
        Targeting,
        Name
    },

    mixins: [EditAdgroupFormMixin],

    props: [
        'min_budget_adgroup',
        'belong_to_atosa',
        'selected_item',
        'advertiser_id',
        'currency',
        'timezone',
        'tiktok_account_id',
        'bc_id',
        'catalog_id',
        'objective_type',
        'type_form' //  edit , create , copy,
    ],

    computed: {
        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        },

        show_shop_settings() {
            return ['PRODUCT_SALES'].some(
                (type) => type === this.objective_type
            )
        },

        show_promotion_type() {
            return ['TRAFFIC'].some((type) => type === this.objective_type)
        },

        show_content_exclusions() {
            return ['REACH', 'VIDEO_VIEWS', 'ENGAGEMENT'].some(
                (type) => type === this.objective_type
            )
        }
    },

    mounted() {
        if (this.type_form !== 'edit') {
            this.m__changeScheduleTime(
                this.selected_item_in_line,
                this.objective_type
            )
        }
    },
    methods: {
        emit_openGetCPA() {
            this.$emit('openGetCPA')
        }
    }
}
</script>
