<template>
    <div v-show="audience_size" class="component-box h-fit">
        <p class="component-label">
            {{ $t('page.campaign.available_audience') }}
        </p>

        <div class="flex items-center space-x-2">
            <el-tag size="small"> {{ evaluation_for_audience }} </el-tag>

            <p class="text-xs font-semibold">
                {{ estimated }}
            </p>
        </div>
    </div>
</template>

<script>
import { getAudienceSize } from '@/services/atosa-tiktok-ads/adgroup'
import EditAdgroupFormMixin from '@/components/orgranisms/edit-adgroup-form/_mixin'

export default {
    mixins: [EditAdgroupFormMixin],

    props: [
        'advertiser_id',
        'selected_item',
        'objective_type',
        'tiktok_account_id'
    ],

    data() {
        return {
            audience_size: null
        }
    },

    computed: {
        evaluation_for_audience() {
            if (!this.audience_size) {
                return ''
            }
            if (this.audience_size.user_count_stage === 1) {
                return 'Too Narrow'
            }
            if (this.audience_size.user_count_stage === 2) {
                return 'Narrow'
            }
            if (this.audience_size.user_count_stage === 3) {
                return 'Balanced'
            }
            if (this.audience_size.user_count_stage === 4) {
                return 'Fairly Broad'
            }

            return ''
        },

        estimated() {
            if (!this.audience_size) {
                return ''
            }
            if (this.audience_size.user_count_stage === 1) {
                return 'N/A'
            }
            return `${this.p__formatNumberHasCommas(
                this.audience_size.user_count.lower_end
            )} - ${this.p__formatNumberHasCommas(
                this.audience_size.user_count.upper_end
            )}`
        }
    },

    watch: {
        'selected_item.id'() {
            this.fetchAudienceSize()
        },

        'selected_item.product_source'() {
            this.fetchAudienceSize()
        },

        'selected_item.promotion_type'() {
            this.fetchAudienceSize()
        },

        'selected_item.location_ids'() {
            this.fetchAudienceSize()
        },

        'selected_item.gender'() {
            this.fetchAudienceSize()
        },

        'selected_item.age_groups'() {
            this.fetchAudienceSize()
        },

        'selected_item.languages'() {
            this.fetchAudienceSize()
        },

        'selected_item.interest_keyword_ids'() {
            this.fetchAudienceSize()
        },

        'selected_item.interest_category_ids'() {
            this.fetchAudienceSize()
        },

        'selected_item.actions'() {
            this.fetchAudienceSize()
        },

        'selected_item.operating_systems'() {
            this.fetchAudienceSize()
        },

        'selected_item.device_model_ids'() {
            this.fetchAudienceSize()
        },

        'selected_item.network_types'() {
            this.fetchAudienceSize()
        },

        'selected_item.device_price_ranges'() {
            this.fetchAudienceSize()
        },

        'selected_item.carrier_ids'() {
            this.fetchAudienceSize()
        },

        'selected_item.isp_ids'() {
            this.fetchAudienceSize()
        },

        'selected_item.audience_ids'() {
            this.fetchAudienceSize()
        },

        'selected_item.excluded_audience_ids'() {
            this.fetchAudienceSize()
        },

        'selected_item.min_ios_version'() {
            this.fetchAudienceSize()
        },

        'selected_item.min_android_version'() {
            this.fetchAudienceSize()
        },

        objective_type() {
            this.fetchAudienceSize()
        }
    },

    methods: {
        handleSetAudienceSize(temp) {
            this.audience_size = temp
        },

        async fetchAudienceSize() {
            if (!this.objective_type) {
                return
            }

            if (!this.selected_item) {
                return
            }

            if (!this.advertiser_id) {
                return
            }

            if (this.selected_item.location_ids.length === 0) {
                this.audience_size = null

                return
            }

            let age_groups = []
            if (
                this.selected_item.age_groups &&
                this.selected_item.age_groups[0] !== 'ALL'
            ) {
                age_groups = this.selected_item.age_groups
            }

            let network_types = []
            if (
                this.selected_item.network_types &&
                this.selected_item.network_types[0] !== 'ALL'
            ) {
                network_types = this.selected_item.network_types
            }

            const temp = {
                advertiser_id: this.advertiser_id,
                placements: this.selected_item.placements,
                placement_type: this.selected_item.placement_type,
                objective_type: this.objective_type,
                product_source: this.selected_item.product_source,
                promotion_type: this.selected_item.promotion_type,
                optimization_goal: this.selected_item.optimization_goal,
                location_ids: this.selected_item.location_ids,
                gender: this.selected_item.gender,
                languages: this.selected_item.languages,
                interest_keyword_ids: this.selected_item.interest_keyword_ids,
                interest_category_ids: this.selected_item.interest_category_ids,
                actions: this.selected_item.actions,
                operating_systems: this.selected_item.operating_systems,
                device_model_ids: this.selected_item.device_model_ids,
                network_types,
                device_price_ranges: this.selected_item.device_price_ranges,
                carrier_ids: this.selected_item.carrier_ids,
                isp_ids: this.selected_item.isp_ids,
                audience_ids: this.selected_item.audience_ids,
                excluded_audience_ids: this.selected_item.excluded_audience_ids,
                min_ios_version: this.selected_item.min_ios_version,
                min_android_version: this.selected_item.min_android_version,
                age_groups
            }

            Object.keys(temp).forEach((k) => {
                if (Array.isArray(temp[k]) && !temp[k].length) {
                    delete temp[k]
                }
                if (temp[k] === '') {
                    delete temp[k]
                }

                if (temp[k] == null) {
                    delete temp[k]
                }
            })

            this.m__changeActionCategories(temp)

            if (temp?.actions?.length > 0) {
                temp.actions = temp.actions.filter(
                    (item) =>
                        item.action_category_ids.length > 0 &&
                        item.video_user_actions.length > 0
                )
            }

            const response = await getAudienceSize(this.tiktok_account_id, temp)

            if (response.code === 0) {
                this.audience_size = response.data
            }
        }
    }
}
</script>
