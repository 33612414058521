import request from './_config'

const path = `ads_accounts`

export function getAdvertiserAccounts(tiktok_account_id, bc_id) {
    return request({
        url: `${path}/${tiktok_account_id}?${bc_id ? `bc_id=${bc_id}` : ``}`,
        method: 'get'
    })
}

export function getAdvertiserAccountDetail(tiktok_account_id, ad_account_ids) {
    return request({
        url: `${path}/detail/${tiktok_account_id}`,
        method: 'get',
        params: { ad_account_ids: JSON.stringify(ad_account_ids) }
    })
}

export function getAdAccountBalance(
    tiktok_account_id,
    bc_id,
    page,
    page_size,
    keyword = ''
) {
    return request({
        url: `${path}/detail/${tiktok_account_id}`,
        method: 'get',
        params: { bc_id, page, page_size, keyword }
    })
}

export function getAdAccountByBC(tiktok_account_id, params) {
    return request({
        url: `${path}/by_bc/${tiktok_account_id}`,
        method: 'get',
        params: { bc_id: params.bc_id }
    })
}
